import { Doctor } from '@/@types';
import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { useCallback, useMemo } from 'react';
import { TextLayout } from '../text';
import { CareTeamTable } from './careTeamTable';

type Props = {
  isVisible?: boolean;
  setIsVisible: (isVisible: boolean) => void;
  doctor: Doctor;
};

export function CareTeamAboutMobile({ isVisible = true, setIsVisible, doctor }: Props) {
  const hasAwards = useMemo(() => doctor.awards && doctor.awards.length > 0, [doctor.awards]);
  const hasCredentials = useMemo(
    () => doctor.credentials && doctor.credentials.length > 0,
    [doctor.credentials]
  );
  const hasAffiliations = useMemo(
    () => doctor.affiliations && doctor.affiliations.length > 0,
    [doctor.affiliations]
  );

  const tableColumns = useMemo(() => {
    const credentials = doctor?.credentials.map((credential) => [
      credential.title,
      credential.description,
    ]);

    return credentials;
  }, [doctor]);

  const tableAwards = useMemo(() => {
    const awards = doctor?.awards.map((award) => [award.title, award.description]);
    return awards;
  }, [doctor]);

  const tableAffiliations = useMemo(() => {
    const affiliations = doctor?.affiliations.map((affiliation) => [
      affiliation.title,
      affiliation.description,
    ]);

    return affiliations;
  }, [doctor]);

  const text = useMemo(() => {
    const processedText = doctor.description.reduce((acc, word, index, array) => {
      const separator = index < array.length - 1 ? '<br /><br />' : '';
      return `${acc}${word}${separator}`;
    });
    return processedText;
  }, [doctor.description]);

  const onClose = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial='closed'
          animate='open'
          exit='closed'
          variants={{
            open: { opacity: 1 },
            closed: { opacity: 0 },
          }}
          transition={{ duration: 0.9, ease: cubicBezier(0.65, 0, 0.35, 1) }}
          className='absolute inset-0 bg-product-sand-100/50 text-white w-screen h-screen z-20'
          onClick={onClose}
        >
          <motion.div
            initial='closed'
            animate='open'
            exit='closed'
            variants={{
              open: { translateY: 0 },
              closed: { translateY: '300%' },
            }}
            transition={{ duration: 0.8, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            className='fixed bottom-0 right-0 text-product-gray-400 w-full h-full flex flex-col items-center justify-end gap-1'
          >
            <div
              className='w-8 h-1 bg-fern rounded-3xl cursor-pointer'
              onClick={onClose}
              style={{ touchAction: 'none' }}
            />
            <div className='w-full flex flex-col gap-2 bg-white py-11 px-7 rounded-t-lg max-h-[95%] overflow-y-auto relative'>
              <p className={cn(TextLayout.h1.scto, 'text-kelp')}>
                {doctor?.name},{' '}
                <span className={cn(TextLayout.callout2, 'text-base')}>{doctor?.degree}</span>
              </p>
              <img src={doctor?.photo} alt={doctor?.name} className='w-full my-[64px]' />
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                className='lg:w-2/3 text-product-gray-400 mb-5 font-medium'
              ></div>
              {hasCredentials && <Section title='Credentials' tableColumns={tableColumns!} />}
              {hasAwards && <Section title='Awards' tableColumns={tableAwards!} />}
              {hasAffiliations && (
                <Section title='Affiliations' tableColumns={tableAffiliations!} />
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const Section = ({ title, tableColumns }: { title: string; tableColumns: string[][] }) => (
  <div className='mt-11'>
    <p className={cn(TextLayout.callout4)}>{title}</p>
    <div className='border-product-forest-100 inline-flex border-b-2 w-full' />
    <CareTeamTable tableColumns={tableColumns} />
  </div>
);
