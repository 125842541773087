import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { OverlayPanel } from 'primereact/overlaypanel';
import { useRef, useState } from 'react';

type Props = {
  children: React.ReactNode;
  content: React.ReactNode;
};

export function CustomTooltip({ children, content }: Props) {
  const [active, setActive] = useState(false);
  const overlayPanelRef = useRef<OverlayPanel>(null);

  const showTip = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (overlayPanelRef.current) {
      setActive(true);
      overlayPanelRef.current.toggle(e);
    }
  };

  const hideTip = () => {
    setActive(false);
    setTimeout(() => {
      if (overlayPanelRef.current) {
        overlayPanelRef.current.hide();
      }
    }, 300);
  };

  return (
    <>
      <div onMouseEnter={showTip} onMouseLeave={hideTip}>
        {children}
        <OverlayPanel
          ref={overlayPanelRef}
          className='after:content-[] before:content-[] bg-transparent shadow-none'
          pt={{ content: { className: 'p-0 m-0' }, root: { className: 'p-0 m-0' } }}
          onHide={hideTip}
        >
          <AnimatePresence mode='popLayout'>
            {active && (
              <motion.div
                transition={{ duration: 0.4, ease: cubicBezier(0.65, 0, 0.35, 1) }}
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 20, opacity: 0 }}
                className='max-w-[378px] min-w-[378px] w-full'
                onMouseLeave={hideTip}
              >
                {content}
              </motion.div>
            )}
          </AnimatePresence>
        </OverlayPanel>
      </div>
    </>
  );
}
