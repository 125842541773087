import { Doctor } from '@/@types';
import { Button } from '@lib-atria/ui-toolkit';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DialogMobile } from '../_common';
import { CustomTooltip } from '../tooltip';

type Props = {
  doctor: Doctor;
};

export function CareTeamContactTooltip({ doctor }: Props) {
  const [openDialog, setOpenDialog] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  const handleDialogModal = () => {
    setOpenDialog(!openDialog);
  };

  return (
    <>
      {isMobile ? (
        <Button label='Contact' variant='link' color='white' onClick={handleDialogModal} />
      ) : (
        <CustomTooltip
          content={
            <div className='mt-5 px-8 py-6 rounded-lg bg-experimental-forest text-white w-full max-w-[300px]'>
              <p className='text-sm'>
                For sensitive medical concerns, call or text {doctor.cmoNumber} at any time to speak
                with {doctor.profession} {doctor.name}.
              </p>
            </div>
          }
        >
          <Button label='Contact' variant='link' color='white' />
        </CustomTooltip>
      )}
      <AnimatePresence>
        {openDialog && isMobile && (
          <DialogMobile
            params={{ buttonClassNames: 'py-5 mb-2' }}
            item={
              <div className='flex space-x-5 items-center pb-4'>
                <p className='text-base leading-6'>
                  For sensitive medical concerns, call or text {doctor.cmoNumber} at any time to
                  speak with {doctor.profession} {doctor.name}.
                </p>
              </div>
            }
            handleOnClose={handleDialogModal}
          />
        )}
      </AnimatePresence>
    </>
  );
}
