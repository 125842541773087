import { CustomTableFilters as CustomTableFiltersType } from '@/@types';
import { StringHelper } from '@/helper';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FilterTableBySelect, FilterTableByText } from './customFilters';

type CustomTableFilterProps = {
  filters: CustomTableFiltersType;
  onFilterChange: (updatedFilters: CustomTableFiltersType) => void;
};

type FilterFunctionProps = {
  type: string;
  value: any;
  range: Array<{ label: string; range: [number, number] }>;
};

export function CustomTableFilters({ filters, onFilterChange }: CustomTableFilterProps) {
  const [localFilters, setLocalFilters] = useState<CustomTableFiltersType>(filters);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  const handleFilterChange = useCallback(
    (key: string, value: any) => {
      setLocalFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.key === key) {
            return {
              ...filter,
              value: value ?? '',
              filterFunction: createFilterFunction({
                type: filter.type,
                value: value ?? '',
                range: filter.range || [],
              }),
            };
          }
          return filter;
        });

        onFilterChange(updatedFilters);
        return updatedFilters;
      });
    },
    [onFilterChange]
  );

  return (
    <div
      ref={containerRef}
      className='relative z-30 flex mb-8 mt-14 scroll-smooth overflow-x-auto md:overflow-x-clip whitespace-nowrap scrollbar-hidden w-[calc(100vw+20px)] md:w-full -ml-10 pl-10 md:pr-0 pr-10 mr-6 md:ml-0 md:pl-0'
    >
      <div className='flex flex-1'>
        <FilterTableByText
          filters={localFilters.filter((filter) => filter.type === 'text')}
          handleFilterChange={handleFilterChange}
          icon='search'
        />
      </div>

      <div className='flex justify-end z-40'>
        <FilterTableBySelect
          filters={localFilters.filter((filter) => filter.type === 'select')}
          handleFilterChange={handleFilterChange}
        />
      </div>
    </div>
  );
}

const createFilterFunction = ({ type, value, range }: FilterFunctionProps) => {
  const selectedRange = range.find((item) => item.label === value)?.range;

  return (itemValue: any) => {
    if (value === '') {
      return true;
    }
    if (type === 'text') {
      return (itemValue || '').toString().toLowerCase().includes(value.toString().toLowerCase());
    } else if (type === 'select') {
      if (selectedRange) {
        return itemValue >= selectedRange[0] && itemValue <= selectedRange[1];
      }
      return StringHelper.compare(itemValue, value) === 0;
    }

    return true;
  };
};
