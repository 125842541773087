import { useCallback, useEffect, useState } from 'react';

import { StaggerChild, StaggerChildren } from '../animation';
import { CareTeamProviderItem } from './careTeamProviderItem';
import { CareTeamProviderNote } from './careTeamProviderNote';
import { CareTeamProviderPagination } from './careTeamProviderPagination';
import { CareTeamProviderTitle } from './careTeamProviderTitle';

type Props = {
  speciality: string;
  note?: string;
  providers: CareTeamProviderItem[];
  paginationLimit: number;
};

export function CareTeamProviderListItem({
  speciality,
  note,
  providers,
  paginationLimit = 9,
}: Props) {
  const [list, setList] = useState<CareTeamProviderItem[]>([]);
  const [pagination, setPagination] = useState<number>(paginationLimit);

  const loadMore = useCallback(() => {
    setPagination((prev) => prev + paginationLimit);
  }, [paginationLimit]);

  useEffect(() => {
    setList(providers.slice(0, pagination));
  }, [pagination, providers]);

  if (providers.length === 0) return null;

  return (
    <div id={speciality}>
      <CareTeamProviderTitle title={speciality} />
      <div className='flex flex-col gap-[2px]'>
        {!!note && <CareTeamProviderNote key={speciality} text={note} />}
        <StaggerChildren>
          <div className='flex flex-col gap-[2px]'>
            {list.map((item) => (
              <StaggerChild key={item.name}>
                <CareTeamProviderItem {...item} />
              </StaggerChild>
            ))}
          </div>
        </StaggerChildren>
        {list.length < providers.length && (
          <CareTeamProviderPagination
            onClick={loadMore}
            paginationLimit={paginationLimit}
            total={list.length}
          />
        )}
      </div>
    </div>
  );
}
