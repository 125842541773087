import { CareTeamProviderLocation } from './careTeamProviderLocations';
import { CareTeamSpecialities } from './careTeamSpecialities';

type Props = {
  locations: string[];
  locationSelected: string;
  specialities: string[];
  onSpecialitySelect: (speciality: string) => void;
  onLocationSelect: (location: string) => void;
};

export function CareTeamProvidersSidebar({
  locations,
  locationSelected,
  specialities,
  onSpecialitySelect,
  onLocationSelect,
}: Props) {
  return (
    <div className='flex md:flex-col items-center justify-start gap-6 md:gap-[60px] border-t border-b md:border-none sticky top-20'>
      <CareTeamSpecialities specialities={specialities} onSelect={onSpecialitySelect} />
      <CareTeamProviderLocation
        selected={locationSelected}
        locations={locations}
        onSelect={onLocationSelect}
      />
    </div>
  );
}
