import { MemberPortal } from '@/@types';
import { api } from '@/providers';

function signIn(params: { patientId: number }) {
  return api.patch<MemberPortal.SignIn.Response>('/api/v1/member-portal/sign-in', {
    patientId: params.patientId,
  });
}

export const MemberPortalService = {
  signIn,
};
