import { DateTime } from 'luxon';
import { Button } from 'primereact/button';

import { ImagingResult } from '@/@types';
import { StringHelper } from '@/helper';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';

interface ImagingResultDetailsCardProps {
  imagingResult: ImagingResult;
}

export function ImagingResultDetailsCard({ imagingResult }: ImagingResultDetailsCardProps) {
  return (
    <div className='flex flex-col gap-4 pb-6'>
      <div className='flex justify-between flex-col items-start md:flex-row md:items-center'>
        <h2 className={cn(TextLayout.h3.scto, 'text-kelp mt-8 mb-12')}>
          {StringHelper.firstLetterUppercase(imagingResult.description)}
        </h2>
        <div className='flex gap-1 items-center'>
          {imagingResult.pacsLink &&
            imagingResult.pacsLink.map(({ href }) => (
              <Button
                key={href}
                icon='pi pi-external-link'
                size='small'
                severity='secondary'
                outlined
                label='View Image'
                onClick={() => window.open(href, '_blank')}
              />
            ))}
        </div>
      </div>
      <div className='flex flex-col'>
        <div className='flex gap-2'>
          <span className={cn(TextLayout.body1, 'text-forest-100')}>Date:</span>
          <span className={cn(TextLayout.body1, 'text-gray-400')}>
            {imagingResult.observationDateTime
              ? DateTime.fromISO(imagingResult.observationDateTime).toFormat('LLLL d, y')
              : DateTime.fromISO(imagingResult.createdDateTime).toFormat('LLLL d, y')}
          </span>
        </div>
        {imagingResult.patientNote && (
          <div className='flex gap-2'>
            <span className={cn(TextLayout.body1, 'text-forest-100')}>Notes:</span>
            <span className={cn(TextLayout.body1, 'text-gray-400')}>
              {imagingResult.patientNote}
            </span>
          </div>
        )}
      </div>
      {imagingResult.documentData && (
        <div
          className='w-full md:min-w-[500px] border-[var(--gray-2)] shadow-lg border-[1px] p-5 md:p-10 rounded-lg text-kelp text-sm leading-6'
          dangerouslySetInnerHTML={{ __html: imagingResult.documentData }}
        ></div>
      )}
    </div>
  );
}
