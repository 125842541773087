import { useCallback } from 'react';
import { useLoaderContext } from '@/contexts';
import { resourcesService } from '@/services';
import axios from 'axios';
import { DateTime } from 'luxon';
import { ResourceCategory } from '@/@types';

export function useResources() {
  const { startLoader, stopLoader } = useLoaderContext();

  const deleteResource = useCallback(
    async (id: number) => {
      startLoader();
      await resourcesService.deleteResourceAttachment(id);
      await resourcesService.deleteResource(id);
      stopLoader();
    },
    [startLoader, stopLoader]
  );

  const openResourceAttachmentInNewTab = useCallback((id: number) => {
    if (!id) return;
    const url = resourcesService.getResourceAttachmentURL(id);
    window.open(url, '_blank');
  }, []);

  const findResourceAttachment = useCallback(
    async (id: number): Promise<{ src: string; type: string } | undefined> => {
      const { data } = await resourcesService.findResourceAttachment(id);

      return new Promise((resolve) => {
        const file = new Blob([data], { type: 'application/pdf' });
        const reader: any = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          resolve({ src: reader.result, type: 'pdf' });
        };
      });
    },
    []
  );

  const findResource = useCallback(
    async (id: number) => {
      startLoader();
      const { data } = await resourcesService.findResource(id);
      stopLoader();

      return data;
    },
    [startLoader, stopLoader]
  );

  const findResources = useCallback(async () => {
    startLoader();
    const { data } = await resourcesService.findResources();
    stopLoader();

    return data.map((resource) => ({
      ...resource,
      createdAtFormatted: DateTime.fromISO(resource.createdAt).toFormat('MMM dd, yyyy'),
    }));
  }, [startLoader, stopLoader]);

  const uploadEditedResource = useCallback(
    async (
      id: number,
      resourceName: string,
      resourceUrl: string,
      file?: File,
      category?: ResourceCategory
    ) => {
      startLoader();
      let url = resourceUrl;

      try {
        if (file) {
          await resourcesService.deleteResourceAttachment(id);

          const fileName = `${resourceName}.${file.name.split('.').pop()}`;
          const form = new FormData();
          form.append('file', file);

          const {
            data: { googleUrl, fileUrl },
          } = await resourcesService.generateSignedUrl(fileName);

          url = fileUrl;

          await axios.put(googleUrl, form, {
            headers: {
              'Content-Type': 'application/pdf',
            },
          });
        }

        const resource = await resourcesService.updateResource(id, resourceName, url, category);

        return resource;
      } catch {
        throw new Error('File upload failed');
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  const uploadNewResource = useCallback(
    async (fileName: string, file: File, category?: ResourceCategory) => {
      startLoader();
      const resourceFileName = `${fileName}.${file.name.split('.').pop()}`;

      try {
        const form = new FormData();
        form.append('file', file);

        const {
          data: { googleUrl, fileUrl },
        } = await resourcesService.generateSignedUrl(resourceFileName);

        await axios.put(googleUrl, form, {
          headers: {
            'Content-Type': 'application/pdf',
          },
        });

        const resource = await resourcesService.createResource(fileName, fileUrl, category);

        return resource;
      } catch {
        throw new Error('File upload failed');
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  return {
    deleteResource,
    findResource,
    findResourceAttachment,
    openResourceAttachmentInNewTab,
    findResources,
    uploadEditedResource,
    uploadNewResource,
  };
}
