import { DateTime } from 'luxon';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { BasicCard, CustomPdfViewer } from '@/components';
import { usePageContext } from '@/contexts';
import { usePatientsChart } from '@/hooks';

export function ChartPdfViewerPage() {
  const { documentId } = useParams<{ documentId: string }>();
  const { patient, fileUrl, getChartByDocumentId } = usePatientsChart();
  const { setPageTitle } = usePageContext();

  const fileName = useMemo(() => {
    const patientName = `${patient?.firstName} ${patient?.lastName}`;
    const date = DateTime.now().toFormat('yyyy-MM-dd');
    return `Medical records of ${patientName} - Atria - ${date}.pdf`;
  }, [patient?.firstName, patient?.lastName]);

  useEffect(() => {
    setPageTitle('Export chart');
  }, [setPageTitle]);

  useEffect(() => {
    getChartByDocumentId(Number(documentId));
  }, [documentId, getChartByDocumentId]);

  return (
    <BasicCard title='Export chart'>
      {fileUrl && (
        <div className='h-[70vh]'>
          <CustomPdfViewer fileName={fileName} fileUrl={fileUrl} />
        </div>
      )}
    </BasicCard>
  );
}
