import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { DateHelper, StringHelper } from '@/helper';
import { BasicCard } from '.';
import { BigButton } from '..';

type Props = {
  title: string;
  labResult?: {
    id: number;
    description: string;
    abnormalFlag: string;
    createdDateTime: string;
  };
};

export function LabResultCard({ title, labResult }: Props) {
  return (
    <BasicCard className='h-full shadow-none bg-[#eeece5] rounded-xl px-5'>
      <p className='block font-medium leading-8 text-kelp mb-2'>{title}</p>
      {labResult ? (
        <>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-0 h-full md:items-center justify-between'>
            <h2 className='text-2xl font-medium leading-8 text-kelp my-2'>
              {StringHelper.firstLetterUppercase(labResult.description)}
            </h2>
            <div className='flex flex-col md:text-center text-kelp leading-[0.875rem]'>
              <p className='text-lg md:font-medium'>
                {DateHelper.formatDateToDisplay(DateTime.fromISO(labResult.createdDateTime!))}
              </p>
            </div>
          </div>
          <Link className='mt-4' to={`/results/lab/${labResult.id}`}>
            <BigButton>View results</BigButton>
          </Link>
        </>
      ) : (
        <p className='leading-[0.875rem] text-gray-700'>No recent lab result.</p>
      )}
    </BasicCard>
  );
}
