import { DateTime } from 'luxon';
import { LabResult } from '@/@types';
import { StringHelper } from '@/helper';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';

type ResultPageHeaderProps = {
  result: LabResult;
};

export function ResultPageHeader({ result }: ResultPageHeaderProps) {
  return (
    <div className='flex flex-col gap-4 pb-6'>
      <div className='flex justify-between flex-col items-start md:flex-row md:items-center'>
        <h2 className={cn(TextLayout.h3.scto, 'text-kelp mt-8 mb-12')}>
          {StringHelper.firstLetterUppercase(result.description)}
        </h2>
      </div>
      <div className='flex flex-col'>
        <div className='flex gap-2'>
          <span className={cn(TextLayout.body1, 'text-forest-100')}>Date:</span>
          <span className={cn(TextLayout.body1, 'text-gray-400')}>
            {DateTime.fromISO(result.createdDateTime).toFormat('LLLL d, y')}
          </span>
        </div>
        {result.patientNote && (
          <div className='flex gap-2'>
            <span className={cn(TextLayout.body1, 'text-forest-100')}>Note:</span>
            <span className={cn(TextLayout.body1, 'text-gray-400')}>{result.patientNote}</span>
          </div>
        )}
      </div>
    </div>
  );
}
