import { motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export function StaggerChild({ children, className }: Props) {
  return (
    <motion.li
      variants={{
        hidden: { translateY: -20, opacity: 0 },
        show: { translateY: 0, opacity: 1, transition: { duration: 0.6 } },
      }}
      className={className}
    >
      {children}
    </motion.li>
  );
}
