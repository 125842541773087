import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAuthContext } from '@/contexts';

export function PatientAuthRoute() {
  const { isAuthenticated } = useAuthContext();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to='/sign-in' state={{ from: location }} replace />;
  }

  return <Outlet />;
}
