import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { DateOfBirthValidation } from '@/components';
import { useHandleToken } from '@/hooks';
import AtriaLogo from '@/assets/img/atria-logo.svg';
import { useToastContext } from '@/contexts';

export function SignUpBySignedURLPage() {
  const params = useLocation();
  const [patientId, setPatientId] = useState<number | undefined>();
  const { decodeToken } = useHandleToken();
  const { toast } = useToastContext();
  const [isRegistrationAvailable, setIsRegistrationAvailable] = useState(false);

  useEffect(() => {
    function isRegistrationPage() {
      const query = new URLSearchParams(params.search);
      const token = query.get('token');
      if (token) {
        try {
          const payload: { patientId: number } = decodeToken(token);
          setPatientId(payload.patientId);
          setIsRegistrationAvailable(true);
        } catch (error) {
          toast?.current.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Token invalid or not found',
          });
        }
      }
    }
    isRegistrationPage();
  }, [decodeToken, params.search, toast]);

  return (
    <div className='w-screen h-screen flex flex-col items-center justify-start bg-kelp overflow-y-auto'>
      <div className='w-full max-w-lg pt-10 px-4'>
        <div className='py-10'>
          <img src={AtriaLogo} alt='Atria' title='Atria' className='max-w-[200px] m-auto' />
        </div>
        <div className=' bg-stone rounded-lg m-auto py-8 flex flex-col items-center justify-center'>
          <h1 className='pb-4 font-display text-xl font-medium text-kelp'>Member Portal</h1>
          {isRegistrationAvailable && patientId ? (
            <DateOfBirthValidation patientId={patientId} />
          ) : (
            <div className='py-4'>
              <p className='font-medium'>No token invalid or not found</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
