import { cn } from '@lib-atria/ui-toolkit';

import { TextLayout } from '../text';

type Props = {
  specialities: string[];
  selected?: string;
  onSelect: (speciality: string) => void;
};

export function CareTeamSpecialitiesDesktop({ specialities, selected, onSelect }: Props) {
  return (
    <div className='hidden md:flex w-[93px] flex-col gap-3'>
      {specialities.map((speciality) => (
        <p
          key={speciality}
          className={cn(
            TextLayout.body3,
            selected === speciality
              ? 'text-product-forest-100'
              : 'text-product-gray-400 hover:text-product-forest-100',
            'text-right cursor-pointer'
          )}
          onClick={() => onSelect(speciality)}
        >
          {speciality}
        </p>
      ))}
    </div>
  );
}
