import { Auth } from '@/@types';
import { useAuthContext } from '@/contexts';
import {
  BaseCard,
  CarouselCard,
  CarouselCareCoordinatorsContent,
  CarouselCMOContent,
} from '@lib-atria/ui-toolkit';
import '@lib-atria/ui-toolkit/dist/style.css';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  handleContactCareTeam: () => void;
};
const dataCard = [
  {
    title: 'Panta Rouhani',
    degree: 'Dr.',
    content: 'Your Chief Medical Officer',
    phone: '555-555-5555',
    email: 'test@test.com',
    podMembers: [
      { title: 'John Doe', name: 'John Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
    ],
    podPhone: '555-555-5555',
  },
  {
    title: ' Jay Rahman',
    degree: 'Dr.',
    content: 'Your Chief Medical Officer',
    phone: '555-555-5555',
    podPhone: '555-555-5555',
    email: 'test@test.com',
    podMembers: [
      { title: 'John Doe', name: 'John Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
    ],
    image:
      'https://cdn.sanity.io/images/3h389p8z/production/ba46d0bb91448e23186e3e7efdd4e715b60dc7ea-464x619.jpg?w=300',
  },
  {
    title: 'John Doe ',
    degree: 'Dr.',
    content: 'Your Chief Medical Officer',
    phone: '555-555-5555',
    image:
      'https://cdn.sanity.io/images/3h389p8z/production/f5e3f7670d1edee6baa23623e3a1d5421b550c7b-464x619.jpg?w=300',
    podMembers: [
      { title: 'John Doe', name: 'John Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
    ],
  },
  {
    title: 'John Doe 2',
    degree: 'Dr.',
    content: 'Your Chief Medical Officer',
    phone: '555-555-5555',
    image:
      'https://cdn.sanity.io/images/3h389p8z/production/f5e3f7670d1edee6baa23623e3a1d5421b550c7b-464x619.jpg?w=300',
    podMembers: [
      { title: 'John Doe', name: 'John Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
      { title: 'Jane Doe', name: 'Jane Doe' },
    ],
    podPhone: '555-555-5555',
    email: 'test@test.com',
  },
];
export function DoctorsCard({ handleContactCareTeam }: Props) {
  const { hasPermission } = useAuthContext();
  const navigate = useNavigate();

  const modifiedDataCard = dataCard.flatMap((card) => {
    const components = [
      <CarouselCMOContent
        key={card.title}
        title={card.title}
        content={card.content}
        phone={card.phone}
        image={card.image}
        degree={card.degree}
      />,
    ];
    if (card.podMembers && card.podMembers.length > 0) {
      components.push(
        <CarouselCareCoordinatorsContent
          key={`coordinators-${card.title}`}
          phone={card.podPhone}
          podMembers={card.podMembers}
          email={card.email}
        />
      );
    }
    return components;
  });
  const buttons = useMemo(
    () => [
      {
        label: 'View all',
        onClick: () => navigate('/care-team'),
        variant: 'quaternary' as const,
        permission: Auth.Permissions.UserPermissions.VIEW_MY_CARE_TEAM,
        size: 'large' as const,
      },
      {
        label: 'Contact care team',
        onClick: handleContactCareTeam,
        variant: 'quaternary' as const,
        permission: Auth.Permissions.UserPermissions.CONTACT_CARE_TEAM,
        size: 'large' as const,
      },
    ],
    [handleContactCareTeam, navigate]
  );
  const filteredButtons = useMemo(
    () =>
      buttons.filter(
        (button) =>
          hasPermission(button.permission as Auth.Permissions.UserPermissions) || !button.permission
      ),
    [buttons, hasPermission]
  );
  return (
    <BaseCard
      title='Your Atria doctors'
      className='bg-sand-100 cursor-pointer'
      buttons={filteredButtons}
      hasBackgroundEffect
    >
      <div className='md:mt-20 mt-6 flex md:h-full flex-1 items-center justify-center'>
        <CarouselCard cardsData={modifiedDataCard} bullets>
          {modifiedDataCard}
        </CarouselCard>
      </div>
    </BaseCard>
  );
}
