import { useEffect } from 'react';

import { LoginLoader } from '@/components';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';

export function SignOutPatientPage() {
  const { setPageTitle } = usePageContext();
  const { signOutUser } = useAuthContext();
  const { reset } = useApplicationContext();

  useEffect(() => {
    setPageTitle('Sign Out');
  }, [setPageTitle]);

  useEffect(() => {
    reset();
    signOutUser();
  }, [signOutUser, reset]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <LoginLoader />
    </div>
  );
}
