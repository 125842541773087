import { AppointmentEncounterSummary, AppointmentLetter, Appointments } from '@/@types';
import { api } from '@/providers';

async function findEncounterSummaryByAppointmentId(appointmentId: number) {
  return api.get<AppointmentEncounterSummary>(`/api/v1/appointments/${appointmentId}/encounters`);
}

function getLetterByPatientIdAndEncounterId(
  appointmentId: number,
  patientId: number,
  encounterId: number
) {
  return api.get<AppointmentLetter>(
    `/api/v1/patients/${patientId}/appointments/${appointmentId}/encounters/${encounterId}/letter`
  );
}

function confirmAppointmentsByPatientId(params: Appointments.ConfirmAppointment.Params) {
  const { patientId, ...restParams } = params;
  return api.patch<Appointments.ConfirmAppointment.Response>(
    `/api/v1/patients/${patientId}/appointments/confirm`,
    restParams
  );
}

export const AppointmentsService = {
  findEncounterSummaryByAppointmentId,
  getLetterByPatientIdAndEncounterId,
  confirmAppointmentsByPatientId,
};
