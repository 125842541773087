import { Appointments } from '@/@types';
import { AppointmentsCardHomeVisit } from '../appointmentsCard';

type Props = {
  appointments: Appointments.FindAllPatientsAppointments.AppointmentList;
  address?: string;
  doctor?: Appointments.FindAllPatientsAppointments.Doctor;
  cmoEmail: string;
};

export function HomeVisitCard({ appointments, address, doctor, cmoEmail }: Props) {
  return (
    <>
      {appointments.map((appt) => (
        <AppointmentsCardHomeVisit
          key={appt.id}
          address={address}
          emailCmo={cmoEmail}
          phoneNumber={doctor?.cmoNumber}
        />
      ))}
    </>
  );
}
