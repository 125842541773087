import { Theme } from '@/theme';

type Props = React.SVGProps<SVGSVGElement>;

export function DiamondSymbolsIcon({
  width = 18,
  height = 18,
  fill = Theme.colors['fern'],
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M12.9643 3.96429L9.05357 7.875L5.08929 3.96429L9.05357 0L12.9643 3.96429ZM18 9L14.0893 12.9643L10.1786 9L14.0893 5.08929L18 9ZM7.92857 9L3.96429 12.9643L0 9L3.96429 5.08929L7.92857 9ZM12.9643 14.0893L9.05357 18L5.08929 14.0893L9.05357 10.125L12.9643 14.0893Z'
        fill={fill}
      />
    </svg>
  );
}
