import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useRef } from 'react';
import { cn } from '@lib-atria/ui-toolkit';

import { CustomTableChevron } from '../customTable/customTableChevron';
import { TextLayout } from '../text';

type Props = {
  locations: string[];
  isOpen: boolean;
  onOpen: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClose: VoidFunction;
  selected: string;
  onSelect: (location: string) => void;
};

export function CareTeamProviderLocationDesktop({
  locations,
  isOpen,
  onOpen,
  onClose,
  selected,
  onSelect,
}: Props) {
  const overlayPanelRef = useRef<OverlayPanel>(null);

  const handleOnOpen = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!overlayPanelRef.current) return;
      overlayPanelRef.current?.toggle(e);
      onOpen(e);
    },
    [onOpen]
  );

  const onHide = useCallback(() => {
    if (!overlayPanelRef.current) return;
    overlayPanelRef.current?.hide();
    onClose();
  }, [onClose]);

  const handleOnSelect = useCallback(
    (location: string) => {
      onHide();
      onSelect(location);
      onSelect(location);
    },
    [onHide, onSelect]
  );

  return (
    <div className='hidden md:flex w-full relative items-end justify-end'>
      <div className='flex gap-1 cursor-pointer' onClick={handleOnOpen}>
        <p className={cn(TextLayout.body3, 'text-product-forest-100 text-right')}>{selected}</p>
        <CustomTableChevron
          animate={isOpen ? 'asc' : 'desc'}
          className='text-xs text-product-forest-100'
        />
      </div>
      <OverlayPanel
        ref={overlayPanelRef}
        className='bg-experimental-forest p-1 shadow-[0_11px_17px_0_rgba(218, 218, 218, 0.24)] after:content-[] before:content-[] rounded-lg'
        pt={{ content: { className: 'p-0' } }}
        onHide={onHide}
      >
        <div className='w-[184px] bg-product-forest-100 p-1 rounded-lg flex flex-col'>
          {locations.map((location) => (
            <LocationItem
              key={location}
              onClick={() => handleOnSelect(location)}
              label={location}
            />
          ))}
        </div>
      </OverlayPanel>
    </div>
  );
}

function LocationItem({ label, onClick }: { label: string; onClick: VoidFunction }) {
  return (
    <a
      onClick={onClick}
      className={cn(
        TextLayout.body2,
        'w-full p-3 rounded-md bg-product-forest-100 hover:bg-kelp-dark transition-colors cursor-pointer text-white'
      )}
    >
      {label}
    </a>
  );
}
