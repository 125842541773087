import { BottomSheet } from '@/components/bottomSheet';
import { MaterialIcons } from '@/components/icons';
import { TextLayout } from '@/components/text';
import { useContactDialogContext } from '@/contexts';
import { Checkbox, cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { InputTextarea } from 'primereact/inputtextarea';
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

type Props = {
  handleSelect: (value: string, selectedValues: string[]) => void;
  inputHeight: string;
  field: ControllerRenderProps<{ topic: any; message?: string | undefined }, 'topic'>;
} & React.ComponentProps<typeof InputTextarea>;

export const DialogModalFormMultiSelect = forwardRef<HTMLTextAreaElement, Props>(
  ({ handleSelect, inputHeight, field, ...rest }: Props, ref) => {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const [isOpen, setIsOpen] = useState(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    const { options } = useContactDialogContext();

    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
          setIsOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [optionsRef]);

    const value = useMemo(() => {
      if (field.value && field.value.length > 0) {
        const filteredValues = field.value.filter((item: string) => item !== '');
        return `Refill request for ${filteredValues.join(' and ')}`;
      }
      return '';
    }, [field.value]);
    const heightCalculator = useMemo(() => {
      if (field.value?.length >= 1 && field.value[0] !== '') {
        return Number(inputHeight) + 10;
      }
      return Number(inputHeight);
    }, [field.value, inputHeight]);

    const onClose = useCallback(() => {
      setIsOpen(false);
    }, []);

    return (
      <>
        <div className='relative'>
          <InputTextarea
            {...rest}
            style={{
              height: `${heightCalculator}px`,
            }}
            className='bg-kelp-dark text-white border-none w-full resize-none overflow-auto scrollbar-hidden cursor-pointer pr-6'
            onClick={() => setIsOpen(!isOpen)}
            value={value}
            ref={ref}
          />
          <button
            type='button'
            aria-controls='options-list'
            className='absolute right-3 top-[1.6rem] transform -translate-y-1/2 focus:outline-none'
            onClick={() => setIsOpen(!isOpen)}
          >
            <MaterialIcons
              name='keyboard_arrow_up'
              className={`text-white transition-transform duration-300 ${
                isOpen ? 'rotate-0' : 'rotate-180'
              }`}
            />
          </button>
        </div>
        <AnimatePresence>
          {isOpen &&
            (!isMobile ? (
              <motion.div
                className='absolute z-10 mt-2 box-content md:bottom-20 bottom-28 flex md:max-h-[200px] max-h-[300px] transition duration-300 !w-fit flex-col overflow-auto rounded-lg bg-white text-forest-100 p-2 pr-3'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={(e) => e.stopPropagation()}
                ref={optionsRef}
              >
                <div className='atria-scrollbar-tiny z-10 mt-2 md:bottom-20 bottom-28 flex md:max-h-[200px] max-h-[300px] transition duration-300 w-full flex-col overflow-auto rounded-lg bg-white text-forest-100 pr-1'>
                  {options.map((option) => (
                    <div
                      className='flex *:select-none [&>*:nth-child(even)]:active:scale-90 transition-all  w-full justify-between items-center align-middle cursor-pointer rounded-md pr-3 text-forest-100 hover:bg-product-sand-100'
                      key={option}
                    >
                      <div
                        className={cn(TextLayout.body2, 'w-full py-3 pl-3 pr-3')}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelect(option, field?.value);
                        }}
                      >
                        <span className='w-full'>{option}</span>
                      </div>
                      <Checkbox
                        checked={field?.value?.includes(option)}
                        variant='quaternary'
                        onChange={(e) => {
                          e.stopPropagation();
                          handleSelect(option, field?.value);
                        }}
                      />
                    </div>
                  ))}
                </div>
              </motion.div>
            ) : (
              <AnimatePresence>
                <BottomSheet isVisible={isOpen} onClose={onClose} headerTitle='' closeIcon={true}>
                  <div
                    className='text-base atria-scrollbar flex flex-col overflow-auto rounded-lg text-white px-5 py-4'
                    ref={optionsRef}
                  >
                    {options.map((option) => (
                      <li
                        key={option}
                        className={cn(
                          TextLayout.body2,
                          'w-full *:select-none [&>*:nth-child(even)]:active:scale-90 transition-all flex justify-between items-center align-middle cursor-pointer rounded-md px-3 py-4 text-white  hover:bg-kelp-dark'
                        )}
                        onClick={() => handleSelect(option, field?.value)}
                      >
                        <span className='w-3/4'>{option}</span>
                        <Checkbox
                          checked={field?.value?.includes(option)}
                          variant='quaternary'
                          onChange={(e) => {
                            e.stopPropagation();
                            handleSelect(option, field?.value);
                          }}
                        />
                      </li>
                    ))}
                  </div>
                </BottomSheet>
              </AnimatePresence>
            ))}
        </AnimatePresence>
      </>
    );
  }
);

DialogModalFormMultiSelect.displayName = 'DialogModalFormMultiSelect';
