import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useAuthContext, usePageContext } from '@/contexts';
import { useMedicalRecords } from '@/hooks';

export function MedicalRecordsDetailsPage() {
  const { id: medicalRecordId } = useParams();
  const { patient } = useAuthContext();
  const patientId = useMemo(() => patient?.id, [patient?.id]);
  const { medicalRecordDetail, findPatientMedicalRecordsById, findPatientMedicalRecordAttachment } =
    useMedicalRecords();
  const { setPageTitle } = usePageContext();

  useEffect(() => {
    setPageTitle(medicalRecordDetail?.documentDescription);
  }, [medicalRecordDetail?.documentDescription, setPageTitle]);

  useEffect(() => {
    if (patientId && medicalRecordId) {
      findPatientMedicalRecordsById(patientId, Number(medicalRecordId));
      findPatientMedicalRecordAttachment(patientId, Number(medicalRecordId));
    }
  }, [
    findPatientMedicalRecordAttachment,
    findPatientMedicalRecordsById,
    medicalRecordId,
    patientId,
  ]);

  return (
    <>
      <div
        className='bg-white p-4 rounded-lg'
        dangerouslySetInnerHTML={{ __html: medicalRecordDetail?.documentData || '' }}
      ></div>
    </>
  );
}
