import { DateTime } from 'luxon';
import { Fragment, useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Appointments } from '@/@types';
import {
  AppointmentsBlank,
  AppointmentsCardFooter,
  AtriaIcon,
  DialogModalFormAppointments,
  PastAppointmentsLoading,
  ResultsList,
} from '@/components';
import { useApplicationContext, useContactDialogContext } from '@/contexts';
import { DateHelper, TimerHelper } from '@/helper';
import { Button, CardNote, cn, InfiniteScroll, TextLayout } from '@lib-atria/ui-toolkit';
import { AppointmentsSidebarList } from '../appointmentsSidebarList';
import { PastAppointmentsCard } from './pastAppointmentsCard';

const formatTime = (date: string) => {
  const dateObj = DateTime.fromISO(date);
  if (dateObj.toJSDate().getMinutes() > 0) {
    return dateObj.toFormat('h:mm');
  }
  return dateObj.toFormat('h');
};

export function PastAppointments() {
  const { pastAppointmentsSidebarList, hasMorePastAppointments, getPastAppointmentsExtended } =
    useApplicationContext();
  const sidebarListRef = useRef<any>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContactDialogTopic,
    setContent,
    setContactDialogTitle,
  } = useContactDialogContext();

  const sidebarSelectPage = useRef<any>();
  const infiniteScrollRef = useRef<any>();
  const navigate = useNavigate();
  const infiniteScrollItemRef = useRef<IntersectionObserver>(
    new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            if (!sidebarSelectPage.current) {
              sidebarListRef.current?.setKey(entry.target.getAttribute('id'));
            }
          }
        }
      },
      { threshold: 0.7 }
    )
  );

  const handleInfiniteScrollItemRef = useCallback((e: HTMLDivElement | null) => {
    if (e) {
      infiniteScrollItemRef.current.observe(e);
    }
  }, []);

  const handleOnClickOpenDialog = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTitle, setContactDialogVisibility, setContent]);

  const handleShowAskAQuestionDialog = useCallback(
    (startDate?: string, endDate?: string) => {
      if (!startDate && !endDate) {
        setContactDialogTopic(`Question about past appointments`);
        setContactDialogVisibility(true);
        return;
      }

      const start = formatTime(startDate as string);
      const end = formatTime(endDate as string);
      if (startDate) {
        const displayDate = `${DateHelper.formatDateToDisplay(DateTime.fromISO(startDate))} ${start}-${end}`;
        setContactDialogTopic(`Question about ${displayDate} appointment`);
      } else {
        setContactDialogTopic(`Question about past appointments`);
      }

      setContactDialogVisibility(true);
    },
    [setContactDialogVisibility, setContactDialogTopic]
  );

  const handleOnCardNoteClick = useCallback(
    (appointmentId: number, encounterId: number) => {
      navigate(`/appointments/${appointmentId}/encounter/${encounterId}/letter`);
    },
    [navigate]
  );

  const handleOnLabResultClick = useCallback(
    (labResultId: number, type: string) => {
      if (type === 'imaging') {
        navigate(`/results/imaging/${labResultId}`);
        return;
      }
      navigate(`/results/lab/${labResultId}`);
    },
    [navigate]
  );

  const endDateTime = useCallback((endTime: string) => {
    const endDateObj = DateTime.fromISO(endTime);
    if (endDateObj.toJSDate().getMinutes() > 0) {
      return endDateObj.toFormat('h:mma').toLowerCase();
    }
    return endDateObj.toFormat('ha').toLowerCase();
  }, []);

  const startDateTime = useCallback((startTime: string) => {
    const startDateObj = DateTime.fromISO(startTime);
    if (startDateObj.toJSDate().getMinutes() > 0) {
      return startDateObj.toFormat('h:mm');
    }
    return startDateObj.toFormat('h');
  }, []);

  const handleAskQuestion = useCallback(
    (dateSelected?: string, startDate?: string, endDate?: string) => {
      if (!dateSelected || !startDate || !endDate) {
        setContactDialogTopic('Question about my appointment');
        setContactDialogVisibility(!isContactDialogVisible);
        return;
      }
      const date = DateTime.fromISO(dateSelected);
      const formatted = DateHelper.formatDateToDisplay(date).split(',')[0];
      setContactDialogTopic(
        `Question about [${formatted} ${startDateTime(startDate)}-${endDateTime(endDate)}] appointment`
      );
      setContactDialogVisibility(!isContactDialogVisible);
    },
    [
      endDateTime,
      isContactDialogVisible,
      setContactDialogTopic,
      setContactDialogVisibility,
      startDateTime,
    ]
  );

  const handleSidebarItemClick = useCallback(async (sidebarItem: Appointments.SidebarList.Item) => {
    const { key, page } = sidebarItem;
    sidebarSelectPage.current = page;
    const element = document.getElementById(key);
    if (element) {
      sidebarListRef.current.setKey(key);
      infiniteScrollRef.current.selectPage(page);
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      sidebarListRef.current.setKey(key);
      await infiniteScrollRef.current.loadMoreItems({ page });
      await TimerHelper.delay(150, () => {
        document.getElementById(key)?.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }, []);

  useEffect(() => {
    const handler = TimerHelper.debounce(() => {
      infiniteScrollRef.current?.cleanUp();
      sidebarSelectPage.current = undefined;
    }, 500);
    const root = document.getElementById('root');
    root?.addEventListener('scrollend', handler);
    return () => root?.removeEventListener('scrollend', handler);
  }, []);

  return (
    <div
      ref={containerRef}
      className='w-full grid grid-cols-1 gap-5 md:gap-[79px] relative md:grid-cols-[1fr_93px]'
    >
      <div className='flex flex-col'>
        <InfiniteScroll
          method='REPLACE'
          ref={infiniteScrollRef}
          hasMore={hasMorePastAppointments}
          LoadingComponent={<PastAppointmentsLoading />}
          NoMoreContentComponent={<></>}
          EmptyList={
            <AppointmentsBlank
              onClick={handleAskQuestion}
              text='You don’t have any upcoming appointments scheduled at this time.'
            />
          }
          fetchData={getPastAppointmentsExtended}
          renderItem={(groupItem) => {
            return (
              <Fragment key={groupItem.key}>
                <div
                  id={groupItem.key}
                  ref={handleInfiniteScrollItemRef}
                  className='flex flex-col w-full rounded-xl gap-4 bg-white p-3'
                >
                  <PastAppointmentsCard groupItem={groupItem} />

                  {groupItem?.appointmentNotes?.length > 0 && (
                    <div className='flex flex-col rounded-[7px] p-8 bg-product-sand-100 gap-2'>
                      <h2 className={cn(TextLayout.callout3, 'text-product-forest-100 mb-4')}>
                        Related appointment notes
                      </h2>
                      {groupItem.appointmentNotes.map((note: any) => (
                        <div
                          className='flex justify-center'
                          key={`card-note-${groupItem.key}-${note.encounterId}`}
                        >
                          <CardNote
                            key={note.encounterId}
                            buttonLabel='View notes'
                            onClick={() =>
                              handleOnCardNoteClick(note.appointmentId, note.encounterId)
                            }
                            title={note.title}
                          />
                        </div>
                      ))}
                    </div>
                  )}
                  {groupItem.labResults?.length > 0 && (
                    <ResultsList
                      results={groupItem.labResults}
                      handleOnLabResultClick={handleOnLabResultClick}
                    />
                  )}
                  <div className='mb-1 [&>button]:w-full mx-5'>
                    <Button
                      variant='tertiary'
                      label='Ask a question'
                      size='large'
                      onClick={() =>
                        handleShowAskAQuestionDialog(groupItem.startDate, groupItem.endDate)
                      }
                    />
                  </div>
                </div>
                <AtriaIcon className='mx-0 w-full my-14 h-8' fill='#CBC6BD' />
              </Fragment>
            );
          }}
        />

        {pastAppointmentsSidebarList.length > 0 && (
          <AppointmentsCardFooter
            text='Your care team is here to assist with your appointments. Please reach out at any time.'
            buttons={[
              { label: 'Schedule an appointment', onClick: handleOnClickOpenDialog },
              { label: 'Ask a question', onClick: () => handleShowAskAQuestionDialog() },
            ]}
          />
        )}
      </div>
      <AppointmentsSidebarList
        ref={sidebarListRef}
        items={pastAppointmentsSidebarList}
        onClick={handleSidebarItemClick}
      />
    </div>
  );
}
