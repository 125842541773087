import { AnimatePresence, cubicBezier, motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
  isVisible?: boolean;
};

export function AccordionContent({ children, isVisible = false }: Props) {
  return (
    <AnimatePresence initial={true}>
      {isVisible && (
        <motion.section
          key='content'
          initial='closed'
          animate='open'
          exit='closed'
          variants={{
            open: { opacity: 1, height: 'auto' },
            closed: { opacity: 0, height: 0 },
          }}
          transition={{ duration: 0.8, ease: cubicBezier(0.65, 0, 0.35, 1) }}
          className='w-full'
        >
          <motion.div
            variants={{ closed: { marginTop: '-50px' }, open: { marginTop: 0 } }}
            transition={{ duration: 0.8 }}
            className='origin-center w-full px-5 md:px-8 py-4 gap-5 flex flex-col items-center justify-start'
          >
            {children}
          </motion.div>
        </motion.section>
      )}
    </AnimatePresence>
  );
}
