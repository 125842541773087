import { cubicBezier, motion } from 'framer-motion';
import { Button } from 'primereact/button';
import { useCallback } from 'react';

import { TableMoreAction } from '@/@types';

type Props = {
  actions: TableMoreAction[];
  onClose: () => void;
};

export function CustomTableActionDialogMobile({ actions, onClose }: Props) {
  const onClick = useCallback(
    (action: TableMoreAction) => {
      onClose();
      action.onClick();
    },
    [onClose]
  );

  return (
    <>
      <motion.div
        initial='closed'
        animate='open'
        exit='closed'
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        transition={{ duration: 0.8, ease: cubicBezier(0.65, 0, 0.35, 1) }}
        className='absolute inset-0 bg-product-sand-100/50 text-white w-screen h-screen z-20'
        onClick={onClose}
      >
        <motion.div
          initial='closed'
          animate='open'
          exit='closed'
          variants={{
            open: { translateY: 0 },
            closed: { translateY: '300%' },
          }}
          transition={{ duration: 0.8, ease: cubicBezier(0.65, 0, 0.35, 1) }}
          className='fixed bottom-0 right-0 text-white w-full h-full flex flex-col items-center justify-end gap-1'
        >
          <div
            className='w-8 h-1 bg-fern rounded-3xl cursor-pointer'
            onClick={onClose}
            style={{ touchAction: 'none' }}
          />
          <div className='w-full flex flex-col gap-2 bg-fern p-4 rounded-t-lg max-h-[50%] overflow-y-auto relative'>
            {actions.map((action) => (
              <div key={action.label} className='w-full h-12 flex items-center'>
                <Button
                  className='w-full text-white text-left hover:bg-product-forest-100/20'
                  label={action.label}
                  onClick={() => onClick(action)}
                  text
                />
              </div>
            ))}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
}
