import { CustomTableHeader, Medications } from '@/@types';
import { StringHelper } from '@/helper';
import { CustomTable } from '../customTable';
import { MedicationName } from './';

type MedicationsTableProps = {
  medications: Medications.Medication[];
  tableHeaders: CustomTableHeader[];
  handleAskQuestion?: (medication: Medications.Medication) => void;
  handleRequestRefill?: (
    medications: Medications.Medication['medication'][],
    medication: Medications.Medication['medication']
  ) => void;
  isActionEnabled: boolean;
  messageEmpty?: string;
};

export function MedicationsTable({
  medications,
  tableHeaders,
  handleAskQuestion,
  handleRequestRefill,
  isActionEnabled,
  messageEmpty,
}: MedicationsTableProps) {
  return (
    <CustomTable
      sortable={['medication', 'prescribedBy']}
      headers={tableHeaders}
      items={
        medications?.map((medication) => ({
          ...medication,
          name: <MedicationName medication={medication} />,
          instructions: StringHelper.firstLetterUppercase(
            medication.instructions?.toLowerCase() || ''
          ),
          actions:
            (isActionEnabled && {
              expand: medication.patientNote,
              more: [
                {
                  label: 'Ask a question',
                  onClick: () => handleAskQuestion?.(medication),
                },
                {
                  label: 'Request a refill',
                  onClick: () => {
                    const medicationNames = medications.map((med) => med.medication);
                    handleRequestRefill?.(medicationNames, medication.medication);
                  },
                },
              ],
            }) ||
            undefined,
        })) || []
      }
      emptyMessage={messageEmpty ?? 'No prescribed medications'}
    />
  );
}
