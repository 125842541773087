import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { useState } from 'react';

type Props = {
  title: string;
  children: React.ReactNode;
};

export function EncounterExpandNotes({ title, children }: Props) {
  const [isVisible, setIsVisible] = useState(false);

  return (
    <AnimatePresence mode='popLayout'>
      {!isVisible && (
        <motion.div
          key='encounter-expand-notes'
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={() => setIsVisible(true)}
          transition={{ duration: 0.5 }}
          className='cursor-pointer hover:bg-[#E2DED8] group flex w-full px-10 py-9 rounded-xl text-product-forest-100 justify-between items-center bg-product-sand-300 z-10'
        >
          <span className='md:text-2xl font-medium text-product-forest-100'>{title}</span>
          <ExpandNotesChevron />
        </motion.div>
      )}
      {isVisible && <EncounterExpandedAnimation>{children}</EncounterExpandedAnimation>}
    </AnimatePresence>
  );
}

function ExpandNotesChevron() {
  return (
    <div className='w-[32px] h-[32px] flex items-center justify-center border border-product-sand-400 rounded-full group-hover:border-[#cbc6bd]'>
      <motion.span
        initial='closed'
        variants={{
          closed: { rotate: '180deg' },
        }}
        transition={{ damping: 40 }}
        animate={'closed'}
        className='material-icons-round text-product-forest-100 text-base'
      >
        expand_more
      </motion.span>
    </div>
  );
}

function EncounterExpandedAnimation({ children }: { children: React.ReactNode }) {
  return (
    <motion.div
      className='space-y-3'
      initial={{ y: '10%', opacity: 0 }}
      animate={{
        y: 0,
        opacity: 1,
        transition: { duration: 0.6, ease: cubicBezier(0.65, 0, 0.35, 1), delay: 0.2 },
      }}
    >
      {children}
    </motion.div>
  );
}
