async function parseContentType(
  attachment: any,
  contentType: string
): Promise<{ src: string; type: string; blob?: Blob } | undefined> {
  contentType = contentType.toLowerCase();
  if (contentType.startsWith('image')) {
    const blob = new Blob([attachment]);
    return Promise.resolve({
      src: URL.createObjectURL(blob),
      type: 'image',
    });
  } else {
    return new Promise((resolve) => {
      const file = new Blob([attachment], { type: contentType });
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        resolve({
          src: reader.result,
          type: contentType.includes('zip') ? 'zip' : 'pdf',
          blob: file,
        });
      };
    });
  }
}

function download(binary: ArrayBuffer, type: string) {
  const file = new Blob([binary], { type });
  const url = window.URL.createObjectURL(file);
  const link = document.createElement('a');
  link.href = url;
  document.body.appendChild(link);
  link.click();
  link.parentNode!.removeChild(link);
}

export const AttachmentHelper = {
  parseContentType,
  download,
};
