import { Auth, LocalStorage } from '@/@types';
import { localStorageHelper } from '@/helper';
import React from 'react';

const checkPermission = (permission: Auth.Permissions.UserPermissions): boolean => {
  const authData = localStorageHelper.getItem(LocalStorage.Keys.AUTH);
  const permissions = authData?.user?.permissions || [];
  const res = permissions.includes(permission);
  return res;
};

export const withPermission = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  permission: Auth.Permissions.UserPermissions
) => {
  return function WithPermission(props: P) {
    if (!localStorageHelper.getItem(LocalStorage.Keys.AUTH)) {
      return null;
    }

    if (checkPermission(permission)) {
      return <WrappedComponent {...props} />;
    } else {
      return null;
    }
  };
};
