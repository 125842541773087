import { Resource, ResourceCategory, SignedUrlResponse } from '@/@types';
import { api, baseURL } from '@/providers';

function createResource(resourceName: string, resourceUrl: string, category?: ResourceCategory) {
  return api.post<Resource>(`/api/v1/resources`, {
    resourceName,
    resourceUrl,
    category,
  });
}

function deleteResource(id: number) {
  return api.delete(`/api/v1/resources/${id}`);
}

function deleteResourceAttachment(id: number) {
  return api.delete(`/api/v1/resources/${id}/attachment`);
}

function findResource(id: number) {
  return api.get<Resource>(`/api/v1/resources/${id}`);
}

function getResourceAttachmentURL(id: number): string {
  return `${baseURL}/api/v1/resources/${id}/attachment`;
}

function findResourceAttachment(id: number) {
  return api.get<ArrayBuffer>(`/api/v1/resources/${id}/attachment`, {
    responseType: 'arraybuffer',
  });
}

function findResourceAttachmentURL(id: number) {
  return api.get<{ url: string }>(`/api/v1/resources/${id}/attachment-url`);
}

function findResources() {
  return api.get<Resource[]>('/api/v1/resources');
}

function generateSignedUrl(fileName: string) {
  return api.get<SignedUrlResponse>('/api/v1/resources/generate', {
    params: { fileName },
  });
}

function updateResource(
  id: number,
  resourceName: string,
  resourceUrl: string,
  category?: ResourceCategory
) {
  return api.put<Resource>(`/api/v1/resources/${id}`, {
    resourceName,
    resourceUrl,
    category,
  });
}

export const resourcesService = {
  createResource,
  deleteResource,
  deleteResourceAttachment,
  findResource,
  getResourceAttachmentURL,
  findResourceAttachment,
  findResourceAttachmentURL,
  findResources,
  generateSignedUrl,
  updateResource,
};
