import { Button, cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  text: string;
  buttons: Array<{ label: string; onClick: () => void }>;
};

export function AppointmentsCardFooter({ text, buttons }: Props) {
  return (
    <div className='mx-auto text-forest-100 space-y-8 w-full'>
      <p className={cn(TextLayout.callout1, 'text-center max-w-2xl mx-auto')}>{text}</p>
      <div className='grid md:grid-cols-2 gap-4 items-center max-w-[530px] mx-auto'>
        {buttons.map((button, index) => (
          <Button
            key={index}
            onClick={button.onClick}
            variant='secondary'
            size='large'
            label={button.label}
          />
        ))}
      </div>
    </div>
  );
}
