import { Auth } from '@/@types';
import { api } from '@/providers';

function getAccessTokenByOktaAccessToken(accessToken: string) {
  return api.post<Auth.GetTokenByOktaAccessToken.Response>('/api/auth/okta/token', { accessToken });
}

function deleteAccessToken() {
  return api.delete<Auth.DeleteAcessToken.Response>('/api/auth/token');
}

export const AuthService = {
  getAccessTokenByOktaAccessToken,
  deleteAccessToken,
};
