import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';
import { CgSpinner as Spinner } from 'react-icons/cg';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
};

export function Button({ children, className, isLoading, ...props }: Props) {
  return (
    <button
      {...props}
      disabled={isLoading}
      className={classNames(
        'flex flex-row items-center justify-center cursor-pointer gap-1 py-4 px-16 rounded-4xl bg-kelp hover:bg-fern transition-colors text-stone font-medium',
        { 'bg-gray-400 hover:bg-gray-400 text-white cursor-not-allowed': isLoading },
        className
      )}
    >
      {isLoading ? (
        <>
          <Spinner className='animate-spin' />
          Loading...
        </>
      ) : (
        children
      )}
    </button>
  );
}
