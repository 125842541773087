import { motion } from 'framer-motion';
import { AnimationHelper, Button } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';

export type TableCheckboxAction = {
  label: string;
  onClick: () => void;
};

type Props = {
  actions: TableCheckboxAction[];
  deselectAll: any;
  length: number;
};

export function CustomTableCheckboxDialogMobile({ length, actions, deselectAll }: Props) {
  const onClick = useCallback((action: TableCheckboxAction) => {
    action.onClick();
  }, []);

  return (
    <motion.div
      initial='closed'
      animate='open'
      exit='closed'
      variants={{
        open: { translateY: 0 },
        closed: { translateY: '300%' },
      }}
      transition={{ duration: 0.8, ease: AnimationHelper.ease }}
      className='fixed bottom-0 right-0 z-20 text-white w-full flex flex-col items-center justify-end gap-1'
    >
      <div className='w-full flex flex-col gap-2 bg-product-forest-100 p-4 rounded-t-lg max-h-[50%] overflow-y-auto relative'>
        <div className='flex flex-col w-full'>
          <div className='flex flex-row justify-between text-white mt-5'>
            <span className='font-body text-[20px]'>{length} selected</span>
            <button className='underline cursor-pointer' onClick={deselectAll}>
              Deselect All
            </button>
          </div>
          <div className='flex flex-row justify-between gap-4 text-white'>
            {actions.map((action) => (
              <div key={action.label} className='bg-product-sand-100 rounded-[42px] my-5'>
                <Button
                  size='large'
                  variant='secondary'
                  label={action.label}
                  onClick={() => onClick(action)}
                />
              </div>
            ))}
          </div>
        </div>
        <div
          className='w-[170px] h-2 bg-product-sand-100 rounded-3xl cursor-pointer my-0 mx-auto'
          onClick={deselectAll}
          style={{ touchAction: 'none' }}
        />
      </div>
    </motion.div>
  );
}
