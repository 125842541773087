import { CustomTableHeader, CustomTableItems } from '@/@types';
import { cn, Checkbox } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TextLayout } from '../text';
import { CustomTableItemActionButtons } from './customTableItemActionButtonts';
import { CustomTableItemActionCollapse } from './customTableItemActionCollapse';
import { CustomTableItemActionMore } from './customTableItemActionMore';

type Props = {
  headers: CustomTableHeader[];
  item: CustomTableItems;
  isCollapseVisible?: boolean;
  displayItems: CustomTableItems[];
  onCollapse: VoidFunction;
  selectable?: boolean;
  checked?: boolean;
  isMobile?: boolean;
  stickyActions?: boolean;
  onChange?: (element: React.ChangeEvent<HTMLInputElement> | boolean) => void;
};

export function CustomTableItem({
  headers,
  item,
  isCollapseVisible = false,
  displayItems,
  onCollapse,
  selectable = false,
  checked = false,
  isMobile = false,
  stickyActions = false,
  onChange,
}: Props) {
  const [selected, setSelected] = useState(checked);
  const hasActions = useMemo(() => displayItems.some((i) => i.actions), [displayItems]);

  const isClickable = !!item.onClick || !!item.actions?.expand;
  const isCollapsable = item?.actions?.expand;
  let onClick = item?.onClick;
  if (isCollapsable) {
    onClick = onCollapse;
  }

  const handleOnChange = (element: React.ChangeEvent<HTMLInputElement>) => {
    setSelected(!selected);
    if (onChange) {
      onChange(element);
    }
  };

  const selectionOnClick = useCallback(() => {
    setSelected(!selected);
    if (onChange) {
      onChange(!selected);
    }
  }, [onChange, selected]);

  if (selectable && isMobile) {
    onClick = selectionOnClick;
  }

  useEffect(() => setSelected(checked), [checked]);

  const applyStickyClasses = useMemo(() => {
    if (isMobile || !stickyActions) {
      return '';
    }

    return 'sticky bg-white right-0 after:absolute after:w-px after:bg-sand-200 after:top-0 after:left-0 after:h-full';
  }, [isMobile, stickyActions]);

  return (
    <tr
      className={cn('w-full px-8 text-center z-[1]', {
        'border-b border-product-sand-200': !isCollapseVisible,
        'cursor-pointer duration-100 hover:bg-product-sand-100 ': isClickable,
        'bg-white': !selected,
        'bg-product-sand-100': selected,
      })}
      onClick={onClick}
    >
      {selectable && !isMobile && (
        <td className='py-5 px-10'>
          <div className='bg-product-sand-200 rounded-[4px]'>
            <Checkbox onChange={handleOnChange} variant='tertiary' checked={selected} />
          </div>
        </td>
      )}
      {headers.map((header) => (
        <td key={`${item.key}-${header.key}`} className='py-5 px-10 text-left'>
          <span className={cn(TextLayout.body1, 'text-product-forest-100')}>
            {item[header.key]}
          </span>
        </td>
      ))}
      {hasActions && (
        <td className={cn('py-5 px-10', applyStickyClasses)}>
          <div className='flex gap-5 items-center justify-end'>
            <CustomTableItemActionButtons item={item} />
            <CustomTableItemActionMore item={item} />
            <CustomTableItemActionCollapse
              isVisible={!!isCollapsable}
              isCollapsed={isCollapseVisible}
              onCollapse={onCollapse}
            />
          </div>
        </td>
      )}
    </tr>
  );
}
