import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const ChartExportFormSchema = z.object({
  startDate: z.string({ required_error: 'Select a start date' }),
  documentClass: z.preprocess(
    (value: unknown) => {
      if (value === undefined) {
        return [];
      }
      return value;
    },
    z.array(z.string()).min(1).nonempty({
      message: 'Please select at least one document class',
    })
  ),
});

export type ChartExportFormType = z.infer<typeof ChartExportFormSchema>;

export const ChartExportFormSchemaResolver = zodResolver(ChartExportFormSchema);
