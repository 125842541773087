import { Variants, motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { BasicCard } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function ProvidersContentLoading() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const cards = Array.from({ length: 6 }, (_, index) => (
    <motion.div key={index} variants={itemVariant} className='space-y-8'>
      <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
        <ContentLoader
          speed={2}
          width='100%'
          height={isMobile ? 80 : 100}
          viewBox={isMobile ? '0 0 400 100' : '0 0 600 100'}
          backgroundColor='#ccc'
          foregroundColor='#ddd'
        >
          {isMobile ? (
            <>
              <rect x='0' y='55%' rx='3' ry='3' width='70%' height='20' />
              <rect x='0' y='20' rx='3' ry='3' width='70%' height='20' />
              <rect x='75%' y='0' rx='3' ry='3' width='30%' height='100%' />
            </>
          ) : (
            <>
              <rect x='0' y='55%' rx='3' ry='3' width='70%' height='20' />
              <rect x='0' y='20' rx='3' ry='3' width='70%' height='20' />
              <rect x='75%' y='0' rx='3' ry='3' width='30%' height='100%' />
            </>
          )}
        </ContentLoader>
      </BasicCard>
    </motion.div>
  ));
  return (
    <motion.div
      initial='hidden'
      animate='show'
      exit='hidden'
      variants={container}
      className='grid grid-cols-1 justify-start gap-6 md:pt-[108px]'
    >
      <motion.div variants={itemVariant} className='space-y-8'>
        {cards}
      </motion.div>
    </motion.div>
  );
}
