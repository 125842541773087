import clsx from 'clsx';
import { useMemo } from 'react';
import { TextLayout } from '../text';

type Props = {
  title: string;
  content: string;
  signature?: string;
};

export function EncounterSummary({ title, content, signature }: Props) {
  const signatureFormatted = useMemo(() => {
    if (!signature) return { name: '', degree: '' };
    const [name, degree] = signature.toLowerCase().split(', ');
    return { name, degree };
  }, [signature]);

  return (
    <div className='w-full bg-noise-fern rounded-xl p-1 md:p-2'>
      <div className='rounded-lg border-[1px] border-product-forest-100 border-opacity-70 p-1'>
        <div className='rounded-[5px] border border-forest-100 border-opacity-30 py-4 px-3 md:p-7 grid md:grid-cols-[290px_1fr]'>
          <p className={clsx(TextLayout.callout2, 'text-white pb-14')}>{title}</p>
          <div>
            <p
              className={clsx(TextLayout.body1, 'text-white max-w-full lg:max-w-[70%] *:list-disc')}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {!!signature && (
              <div
                className={clsx(
                  TextLayout.body1,
                  'text-white max-w-full lg:max-w-[70%] *:list-disc mt-6'
                )}
              >
                <span>Warm regards,</span>
                <p className='capitalize'>
                  {signatureFormatted.name},{' '}
                  <span className='uppercase'>{signatureFormatted.degree}</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
