import { Button, cn, Sheet, TextLayout } from '@lib-atria/ui-toolkit';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useToastContext } from '@/contexts';
import { UserService } from '@/services';
import { patientEditSheetResolver, PatientEditSheetType } from './patientEditSheetSchema';
import { admViewRelations, Relation, selfKey } from '@/hooks';

type Patient = {
  patientId: number;
  name: string;
  relation: Relation;
  permissions: Array<string>;
};

type Patients = Array<Omit<Patient, 'name'>>;

type Props = {
  userId: string;
  isVisible?: boolean;
  onClose: VoidFunction;
  patient?: Patient;
  patients: Patients;
  permissions?: Array<{ id: string; name: string; description: string }>;
  onEdit: (patient: Omit<Patient, 'name'>) => void;
};

export function PatientEditSheet({
  userId,
  isVisible = false,
  onClose,
  patients,
  patient,
  permissions,
  onEdit,
}: Props) {
  const {
    control,
    formState: { errors },
    setValue,
    reset,
    handleSubmit,
  } = useForm<PatientEditSheetType>({ resolver: patientEditSheetResolver });
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToastContext();

  const hasErrors = Object.keys(errors).length > 0;

  const userRelationsOptions = useMemo(
    () => Object.entries(admViewRelations).map(([key, val]) => ({ label: val, value: key })),
    []
  );

  const onSubmit = useCallback(
    (values: PatientEditSheetType) => {
      setIsLoading(true);
      const newPatients = patients.map((p) => {
        if (p.patientId === patient!.patientId) {
          return { ...p, relation: values.relation as Relation, permissions: values.permissions };
        }
        return p;
      });
      const body = { appMetadata: { patients: newPatients } };
      UserService.updateUserById(userId, body)
        .then(() => {
          toast?.current.show({
            severity: 'success',
            summary: 'Updating patient',
            detail: 'Patient successfully updated',
            life: 2500,
          });
          onEdit({
            patientId: patient!.patientId,
            permissions: values.permissions,
            relation: values.relation as Relation,
          });
          onClose();
        })
        .catch((error) => {
          toast?.current.show({
            severity: 'error',
            summary: 'Updating patient',
            detail: error.response.data?.message || 'An unexpected error occurred',
            life: 2500,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [onClose, onEdit, patient, patients, toast, userId]
  );

  const patientPermissionsList = useMemo(() => {
    return patients.find((p) => p.patientId === patient?.patientId)?.permissions || [];
  }, [patient?.patientId, patients]);

  useEffect(() => {
    setValue('permissions', patientPermissionsList);
  }, [patientPermissionsList, setValue]);

  useEffect(() => {
    if (patient?.relation) {
      setValue('relation', patient.relation);
    }
  }, [patient?.relation, setValue]);

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <Sheet isVisible={isVisible} onClose={onClose}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={cn(TextLayout.callout1, 'font-display text-product-forest-100 mb-4')}>
          {patient?.name},
          <span className={cn(TextLayout.callout3, 'font-body  text-product-forest-100 p-2')}>
            MRN {patient?.patientId}
          </span>
        </p>
        <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Relation</p>
        <Controller
          control={control}
          name='relation'
          render={({ field }) => (
            <Dropdown
              {...field}
              value={field.value}
              options={userRelationsOptions}
              placeholder='Relation'
              className='w-full rounded-[42px] outline-none px-9 py-2 mb-4'
              disabled={patient?.relation === selfKey}
            />
          )}
        />
        {errors.relation?.message && (
          <p className={cn(TextLayout.body3, 'text-rust mb-4')}>{errors.relation.message}</p>
        )}
        <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Permissions</p>
        <Controller
          control={control}
          name='permissions'
          render={({ field }) => (
            <div className='pb-4'>
              <MultiSelect
                multiple
                className='w-full rounded-[42px] outline-none px-9 py-2 hover:ring-0'
                options={permissions}
                placeholder='Select permissions'
                optionLabel='description'
                optionValue='id'
                filter
                {...field}
              />
            </div>
          )}
        />
        {errors.permissions?.message && (
          <p className={cn(TextLayout.body3, 'text-rust mb-4')}>{errors.permissions.message}</p>
        )}
        <div className='w-full py-4 flex justify-end pr-10'>
          <Button type='submit' label='Save' disabled={isLoading || hasErrors} />
        </div>
      </form>
    </Sheet>
  );
}
