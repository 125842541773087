import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const UploadNewDocumentFormSchema = z.object({
  filename: z.string({
    required_error: 'Please provide a document name',
  }),
  file: z.instanceof(File),
  date: z.preprocess(
    (value: unknown) => {
      if (value) {
        return Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(new Date(String(value)));
      }
      return undefined;
    },
    z.string({
      required_error: 'Please select a date',
    })
  ),
  favorite: z.boolean().default(false),
});

export type UploadNewDocumentFormType = z.infer<typeof UploadNewDocumentFormSchema>;
export const UploadNewDocumentFormSchemaResolver = zodResolver(UploadNewDocumentFormSchema);
