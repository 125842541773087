import { useCallback } from 'react';
import { Button } from '@lib-atria/ui-toolkit';
import { useContactDialogContext } from '@/contexts';
import { TextLayout } from '../text';

type Props = {
  title: string;
  description: string;
};

export function CareTeamCardHeader({ title, description }: Props) {
  const { setContactDialogVisibility, setContactDialogTopic } = useContactDialogContext();

  const handleContact = useCallback(() => {
    setContactDialogVisibility(true);
    setContactDialogTopic(`General question`);
  }, [setContactDialogTopic, setContactDialogVisibility]);

  return (
    <div className=' flex flex-col justify-start items-left space-y-[18px] lg:w-[380px] w-full'>
      <p className={TextLayout.h2.scto}>{title}</p>
      <p className={TextLayout.body1}>{description}</p>
      <div className='w-fit'>
        <Button label='Contact' variant='primary' size='medium' onClick={handleContact} />
      </div>
    </div>
  );
}
