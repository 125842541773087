import { CustomTableFilters } from '@/@types';
import { Select } from '@lib-atria/ui-toolkit';

type FilterTableBySelectProps = {
  filters: CustomTableFilters;
  handleFilterChange: (key: string, value: any) => void;
};

export function FilterTableBySelect({ filters, handleFilterChange }: FilterTableBySelectProps) {
  return filters
    .filter((filter) => filter.type === 'select')
    .map((filter) => (
      <Select
        key={filter.key}
        options={[
          { value: '', label: 'All' },
          ...(filter.options?.map((option) => ({
            value: option,
            label: option,
          })) || []),
        ]}
        value={filter.value!}
        placeholder={filter.label}
        onChange={(e) => {
          handleFilterChange(filter.key, e);
          return () => {};
        }}
        className='ml-2 text-white'
      />
    ));
}
