import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';

import { LoginLoader } from '@/components';
import { env } from '@/constants';
import { useAuthContext } from '@/contexts';

export function OktaAuthRoute() {
  if (env.APP_NODE_ENV === 'test') {
    return <AutomatedTesting />;
  } else {
    return <OktaAuth />;
  }
}

function AutomatedTesting() {
  const { user, setUser } = useAuthContext();

  useEffect(() => {
    setUser({
      id: 'fake-id',
      name: 'test',
      email: 'test@test.com',
      isAdmin: true,
    });
  }, [setUser]);

  if (!user?.isAdmin) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <LoginLoader />
      </div>
    );
  }

  return <Outlet />;
}

function OktaAuth() {
  const { oktaAuth, authState } = useOktaAuth();
  const { user, setUser } = useAuthContext();
  const pendingLogin = useRef(false);

  useEffect(() => {
    async function init() {
      if (!authState) {
        return;
      }
      if (authState.isAuthenticated) {
        pendingLogin.current = false;
        return;
      }
      if (!authState.isAuthenticated) {
        if (pendingLogin.current) {
          return;
        }
        pendingLogin.current = true;
        const originalUri = toRelativeUrl(window.location.href, window.location.origin);
        oktaAuth.setOriginalUri(originalUri);
        await oktaAuth.signInWithRedirect();
      }
    }
    init();
  }, [authState, oktaAuth, setUser]);

  useEffect(() => {
    async function init() {
      if (authState?.isAuthenticated) {
        const oktaUser = await oktaAuth.getUser();

        setUser({
          id: oktaUser.sub!,
          name: oktaUser.name!,
          email: oktaUser.email!,
          isAdmin: true,
        });
      }
    }
    init();
  }, [authState?.isAuthenticated, oktaAuth, setUser]);

  if (!authState || !authState?.isAuthenticated || !user?.isAdmin) {
    return (
      <div className='flex items-center justify-center h-screen'>
        <LoginLoader />
      </div>
    );
  }

  return <Outlet />;
}
