import { Appointments } from '@/@types';
import { locations } from '@/config/locationsData';
import { useAuthContext } from '@/contexts';
import { AppointmentsCardLocation } from '../appointmentsCard';
import { HomeVisitCard } from './homeVisitCard';
import { PhoneCallCard } from './phoneCallCard';
import { TelehealthCard } from './teleHealthCard';

type Props = {
  appointmentGroup: Appointments.FindAllPatientsAppointments.GroupItem;
};

export function UpcomingAppointmentsLocation({ appointmentGroup }: Props) {
  const { patient } = useAuthContext();

  switch (appointmentGroup.location?.toLowerCase()) {
    case 'new york':
      return <AppointmentsCardLocation location={locations[0]} />;
    case 'palm beach':
      return <AppointmentsCardLocation location={locations[1]} />;
    case 'telehealth':
      return (
        <TelehealthCard
          appointments={appointmentGroup.appointments}
          cmoEmail={patient?.cmoEmail}
          cmoPhone={appointmentGroup.doctor?.pods.podPhone}
        />
      );
    case 'home':
      return (
        <HomeVisitCard
          appointments={appointmentGroup.appointments}
          address={appointmentGroup.address}
          doctor={appointmentGroup.doctor}
          cmoEmail={patient?.cmoEmail || ''}
        />
      );
    case 'phone':
      return (
        <PhoneCallCard
          appointments={appointmentGroup.appointments}
          phoneNumber={appointmentGroup.doctor!.pods.podPhone}
          cmoEmail={patient?.cmoEmail || ''}
        />
      );
    default:
      return null;
  }
}
