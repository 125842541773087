import { Link } from 'react-router-dom';

import { StringHelper } from '@/helper';
import { BasicCard } from '.';
import { BigButton } from '..';

type Props = {
  title: string;
  medicationsList?: {
    id: number;
    medication: string;
    prescribedBy: string;
  }[];
};

export function MedicationsListCard({ title, medicationsList }: Props) {
  return (
    <BasicCard className='h-full shadow-none bg-[#eeece5] rounded-xl px-5'>
      <p className='block font-medium leading-8 text-kelp mb-2'>{title}</p>
      {medicationsList?.map((medication) => (
        <div key={medication.id || medication.medication} className='mb-1'>
          <p className='text-lg leading-8 text-kelp grow'>
            {StringHelper.firstLetterUppercase(medication.medication)}
          </p>
        </div>
      ))}
      <Link to='/medications' className='mt-4'>
        <BigButton>View all medications</BigButton>
      </Link>
    </BasicCard>
  );
}
