import { useCallback } from 'react';

import { useLoaderContext } from '@/contexts';
import { ImagingResult } from '@/@types';
import { patientsService } from '@/services';
import { AttachmentHelper } from '@/helper';

export function usePatientImagingResults() {
  const { startLoader, stopLoader } = useLoaderContext();

  const findPatientImagingResults = useCallback(
    async (patientId: number): Promise<ImagingResult[]> => {
      startLoader();
      const { data } = await patientsService.getPatientImagingResults(patientId);
      stopLoader();
      return data;
    },
    [startLoader, stopLoader]
  );

  const findPatientImagingResultsDetailsAttachment = useCallback(
    async (patientId: number, documentId: number) => {
      try {
        const { data, headers } = await patientsService.getPatientImagingResultsDetailsAttachment(
          patientId,
          documentId
        );
        return AttachmentHelper.parseContentType(data, headers['content-type']);
      } catch (error) {
        return;
      }
    },
    []
  );

  const findPatientImagingResultAttachmentByPageId = useCallback(
    async (patientId: number, documentId: number, pageId: number) => {
      try {
        const { data, headers } = await patientsService.getPatientImagingResultAttachmentByPageId(
          patientId,
          documentId,
          pageId
        );
        return AttachmentHelper.parseContentType(data, headers['content-type']);
      } catch (error) {
        return;
      }
    },
    []
  );

  const findPatientImagingResultsDetails = useCallback(
    async (patientId: number, documentId: number) => {
      startLoader();
      const { data } = await patientsService.getPatientImagingResultsDetails(patientId, documentId);
      const documentData = data?.documentData?.replace(/\n/gi, '<br />');
      const result = { ...data, documentData } as ImagingResult;
      stopLoader();
      return result;
    },
    [startLoader, stopLoader]
  );

  return {
    findPatientImagingResults,
    findPatientImagingResultsDetailsAttachment,
    findPatientImagingResultsDetails,
    findPatientImagingResultAttachmentByPageId,
  };
}
