import { Button, cn, DisplayCard } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';
import { TextLayout } from '../text';
import { AppointmentsCardDivider, AppointmentsCardWrapper } from './';

type Props = {
  meetingId: string;
  passcode: string;
  phoneNumber?: string;
  emailCmo?: string;
  videoLink: string;
};

export function AppointmentsCardTelehealth({
  meetingId,
  passcode,
  phoneNumber,
  emailCmo,
  videoLink,
}: Props) {
  const handleEnterVideoTelehealth = useCallback(() => {
    window.open(videoLink, '_blank');
  }, [videoLink]);

  return (
    <DisplayCard>
      <AppointmentsCardWrapper title='Video visit'>
        <div className='flex flex-col items-center text-center mt-3'>
          <span className={TextLayout.body1}>
            Please use the following link to enter your video visit on Zoom.
          </span>
          <div className='my-12'>
            <Button
              label='Enter video visit'
              size='extra-large'
              type='button'
              variant='primary'
              onClick={handleEnterVideoTelehealth}
            />
          </div>
          <AppointmentsCardDivider />
          <div className={cn(TextLayout.body2, 'flex flex-col mt-6 space-y-4 text-gray-400 ')}>
            <p>
              For assistance, reach out to your care team at {phoneNumber} or {emailCmo}
            </p>
            {meetingId && passcode && (
              <>
                <p>Zoom details:</p>
                <div>
                  <p>Meeting ID: {meetingId}</p>
                  <p>Passcode: {passcode}</p>
                </div>
              </>
            )}
          </div>
        </div>
      </AppointmentsCardWrapper>
    </DisplayCard>
  );
}
