import { env } from '@/constants';
import { Button } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import { InputText } from 'primereact/inputtext';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMediaQuery } from 'react-responsive';

import { useAuthContext } from '@/contexts';
import { DialogMobile } from '../_common';
import { AuthZeroLoginFormSchemaResolver, AuthZeroLoginFormType } from './authZeroLoginFormSchema';

export const AuthZeroLoginForm = () => {
  const { control, handleSubmit } = useForm<AuthZeroLoginFormType>({
    resolver: AuthZeroLoginFormSchemaResolver,
  });
  const { loginWithRedirect } = useAuthContext();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const [invitation, setInvitation] = useState(false);

  const onSubmit = useCallback(
    async (form: AuthZeroLoginFormType) => {
      loginWithRedirect({ email: form.email });
    },
    [loginWithRedirect]
  );

  const noticeImage = `${env.APP_CDN_URL}/images/records/authzero-login-img.jpg`;

  return (
    <motion.div
      initial={{ opacity: 0, translateY: 100 }}
      animate={{ opacity: 1, translateY: 0 }}
      transition={{ duration: 1, bounce: 0.5 }}
    >
      <div className='relative flex h-screen items-center'>
        <div className='w-full max-w-sm p-4'>
          <h1 className='font-display text-experimental-forest text-center text-4xl'>Welcome</h1>
          <div className='mt-8 relative'>
            <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
              <div>
                <Controller
                  control={control}
                  name='email'
                  render={({ field, fieldState }) => (
                    <>
                      <InputText
                        name='email'
                        className='w-full rounded-[42px] outline-none px-9 py-5'
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || ''}
                        placeholder='Email'
                      />
                      {fieldState.error?.message && (
                        <span className='p-error text-sm'>{fieldState.error.message}</span>
                      )}
                    </>
                  )}
                />
              </div>
              <div className='flex flex-col mt-3'>
                <Button type='submit' label='Continue' size='large' />
              </div>
              <div className='text-center text-experimental-forest mt-10'>
                <a
                  className='p-button-link text-sm cursor-pointer'
                  onClick={() => isMobile && setInvitation(true)}
                  onMouseOver={() => !isMobile && setInvitation(true)}
                  onMouseLeave={() => !isMobile && setInvitation(false)}
                >
                  Request an invitation
                </a>
              </div>
              {!isMobile && invitation ? (
                <motion.div
                  initial={{ opacity: 0, translateY: 30 }}
                  animate={{ opacity: 1, translateY: 0 }}
                  transition={{ duration: 0.2 }}
                  className='relative'
                >
                  <div className='flex absolute p-4 mt-4 text-sm rounded-[10px] bg-experimental-forest text-white'>
                    <div className='w-full'>
                      <img src={noticeImage} className='rounded-md' alt='' />
                    </div>
                    <div className='ml-3 mt-2 leading-5'>
                      We would love to get you set up with an account. Reach out to your Care
                      Coordinator for a personal invitation.
                    </div>
                  </div>
                </motion.div>
              ) : (
                invitation && (
                  <DialogMobile
                    params={{ buttonClassNames: 'py-5 mb-2' }}
                    item={
                      <div className='flex space-x-5 items-center pb-4'>
                        <img src={noticeImage} alt='' className='w-1/3 rounded' />
                        <p className='text-sm leading-6'>
                          We would love to get you set up with an account. Reach out to your Care
                          Coordinator for a personal invitation.
                        </p>
                      </div>
                    }
                    handleOnClose={() => {
                      setInvitation(false);
                    }}
                  />
                )
              )}
            </form>
          </div>
        </div>
      </div>
    </motion.div>
  );
};
