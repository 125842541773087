import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadSelectEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';

import { useToastContext } from '@/contexts';

import {
  UploadNewResourceFormSchemaResolver,
  UploadNewResourceFormType,
} from './uploadNewResourceFormSchema';
import styles from './uploadResourceForm.module.css';
import { useResources } from '@/hooks';
import { Dropdown } from 'primereact/dropdown';
import { ResourceCategory, ResourceCategoryWithGeneral } from '@/@types';

type Props = {
  handleUpload: () => void;
};

export function UploadNewResourceForm({ handleUpload }: Props) {
  const { control, formState, handleSubmit, setValue, reset } = useForm<UploadNewResourceFormType>({
    resolver: UploadNewResourceFormSchemaResolver,
  });
  const { toast } = useToastContext();
  const { uploadNewResource } = useResources();

  const onFileSelect = (event: FileUploadSelectEvent) => {
    const selectedFile = event.files[0];
    setValue('file', selectedFile);
  };

  const onSubmit = async (form: UploadNewResourceFormType) => {
    try {
      await uploadNewResource(form.fileName, form.file, form.category);

      toast?.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'File uploaded successfully',
        life: 2500,
      });

      reset();
      handleUpload();
    } catch {
      toast?.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Document import failed',
        life: 2500,
      });
    }
  };

  return (
    <div className='flex w-full w-12/12'>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <Controller
          control={control}
          name='fileName'
          render={({ field, fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='fileName'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                Resource name
              </label>
              <InputText
                id='filename'
                name={field.name}
                className={classNames('my-2', { 'p-invalid': !!fieldState.error })}
                placeholder='Resource name'
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name='category'
          render={({ field, fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='category'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                Category
              </label>
              <Dropdown
                id='category'
                name={field.name}
                className={classNames('my-2', { 'p-invalid': !!fieldState.error })}
                value={field.value || ''}
                options={Object.values(ResourceCategory).map((key) => ({
                  value: key,
                  label: ResourceCategoryWithGeneral[key],
                }))}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name='file'
          render={({ fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='file'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                File
              </label>
              <FileUpload
                id='file'
                accept='application/pdf'
                chooseLabel='Choose file'
                className={styles.uploadButton}
                mode='basic'
                onSelect={onFileSelect}
              />
            </div>
          )}
        />

        <div className='mt-5'>
          <Button
            disabled={Object.values(formState.errors).length > 0}
            type='submit'
            label='Upload'
            className='w-full'
          />
        </div>
      </form>
    </div>
  );
}
