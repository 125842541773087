import ContentLoader from 'react-content-loader';

import { Theme } from '@/theme';
import classNames from 'classnames';

type Props = {
  width?: string | number;
  percentage?: number;
  className?: string;
};

export function CustomPdfViewerLoader({ percentage, className }: Props) {
  return (
    <div className={classNames('relative w-full h-full max-h-[600px]', className)}>
      <ContentLoader
        speed={2}
        width='100%'
        height='600px'
        backgroundColor={Theme.colors['product-sand-100']}
        foregroundColor='#ddd'
      >
        <rect x='0%' y='0%' rx='8' ry='8' width='100%' height='600px' />
      </ContentLoader>
      {percentage !== undefined && (
        <div className='absolute inset-0 flex items-center justify-center'>
          <span className='font-body text-sm font-medium leading-6 text-product-gray-400'>
            {Math.ceil(percentage)}% Loading...
          </span>
        </div>
      )}
    </div>
  );
}
