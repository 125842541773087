import { useMemo } from 'react';
import { CustomTableHeader, CustomTableItems, LabResult } from '@/@types';
import { StringHelper } from '@/helper';
import { CustomTable, ResultPageHeader } from '@/components';
import { cn, TextLayout } from '@lib-atria/ui-toolkit';

const tableHeaders: CustomTableHeader[] = [
  {
    label: 'Name',
    key: 'analyteNameHtml',
    sortKey: 'analyteName',
  },
  {
    label: 'Range',
    key: 'referenceRange',
    width: 200,
  },
  {
    label: 'Result',
    key: 'value',
    width: 200,
  },
  {
    label: 'Result Status',
    key: 'resultStatus',
    width: 200,
    sortKey: 'abnormalFlag',
  },
];

type Props = {
  labResult: LabResult;
};

export function LabResultDetailsCard({ labResult }: Props) {
  const analytesTableItems: CustomTableItems[] = useMemo(() => {
    return labResult.observations
      .filter((observation) => observation.resultsStatus !== 'cancelled')
      .map(({ note, ...observation }) => ({
        ...observation,
        resultStatus: observation.abnormalFlag && (
          <div className='flex'>
            <span
              className={cn(
                TextLayout.body2,
                'flex text-center justify-center rounded-full text-white py-1 px-3',
                observation.abnormalFlag == 'normal' ? 'bg-fern' : 'bg-rust-300'
              )}
            >
              {observation.abnormalFlag == 'normal' ? 'In range' : 'Out of range'}
            </span>
          </div>
        ),
        analyteNameHtml: StringHelper.firstLetterUppercase(observation.analyteName),
        id: observation.analyteId,
        value: observation.value ? `${observation.value} ${observation?.units || ''}`.trim() : '',
        referenceRange:
          !observation.referenceRange || observation.referenceRange === '*see table'
            ? ''
            : observation.referenceRange,
        actions: {
          ...(!!note && { expand: <pre>{note}</pre> }),
        },
      }));
  }, [labResult]);

  return (
    <>
      <ResultPageHeader result={labResult} />

      {labResult?.observations?.length > 0 && (
        <CustomTable
          sortable={['analyteNameHtml', 'resultStatus']}
          headers={tableHeaders}
          items={analytesTableItems}
        />
      )}
    </>
  );
}
