import { FindDashboardSummary } from '@/@types';
import { api } from '@/providers';

function findSummary(params: { patientId: number; atriaAppointment?: boolean }) {
  return api.get<FindDashboardSummary>('/api/v1/dashboard/summary', { params });
}

export const DashboardService = {
  findSummary,
};
