import { Auth } from '@/@types';

function convertToUserPermissionEnum(p: number | string) {
  const permission = p.toString() as Auth.Permissions.UserPermissions &
    Auth.Permissions.AdmPermissions;
  return permission;
}

export const AuthHelper = {
  convertToUserPermissionEnum,
};
