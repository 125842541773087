import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { EncounterMoreActionsButton } from '.';
import { DiamondIcon, DiamondSymbolsIcon, SquareIcon, SunRayIcon } from '../icons';
import { TextLayout } from '../text';
import { useContactDialogContext } from '@/contexts';

type Props = {
  type: 'square' | 'diamond' | 'diamond-symbols' | 'sun-ray';
  appointmentTitle: string;
  title: string;
  content: string;
};

export function EncounterNotesCard({ type = 'square', appointmentTitle, title, content }: Props) {
  const [showActions, setShowActions] = useState(false);
  const { setContactDialogVisibility, setContactDialogTopic } = useContactDialogContext();

  const isMobile = useMediaQuery({ query: '(max-width:768px)' });

  const handleCopyClipboard = useCallback(() => {
    const parser = new DOMParser();
    const htmlDocument = parser.parseFromString(content, 'text/html');
    const textContent = htmlDocument.body.innerHTML.replace(/<br\s*\/?>/gi, '\n') || '';
    const textToCopy = `${title.trim()}\n${textContent.trim()}`.trim();

    navigator.clipboard.writeText(textToCopy);
    setShowActions(false);
  }, [content, title]);

  const actions = [
    {
      label: 'Copy to clipboard',
      onClick: handleCopyClipboard,
    },
    {
      label: 'Contact care team',
      onClick: () => {
        setShowActions(false);
        setContactDialogVisibility(true);
        setContactDialogTopic(`${appointmentTitle}: ${title}`);
      },
    },
  ];

  return (
    <>
      <div className='bg-noise-sand w-full flex flex-col rounded-xl py-7 lg:py-9 px-5 lg:px-10 gap-6'>
        <div className='flex-1 grid grid-cols-1 lg:grid-cols-[189px_1fr_35px] xl:grid-cols-[289px_1fr_35px] gap-10 xl:gap-[102px] z-10'>
          <div className='grid grid-cols-[1fr_60px]'>
            <p
              className={clsx(
                isMobile ? TextLayout.callout1 : TextLayout.callout2,
                'text-product-forest-100 whitespace-pre-wrap'
              )}
            >
              {title}
            </p>
            {isMobile && (
              <div className='flex justify-end items-start relative'>
                <EncounterMoreActionsButton
                  actions={actions}
                  setShowActions={setShowActions}
                  showActions={showActions}
                />
              </div>
            )}
          </div>
          <div className='flex-1 flex flex-col items-start justify-start gap-6'>
            <p
              className={clsx(TextLayout.body1, 'text-product-forest-100 flex')}
              dangerouslySetInnerHTML={{ __html: content }}
            />
            {type === 'square' && <SquareIcon width={10} height={10} />}
            {type === 'diamond' && <DiamondIcon width={14} height={14} />}
            {type === 'diamond-symbols' && <DiamondSymbolsIcon width={14} height={14} />}
            {type === 'sun-ray' && <SunRayIcon width={16} height={16} />}
          </div>
          {!isMobile && (
            <div className='flex justify-end items-start relative'>
              <EncounterMoreActionsButton
                actions={actions}
                setShowActions={setShowActions}
                showActions={showActions}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
