type EnvType = {
  APP_NODE_ENV: string;
  APP_ATRIA_API_URL: string;
  APP_ATHENA_URL: string;
  APP_ATHENA_CLIENT_ID: string;
  APP_MEMBER_PORTAL_FRONTEND_URL: string;
  APP_CDN_URL: string;
  APP_SENTRY_DSN: string;
  APP_MIXPANEL_TOKEN: string;
  APP_OKTA_DOMAIN: string;
  APP_OKTA_CLIENT_ID: string;
  AUTH_ZERO_URL: string;
  AUTH_ZERO_CLIENT_ID: string;
  AUTH_ZERO_API_AUDIENCE: string;
  APP_FEATURE_FLAGS: {
    IS_TO_SHOW_AUTH_ZERO_LOGIN: boolean;
    IS_NEW_LETTER_TEMPLATE_VISIBLE: boolean;
    IS_TO_SHOW_CARE_TEAM_PAGE: boolean;
    IS_TO_SHOW_AI_ADMIN_PAGE: boolean;
    IS_TO_SHOW_IMMUNIZATIONS_PAGE: boolean;
    IS_TO_SHOW_MEDICAL_RECORDS_PAGE: boolean;
    IS_TO_USE_AUTH_ZERO_ACCESS_TOKEN: boolean;
    IS_TO_ENABLE_FAMILY_ASSOCIATION: boolean;
    IS_TO_SHOW_NEW_APPOINTMENTS_PAGE: boolean;
    IS_TO_SHOW_NEW_ADMIN_PATIENTS_PAGE: boolean;
    IS_TO_SHOW_NEW_HOME_PAGE: boolean;
  };
};
declare global {
  interface Window {
    env: EnvType;
  }
}

export const env: EnvType = { ...window.env };
