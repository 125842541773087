import { Appointments } from '@/@types';
import { CircleIcon, cn, DisplayCard, TextLayout } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { AppointmentsCardTitle } from './appointmentsCardTitle';

type Props = {
  appointments: Appointments.FindAllPatientsAppointments.AppointmentList;
  doctor?: Appointments.FindAllPatientsAppointments.Doctor;
  lastModified: string | null;
};

export function AppointmentsCardSchedule({ appointments, doctor, lastModified }: Props) {
  const scheduleItems = useMemo(() => {
    return appointments?.map((appointment) => ({
      title: appointment.title,
      time: `${appointment.duration}m`,
    }));
  }, [appointments]);

  return (
    <DisplayCard>
      <div className='flex w-full flex-col gap-4'>
        <div className='mx-auto flex w-full max-w-xs flex-col items-center justify-center space-y-3'>
          <AppointmentsCardTitle title='Schedule' />
          {!!doctor && (
            <div className='mx-auto flex w-full flex-col items-center justify-center gap-4 text-center'>
              <img src={doctor?.photo} className='w-28 rounded-lg' />
              <p className='text-gray-400'>
                Your evaluation across key health domains will be led by your Chief Medical Officer,{' '}
                <a href='#'>
                  {doctor?.profession} {doctor?.name}
                </a>
              </p>
            </div>
          )}
        </div>

        <ul className='w-full'>
          {scheduleItems?.map((item, index) => (
            <li key={index} className='flex w-full flex-row items-center justify-between py-2.5'>
              <div className='flex items-center'>
                <CircleIcon />
                <p className='text-forest-100'>{item.title}</p>
              </div>
              <span className='text-gray-400'>{item.time}</span>
            </li>
          ))}
        </ul>
        {!!lastModified && (
          <>
            <div className='w-4 border-b-2 border-product-sand-500 mx-auto mt-11' />
            <p className={cn(TextLayout.body1, 'text-product-gray-400 text-center mt-4')}>
              Your schedule was last adjusted on{' '}
              {DateTime.fromISO(lastModified).toFormat('MMM d, yyyy')}
            </p>
          </>
        )}
      </div>
    </DisplayCard>
  );
}
