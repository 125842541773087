import { Button } from '@lib-atria/ui-toolkit';
import { CustomTableItems } from '@/@types';
import { AtriaIconButton } from '../atriaLibrary';
import React from 'react';

type Props = {
  item: CustomTableItems;
};

export function CustomTableItemActionButtons({ item }: Props) {
  if (item.actions?.buttons?.length === 0) return null;

  return (
    <>
      {item.actions?.buttons && (
        <>
          {item.actions.buttons.map((button, idx) => {
            if (button.icon) {
              return (
                <React.Fragment key={`${button.label}_${idx}`}>
                  <AtriaIconButton
                    icon={button.icon}
                    className='hover:bg-kelp border border-product-sand-200 hover:border-kelp'
                    iconClassName='group-hover:text-white'
                    onClick={button.onClick}
                  />
                </React.Fragment>
              );
            }
            return (
              <React.Fragment key={`${button.label}_${idx}`}>
                <Button
                  variant='tertiary'
                  size='small'
                  label={button.label}
                  onClick={button.onClick}
                />
              </React.Fragment>
            );
          })}
        </>
      )}
    </>
  );
}
