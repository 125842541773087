import { cn } from '@lib-atria/ui-toolkit';
import { PanInfo, cubicBezier, motion } from 'framer-motion';
import { BigButton } from '../button';

type CustomTableMobileActionDialogProps = {
  item: string | React.ReactNode;
  handleOnClose: (event?: React.ChangeEvent<any>) => void;
  params?: {
    buttonClassNames?: string;
  };
};

const overlayVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, y: 0, transition: { duration: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.6 } },
};

export function DialogMobile({ item, handleOnClose, params }: CustomTableMobileActionDialogProps) {
  const handleDragEnd = (info: PanInfo) => {
    if (info.offset.y > 50) {
      handleOnClose();
    }
  };
  return (
    <>
      <motion.div
        variants={overlayVariants}
        initial='initial'
        animate='animate'
        exit='exit'
        className='fixed bottom-0 right-0 bg-protection-200 text-white w-[100vw] h-[100vh] z-20'
        onClick={handleOnClose}
      ></motion.div>
      <motion.div
        initial={{ y: '200', opacity: 1 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: '100%', opacity: 1 }}
        transition={{ duration: 0.9, ease: cubicBezier(0.65, 0, 0.35, 1) }}
        dragConstraints={{ top: 0 }}
        drag={'y'}
        onDragEnd={(_event, info) => handleDragEnd(info)}
        className='fixed bottom-0 right-0 bg-kelp text-white rounded-t-xl w-full h-auto p-8 z-30'
      >
        <div
          className='absolute top-[-12px] left-0 right-0 mx-auto w-8 h-1 bg-fern rounded-3xl cursor-pointer'
          onClick={handleOnClose}
          style={{ touchAction: 'none' }}
        />
        <div className='flex flex-col gap-2 pb-3'>{item}</div>
        <BigButton
          variant='white'
          className={cn('py-5', params?.buttonClassNames)}
          onClick={handleOnClose}
        >
          Close
        </BigButton>
      </motion.div>
    </>
  );
}
