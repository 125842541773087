import { cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  text: string;
};

export function CareTeamProviderNote({ text }: Props) {
  return (
    <div className='w-full bg-noise-fern rounded-xl relative p-2'>
      <div className='w-full border border-product-forest-100 rounded-lg p-1'>
        <div className='w-full border border-product-forest-100/40 rounded-[5px] py-7 md:py-14 px-5 md:px-10'>
          <p className={cn(TextLayout.body1, 'text-white text-left md:text-center')}>{text}</p>
        </div>
      </div>
    </div>
  );
}
