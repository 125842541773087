import { env } from '@/constants';

function getLoginURL(email: string) {
  const redirectURL = `${env.APP_MEMBER_PORTAL_FRONTEND_URL}/sign-in/callback?provider=auth0`;
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: env.AUTH_ZERO_CLIENT_ID,
    redirect_uri: redirectURL,
    scope: 'openid',
    state: '1',
    login_hint: email,
  });
  const url = `${env.AUTH_ZERO_URL}/authorize?${params.toString()}`;
  return url;
}

function getLogoutURL() {
  const redirectURL = `${env.APP_MEMBER_PORTAL_FRONTEND_URL}/sign-out`;
  const params = new URLSearchParams({
    returnTo: redirectURL,
    client_id: env.AUTH_ZERO_CLIENT_ID,
  });
  const url = `${env.AUTH_ZERO_URL}/v2/logout?${params.toString()}`;
  return url;
}

export const AuthZeroProvider = {
  getLoginURL,
  getLogoutURL,
};
