import { cn } from '@lib-atria/ui-toolkit';

import { AtriaIcon, SidebarVariants, TextLayout } from '@/components';

type Props = {
  variant?: SidebarVariants;
  isAdmin?: boolean;
  patientName: string;
};

export function SidebarMobileFooter({ variant, isAdmin = false, patientName }: Props) {
  return (
    <div className='absolute left-0 bottom-0 w-full flex items-end justify-between px-10 pb-10 z-10'>
      <div className='w-full max-w-[50%]'>
        <p
          className={cn(
            TextLayout.h2.ivar,
            isAdmin ? 'text-[36px] leading-[36px]' : 'text-[64px] leading-[56px]',
            'whitespace-pre-line',
            variant === 'forest' && 'text-white',
            variant === 'gray' && 'text-product-forest-100'
          )}
        >
          {patientName}
        </p>
      </div>
      <AtriaIcon width={33} height={45} fill='rgba(255,255,255,0.3)' />
    </div>
  );
}
