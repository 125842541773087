import { Auth } from '@/@types';
import { useAuthContext } from '@/contexts';
import { Navigate, Outlet } from 'react-router-dom';

type ProtectedRouteProps = {
  requiredPermission: Auth.Permissions.UserPermissions[];
};
type PermissionCheckProps = {
  permissions: Auth.Permissions.UserPermissions[] | undefined;
  requiredPermission: Auth.Permissions.UserPermissions[];
};

export function PermissionAuth({ requiredPermission }: ProtectedRouteProps) {
  const { user } = useAuthContext();

  const userHasPermission = PermissionCheck({
    permissions: user?.permissions || [],
    requiredPermission,
  });

  if (!userHasPermission) {
    return <Navigate to='/unauthorized' />;
  }

  return <Outlet />;
}

const PermissionCheck = ({ permissions, requiredPermission }: PermissionCheckProps): boolean => {
  return requiredPermission.every((permission) => permissions?.includes(permission) || false);
};
