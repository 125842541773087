import { Toaster } from 'react-hot-toast';

import { CheckNetworkConnectivity } from './components';
import { AppContexts } from './contexts';
import { AppRoutes } from './routes';
export function App() {
  return (
    <AppContexts>
      <CheckNetworkConnectivity />
      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
      <AppRoutes />
    </AppContexts>
  );
}
