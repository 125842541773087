import { Disclaimer, ImmunizationsCard, PageTitle } from '@/components';
import { useApplicationContext, useContactDialogContext, usePageContext } from '@/contexts';
import { useEffect } from 'react';

export function ImmunizationsPage() {
  const { setPageTitle } = usePageContext();
  const { getImmunizations, immunizations } = useApplicationContext();
  const { setContactDialogTopic, setContactDialogVisibility } = useContactDialogContext();

  useEffect(() => {
    function loadInitialData() {
      if (!immunizations) {
        getImmunizations();
      }
    }
    loadInitialData();
  }, [getImmunizations, immunizations]);

  useEffect(() => {
    setPageTitle('Immunizations');
  }, [setPageTitle]);

  const handleOpenContactCareButton = () => {
    setContactDialogTopic('Request to add immunizations');
    setContactDialogVisibility(true);
  };

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Your latest vaccines' />

      {immunizations?.length === 0 ? (
        <EmptyDisclaimer handleOpenContactCareButton={handleOpenContactCareButton} />
      ) : (
        <ImmunizationsCard />
      )}
    </div>
  );
}

const EmptyDisclaimer = ({
  handleOpenContactCareButton,
}: {
  handleOpenContactCareButton: () => void;
}) => {
  return (
    <div className='mb-4'>
      <Disclaimer
        text={
          <p>
            You will find your immunization records here once they&lsquo;re ready. If there are any
            immunizations you wish to add, please notify{' '}
            <span className='underline cursor-pointer' onClick={handleOpenContactCareButton}>
              your care team.
            </span>
          </p>
        }
      />
    </div>
  );
};
