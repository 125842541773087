import { DateHelper, DateModule, InfiniteScroll } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useRef } from 'react';

import { Appointments } from '@/@types';
import {
  useApplicationContext,
  useAuthContext,
  useContactDialogContext,
  useToastContext,
} from '@/contexts';
import { TimerHelper } from '@/helper';
import { useAppointments } from '@/hooks';
import { AppointmentsCardFooter, AppointmentsCardSchedule } from '../appointmentsCard';
import { AppointmentsSidebarList } from '../appointmentsSidebarList';
import { AtriaIcon } from '../icons';
import { DialogModalFormAppointments } from '../modal';
import { AppointmentsBlank } from '../pastAppointments/appointmentsBlank';
import { UpcomingAppointmentsLocation } from './upcomingAppointmentsLocation';
import { UpcomingSkeletonLoader } from './upcomingSkeletonLoader';

export function UpcomingAppointments() {
  const {
    upcomingAppointmentsSidebarList,
    hasMoreUpcomingAppointments,
    getUpcomingAppointmentsExtended,
  } = useApplicationContext();
  const { confirmAppointments } = useAppointments();
  const { patient } = useAuthContext();
  const { toast } = useToastContext();
  const {
    isContactDialogVisible,
    setContactDialogVisibility,
    setContent,
    setContactDialogTitle,
    setContactDialogTopic,
  } = useContactDialogContext();
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const infiniteScrollRef = useRef<any>();
  const sidebarListRef = useRef<any>();
  const infiniteScrollItemRef = useRef<IntersectionObserver>(
    new IntersectionObserver(
      (entries) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            sidebarListRef.current?.setKey(entry.target.getAttribute('id'));
          }
        }
      },
      { threshold: 0.3 }
    )
  );

  const handleScheduleAppointment = useCallback(() => {
    setContent(<DialogModalFormAppointments />);
    setContactDialogTitle('Request appointment');
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTitle, setContactDialogVisibility, setContent]);

  const endDateTime = useCallback((endTime: string) => {
    const endDateObj = DateTime.fromISO(endTime);
    if (endDateObj.toJSDate().getMinutes() > 0) {
      return endDateObj.toFormat('h:mma').toLowerCase();
    }
    return endDateObj.toFormat('ha').toLowerCase();
  }, []);

  const startDateTime = useCallback((startTime: string) => {
    const startDateObj = DateTime.fromISO(startTime);
    if (startDateObj.toJSDate().getMinutes() > 0) {
      return startDateObj.toFormat('h:mm');
    }
    return startDateObj.toFormat('h');
  }, []);

  const handleAskQuestion = useCallback(
    (dateSelected?: string, startDate?: string, endDate?: string) => {
      if (!dateSelected || !startDate || !endDate) {
        setContactDialogTopic('Question about my appointment');
        setContactDialogVisibility(!isContactDialogVisible);
        return;
      }
      const date = DateTime.fromISO(dateSelected);
      const formatted = DateHelper.formatDateToDisplay(date).split(',')[0];
      setContactDialogTopic(
        `Question about [${formatted} ${startDateTime(startDate)}-${endDateTime(endDate)}] appointment`
      );
      setContactDialogVisibility(!isContactDialogVisible);
    },
    [
      endDateTime,
      isContactDialogVisible,
      setContactDialogTopic,
      setContactDialogVisibility,
      startDateTime,
    ]
  );

  const updateAppointment = useCallback(
    async (
      groupItem: Appointments.FindAllPatientsAppointments.GroupItem,
      { confirmed, canceled }: { confirmed: boolean; canceled: boolean }
    ) => {
      try {
        const date = DateTime.fromISO(groupItem.date);
        const formatted = DateHelper.formatDateToDisplay(date).split(',')[0];
        const startDate = startDateTime(groupItem.startDate);
        const endDate = endDateTime(groupItem.endDate);
        const dateTitle = `[${formatted} ${startDate}-${endDate}]`;
        const messageToCMO: string = canceled
          ? `Cancellation request for their ${dateTitle} appointment.`
          : confirmed
            ? `Appointment confirmation for their ${dateTitle} appointment.`
            : `Appointment unconfirmed for their ${dateTitle} appointment.`;
        const success = await confirmAppointments({
          canceled,
          confirmed,
          keyGroup: groupItem.key,
          patientId: patient!.id,
          message: messageToCMO,
        });
        if (success) {
          toast?.current.show({
            severity: 'success',
            summary: 'Success',
            detail: `${canceled ? 'Appointment canceled' : confirmed ? 'Appointment confirmed' : 'Appointment canceled'}`,
            life: 2500,
          });
        } else {
          toast?.current.show({
            severity: 'warn',
            summary: 'Error',
            detail: 'The operation could not be completed, please try again.',
            life: 2500,
          });
        }
      } catch (error: any) {
        const message =
          error?.response?.data?.messages?.[0]?.message ||
          error?.response?.data?.message ||
          'An error occurred, please try again later.';
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: message,
          life: 2500,
        });
      }
    },
    [confirmAppointments, endDateTime, patient, startDateTime, toast]
  );

  const handleInfiniteScrollItemRef = useCallback((e: HTMLDivElement | null) => {
    if (e) {
      infiniteScrollItemRef.current.observe(e);
    }
  }, []);

  const handleSidebarItemClick = useCallback(async (sidebarItem: Appointments.SidebarList.Item) => {
    const { key, page } = sidebarItem;
    const element = document.getElementById(key);
    if (element) {
      infiniteScrollRef.current.selectPage(page);
      element?.scrollIntoView({ behavior: 'smooth' });
    } else {
      await infiniteScrollRef.current.loadMoreItems({ page });
      await TimerHelper.delay(300, () => {
        document.getElementById(key)?.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }, []);

  useEffect(() => {
    const handler = TimerHelper.debounce(() => {
      infiniteScrollRef.current?.cleanUp();
    }, 500);
    const root = document.getElementById('root');
    root?.addEventListener('scrollend', handler);
    return () => root?.removeEventListener('scrollend', handler);
  }, []);

  return (
    <div className='w-full grid grid-cols-1 gap-5 md:gap-[79px] relative md:grid-cols-[1fr_93px]'>
      <div className='flex-1 w-full flex flex-col gap-0.5'>
        <InfiniteScroll
          method='REPLACE'
          ref={infiniteScrollRef}
          hasMore={hasMoreUpcomingAppointments}
          fetchData={getUpcomingAppointmentsExtended}
          LoadingComponent={<UpcomingSkeletonLoader />}
          NoMoreContentComponent={<></>}
          EmptyList={
            <AppointmentsBlank
              onClick={handleAskQuestion}
              text='You don’t have any upcoming appointments scheduled at this time.'
            />
          }
          renderItem={(groupItem, index) => {
            return (
              <div key={groupItem.key} id={groupItem.key} ref={handleInfiniteScrollItemRef}>
                <div className='space-y-4 relative' ref={(el) => (sectionRefs.current[index] = el)}>
                  <DateModule
                    animated={false}
                    date={groupItem.date}
                    endTime={groupItem.endDate}
                    onAskQuestion={() =>
                      handleAskQuestion(groupItem.date, groupItem.startDate, groupItem.endDate)
                    }
                    onUndo={() =>
                      updateAppointment(groupItem, { confirmed: false, canceled: false })
                    }
                    startTime={groupItem.startDate}
                    onConfirm={() =>
                      updateAppointment(groupItem, { confirmed: true, canceled: false })
                    }
                    canceled={groupItem.canceled}
                    confirmed={groupItem.confirmed}
                    threeDots={[
                      {
                        label: 'Cancel appointment',
                        onClick: () => {
                          updateAppointment(groupItem, {
                            confirmed: false,
                            canceled: true,
                          });
                        },
                      },
                      {
                        label: 'Reschedule appointment',
                        onClick: () => {
                          handleAskQuestion(groupItem.date, groupItem.startDate, groupItem.endDate);
                        },
                      },
                    ]}
                  />
                  <UpcomingAppointmentsLocation appointmentGroup={groupItem} />
                  <AppointmentsCardSchedule
                    appointments={groupItem.appointments}
                    doctor={groupItem.doctor}
                    lastModified={groupItem.lastModified}
                  />
                </div>
                <AtriaIcon className='mx-0 w-full my-14 h-8 ' fill='#CBC6BD' />
              </div>
            );
          }}
        />

        {upcomingAppointmentsSidebarList.length > 0 && (
          <AppointmentsCardFooter
            text='Your care team is here to assist with your appointments. Please reach out at any time.'
            buttons={[
              { label: 'Schedule an appointment', onClick: handleScheduleAppointment },
              { label: 'Ask a question', onClick: handleAskQuestion },
            ]}
          />
        )}
      </div>
      <AppointmentsSidebarList
        items={upcomingAppointmentsSidebarList}
        ref={sidebarListRef}
        onClick={handleSidebarItemClick}
      />
    </div>
  );
}
