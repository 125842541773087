import { useCallback } from 'react';

import { LabResult } from '@/@types';
import { useLoaderContext } from '@/contexts';
import { patientsService } from '@/services';
import { AttachmentHelper } from '@/helper';

export function usePatientLabResults() {
  const { startLoader, stopLoader } = useLoaderContext();

  const findPatientLabResults = useCallback(
    async (patientId: number) => {
      startLoader();
      const { data } = await patientsService.getPatientLabResults(patientId);
      stopLoader();
      return data;
    },
    [startLoader, stopLoader]
  );

  const findPatientLabResult = useCallback(
    async (patientId: number, resultId: number): Promise<LabResult> => {
      startLoader();
      const { data } = await patientsService.getPatientLabResult(patientId, resultId);
      stopLoader();
      return data;
    },
    [startLoader, stopLoader]
  );

  const findPatientLabResultAttachment = useCallback(
    async (patientId: number, documentId: number) => {
      try {
        const { data, headers } = await patientsService.getPatientLabResultAttachment(
          patientId,
          documentId
        );
        if (data.byteLength === 0) {
          return;
        }
        return AttachmentHelper.parseContentType(data, headers['content-type']);
      } catch {
        return;
      }
    },
    []
  );

  const findPatientLabResultsAnalytes = useCallback(
    async (patientId: number, documentId: number) => {
      try {
        const { data, headers } = await patientsService.getPatientLabResultAnalytes(
          patientId,
          documentId
        );
        if (data.byteLength === 0) {
          return;
        }

        return AttachmentHelper.parseContentType(data, headers['content-type']);
      } catch {
        return;
      }
    },
    []
  );

  const findPatientLabResultAttachmentByPageId = useCallback(
    async (patientId: number, documentId: number, pageId: number) => {
      try {
        const { data, headers } = await patientsService.getPatientLabResultAttachmentByPageId(
          patientId,
          documentId,
          pageId
        );
        return AttachmentHelper.parseContentType(data, headers['content-type']);
      } catch {
        return;
      }
    },
    []
  );

  const downloadPatientLabResult = useCallback(async (patientId: number, documentId: number) => {
    try {
      const { data, headers } = await patientsService.downloadPatientLabResult(
        patientId,
        documentId
      );
      if (data.byteLength === 0) {
        return;
      }

      return AttachmentHelper.parseContentType(data, headers['content-type']);
    } catch {
      return;
    }
  }, []);

  return {
    downloadPatientLabResult,
    findPatientLabResults,
    findPatientLabResult,
    findPatientLabResultsAnalytes,
    findPatientLabResultAttachment,
    findPatientLabResultAttachmentByPageId,
  };
}
