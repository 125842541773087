import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ResourceCategory } from '@/@types/resource';

export const UploadNewResourceFormSchema = z.object({
  fileName: z.string({
    required_error: 'Please provide a resource name',
  }),
  file: z.instanceof(File),
  category: z.nativeEnum(ResourceCategory).optional(),
});

export type UploadNewResourceFormType = z.infer<typeof UploadNewResourceFormSchema>;
export const UploadNewResourceFormSchemaResolver = zodResolver(UploadNewResourceFormSchema);
