import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadSelectEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import classNames from 'classnames';

import { useResources } from '@/hooks';
import { useAuthContext, useToastContext } from '@/contexts';
import { Resource, ResourceCategory, ResourceCategoryWithGeneral } from '@/@types';

import {
  UploadEditedResourceFormSchemaResolver,
  UploadEditedResourceFormType,
} from './uploadEditedResourceFormSchema';
import styles from './uploadResourceForm.module.css';
import { useEffect } from 'react';
import { Dropdown } from 'primereact/dropdown';

type Props = {
  handleUpload: () => void;
  data: Resource;
};

export function UploadEditedDocumentForm({ data, handleUpload }: Props) {
  const { control, formState, handleSubmit, setValue, reset } =
    useForm<UploadEditedResourceFormType>({
      resolver: UploadEditedResourceFormSchemaResolver,
    });
  const { toast } = useToastContext();
  const { patient } = useAuthContext();
  const { uploadEditedResource } = useResources();

  const onFileSelect = (event: FileUploadSelectEvent) => {
    const selectedFile = event.files[0];
    setValue('file', selectedFile);
  };

  const onSubmit = async (form: UploadEditedResourceFormType) => {
    if (patient?.id) {
      try {
        await uploadEditedResource(
          data.id,
          form.fileName,
          data.resourceUrl,
          form.file,
          form.category
        );
        toast?.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'File uploaded successfully',
          life: 2500,
        });

        reset();
        handleUpload();
      } catch {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Document import failed',
          life: 2500,
        });
      }
    }
  };

  useEffect(() => {
    if (data) {
      setValue('fileName', data.resourceName);
      setValue('category', data.category || undefined);
    }
  }, [data, setValue]);

  return (
    <div className='flex w-full w-12/12'>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <Controller
          control={control}
          name='fileName'
          render={({ field, fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='fileName'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                Resource name
              </label>
              <InputText
                id='fileName'
                name={field.name}
                className={classNames('my-2', { 'p-invalid': !!fieldState.error })}
                placeholder='Document name'
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name='category'
          render={({ field, fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='category'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                Category
              </label>
              <Dropdown
                id='category'
                name={field.name}
                className={classNames('my-2', { 'p-invalid': !!fieldState.error })}
                value={field.value || ''}
                options={Object.values(ResourceCategory).map((key) => ({
                  value: key,
                  label: ResourceCategoryWithGeneral[key],
                }))}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name='file'
          render={({ fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='file'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                File
              </label>
              <FileUpload
                id='file'
                accept='application/pdf'
                chooseLabel='Choose file'
                className={styles.uploadButton}
                mode='basic'
                onSelect={onFileSelect}
              />
            </div>
          )}
        />
        <div className='mt-5'>
          <Button
            disabled={Object.values(formState.errors).length > 0}
            type='submit'
            label='Upload'
            className='w-full'
          />
        </div>
      </form>
    </div>
  );
}
