import { StringHelper } from '@/helper';
import { selfKey } from '@/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  patient: z.object(
    {
      id: z.preprocess(Number, z.number({ invalid_type_error: 'Select a patient' }), {
        message: 'Select a patient',
      }),
      name: z.string(),
    },
    { message: 'Select a patient' }
  ),
  relation: z
    .string()
    .min(1, 'Patient must contain one relation name')
    .refine((value) => StringHelper.compare(value, selfKey), '"Self" is not allowed'),
  permissions: z.array(z.string()).min(1, 'Patient must contain at least 1 permission'),
});

export type PatientAddSheetType = z.infer<typeof schema>;

export const patientAddSheetResolver = zodResolver(schema);
