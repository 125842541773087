import { useLoaderContext } from '@/contexts';
import { patientsService } from '@/services';
import { useCallback } from 'react';

export function useMedications() {
  const { startLoader, stopLoader } = useLoaderContext();

  const findPatientsMedicationsList = useCallback(
    async (patientId: number) => {
      startLoader();
      const { data } = await patientsService.findPatientsMedicationsList(patientId);
      stopLoader();

      return data;
    },
    [startLoader, stopLoader]
  );

  return {
    findPatientsMedicationsList,
  };
}
