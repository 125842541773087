import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';

type Props = {
  onClick: VoidFunction;
  text: string;
};

export function AppointmentsBlank({ onClick, text }: Props) {
  return (
    <div className='bg-white p-2 rounded-[12px]'>
      <div className='gap-3 rounded-[8px] w-full border-fern border-solid border-[1px] p-1 border-opacity-30'>
        <div className='flex flex-col justify-center items-center gap-8 rounded-[5px] w-full border-fern border-solid border-[1px] py-[64px] px-12 border-opacity-20'>
          <h1 className={cn(TextLayout.callout1, 'text-forest-100 text-center max-w-2xl')}>
            {text}
          </h1>
          <Button
            onClick={onClick}
            variant='secondary'
            size='large'
            label='Schedule an appointment'
          />
        </div>
      </div>
    </div>
  );
}
