import classNames from 'classnames';
import { useMemo } from 'react';

import { CustomPdfViewer, GalleriaToPDF } from '..';

type Attachment = { src: string; type: string; options?: AttachmentOptions };

export type AttachmentOptions = {
  borderVisible?: boolean;
  pageShadowVisible?: boolean;
  pageRounded?: boolean;
  toolbarVisible?: boolean;
  loaderContainerClassName?: string;
  fileName?: string;
};

type FilesProps = { src: Attachment['src']; options?: AttachmentOptions };

type Props = {
  attachments: Attachment[];
  options?: AttachmentOptions;
};

export function AttachmentViewer({ attachments, options }: Props) {
  const images = useMemo(() => attachments.filter(({ type }) => type === 'image'), [attachments]);
  const others = useMemo(() => attachments.filter(({ type }) => type !== 'image'), [attachments]);

  if (!attachments?.length) {
    return (
      <div className='w-full p-4'>
        <p className='font-body text-sm text-center text-gray-700'>No attachment found</p>
      </div>
    );
  }

  return (
    <>
      {images?.length > 0 && <GalleriaToPDF images={images} options={options} />}
      {others?.map((attachment, index) => (
        <RenderFile key={index} {...attachment} options={options} />
      ))}
    </>
  );
}

function RenderFile({ type, src, options }: Attachment) {
  switch (type) {
    case 'pdf':
      return <Pdf src={src} options={options} />;
    default:
      return null;
  }
}

function Pdf({ src, options }: FilesProps) {
  return (
    <div
      className={classNames(
        'w-full min-w-full bg-white rounded-lg flex items-center justify-center',
        { 'border border-gray-100': options?.borderVisible }
      )}
    >
      <CustomPdfViewer fileUrl={src} fileName={options?.fileName} options={options} />
    </div>
  );
}
