import { Sidebar } from 'primereact/sidebar';
import { UploadNewResourceForm } from './uploadNewResourceForm';
import { Resource } from '@/@types';
import { UploadEditedDocumentForm } from './uploadEditedResourceForm';

type UploadDocumentSidebarProps = {
  data?: Resource;
  isEditing?: boolean;
  visible: boolean;
  handleOnHide: () => void;
  handleDocumentUpload: () => void;
};

export function UploadResourceSidebar({
  data,
  isEditing,
  visible,
  handleOnHide,
  handleDocumentUpload,
}: UploadDocumentSidebarProps) {
  return (
    <Sidebar
      visible={visible}
      onHide={handleOnHide}
      position='right'
      className='min-w-[100vw] md:min-w-[500px]'
    >
      {isEditing && data ? (
        <UploadEditedDocumentForm handleUpload={handleDocumentUpload} data={data} />
      ) : (
        <UploadNewResourceForm handleUpload={handleDocumentUpload} />
      )}
    </Sidebar>
  );
}
