import { createContext, useCallback, useContext, useState } from 'react';

type AccordionType = {
  selected?: number;
  open: (index: number) => void;
};

const Context = createContext<AccordionType>(undefined!);

export const useAccordionContext = () => useContext(Context);

type Props = {
  children: React.ReactNode;
  header?: string;
  onOpen?: (id: number) => void;
};

export function Accordion({ children, header, onOpen }: Props) {
  const [selected, setSelected] = useState<number | undefined>(undefined);

  const open = useCallback(
    (index: number) => {
      setSelected((prev) => {
        if (prev === index) return;
        if (onOpen) onOpen(index);
        return index;
      });
    },
    [onOpen]
  );

  return (
    <Context.Provider value={{ selected, open }}>
      <div className='w-full rounded-lg bg-white overflow-hidden'>
        {header && (
          <div className='flex items-center min-h-[64px] py-4 px-8 bg-fern'>
            <p className='text-white text-xs italic font-bold uppercase tracking-[1.1px]'>
              {header}
            </p>
          </div>
        )}
        {children}
      </div>
    </Context.Provider>
  );
}
