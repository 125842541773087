import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomTableHeader, User } from '@/@types';
import { CustomTable, UserAddSheet, UserEditSheet, UserAddSheetType } from '@/components';
import { usePageContext, useToastContext } from '@/contexts';
import { admViewRelations, Relation, selfKey, useUsers } from '@/hooks';
import { UserService } from '@/services';

type UserItem = User.FindAllUsers.Response[0];

const headers: Array<CustomTableHeader> = [
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'email',
    label: 'Email',
  },
  {
    key: 'relation',
    label: 'Relation',
  },
  {
    key: 'permissions',
    label: 'Permissions',
  },
  {
    key: 'loginsCount',
    label: 'Logins count',
  },
  {
    key: 'lastLogin',
    label: 'Last login',
  },
  {
    key: 'createdAt',
    label: 'Created at',
  },
];

export function PatientEditPage() {
  const { id: userId } = useParams<{ id: string }>();
  const { setPageTitle, setIsBackButtonVisible } = usePageContext();
  const { user, findUserById, users, findAllUsers, setUsers, resendInvitation } = useUsers();
  const { toast } = useToastContext();

  const [isUserEditSheetVisible, setIsUserEditSheetVisible] = useState(false);
  const [isUserAddSheetVisible, setIsUserAddSheetVisible] = useState(false);
  const [userSelected, setUserSelected] = useState<UserItem | undefined>();

  const currentPatientId = useMemo(
    () => user?.appMetadata.patientId,
    [user?.appMetadata.patientId]
  );

  const handleSelectUser = useCallback((connectedUser: UserItem) => {
    setIsUserEditSheetVisible(true);
    setUserSelected(connectedUser);
  }, []);

  const deleteConnectedUser = useCallback(
    (deletedUser: UserItem) => {
      setUsers((prev) => {
        const usersListCopy = [...prev];
        const newUsersList = usersListCopy.filter((userItem) => userItem.id !== deletedUser.id);
        const body = {
          appMetadata: {
            patients: deletedUser.patients.filter((p) => p.patientId !== currentPatientId),
          },
        };
        UserService.updateUserById(deletedUser.id, body)
          .then(() => {
            toast?.current.show({
              severity: 'success',
              summary: 'Removing connected person',
              detail: 'Person was successfully disconnected',
              life: 2500,
            });
          })
          .catch((error) => {
            toast?.current.show({
              severity: 'error',
              summary: 'Removing connected person',
              detail: error.response.data?.message || 'An unexpected error occurred',
              life: 2500,
            });
            setUsers(usersListCopy);
          });
        return newUsersList;
      });
    },
    [currentPatientId, setUsers, toast]
  );

  const handleDeleteConnectedUser = useCallback(
    async (connectedUser: UserItem) => {
      const confirm = window.confirm('Are you sure you want to delete this access?');
      if (confirm) {
        return deleteConnectedUser(connectedUser);
      }
    },
    [deleteConnectedUser]
  );

  const createActionsButton = useCallback(
    (connectedUser: UserItem) => {
      const patient = connectedUser.patients.find((p) => p.patientId === currentPatientId!)!;
      return {
        actions: {
          more: [
            {
              label: 'Edit',
              onClick: () => handleSelectUser(connectedUser),
            },
            ...(connectedUser.registrationStatus === 'invited' && connectedUser.loginsCount === 0
              ? [{ label: 'Resend invite', onClick: () => resendInvitation(connectedUser.id) }]
              : []),
            ...(patient.relation !== selfKey
              ? [{ label: 'Delete', onClick: () => handleDeleteConnectedUser(connectedUser) }]
              : []),
          ],
        },
      };
    },
    [currentPatientId, handleDeleteConnectedUser, handleSelectUser, resendInvitation]
  );

  const connectedUsers = useMemo(
    () =>
      users.map((connectedUser) => {
        const patient = connectedUser.patients.find((p) => p.patientId === currentPatientId!)!;
        return {
          name: connectedUser.name,
          email: connectedUser.email,
          relation: admViewRelations[patient?.relation as Relation],
          loginsCount: connectedUser.loginsCount,
          lastLogin: connectedUser.lastLogin,
          createdAt: connectedUser.createdAt,
          permissions: (
            <Button
              variant='link'
              size='small'
              label={`${patient.permissions.length} permissions`}
              onClick={() => handleSelectUser(connectedUser)}
            />
          ),
          ...createActionsButton(connectedUser),
        };
      }),
    [createActionsButton, currentPatientId, handleSelectUser, users]
  );

  const handleOnEditUser = useCallback(
    (values: { userId: string; relation: Relation; permissions: Array<string> }) => {
      const currentUser = users.find((u) => u.id === values.userId)!;
      const updatedUser = {
        ...currentUser,
        patients: currentUser.patients.map((p) => {
          if (p.patientId === currentPatientId) {
            return {
              ...p,
              relation: values.relation,
              permissions: values.permissions,
            };
          }
          return p;
        }),
      };
      setUsers((prev) => {
        return [...prev].map((item) => {
          if (item.id === values.userId) {
            return updatedUser;
          }
          return item;
        });
      });
    },
    [currentPatientId, setUsers, users]
  );

  const handleOnAddUser = useCallback(
    (newUser: UserAddSheetType.OnAddParams) => {
      setUsers((prev) => {
        const copy = [...prev];
        copy.push({
          createdAt: newUser.createdAt,
          email: newUser.email,
          firstName: newUser.firstName,
          id: newUser.id,
          lastName: newUser.lastName,
          loginsCount: newUser.loginsCount,
          lastLogin: newUser.lastLogin,
          name: newUser.name,
          registrationStatus: newUser.registrationStatus,
          resendInviteCount: newUser.resendInviteCount || 0,
          patients: [
            {
              patientId: currentPatientId!,
              relation: newUser.relation,
              permissions: newUser.permissions,
            },
          ],
        });
        return copy;
      });
    },
    [currentPatientId, setUsers]
  );

  useEffect(() => {
    setPageTitle('Patients View');
    setIsBackButtonVisible(true);
    return () => {
      setIsBackButtonVisible(false);
    };
  }, [setIsBackButtonVisible, setPageTitle]);

  useEffect(() => {
    if (userId) {
      findUserById(userId);
    }
  }, [findUserById, userId]);

  useEffect(() => {
    if (user?.appMetadata?.patientId) {
      findAllUsers({ 'patients.patientId': user.appMetadata.patientId });
    }
  }, [findAllUsers, user?.appMetadata?.patientId]);

  return (
    <div className='py-10'>
      <h1 className={cn(TextLayout.callout1, 'text-product-forest-100 mb-4')}>
        {user?.appMetadata.firstName} {user?.appMetadata.lastName}
      </h1>
      <div className='w-full flex items-center justify-between pt-6 pb-2'>
        <h2 className={cn(TextLayout.callout2, 'text-product-forest-100 my-4')}>Connected users</h2>
        <Button
          label='Add'
          icon='add'
          iconVisibility='mobile-only'
          textVisibility='desktop-only'
          onClick={() => setIsUserAddSheetVisible(true)}
        />
      </div>
      <CustomTable headers={headers} items={connectedUsers} />
      {!!userSelected && (
        <UserEditSheet
          patientId={currentPatientId!}
          user={userSelected}
          isVisible={isUserEditSheetVisible}
          onClose={() => setIsUserEditSheetVisible(false)}
          onEdit={handleOnEditUser}
        />
      )}
      <UserAddSheet
        patientId={currentPatientId!}
        users={users}
        isVisible={isUserAddSheetVisible}
        onClose={() => setIsUserAddSheetVisible(false)}
        onAdd={handleOnAddUser}
      />
    </div>
  );
}
