import { useCallback } from 'react';

import { Appointment } from '@/@types';
import { patientsService } from '@/services';

export function usePatientAppointments() {
  const findPatientAppointments = useCallback(
    async (
      id: number,
      params: { startDate: string; endDate: string; atriaAppointment?: boolean }
    ): Promise<Appointment[]> => {
      const { data } = await patientsService.getPatientAppointments(id, params);
      return data;
    },
    []
  );

  return {
    findPatientAppointments,
  };
}
