import { Controller, useForm } from 'react-hook-form';

import { usePatients } from '@/hooks';
import { Button, InputMask } from '@/components';
import { dateOfBirthValidationResolver } from '@/validation';
import { useNavigate } from 'react-router-dom';

type Form = {
  dateOfBirth: string;
};

type Props = {
  patientId: number;
};

export function DateOfBirthValidation({ patientId }: Props) {
  const {
    control,
    formState: { isSubmitting },
    setValue,
    handleSubmit,
  } = useForm<Form>({ resolver: dateOfBirthValidationResolver });
  const { dateOfBirthValidation } = usePatients();
  const navigate = useNavigate();

  async function handleDateOfBirthSubmit(values: Form) {
    const dateOfBirth = Intl.DateTimeFormat('en-US', { dateStyle: 'short' }).format(
      new Date(values.dateOfBirth)
    );
    const success = await dateOfBirthValidation({
      patientId,
      dateOfBirth,
    });
    setValue('dateOfBirth', '');
    if (success) {
      navigate('/sign-in');
    }
  }

  return (
    <form onSubmit={handleSubmit(handleDateOfBirthSubmit)} className='flex flex-col items-start'>
      <p className='py-2'>Please, confirm your date of birth:</p>
      <Controller
        control={control}
        name='dateOfBirth'
        render={({ field, fieldState }) => (
          <InputMask
            {...field}
            fieldState={fieldState}
            mask='99/99/9999'
            placeholder='mm/dd/yyyy'
            className='w-full text-center'
          />
        )}
      />
      <Button type='submit' className='w-full my-6' isLoading={isSubmitting}>
        Send
      </Button>
    </form>
  );
}
