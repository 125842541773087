import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  email: z
    .string({ required_error: 'Please enter an email address' })
    .email({ message: 'Please enter a valid email address' }),
});

export type SearchUserByEmailType = z.infer<typeof schema>;

export const searchUserByEmailResolver = zodResolver(schema);
