import { ChartExportForm, PageTitle } from '@/components';
import { usePageContext } from '@/contexts';
import { useEffect } from 'react';

export function ChartExportPage() {
  const { setPageTitle } = usePageContext();

  useEffect(() => {
    setPageTitle('Export chart');
  }, [setPageTitle]);

  return (
    <div className='w-full flex-1 flex flex-col gap-16 md:gap-[6.25rem]'>
      <PageTitle title='Download your records' />
      <ChartExportForm />
    </div>
  );
}
