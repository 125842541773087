import { OverlayPanel } from 'primereact/overlaypanel';
import { useCallback, useRef } from 'react';
import { cn } from '@lib-atria/ui-toolkit';

import { AtriaIconButton } from '../atriaLibrary';
import { EncounterExpandedContent, ExpandedContentItem } from './encounterExpandedContent';

type Props = {
  actions: ExpandedContentItem[];
  showActions: boolean;
  setShowActions: (show: boolean) => void;
};

export function EncounterMoreActionsButton({ showActions, setShowActions, actions }: Props) {
  const overlayPanelRef = useRef<OverlayPanel>(null);

  const handleToggle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setShowActions(true);
      overlayPanelRef.current?.toggle(e);
    },
    [setShowActions]
  );

  const hide = useCallback(() => {
    setShowActions(false);
    overlayPanelRef.current?.hide();
  }, [setShowActions]);

  return (
    <>
      <AtriaIconButton
        icon='more_horiz'
        className={cn(
          'focus:shadow-transparent group hover:bg-kelp border border-product-sand-200  hover:border-kelp',
          showActions ? 'bg-product-forest-100 text-white' : 'bg-[#F2F2F2] text-fern'
        )}
        iconClassName={cn('text-fern group-hover:text-white', showActions && 'text-white')}
        onClick={handleToggle}
      />
      <OverlayPanel
        ref={overlayPanelRef}
        className='after:content-[] before:content-[] bg-transparent shadow-none'
        pt={{ content: { className: 'p-0 m-0' }, root: { className: 'p-0 m-0' } }}
        onHide={hide}
      >
        <EncounterExpandedContent items={actions} isVisible={showActions} />
      </OverlayPanel>
    </>
  );
}
