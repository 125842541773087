import { cn } from '@lib-atria/ui-toolkit';

type PageTitleProps = {
  title: string | React.ReactNode;
  className?: string;
};

export function PageTitle({ title, className }: PageTitleProps) {
  return (
    <h1
      className={cn([
        'block w-full font-display text-5xl leading-[48px] tracking-[-0.96px] md:text-[80px] md:leading-[80px] md:tracking-[-1.6px] text-kelp text-wrap',
        className,
      ])}
    >
      {title}
    </h1>
  );
}
