import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarType } from '@/@types';
import { StaggerChild, StaggerChildren } from '../../animation';
import { MaterialIcons } from '../../icons';
import { SidebarVariants } from '../sidebar';
import { SidebarListItemIcon } from './sidebarListItemIcon';

type Props = {
  variant?: SidebarVariants;
  item: SidebarType.Item;
  onClick: (url: string) => void;
  isSubItem?: boolean;
  prefix?: string;
};

export function SidebarListItem({ variant, item, onClick, isSubItem = false, prefix }: Props) {
  const location = useLocation();

  const hasChildren = useMemo(() => !!item.items?.length, [item]);

  const removePrefixFromURL = (url: string, p?: string) => {
    return url.substring(p?.length || 0, url.length) || '/';
  };

  const isSelected = useMemo(() => {
    const pathName = removePrefixFromURL(location.pathname, prefix);
    if (item.url === '/') return pathName === '/';

    const childrenURLs = item?.items?.map((i) => i.url) || [];
    const allURLs = [item.url, ...childrenURLs].map(
      (url) => url && removePrefixFromURL(url, prefix)
    ) as string[];

    return allURLs.some((url) => {
      if (url === '/') return pathName === '/';
      return pathName.startsWith(url);
    });
  }, [item?.items, item.url, location.pathname, prefix]);

  const [active, setActive] = useState<boolean>(isSelected);

  useEffect(() => {
    setActive(isSelected);
  }, [isSelected]);

  const handleClick = (url: string) => {
    if (url) onClick(url);
    else setActive((prev: boolean) => !prev);
  };

  return (
    <div>
      <button
        className={cn(
          'w-full flex items-center justify-between py-3 px-4 rounded-lg transition-all duration-150 hover:bg-product-forest-200 group',
          active && 'bg-product-forest-300',
          hasChildren && active && 'rounded-none rounded-t-lg bg-product-forest-200',
          isSubItem && 'pl-[44px]',
          isSubItem && variant === 'forest' && 'hover:bg-product-forest-300',
          isSubItem && variant === 'gray' && 'hover:bg-product-forest-300'
        )}
        onClick={() => handleClick(item.url!)}
      >
        <div className='flex-1 flex items-center justify-start gap-5'>
          {!!item.icon && (
            <SidebarListItemIcon variant={variant} icon={item.icon} isSelected={active} />
          )}
          <span
            className={cn(
              'font-body text-base font-medium leading-6 -tracking-[0.16px]',
              variant === 'forest' && !active && 'text-stone',
              variant === 'forest' && active && 'text-product-sand-100 group-hover:text-stone',
              variant === 'gray' && active && 'text-product-sand-100 group-hover:text-stone',
              variant === 'gray' && !active && 'text-product-forest-100 group-hover:text-stone',
              variant === 'gray' &&
                !active &&
                isSubItem &&
                'text-product-sand-100 group-hover:text-stone'
            )}
          >
            {item.label}
          </span>
        </div>
        {hasChildren && (
          <motion.i
            initial={active ? 'open' : 'closed'}
            variants={{
              open: { rotate: '180deg', opacity: 1 },
              closed: { rotate: '0deg', opacity: 1 },
            }}
            transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            animate={active ? 'open' : 'closed'}
          >
            <MaterialIcons
              name='expand_more'
              className={cn(
                'leading-4',
                variant === 'forest' && 'text-product-sand-100',
                variant === 'gray' && 'text-product-forest-100 group-hover:text-product-sand-100',
                variant === 'gray' && active && 'text-product-sand-100'
              )}
            />
          </motion.i>
        )}
      </button>
      <AnimatePresence>
        {hasChildren && active && (
          <motion.div
            initial='closed'
            animate='open'
            exit='closed'
            variants={{
              open: { y: 0, opacity: 1 },
              closed: { y: -20, opacity: 0 },
            }}
            transition={{ duration: 0.4, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            className='bg-product-forest-200 rounded-b-lg pt-1 pb-2 px-2 -z-10'
          >
            <StaggerChildren duration={0.2}>
              <div className='w-full flex flex-col gap-1'>
                {item.items?.map((subItem) => (
                  <StaggerChild key={subItem.label}>
                    <SidebarListItem
                      variant={variant}
                      item={subItem}
                      onClick={() => onClick(subItem.url)}
                      prefix={prefix}
                      isSubItem
                    />
                  </StaggerChild>
                ))}
              </div>
            </StaggerChildren>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
