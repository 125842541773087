import { Variants, motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';

import { BasicCard } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function HomeContentLoading() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <motion.div
      initial='hidden'
      animate='show'
      exit='hidden'
      variants={container}
      className='grid grid-cols-1 lg:grid-cols-2 justify-start gap-6 py-6'
    >
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 260 : 125}
            viewBox={isMobile ? '0 0 300 260' : '0 0 600 125'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='50%' height='50' />
                <rect x='0%' y='25%' rx='3' ry='3' width='100%' height='120' />
                <rect x='0%' y='80%' rx='3' ry='3' width='100%' height='60' />
              </>
            ) : (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='30%' height='20' />
                <rect x='0%' y='25%' rx='3' ry='3' width='30%' height='60' />
                <rect x='0%' y='80%' rx='3' ry='3' width='20%' height='20' />
                <rect x='65%' y='45' rx='3' ry='3' width='30%' height='30' />
                <rect x='70%' y='85' rx='3' ry='3' width='20%' height='20' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 260 : 125}
            viewBox={isMobile ? '0 0 300 260' : '0 0 600 125'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='50%' height='50' />
                <rect x='0%' y='25%' rx='3' ry='3' width='100%' height='120' />
                <rect x='0%' y='80%' rx='3' ry='3' width='100%' height='60' />
              </>
            ) : (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='30%' height='20' />
                <rect x='0%' y='25%' rx='3' ry='3' width='30%' height='60' />
                <rect x='0%' y='80%' rx='3' ry='3' width='20%' height='20' />
                <rect x='65%' y='45' rx='3' ry='3' width='30%' height='30' />
                <rect x='70%' y='85' rx='3' ry='3' width='20%' height='20' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 260 : 200}
            viewBox={isMobile ? '0 0 300 260' : '0 0 600 200'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='50%' height='50' />
                <rect x='0%' y='25%' rx='3' ry='3' width='100%' height='120' />
                <rect x='0%' y='80%' rx='3' ry='3' width='100%' height='60' />
              </>
            ) : (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='30%' height='20' />
                <rect x='0%' y='20%' rx='3' ry='3' width='50%' height='60' />
                <rect x='65%' y='20%' rx='3' ry='3' width='30%' height='30' />
                <rect x='0' y='60%' rx='35' ry='35' width='100%' height='70' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 260 : 200}
            viewBox={isMobile ? '0 0 300 260' : '0 0 600 200'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='50%' height='50' />
                <rect x='0%' y='25%' rx='3' ry='3' width='100%' height='120' />
                <rect x='0%' y='80%' rx='3' ry='3' width='100%' height='60' />
              </>
            ) : (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='30%' height='20' />
                <rect x='0%' y='20%' rx='3' ry='3' width='50%' height='60' />
                <rect x='65%' y='20%' rx='3' ry='3' width='30%' height='30' />
                <rect x='0' y='60%' rx='35' ry='35' width='100%' height='70' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 260 : 230}
            viewBox={isMobile ? '0 0 300 260' : '0 0 600 230'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='50%' height='50' />
                <rect x='0%' y='25%' rx='3' ry='3' width='100%' height='120' />
                <rect x='0%' y='80%' rx='3' ry='3' width='100%' height='60' />
              </>
            ) : (
              <>
                <rect x='0' y='0' rx='3' ry='3' width='20%' height='25' />
                <rect x='0' y='20%' rx='3' ry='3' width='60%' height='20' />
                <rect x='0' y='35%' rx='3' ry='3' width='60%' height='20' />
                <rect x='0' y='50%' rx='3' ry='3' width='60%' height='20' />
                <rect x='0' y='70%' rx='35' ry='35' width='100%' height='70' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 260 : 230}
            viewBox={isMobile ? '0 0 300 260' : '0 0 600 230'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0%' rx='3' ry='3' width='50%' height='50' />
                <rect x='0%' y='25%' rx='3' ry='3' width='100%' height='120' />
                <rect x='0%' y='80%' rx='3' ry='3' width='100%' height='60' />
              </>
            ) : (
              <>
                <rect x='0' y='0' rx='3' ry='3' width='20%' height='25' />
                <rect x='0' y='20%' rx='3' ry='3' width='60%' height='20' />
                <rect x='0' y='35%' rx='3' ry='3' width='60%' height='20' />
                <rect x='0' y='50%' rx='3' ry='3' width='60%' height='20' />
                <rect x='0' y='70%' rx='35' ry='35' width='100%' height='70' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
    </motion.div>
  );
}
