import { Doctor } from './';
import { PaginatingTypes } from './paginating';

type SelectOption = { id: number; label: string };

export type Appointment = {
  id: number;
  patientId: number;
  appointmentId: number;
  date: string;
  end: string;
  description?: string | null;
  title: string;
  type: string;
  typeId: number;
  resourceId: number;
  statusid: any;
  patientName: string;
  duration: number;
  firstname: string;
  lastname: string;
  atriaAppointment: boolean;
  providerId: number;
  providerName: string;
  additionalProviders: SelectOption[];
  rooms: { id: string | number; label: string }[];
  confirmed: boolean;
  checkIn: string;
  checkOut: string;
  encounterState?: 'OPEN' | 'CLOSED';
  encounterId?: number;
  appointmentStatus: string;
  hasLetter: boolean;
  doctor?: Doctor;
  conferenceLink?: string;
  conferenceId?: string;
  conferencePassword?: string;
};
export namespace Appointments {
  export namespace SidebarList {
    export type Item = {
      key: string;
      date: string;
      page: number;
    };
  }

  export namespace FindAllPatientsAppointments {
    export type Params = {
      patientId: number;
      startDate: string;
      endDate?: string;
      athenaAppointments?: boolean;
      atriaAppointment?: boolean;
      includesDocuments?: boolean;
      limit?: number;
      page?: number;
    };

    export type GroupItem = {
      key: string;
      date: string;
      location: string;
      appointments: Array<{
        id: number;
        appointmentId: number;
        patientId: number;
        patientPrimaryProviderId?: number;
        date: string;
        duration: number;
        end: string;
        title: string;
        description?: string;
        type?: string;
        typeId?: number;
        providerId: number;
        providerName: string;
        patientName: string;
        firstName: string;
        lastName: string;
        firstNameUsed: any;
        createdAt: string;
        updatedAt: string;
        deletedAt: any;
        confirmed: boolean;
        atriaAppointment: boolean;
        encounterId: any;
        encounterState: any;
        athenaDepartmentId?: number;
        providers: Array<{
          id: number;
          name: string;
          type: string;
        }>;
        rooms: Array<{
          id: number;
          name: string;
          type: string;
        }>;
        conflicts: Array<any>;
        hasLetter: boolean;
        athenaAppointmentId: any;
        hasConflict: boolean;
        googleCalendarEventId: any;
        conferenceLink?: string | null;
        conferencePassword?: string | null;
        conferenceId?: string | null;
        address?: string | null;
        addressDetails?: string | null;
        addressNumber?: string | null;
        city?: string | null;
        state?: string | null;
        zipCode?: string | null;
        country?: string | null;
        latitude?: string | null;
        longitude?: string | null;
        rescheduledAppointmentId?: string | null;
      }>;
      startDate: string;
      endDate: string;
      lastModified: string | null;
      confirmed: boolean;
      canceled: boolean;
      labResults: Array<any>;
      imagingResults: Array<any>;
      appointmentNotes: Array<any>;
      providerId: string;
      address: any;
      doctor?: {
        title: string;
        photo: string;
        location: Array<string>;
        cmoNumber: string;
        affiliations: Array<{
          description: string;
          title: string;
        }>;
        pods: {
          podPhone: string;
          podMembers: Array<{
            title: string;
            name: string;
            location: string;
          }>;
        };
        degree: string;
        profession: string;
        name: string;
        _id: string;
        description: Array<string>;
        athenaProvider: string;
        credentials: Array<{
          description: string;
          title: string;
        }>;
        awards: Array<{
          description: string;
          title: string;
        }>;
        tags: Array<string>;
        id: string;
      };
    };

    export type GroupItemContext = GroupItem & {
      confirmed: boolean;
      canceled: boolean;
      metadata: {
        loaded?: boolean;
        page: number;
        firstElement: boolean;
        lastElement: boolean;
      };
    };

    export type GroupItemConfirmedPaginated = Array<
      PaginatingTypes.FullPaginatedItem<GroupItemContext>
    >;

    export type GroupList = Array<GroupItem>;

    export type Response = PaginatingTypes.FullPaginatedList<GroupItem>;

    export type Doctor = GroupItem['doctor'];

    export type AppointmentList = GroupItem['appointments'];
  }
  export namespace GetPatientsResultList {
    export type Params = FindAllPatientsAppointments.Params & {
      pagesLoaded: Array<number>;
      appointmentCallback: React.Dispatch<
        React.SetStateAction<Appointments.FindAllPatientsAppointments.GroupItemContext[]>
      >;
    };

    export type Response = {
      hasMore: boolean;
      sidebarList: Array<{ key: string; date: string; page: number }>;
      appointments: Array<Appointments.FindAllPatientsAppointments.GroupItemContext>;
    };
  }

  export namespace ConfirmAppointment {
    export type Params = {
      patientId: number;
      message: string;
      confirmed: boolean;
    };

    export type Response = {
      success: boolean;
    };
  }

  export type UpcomingAppointment = {
    appointments: Appointment[];
    key: string;
    date: string;
    endDate: string;
    startDate: string;
    lastModified: string | null;
    doctor?: Doctor;
    patientPhone?: string;
    address?: string;
    confirmed: boolean;
    appointmentNotes: Array<{
      appointmentId: number;
      encounterId: number;
      title: string;
    }>;
    labResults: Array<{
      id: number;
      status: string;
      description: string;
      documentDescription?: string;
      resultsStatus: string;
      resultCategory: string;
      patientNote: string;
      createdDateTime: string;
      observations?: {
        resultsStatus: string;
        referenceRange?: string;
        units: string;
        analyteId: number;
        analyteName: string;
        note?: string;
        value: string;
        loinc: string;
        abnormalFlag?: string;
      }[];
    }>;
    location: 'New York' | 'Palm Beach' | 'Telehealth' | 'Home' | 'Phone';
    canceled: boolean;
  };

  export type Response = UpcomingAppointment[];

  export type UpdateAppointment = {
    ids: number[];
    confirmed: boolean;
    canceled: boolean;
    keyGroup?: string;
    patientId?: number;
    dateSelected?: string;
    lastModified?: string;
    startDate?: string;
    endDate?: string;
    message?: string;
  };

  export type UpdateAppointmentResponse = {
    success: boolean;
  };
}

export type AppointmentCard = {
  id: number;
  appointmentId: number;
  start: string;
  providerName: string;
  title: string;
  hasEncounter: boolean;
  hasLetter: boolean;
  encounterId?: number;
};

export type AppointmentEncounterSummary = {
  summaryHtml: string;
};

export type AppointmentLetter = {
  summaryJSON?: LetterJSON;
  summaryHtml: string;
  attachmentsDocumentId?: number;
  appointment: {
    title: string;
    date: string;
  };
};

type LetterJSON = {
  patient: {
    id: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    age: string;
    sex: string;
    dob: string;
    address: {
      address1: string;
      city: string;
      state: string;
      zip: string;
    };
    phone: {
      home: string;
      work?: string;
    };
  };
  encounter: Partial<{
    reason: string;
    info: string;
    historyOfPresentIllness: string;
    reviewOfSystems: string;
    vitals?: { [key: string]: any }[];
    physicalExam: string;
    assessmentAndPlan: string;
    discussionNotes?: string;
  }>;
  provider?: Partial<{
    signature: string;
    name: string;
  }>;
};

export enum AppointmentSelectionType {
  UPCOMING,
  PAST,
}
