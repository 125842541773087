import { useMediaQuery } from 'react-responsive';
import { Button } from '@lib-atria/ui-toolkit';
import { BackButton, ContactButton } from '../button';
import { DownloadIcon } from '../icons';
import { PageTitle } from '../pageTitle';

type Props = {
  attachmentsDocumentId?: number;
  pageTitle: string;
  buttonTitle: string;
};

export function EncounterLetterHeader({ attachmentsDocumentId, pageTitle, buttonTitle }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <div className='w-full'>
      <div className='flex justify-between w-full mb-8'>
        <BackButton />
        <div className='flex md:gap-2 gap-2'>
          {attachmentsDocumentId && (
            <a
              target='_blank'
              href={'/patient/chart/pdf/' + attachmentsDocumentId}
              rel='noreferrer'
            >
              <Button
                label={isMobile ? <DownloadIcon width={13} height={13} /> : 'Download'}
                variant='tertiary'
                size='medium'
              />
            </a>
          )}

          <ContactButton icon='email' label={buttonTitle} topic={pageTitle} />
        </div>
      </div>

      <PageTitle title={pageTitle} className='md:ml-[55px]' />
    </div>
  );
}
