import { env } from '@/constants';
import { ConsoleEventAnalytics } from './consoleEventAnalytics';
import { EventAnalytics } from './eventAnalytics';
import { MixPanelAnalytics } from './mixPanelAnalytics';

export class EventAnalyticsFactory {
  create(): EventAnalytics {
    if (env.APP_NODE_ENV === 'production') {
      return new MixPanelAnalytics();
    }
    return new ConsoleEventAnalytics();
  }
}

export const eventAnalytics = new EventAnalyticsFactory().create();
