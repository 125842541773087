import { useCallback, useEffect, useMemo, useState } from 'react';

import { cn } from '@lib-atria/ui-toolkit';
import { ProvidersContentLoading } from '../loading';
import { CareTeamProviderItem } from './careTeamProviderItem';
import { CareTeamProviderListItem } from './careTeamProviderListItem';
import { CareTeamProvidersSidebar } from './careTeamProvidersSidebar';

type Props = {
  data: CareTeamProviderList;
  paginationLimit?: number;
  isSidebarVisible?: boolean;
};

type CareTeamProviderList = {
  speciality: string;
  note?: string;
  providers: CareTeamProviderItem[];
}[];

export function CareTeamProviderList({
  data,
  paginationLimit = 9,
  isSidebarVisible = true,
}: Props) {
  const [list, setList] = useState<CareTeamProviderList>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const locations = useMemo(() => {
    const output: string[] = [
      ...new Set(data.map((i) => i.providers.map((p) => p.locations).flat()).flat()),
    ];
    output.push('All locations');
    return output;
  }, [data]);
  const [location, setLocation] = useState<string>(locations[locations.length - 1]);
  const specialities = useMemo(() => {
    return data.filter((i) => i.providers.length > 0).map((i) => i.speciality);
  }, [data]);

  const handleOnSpecialitySelect = useCallback((speciality: string) => {
    document.getElementById(speciality)?.scrollIntoView({
      behavior: 'smooth',
    });
  }, []);

  const updateList = useCallback(
    (newLocation: string) => {
      setList(() => {
        if (!isSidebarVisible || newLocation === 'All locations') {
          return data;
        }
        return data.map((item) => {
          const providers = item.providers
            .filter((p) => p.locations.includes(newLocation))
            .sort((a, b) => a.name.localeCompare(b.name));
          return { ...item, providers };
        });
      });
    },
    [data, isSidebarVisible]
  );

  const handleOnLocationSelect = useCallback(
    (newLocation: string) => {
      setLoading(true);
      setTimeout(() => {
        setLocation(newLocation);
        setLoading(false);
      }, 800);

      updateList(newLocation);
    },
    [updateList]
  );

  useEffect(() => {
    const defaultLocation = locations[locations.length - 1];
    updateList(defaultLocation);
  }, [locations, updateList]);

  return (
    <div
      className={cn(
        'w-full grid grid-cols-1 gap-5 md:gap-[78px] relative',
        isSidebarVisible && 'md:grid-cols-[1fr_93px]'
      )}
    >
      <div className='flex-1 w-full flex flex-col gap-0.5'>
        <div className='w-full flex flex-col gap-0.5 pb-16'>
          {loading ? (
            <ProvidersContentLoading />
          ) : (
            <>
              {list.map(({ speciality, note, providers }) => (
                <CareTeamProviderListItem
                  key={speciality}
                  speciality={speciality}
                  note={note}
                  providers={providers}
                  paginationLimit={paginationLimit}
                />
              ))}
            </>
          )}
        </div>
      </div>
      {isSidebarVisible && (
        <div
          className='-order-1 md:order-1 md:pt-[108px]'
          style={{ display: 'block', overflow: 'visible' }}
        >
          <CareTeamProvidersSidebar
            specialities={specialities}
            locations={locations}
            locationSelected={location}
            onSpecialitySelect={handleOnSpecialitySelect}
            onLocationSelect={handleOnLocationSelect}
          />
        </div>
      )}
    </div>
  );
}
