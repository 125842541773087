import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';

export function UpcomingSkeletonLoader() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <ContentLoader
      speed={2}
      width='100%'
      height={isMobile ? '560px' : '600'}
      viewBox={isMobile ? '0 0 600 560' : '0 0 844 600'}
      backgroundColor='#ccc'
      foregroundColor='#ddd'
    >
      {isMobile ? (
        <>
          <rect x='0' y='0' rx='10' ry='10' width='100%' height='360' />
          <rect x='0%' y='380' rx='25' ry='25' width='100%' height='60' />
          <rect x='0%' y='460' rx='25' ry='25' width='100%' height='60' />
        </>
      ) : (
        <>
          <rect x='0' y='0' rx='10' ry='10' width='100%' height='310' />

          <rect x='0' y='330' rx='25' ry='25' width='380' height='50' />
          <rect x='400' y='330' rx='25' ry='25' width='380' height='50' />
          <rect x='790' y='330' rx='25' ry='25' width='50' height='50' />

          <rect x='0' y='400' width='2' height='180' />
          <rect x='842' y='400' width='2' height='180' />
          <rect x='0' y='400' width='100%' height='2' />
          <rect x='0' y='580' width='100%' height='2' />

          <rect x='30%' y='420' rx='10' ry='10' width='40%' height='35' />
          <rect x='25%' y='465' rx='10' ry='10' width='50%' height='15' />
          <rect x='30%' y='490' rx='10' ry='10' width='40%' height='15' />
          <rect x='18%' y='520' rx='10' ry='10' width='30%' height='35' />
          <rect x='50%' y='520' rx='10' ry='10' width='30%' height='35' />
        </>
      )}
    </ContentLoader>
  );
}
