import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, cubicBezier, motion, PanInfo } from 'framer-motion';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';

import { AtriaIconButton } from '../atriaLibrary';
import styles from './bottomSheet.module.css';
import { BottomSheetHeader } from './bottomSheetHeader';

type Props = {
  children: React.ReactNode;
  headerTitle?: string;
  onClose: VoidFunction;
  isVisible: Boolean;
  closeIcon?: Boolean;
};

export function BottomSheet({
  children,
  headerTitle,
  onClose,
  isVisible = false,
  closeIcon = false,
}: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleDragEnd = useCallback(
    (info: PanInfo) => {
      if (info.offset.y > 100 && isVisible) {
        if (onClose) onClose();
      }
    },
    [isVisible, onClose]
  );

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0.3 } }}
            exit={{ opacity: 0, transition: { duration: 0.6 } }}
            className='fixed inset-0 bg-[#f8f6f6d9]/40 w-screen h-full z-10'
          />

          <motion.div
            initial={{ y: '100%' }}
            animate={{ y: 0, transition: { duration: 0.6, ease: cubicBezier(0.65, 0, 0.35, 1) } }}
            exit={{ y: '100%', transition: { duration: 0.3 } }}
            dragConstraints={{ top: 0 }}
            drag={isMobile ? 'y' : false}
            onDragEnd={(_event, info) => handleDragEnd(info)}
            className='fixed bottom-0 right-0 bg-kelp z-30 max-h-[80%] h-full rounded-t-xl w-full flex flex-col'
          >
            <div className='absolute -top-3 left-1/2 -translate-x-1/2 bg-kelp rounded-full w-10 h-2' />
            {!!headerTitle && <BottomSheetHeader title={headerTitle} onClose={onClose} />}
            {closeIcon && (
              <div className={cn('w-full flex justify-end items-center px-8 pt-5')}>
                <AtriaIconButton
                  icon='close'
                  iconClassName='text-base text-white'
                  onClick={onClose}
                />
              </div>
            )}
            <div className={cn(['flex-1 overflow-y-auto', styles.scroll])}>{children}</div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
