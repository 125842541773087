import { AnimatePresence, motion } from 'framer-motion';
import ConfirmImage from '@/assets/img/thanks-page-contact-cmo.webp';
import { useCallback, useEffect } from 'react';
import Particles, { IParticlesProps, initParticlesEngine } from '@tsparticles/react';
import { loadSlim } from '@tsparticles/slim';
import { useMediaQuery } from 'react-responsive';
import { BigButton } from '@/components';
import { cubicBezier } from 'framer-motion/dom';

type Props = {
  isVisibleConfirm: boolean;
  setIsVisibleModal: (isVisible: boolean) => void;
  setIsVisibleConfirm: (isVisible: boolean) => void;
  username: string;
};

const options = {
  background: {
    color: '#00001',
  },
  fullScreen: {
    enable: false,
    zIndex: -1,
  },
  interactivity: {
    events: {
      onClick: {
        enable: false,
      },
      onHover: {
        enable: false,
      },
    },
  },
  particles: {
    number: {
      value: 80,
    },
    shape: {
      type: 'circle',
    },
    size: {
      value: 2,
      random: true,
    },
    move: {
      enable: true,
      speed: 1,
      direction: 'top',
      outMode: 'out',
    },
    opacity: {
      value: 0.2,
      random: true,
    },
    color: {
      value: '#775b4a08',
    },
  },
};

export function ConfirmDialogModal({
  isVisibleConfirm,
  setIsVisibleModal,
  setIsVisibleConfirm,
  username,
}: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleCloseModal = useCallback(() => {
    setIsVisibleConfirm(false);
    setIsVisibleModal(false);
  }, [setIsVisibleModal, setIsVisibleConfirm]);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    });
  }, []);

  return (
    <AnimatePresence>
      {isVisibleConfirm && (
        <motion.div
          key='modal'
          initial={{ maxHeight: 0 }}
          animate={{ maxHeight: '100%' }}
          transition={{ duration: 1, ease: cubicBezier(0.75, 0, 0.25, 1) }}
          style={{
            backgroundImage: `url(${ConfirmImage})`,
            backgroundPosition: 'bottom center',
          }}
          className='h-full z-20 rounded-t-xl absolute bg-kelp w-full bg-no-repeat bg-cover px-4'
        >
          <Particles
            options={options as IParticlesProps['options']}
            className='h-[80vh]  z-10 absolute md:w-full '
          />
          <i
            className='pi pi-times cursor-pointer absolute top-9 right-6 text-kelp z-30 md:block hidden'
            onClick={handleCloseModal}
          />

          <div className='flex flex-col justify-between items-center text-kelp h-full pb-6 pt-20'>
            <motion.div
              initial={{ y: -200 }}
              animate={{ y: 0 }}
              transition={{ duration: 1, ease: cubicBezier(0.75, 0, 0.25, 1) }}
            >
              <div className='flex flex-col justify-center items-center'>
                <h3 className='font-display md:text-4xl text-5xl mb-2'>All set,&nbsp;{username}</h3>
                <span className='font-medium font-body text-base leading-6'>
                  We&#39;ll be in touch soon
                </span>
              </div>
            </motion.div>

            {isMobile && (
              <div onClick={handleCloseModal} className='mt-14 relative w-full'>
                <motion.div
                  initial={{ y: -400, maxHeight: '0%' }}
                  animate={{ y: 0, maxHeight: '100%' }}
                  transition={{ duration: 0.8 }}
                >
                  <BigButton variant='white' className='py-5 px-4 absolute bottom-0 z-20'>
                    Close
                  </BigButton>
                </motion.div>
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
