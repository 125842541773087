import { useEffect } from 'react';

import { usePageContext } from '@/contexts';

export function OktaNotAuthorizedPage() {
  const { setPageTitle } = usePageContext();

  useEffect(() => {
    setPageTitle('Not Authorized');
  }, [setPageTitle]);

  return (
    <div className='text-center mt-10 text-lg'>
      Sorry, you&apos;re not authorized to access this page. Please contact Cale Reid.
    </div>
  );
}
