import { SpecialZoomLevel, Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin } from '@react-pdf-viewer/get-file';
import { rotatePlugin } from '@react-pdf-viewer/rotate';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import pdfJSWorkerURL from 'pdfjs-dist/build/pdf.worker?url';

import { AttachmentOptions } from '../attachmentViewer';
import { CustomPdfViewerLoader } from './customPdfViewerLoader';

type FiltersToggleProps = {
  fileName?: string;
  fileUrl: string | Uint8Array;
  options?: AttachmentOptions;
};

export function CustomPdfViewer({
  fileName,
  fileUrl,
  options = {
    borderVisible: true,
    pageShadowVisible: true,
    pageRounded: true,
    toolbarVisible: true,
  },
}: FiltersToggleProps) {
  const getFilePluginInstance = getFilePlugin({
    fileNameGenerator: () => {
      const date = DateTime.now().toFormat('yyyy-MM-dd');
      return fileName ? fileName : `Atria-${date}.pdf`;
    },
  });
  const { DownloadButton } = getFilePluginInstance;
  const rotateInstance = rotatePlugin();
  const { RotateBackwardButton, RotateForwardButton } = rotateInstance;

  return (
    <div className='flex-1 flex flex-col'>
      {options?.pageRounded && (
        <style>
          {`.rpv-core__page-layer--single {
            border-radius: 8px;
            overflow: hidden;
          }`}
        </style>
      )}
      {options?.pageShadowVisible && (
        <style>
          {`.rpv-core__page-layer::after {
            box-shadow: none;
          }`}
        </style>
      )}
      <div className='flex-1 w-full'>
        <Worker workerUrl={pdfJSWorkerURL}>
          <div
            className={classNames('flex flex-col', {
              'border border-black/30': options?.borderVisible,
            })}
          >
            {options?.toolbarVisible && (
              <div className='p-1 flex items-center justify-center gap-2 bg-gray-100 border-b border-b-black/30'>
                <DownloadButton />
                <RotateBackwardButton />
                <RotateForwardButton />
              </div>
            )}
            <div className='flex-1 max-h-[900px] overflow-y-auto atria-scrollbar'>
              <Viewer
                fileUrl={fileUrl}
                plugins={[getFilePluginInstance, rotateInstance]}
                defaultScale={SpecialZoomLevel.PageWidth}
                renderError={(error) => {
                  Sentry.captureException(error);
                  return (
                    <div className='py-8 flex items-center justify-center'>
                      <p>
                        Some unexpected problem has occurred here, please try again by refreshing
                        the page.
                      </p>
                    </div>
                  );
                }}
                pageLayout={{
                  transformSize: ({ size }) => ({
                    height: size.height + 11,
                    width: size.width + 11,
                  }),
                }}
                renderLoader={(percentage: number) => (
                  <CustomPdfViewerLoader
                    percentage={percentage}
                    className={options?.loaderContainerClassName}
                  />
                )}
              />
            </div>
          </div>
        </Worker>
      </div>
    </div>
  );
}
