import { cn } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomTableFilters, CustomTableHeader, User } from '@/@types';
import { CustomTable, PatientsCard } from '@/components';
import { useAuthContext, usePageContext } from '@/contexts';
import { selfKey, usePatients, useUsers } from '@/hooks';

const sortable = [
  'firstName',
  'lastName',
  'email',
  'mrn',
  'registrationStatus',
  'cmoEmail',
  'lastLogin',
  'loginsCount',
  'createdAt',
  'resendInviteCount',
  'lastResendInvite',
];

const tableHeaders: CustomTableHeader[] = [
  {
    label: 'First name',
    key: 'firstName',
  },
  {
    label: 'Last name',
    key: 'lastName',
  },
  {
    label: 'MRN',
    key: 'mrn',
  },
  {
    label: 'Portal Link',
    key: 'portalLink',
    width: '200px',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'CMO Email',
    key: 'cmoEmail',
  },
];

export function PatientsPage() {
  const navigate = useNavigate();
  const { setPageTitle, setActions, isSidebarVisible } = usePageContext();
  const { signInAdmin } = useAuthContext();
  const { findPatientById, patientData, setPatientData } = usePatients();
  const { users, isLoading, findAllMembers, exportMembersCSVFile, resendInvitations } = useUsers();
  const [checkboxDialogOffset, setCheckboxDialogOffset] = useState(isSidebarVisible ? 282 : 0);

  useEffect(() => {
    findAllMembers();
  }, [findAllMembers]);

  useEffect(() => {
    setPageTitle('Members');
    setActions({
      buttons: [
        {
          label: 'Create',
          icon: 'add',
          onClick: () => navigate('/admin/users/create'),
        },
        {
          label: isLoading ? 'Loading...' : 'Export to CSV',
          icon: 'download',
          onClick: () => {
            if (!isLoading) {
              exportMembersCSVFile();
            }
          },
        },
      ],
    });
    return () => {
      setActions(undefined);
    };
  }, [exportMembersCSVFile, isLoading, navigate, setActions, setPageTitle]);

  useEffect(() => {
    if (!patientData) return;
    signInAdmin({
      id: patientData.id,
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      dateOfBirth: patientData.dateOfBirth,
      patientName: patientData.patientName,
      primaryProvider: {
        ...patientData.primaryProvider,
        lastName: patientData?.primaryProvider?.lastName || '',
      },
      email: patientData.email,
      firstNameUsed: patientData.firstName,
    });
  }, [patientData, signInAdmin]);

  const items = useMemo(() => {
    const onGoToPortal = async (mrn?: number) => {
      if (!mrn) return;
      const patient = await findPatientById({ patientId: mrn });

      if (!patient) return;
      setPatientData(patient);
    };

    return users.map((user, key) => {
      const goToPortalButton = user.mrn && (
        <button
          className={cn(
            '!px-0 text-forest-100 underline underline-offset-[3px] hover:text-forest-300 focus:text-forest-300'
          )}
          onClick={() => onGoToPortal(user.mrn)}
        >
          Go to portal
        </button>
      );

      return {
        id: user.id,
        registrationStatus: user.registrationStatus,
        key: key,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        mrn: user.mrn,
        portalLink: goToPortalButton,
        cmoEmail: user.cmoEmail,
        lastLogin: user.lastLogin,
        loginsCount: user.loginsCount,
        createdAt: user.createdAt,
        patients: user.patients,
        resendInviteCount: user.resendInviteCount,
        lastResendInvite: user.lastResendInvite
          ? DateTime.fromFormat(user.lastResendInvite, 'MM/dd/yyyy hh:mma')
              .setZone('America/New_York')
              .toFormat('MM/dd/yyyy')
              .toLocaleLowerCase()
          : '',
        actions: {
          more: [
            {
              label: 'Edit',
              onClick: () => navigate(`/admin/members/edit/${user.id}`),
            },
          ],
        },
      };
    });
  }, [findPatientById, navigate, setPatientData, users]);

  const filters: CustomTableFilters = [
    {
      key: 'lastName',
      filterFunction: () => true,
      label: 'Search last name',
      type: 'text',
    },
    {
      key: 'registrationStatus',
      filterFunction: () => true,
      label: 'Status',
      type: 'select',
      options: [
        ...new Set(users.map((user) => user.registrationStatus).filter((status) => status)),
      ] as string[],
    },
    {
      key: 'cmoEmail',
      filterFunction: () => true,
      label: 'Pod',
      type: 'select',
      options: [
        ...new Set(users.map((user) => user.cmoEmail).filter((email) => email)),
      ] as string[],
    },
    {
      key: 'loginsCount',
      filterFunction: () => true,
      label: 'Logins count',
      type: 'select',
      options: ['0', '1-4', '5+'],
      range: [
        { label: '0', range: [0, 0] },
        { label: '1-4', range: [1, 4] },
        { label: '5+', range: [5, Infinity] },
      ],
    },
  ];

  const handleResendInvitations = useCallback(
    (list: User.FindAllUsers.Response) => {
      const resendInvitationIds = list
        .filter(
          ({ registrationStatus, loginsCount }) =>
            registrationStatus === 'invited' || loginsCount === 0
        )
        .map(({ id }) => id);

      resendInvitations(resendInvitationIds);
    },
    [resendInvitations]
  );

  const tableDialogActions = [
    {
      label: 'Export CSV',
      onClick: (list: User.FindAllUsers.Response) => {
        exportMembersCSVFile({ userIds: list.map(({ id }) => id) });
      },
    },
    {
      label: 'Resend Invitation',
      onClick: (list: User.FindAllUsers.Response) => handleResendInvitations(list),
    },
  ];

  const uniqueMembers = useMemo(() => {
    const ids = new Set();
    return users.filter((user) => {
      const self = user.patients.find((p) => p.relation === selfKey);
      if (!self) {
        return;
      }
      if (ids.has(self.patientId)) {
        return;
      }
      ids.add(self.patientId);
      return user;
    });
  }, [users]);

  useEffect(() => {
    setCheckboxDialogOffset(isSidebarVisible ? 282 : 0);
  }, [isSidebarVisible]);

  return (
    <>
      <PatientsCard
        users={uniqueMembers}
        labels={{
          invited: 'Invited members',
          registered: 'Registered members',
          activeInLastMonth: 'Active in the last month',
          activeInLastWeek: 'Active in the last week',
        }}
      />
      <CustomTable
        sortable={sortable}
        headers={tableHeaders}
        items={items}
        filters={filters}
        dialogActions={tableDialogActions}
        checkboxDialogOffset={checkboxDialogOffset}
        selectable
        stickyActions
      />
    </>
  );
}
