import classNames from 'classnames';

type Props = React.SVGProps<SVGSVGElement>;

export function DownloadIcon({
  width = 18,
  height = 18,
  fill = '#607663',
  className,
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.34375 11.3438V10H9.65625V11.3438H0.34375ZM9.65625 4L5 8.65625L0.34375 4H3V0H7V4H9.65625Z'
        fill={fill}
        className={classNames([className, 'fill-current'])}
      />
    </svg>
  );
}
