import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';

import { DateHelper, StringHelper } from '@/helper';
import { BasicCard } from '.';
import { BigButton } from '..';

type Props = {
  title: string;
  imagingResult?: {
    id: number;
    description: string;
    createdDateTime: string;
    observationDateTime: string;
  };
};

export function ImagingResultCard({ title, imagingResult }: Props) {
  return (
    <BasicCard className='h-full shadow-none bg-[#eeece5] rounded-xl px-5'>
      <p className='block font-medium leading-8 text-kelp mb-2'>{title}</p>
      {imagingResult ? (
        <>
          <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-0 h-full md:items-center justify-between'>
            <h2 className='text-2xl font-medium leading-8 text-kelp my-2'>
              {StringHelper.firstLetterUppercase(imagingResult.description)}
            </h2>
            <div className='flex flex-col md:text-center text-kelp leading-[0.875rem]'>
              <p className='text-lg md:font-medium'>
                {imagingResult.observationDateTime
                  ? DateHelper.formatDateToDisplay(
                      DateTime.fromISO(imagingResult.observationDateTime)
                    )
                  : DateHelper.formatDateToDisplay(DateTime.fromISO(imagingResult.createdDateTime))}
              </p>
            </div>
          </div>
          <Link className='mt-4' to={`/results/imaging/${imagingResult.id}`}>
            <BigButton>View results</BigButton>
          </Link>
        </>
      ) : (
        <p className='leading-[0.875rem] text-gray-700'>No recent imaging results.</p>
      )}
    </BasicCard>
  );
}
