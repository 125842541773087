import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const schema = z.object({
  relation: z.string().min(1, 'Patient must contain one relation name'),
  permissions: z.array(z.string()).min(1, 'Patient must contain at least 1 permission'),
});

export type PatientEditSheetType = z.infer<typeof schema>;

export const patientEditSheetResolver = zodResolver(schema);
