import { AppointmentLetter } from '@/@types';
import jsPDF from 'jspdf';
import { StringHelper } from '@/helper';

function createAndSavePdfWithLetter(letter: AppointmentLetter) {
  const pdf = new jsPDF('p', 'px', 'a4');
  const pageWidth = pdf.internal.pageSize.width - 20;

  pdf.html(letter.summaryHtml, {
    callback: function (doc) {
      doc.save(`${StringHelper.generateRandomString(12)}-${Date.now()}.pdf`);
    },
    x: 10,
    y: 0,
    width: pageWidth,
    windowWidth: 800,
  });
}

export const LetterHelper = {
  createAndSavePdfWithLetter,
};
