import { useCallback, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { LoginLoader } from '@/components';
import { useAuthContext } from '@/contexts';
import { setPatientIdHeader } from '@/providers';

export function PatientAuthZeroRoute() {
  const navigate = useNavigate();
  const { isAuthenticated, isAdmin, patient, hasMultiplePatients, getAccessToken } =
    useAuthContext();

  const getAuth0AccessToken = useCallback(async () => {
    try {
      await getAccessToken();
    } catch (error: any) {
      navigate('/sign-in');
      return;
    }
  }, [getAccessToken, navigate]);

  useEffect(() => {
    if (!isAdmin) {
      getAuth0AccessToken();
    }
  }, [getAuth0AccessToken, isAdmin]);

  useEffect(() => {
    if (isAdmin && !patient) {
      return navigate('/admin');
    }
    if (!isAdmin && patient) {
      return setPatientIdHeader(patient.id?.toString());
    }
    if (!isAdmin && !patient && hasMultiplePatients) {
      return navigate('/sign-in/patients');
    }
    if (!isAdmin && !patient && !hasMultiplePatients) {
      return navigate('/sign-in');
    }
  }, [hasMultiplePatients, isAdmin, navigate, patient]);

  if (isAuthenticated) {
    return <Outlet />;
  }

  return (
    <div className='flex items-center justify-center h-screen'>
      <LoginLoader />
    </div>
  );
}
