import { cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  text: string;
};

export function StoneCard({ text }: Props) {
  return (
    <div className='w-full min-h-[380px] md:min-h-[529px] rounded-xl bg-stone-horizontal-1 bg-cover bg-center p-5 md:p-10 flex flex-col'>
      <div className='flex-1 flex flex-col md:flex-row items-stretch justify-between'>
        <div className='w-full md:w-[70%]'>
          <p className={cn(TextLayout.h2.scto, 'font-body text-white')}>{text}</p>
        </div>
        <div className='flex items-start justify-start md:justify-end'>
          <div className='w-[12px] h-[12px] rounded-full bg-product-sand-200'></div>
        </div>
      </div>
    </div>
  );
}
