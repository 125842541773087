import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';

type Props = InputHTMLAttributes<HTMLInputElement> & {
  mask: string;
  placeholder?: string;
  className?: string;
  fieldState: ControllerFieldState;
};

export function InputMask({ mask, className, fieldState, ...props }: Props) {
  return (
    <>
      <ReactInputMask {...props} mask={mask} className={classNames('p-2 rounded-md', className)} />
      {fieldState.error && (
        <span className='text-sm font-medium text-red-500 text-center py-1'>
          {fieldState.error.message}
        </span>
      )}
    </>
  );
}
