import { Doctor } from '@/@types';
import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { TextLayout } from '../text';
import { CareTeamTable } from './careTeamTable';

type Props = {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  doctor: Doctor;
};
export function CareTeamAbout({ isVisible = true, setIsVisible, doctor }: Props) {
  const hasAwards = useMemo(() => doctor.awards && doctor.awards.length > 0, [doctor.awards]);
  const hasCredentials = useMemo(
    () => doctor.credentials && doctor.credentials.length > 0,
    [doctor.credentials]
  );
  const hasAffiliations = useMemo(
    () => doctor.affiliations && doctor.affiliations.length > 0,
    [doctor.affiliations]
  );

  const tableColumns = useMemo(() => {
    const credentials = doctor?.credentials.map((credential) => [
      credential.title,
      credential.description,
    ]);

    return credentials;
  }, [doctor]);

  const tableAwards = useMemo(() => {
    const awards = doctor?.awards.map((award) => [award.title, award.description]);

    return awards;
  }, [doctor]);

  const tableAffiliations = useMemo(() => {
    const affiliations = doctor?.affiliations.map((affiliation) => [
      affiliation.title,
      affiliation.description,
    ]);

    return affiliations;
  }, [doctor]);

  const text = useMemo(() => {
    const processedText = doctor.description.reduce((acc, word, index, array) => {
      const separator = index < array.length - 1 ? '<br /><br />' : '';
      return `${acc}${word}${separator}`;
    });
    return processedText;
  }, [doctor.description]);

  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setIsVisible(false);
      }
    };

    document.addEventListener('keydown', closeOnEscapeKey);

    return () => {
      document.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [setIsVisible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <>
          <motion.div
            className='z-10 inset-0 lg:ml-[calc(282px)] fixed h-screen bg-white bg-opacity-50 rounded-xl cursor-default'
            onClick={(event) => {
              if (event.target === event.currentTarget) {
                setIsVisible(!isVisible);
              }
            }}
            initial='closed'
            animate='open'
            exit='closed'
            transition={{ duration: 0.9, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            variants={{
              open: { opacity: 1 },
              closed: { opacity: 0 },
            }}
          ></motion.div>
          <motion.div
            initial={{ x: '-100%', opacity: 1 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: '-100%', opacity: 1 }}
            transition={{ duration: 0.9, ease: cubicBezier(0.65, 0, 0.35, 1) }}
            onClick={(event) => event.stopPropagation()}
            className='z-10 inset-0 lg:ml-[calc(282px)] fixed rounded-xl scrollbar-hidden xl:w-[calc(100%-440px)] 2xl:w-[calc(100%-640px)] md:w-[calc(100%-340px)] sm:w-[calc(100%-140px)] shadow bg-white text-product-forest-100 h-full px-[60px] md:pr-[40px] py-10 overflow-y-auto cursor-default'
          >
            <CareTeamHeader isVisible={isVisible} setIsVisible={setIsVisible} />

            <p className={cn(TextLayout.h1.scto, 'text-kelp')}>
              {doctor?.name},{' '}
              <span className={cn(TextLayout.callout2, 'text-base')}>{doctor.degree}</span>
            </p>
            <DoctorInfoSection text={text} doctor={doctor!} />
            {hasCredentials && (
              <Section title='Credentials' tableColumns={tableColumns!} className='mt-[84px]' />
            )}
            {hasAwards && <Section title='Awards' tableColumns={tableAwards!} />}
            {hasAffiliations && <Section title='Affiliations' tableColumns={tableAffiliations!} />}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
const Section = ({
  title,
  tableColumns,
  className,
}: {
  title: string;
  tableColumns: string[][];
  className?: string;
}) => (
  <div className={cn('mt-11', className)}>
    <p className={cn(TextLayout.callout4)}>{title}</p>
    <div className='border-product-forest-100 inline-flex border-b-2 w-full' />
    <CareTeamTable tableColumns={tableColumns} />
  </div>
);

const CareTeamHeader = ({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
}) => {
  return (
    <div className='flex justify-start items-center mb-[72px]'>
      <i className='pi pi-times cursor-pointer mr-8' onClick={() => setIsVisible(!isVisible)} />
      <p className='text-2xl font-body tracking-[-0.48px] font-medium'>About</p>
    </div>
  );
};

const DoctorInfoSection = ({ text, doctor }: { text: string; doctor: Doctor }) => (
  <div className='flex items-start relative gap-4 mt-[59px]'>
    <div className={'md:w-2/3 text-product-gray-400 font-medium text ml-2'}>
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </div>
    <div className='sticky top-0 flex justify-end lg:mr-2 mr-0 xl:mr-10  w-1/3 '>
      <img src={`${doctor?.photo}?h=500`} alt={doctor?.name} className='lg:w-[200px]' />
    </div>
  </div>
);
