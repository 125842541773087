type Props = {
  emptyMessage: React.ReactNode;
};

export function CustomTableEmpty({ emptyMessage }: Props) {
  return (
    <tr>
      <td colSpan={100}>
        <p className='pl-4 py-3 text-left text-sm text-forest-100'>{emptyMessage}</p>
      </td>
    </tr>
  );
}
