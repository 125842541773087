import { useContactDialogContext, usePageContext } from '@/contexts';
import '@lib-atria/ui-toolkit/dist/style.css';
import { useCallback, useEffect } from 'react';
import { DoctorsCard } from './doctorsCard';
import { Tootlbox } from './toolbox';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';
import { eventAnalytics } from '@/providers';

export function HomeContentCards() {
  const { setPageTitle } = usePageContext();

  const {
    setContactDialogVisibility,
    setContactDialogTopic,
    setContactDialogTitle,
    setContactDialogMessagePlaceholder,
    setIsContactDialogBodyRequired,
    setTopicLabel,
    resetContactDialog,
    setAnalyticsFormSubmittedEvent,
  } = useContactDialogContext();

  useEffect(() => {
    setPageTitle('Home');
  }, [setPageTitle]);

  const handleRequestRefill = useCallback(() => {
    setContactDialogTitle('Request a refill');
    setTopicLabel('Medication');
    setContactDialogTopic('Request a refill');
    setContactDialogMessagePlaceholder('Any other details? (optional)');
    setIsContactDialogBodyRequired(false);
    setContactDialogVisibility(true);
    setAnalyticsFormSubmittedEvent(TRACK_EVENTS.REQUEST_REFILL_BUTTON_SENT);
    eventAnalytics.track(TRACK_EVENTS.REQUEST_REFILL_BUTTON_CLICKED, {
      'parent page': 'home',
    });
  }, [
    setAnalyticsFormSubmittedEvent,
    setContactDialogMessagePlaceholder,
    setContactDialogTitle,
    setContactDialogTopic,
    setContactDialogVisibility,
    setIsContactDialogBodyRequired,
    setTopicLabel,
  ]);

  const handleContactCareTeam = useCallback(() => {
    resetContactDialog();
    eventAnalytics.track(TRACK_EVENTS.CONTACT_CARE_BUTTON);
    setContactDialogVisibility(true);
  }, [resetContactDialog, setContactDialogVisibility]);

  return (
    <div className='grid md:grid-cols-12 grid-cols-1 mt-5 md:gap-5 gap-y-4'>
      <div className='lg:col-span-5 md:col-span-5 sm:col-span-12'>
        <Tootlbox handleRequestRefill={handleRequestRefill} />
      </div>
      <div className='lg:col-span-7 md:col-span-7 sm:col-span-12 '>
        <DoctorsCard handleContactCareTeam={handleContactCareTeam} />
      </div>
    </div>
  );
}
