import { cn } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';

import atriaFernLogo from '@/assets/img/atria-word-fern.svg';
import atriaStoneLogo from '@/assets/img/atria-word-stone.svg';
import { TextLayout } from '../../text';
import { SidebarVariants } from '../sidebar';

type Props = {
  variant?: SidebarVariants;
  userName: string;
  userPhoto?: string;
  isAdminView?: boolean;
};

export function SidebarUserProfile({ variant, userName, userPhoto, isAdminView = false }: Props) {
  const atriaLogo = useMemo(
    () => (variant === 'forest' ? atriaStoneLogo : atriaFernLogo),
    [variant]
  );

  const fontSize = useMemo(() => {
    if (!userName) return '5xl';
    const splittedName = userName.split(' ');
    splittedName.forEach((name) => {
      if (name.length > 12) {
        return '2xl';
      } else if (name.length > 10) {
        return '3xl';
      } else if (name.length > 9) {
        return '4xl';
      } else {
        return '5xl';
      }
    });
  }, [userName]);

  return (
    <>
      <div className='flex flex-col items-start gap-11 pt-12 pb-11 px-10'>
        <div className='flex flex-row items-baseline'>
          <img
            src={userPhoto || atriaLogo}
            className='w-[44px] h-[17px] object-contain pointer-events-none select-none'
          />
          {isAdminView && (
            <i
              className={cn(TextLayout.h2.ivar, 'pl-1', 'md:text-[14px]', 'text-product-grey-100')}
            >
              admin panel
            </i>
          )}
        </div>
        <p
          className={cn(
            TextLayout.h2.ivar,
            ' max-w-[200px]',
            `md:text-${fontSize}`,
            variant === 'forest' && 'text-product-sand-100',
            variant === 'gray' && 'text-product-forest-100'
          )}
        >
          {userName}
        </p>
      </div>
    </>
  );
}
