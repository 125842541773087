import { motion } from 'framer-motion';

type Props = {
  isOpen?: boolean;
};

export function AccordionChevron({ isOpen = false }: Props) {
  return (
    <div className='w-[32px] h-[32px] flex items-center justify-center border border-product-sand-200 rounded-full'>
      <motion.span
        initial='closed'
        variants={{
          open: { rotate: '180deg' },
          closed: { rotate: '0deg' },
        }}
        transition={{ damping: 40 }}
        animate={isOpen ? 'open' : 'closed'}
        className='material-icons-round text-product-forest-100 text-base'
      >
        expand_more
      </motion.span>
    </div>
  );
}
