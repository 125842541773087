import { cn } from '@lib-atria/ui-toolkit';
import { MaterialIcons, MaterialIconsProps } from '../../icons';
import { SidebarVariants } from '../sidebar';

type Props = {
  variant?: SidebarVariants;
  icon: MaterialIconsProps;
  isSelected: boolean;
};

export function SidebarListItemIcon({ variant, icon, isSelected = false }: Props) {
  return (
    <MaterialIcons
      {...icon}
      className={cn(
        'leading-3',
        variant === 'forest' && isSelected && 'text-stone',
        variant === 'forest' && !isSelected && 'text-product-sand-100',
        variant === 'gray' && isSelected && 'text-stone',
        variant === 'gray' && !isSelected && 'text-product-forest-100 group-hover:text-stone'
      )}
    />
  );
}
