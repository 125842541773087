import { CustomTableHeader } from '@/@types';
import {
  CustomTable,
  Disclaimer,
  EncounterHeader,
  EncounterLetterCard,
  EncounterNotesCard,
  EncounterSignatureFooter,
  EncounterSummary,
  PageTitle,
} from '@/components';
import { env } from '@/constants';
import { useAuthContext, usePageContext } from '@/contexts';
import { LetterHelper } from '@/helper';
import { useAppointments } from '@/hooks';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const tableHeaders: CustomTableHeader[] = [
  {
    key: 'title',
    label: 'Vitals',
  },
  {
    key: 'data',
    label: 'Result',
  },
  {
    key: 'audit',
    label: 'Date & Time',
  },
];

export function EncounterLetterPage() {
  const { setPageTitle, setIsBackButtonVisible, setActions } = usePageContext();
  const { patient } = useAuthContext();
  const { appointmentId, encounterId } = useParams();
  const { letter, findLetterByPatientIdAndEncounterIdSchema } = useAppointments();

  useEffect(() => {
    if (patient?.id && encounterId) {
      findLetterByPatientIdAndEncounterIdSchema(
        Number(appointmentId),
        patient.id,
        Number(encounterId)
      );
    }
  }, [appointmentId, encounterId, findLetterByPatientIdAndEncounterIdSchema, patient?.id]);

  useEffect(() => {
    if (letter) {
      setPageTitle(letter.appointment.title, false);
      setIsBackButtonVisible(true);
      setActions({
        buttons: [
          {
            icon: 'download',
            label: 'Download',
            onClick: () => LetterHelper.createAndSavePdfWithLetter(letter),
          },
        ],
      });
    }
    return () => {
      setIsBackButtonVisible(false);
      setActions();
    };
  }, [letter, setActions, setIsBackButtonVisible, setPageTitle]);

  const { summaryJSON } = letter || {};
  const showNewLetterTemplate = useMemo(
    () =>
      env.APP_FEATURE_FLAGS.IS_NEW_LETTER_TEMPLATE_VISIBLE &&
      Object.values(letter?.summaryJSON || {}).length,
    [letter?.summaryJSON]
  );

  if (!letter) {
    return null;
  }

  return (
    <>
      {showNewLetterTemplate ? (
        <div className='flex flex-col gap-[100px]'>
          <PageTitle title={letter.appointment.title} />
          <div className='flex flex-col gap-5'>
            <EncounterHeader
              title='Appointments notes'
              dateTime={letter.appointment.date}
              description={summaryJSON?.encounter.reason}
            />
            {summaryJSON?.encounter.discussionNotes && (
              <EncounterSummary
                title='Summary'
                content={summaryJSON?.encounter.discussionNotes}
                signature={summaryJSON.provider?.signature}
              />
            )}
            <Disclaimer
              text={
                <p className='font-medium text-product-forest-100'>
                  The notes below are for internal clinical use. Your doctor would be happy to
                  explain all salient findings to you in non-clinical terms. Please contact your
                  Care team with any questions.
                </p>
              }
            />
            {summaryJSON?.encounter.vitals && Array.isArray(summaryJSON?.encounter.vitals) && (
              <CustomTable headers={tableHeaders} items={summaryJSON?.encounter.vitals} />
            )}
            {summaryJSON?.encounter.historyOfPresentIllness && (
              <EncounterNotesCard
                type='square'
                title='Notes on your current concerns'
                appointmentTitle={letter.appointment.title}
                content={`(History of present illness)<br /><br />${summaryJSON?.encounter.historyOfPresentIllness}`}
              />
            )}
            {summaryJSON?.encounter.reviewOfSystems && (
              <EncounterNotesCard
                type='diamond'
                title='Notes on other symptoms'
                appointmentTitle={letter.appointment.title}
                content={`(Review of systems)<br /><br />${summaryJSON?.encounter.reviewOfSystems}`}
              />
            )}
            {summaryJSON?.encounter.physicalExam && (
              <EncounterNotesCard
                type='diamond-symbols'
                title='Physical exam'
                appointmentTitle={letter.appointment.title}
                content={summaryJSON?.encounter.physicalExam}
              />
            )}
            {summaryJSON?.encounter.assessmentAndPlan && (
              <EncounterNotesCard
                type='sun-ray'
                title='Results and next steps'
                appointmentTitle={letter.appointment.title}
                content={`(Assessment and plan)<br /><br />${summaryJSON?.encounter.assessmentAndPlan}`}
              />
            )}
            {summaryJSON?.provider?.signature && (
              <EncounterSignatureFooter signature={summaryJSON.provider.signature} />
            )}
          </div>
        </div>
      ) : (
        <EncounterLetterCard encounterLetter={letter} />
      )}
      {letter.attachmentsDocumentId && (
        <div className='flex justify-center underline'>
          <a
            target='_blank'
            href={'/patient/chart/pdf/' + letter.attachmentsDocumentId}
            rel='noreferrer'
          >
            Download Attachments
          </a>
        </div>
      )}
    </>
  );
}
