import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LoginLoader } from '@/components';
import { useAuthContext, useToastContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { MemberPortalService } from '@/services';

export function SignInCallbackPage() {
  const [searchParams] = useSearchParams();
  const { toast } = useToastContext();
  const navigate = useNavigate();
  const { getAccessToken, setExpiresIn, signInPatient, setUser, setHasMultiplePatients } =
    useAuthContext();

  useEffect(() => {
    async function initialLoad() {
      try {
        const { patients } = await getAccessToken();
        if (patients[0]) {
          const { data } = await MemberPortalService.signIn({
            patientId: patients[0].patientId,
          });
          signInPatient(data);
          return;
        }
        eventAnalytics.track('MemberSignInError', { message: 'No patient found' });
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'No patient found, please notify your care team.',
        });
        setUser(undefined);
        setExpiresIn(undefined);
        navigate('/sign-in');
        return;
      } catch (error: unknown) {
        eventAnalytics.track('MemberSignInError', { message: (error as any)?.message });
        if (error instanceof AxiosError) {
          const response = (error as AxiosError).response?.data as { message?: string };
          toast?.current.show({
            severity: 'error',
            summary: 'Error',
            detail: response?.message || 'An error has occurred, please log in again to continue.',
          });
        }
        navigate('/');
      }
    }

    initialLoad();
  }, [
    getAccessToken,
    navigate,
    searchParams,
    setExpiresIn,
    setHasMultiplePatients,
    setUser,
    signInPatient,
    toast,
  ]);

  return (
    <>
      <div className='flex items-center justify-center h-screen'>
        <LoginLoader />
      </div>
    </>
  );
}
