import { useCallback, useMemo, useState } from 'react';

import { CustomTableHeader, CustomTableHeaderAction } from '@/@types';
import { TextLayout } from '../text';
import { CustomTableChevron } from './customTableChevron';
import { cn } from '@lib-atria/ui-toolkit';

type SortedByType = {
  [key: string]: 'asc' | 'desc';
};

type Props = {
  headers: CustomTableHeader[];
  headerActions?: CustomTableHeaderAction[];
  sortable: string[];
  index: number;
  header: any;
  onSort: (key: string, ordering: 'asc' | 'desc') => void;
};

export function CustomTableHeaderItem({
  headers,
  headerActions,
  sortable,
  index,
  header,
  onSort,
}: Props) {
  const [sortedBy, setSortedBy] = useState<SortedByType | undefined>(undefined!);
  const isSortable = useMemo(() => sortable.includes(header.key), [header.key, sortable]);

  const handleOnSorting = useCallback(
    (key: string, sortKey?: string) => {
      setSortedBy((prev) => {
        const ordering = prev?.[key] ? (prev[key] === 'asc' ? 'desc' : 'asc') : 'asc';
        onSort(sortKey || key, ordering);
        return { [key]: ordering };
      });
    },
    [onSort]
  );

  return (
    <th
      key={header.key ? header.key : `header-${index}`}
      className={cn(
        'duration-300 px-10 py-[26px] border-fern border-l-[1px] uppercase',
        isSortable && 'cursor-pointer hover:bg-black/5 ',
        index === headers.length - 1 && !headerActions && 'pr-8',
        header.width && [`min-w-[${header.width}]`]
      )}
      onClick={() => isSortable && handleOnSorting(header.key, header.sortKey)}
    >
      <div className='flex items-center justify-start gap-2 text-white text-xs text-left'>
        <span className={cn(TextLayout.empashis)}>{header.label}</span>
        <CustomTableChevron
          animate={
            sortedBy?.[header.key] ? (sortedBy[header.key] === 'asc' ? 'asc' : 'desc') : 'invisible'
          }
        />
      </div>
    </th>
  );
}
