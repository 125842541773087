import { Controller, useForm } from 'react-hook-form';
import { Button } from 'primereact/button';
import { FileUpload, FileUploadSelectEvent } from 'primereact/fileupload';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import classNames from 'classnames';

import { usePatientDocuments } from '@/hooks';
import { useAuthContext, useToastContext } from '@/contexts';

import {
  UploadNewDocumentFormSchemaResolver,
  UploadNewDocumentFormType,
} from './uploadNewDocumentFormSchema';
import styles from './uploadDocumentForm.module.css';
import { Calendar } from 'primereact/calendar';

type Props = {
  handleUpload: () => void;
};

export function UploadNewDocumentForm({ handleUpload }: Props) {
  const { control, formState, handleSubmit, setValue, reset } = useForm<UploadNewDocumentFormType>({
    resolver: UploadNewDocumentFormSchemaResolver,
  });
  const { toast } = useToastContext();
  const { patient } = useAuthContext();
  const { uploadNewPatientDocument } = usePatientDocuments();

  const onFileSelect = (event: FileUploadSelectEvent) => {
    const selectedFile = event.files[0];
    setValue('file', selectedFile);
  };

  const onSubmit = async (form: UploadNewDocumentFormType) => {
    if (patient?.id) {
      try {
        await uploadNewPatientDocument(
          patient.id,
          form.filename,
          form.file,
          new Date(form.date).toISOString(),
          form.favorite
        );
        toast?.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'File uploaded successfully',
          life: 2500,
        });

        reset();
        handleUpload();
      } catch {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Document import failed',
          life: 2500,
        });
      }
    }
  };

  return (
    <div className='flex w-full w-12/12'>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <Controller
          control={control}
          name='filename'
          render={({ field, fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='filename'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                Document name
              </label>
              <InputText
                id='filename'
                name={field.name}
                className={classNames('my-2', { 'p-invalid': !!fieldState.error })}
                placeholder='Document name'
                value={field.value || ''}
                onChange={(e) => field.onChange(e.target.value)}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name='file'
          render={({ fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='file'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                File
              </label>
              <FileUpload
                id='file'
                accept='application/pdf'
                chooseLabel='Choose file'
                className={styles.uploadButton}
                mode='basic'
                onSelect={onFileSelect}
              />
            </div>
          )}
        />

        <Controller
          control={control}
          name='date'
          render={({ field, fieldState }) => (
            <div className='flex flex-col my-2'>
              <label
                htmlFor='date'
                className={classNames('font-medium', { 'p-error': fieldState.error })}
              >
                Date
              </label>
              <Calendar
                id='date'
                className={classNames(styles.calendar, {
                  'p-invalid': !!fieldState.error,
                })}
                showIcon
                onChange={field.onChange}
                value={new Date(field.value)}
              />
              {fieldState.error?.message && (
                <span className='p-error text-sm'>{fieldState.error.message}</span>
              )}
            </div>
          )}
        />

        <Controller
          control={control}
          name='favorite'
          render={({ field }) => (
            <div className='flex flex-col my-2'>
              <label htmlFor='favorite' className='font-medium'>
                Favorite
              </label>
              <InputSwitch
                checked={field.value}
                onChange={(e) => field.onChange(e.value)}
                id='favorite'
                className='my-2'
              />
            </div>
          )}
        />
        <div className='mt-5'>
          <Button
            disabled={Object.values(formState.errors).length > 0}
            type='submit'
            label='Upload'
            className='w-full'
          />
        </div>
      </form>
    </div>
  );
}
