import clsx from 'clsx';
import { useMemo } from 'react';

import { useAuthContext } from '@/contexts';
import { env as Environment } from '@/constants';
import { StringHelper } from '@/helper';

export function EnvironmentBadge() {
  const { isAdmin } = useAuthContext();
  const env = useMemo(() => Environment.APP_NODE_ENV, []);

  if (!isAdmin) return null;

  return (
    <p
      className={clsx([
        'fixed top-[15px] right-[-45px] md:top-[20px] md:right-[-35px] w-[200px] text-white font-medium text-center rotate-[25deg] z-10',
        env === 'development' ? 'bg-navy show' : 'bg-rust block',
      ])}
    >
      {StringHelper.firstLetterUppercase(env)}
    </p>
  );
}
