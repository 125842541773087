import AtriaLogo from '@/assets/img/atria-logo.svg';
import {
  AppointmentsCardFooter,
  AppointmentsCardHomeVisit,
  AppointmentsCardLocation,
  AppointmentsCardPhoneCall,
  AppointmentsCardTelehealth,
  CareTeamCard,
  CareTeamProviderList,
  CmoCard,
  CustomTable,
  Disclaimer,
  EncounterExpandNotes,
  EncounterHeader,
  EncounterLetterHeader,
  EncounterNotesCard,
  EncounterSignatureFooter,
  EncounterSummary,
  StoneCard,
  TextLayout,
} from '@/components';
import { Button } from '@lib-atria/ui-toolkit';
import clsx from 'clsx';

export function ComponentsPage() {
  return (
    <div className='w-full max-w-[1312px] m-auto flex flex-col gap-[52px] py-10 px-4'>
      <Header title='Button' />
      <Container title='Styling'>
        <Box title='Sizes'>
          <Card description='Small'>
            <Button variant='primary' size='small' label='Primary' />
            <Button variant='secondary' size='small' label='Secondary' />
            <Button variant='tertiary' size='small' label='Tertiary' />
            <Button variant='link' size='small' label='Link' />
          </Card>
          <Card description='Medium'>
            <Button variant='primary' size='medium' label='Primary' />
            <Button variant='secondary' size='medium' label='Secondary' />
            <Button variant='tertiary' size='medium' label='Tertiary' />
            <Button variant='link' size='medium' label='Link' />
          </Card>
          <Card description='Large'>
            <Button variant='primary' size='large' label='Primary' />
            <Button variant='secondary' size='large' label='Secondary' />
            <Button variant='tertiary' size='large' label='Tertiary' />
            <Button variant='link' size='large' label='Link' />
          </Card>
        </Box>
        <Box title='Disabled'>
          <Card description='Primary'>
            <Button variant='primary' size='medium' label='Primary' disabled />
          </Card>
          <Card description='Secondary'>
            <Button variant='secondary' size='medium' label='Secondary' disabled />
          </Card>
          <Card description='Tertiary'>
            <Button variant='tertiary' size='medium' label='Tertiary' disabled />
          </Card>
          <Card description='Link'>
            <Button variant='link' size='medium' label='Link' disabled />
          </Card>
        </Box>
      </Container>

      <Container title='Table'>
        <Box title='Desktop & Mobile' className='bg-product-sand-200'>
          <div className='w-screen lg:w-full'>
            <CustomTable
              headerActions={[
                { label: 'Download', onClick: () => {} },
                { label: 'Print', onClick: () => {} },
                { label: 'Copy to clipboard', onClick: () => {} },
              ]}
              headers={[
                { key: 'test', label: 'Testitals', width: '300px' },
                { key: 'clinician', label: 'Ordering Clinician', width: '250px' },
                { key: 'results', label: 'Results', width: '50px' },
                { key: 'date', label: 'Date', width: '250px' },
              ]}
              items={[
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                  note: (
                    <div className='w-full flex items-center justify-start gap-4'>
                      <span className={TextLayout.body2}>
                        We are here to help if you have any questions
                      </span>
                      <Button variant='secondary' size='small' label='Contact care team' />
                    </div>
                  ),
                  actions: {
                    expand: (
                      <EncounterNotesCard
                        type='diamond'
                        appointmentTitle='Cardiology CHA'
                        title='Notes on other symptoms'
                        content='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac molestie sem. In varius felis nulla, et porttitor justo fermentum a. Morbi mattis enim ut tortor vehicula, ac aliquet nisl pharetra. Nunc maximus justo sit amet odio varius ultrices. In magna magna, vulputate a facilisis gravida, egestas sed massa. Ut erat tortor, laoreet quis congue non, pretium eu nisl. Cras et ex malesuada, facilisis enim sit amet, auctor sapien. '
                      />
                    ),
                  },
                },
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                  actions: {
                    more: [
                      { label: 'Item 1', onClick: () => {} },
                      { label: 'Item 2', onClick: () => {} },
                    ],
                  },
                },
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                  actions: {
                    more: [
                      { label: 'Item 3', onClick: () => {} },
                      { label: 'Item 4', onClick: () => {} },
                    ],
                  },
                },
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                },
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                  actions: {
                    buttons: [
                      {
                        icon: 'email',
                        onClick: () => {},
                      },
                    ],
                    expand: (
                      <EncounterNotesCard
                        type='diamond'
                        appointmentTitle='Cardiology CHA'
                        title='Notes on other symptoms'
                        content='Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce ac molestie sem. In varius felis nulla, et porttitor justo fermentum a. Morbi mattis enim ut tortor vehicula, ac aliquet nisl pharetra. Nunc maximus justo sit amet odio varius ultrices. In magna magna, vulputate a facilisis gravida, egestas sed massa. Ut erat tortor, laoreet quis congue non, pretium eu nisl. Cras et ex malesuada, facilisis enim sit amet, auctor sapien. '
                      />
                    ),
                  },
                },
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                  actions: {
                    buttons: [{ label: 'Contact', onClick: () => {} }],
                  },
                },
                {
                  test: 'Strep Group A, DNA, swab',
                  clinician: 'Justin Boyd',
                  results: '1',
                  date: 'Sep 25, 2023',
                  actions: {
                    more: [
                      { label: 'Item 1', onClick: () => {} },
                      { label: 'Item 2', onClick: () => {} },
                    ],
                    buttons: [{ label: 'Contact', onClick: () => {} }],
                  },
                },
              ]}
            />
          </div>
        </Box>
      </Container>

      <Header title='Encounter' />
      <Container title='Header'>
        <Box title='Desktop & Mobile'>
          <EncounterHeader
            title='Appointments notes'
            dateTime={new Date().toISOString()}
            description='Transition of Care Encounter'
          />
        </Box>
        <Box title='Encounter Letter Header'>
          <EncounterLetterHeader
            pageTitle='Cardiology CHA'
            buttonTitle='Contact care team'
            attachmentsDocumentId={1234}
          />
        </Box>
      </Container>

      <Container title='Summary'>
        <Box title='Desktop & Mobile'>
          <EncounterSummary
            title='Summary'
            content={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.  `}
            signature='Benjamin Lewis, MD'
          />
        </Box>
      </Container>

      <Container title='Notes'>
        <Box title='Desktop & Mobile' className='flex-col'>
          <EncounterNotesCard
            type='square'
            appointmentTitle='Cardiology CHA'
            title='Notes on your current concerns'
            content={`
          (Review of Systems)
        <br/><br/>
        Patient reports no bladder symptoms, no bowel symptoms, no numbness, no tingling, no confusion, no memory loss, no speech disorder, no convulsions, no syncope, no blackouts, no weakness, no muscle twitching, no muscle cramps, no headaches, no vertigo, no dizziness, no loss of hearing, no tinnitus, (normal) abnormality of walk: frequent falls while walking, no blurred vision, no visual loss, no double vision, (normal) difficulty walking, no insomnia, no restless legs, no sleep disorder, no loss of smell, no incoordination, no loss of taste, no neck pain, and no snoring.
        <br/>
          ROS as noted in the HPI
          `}
          />
          <EncounterNotesCard
            type='diamond'
            appointmentTitle='Cardiology CHA'
            title='Notes on other symptoms'
            content={`
          (Review of Systems)
        <br/><br/>
        Patient reports no bladder symptoms, no bowel symptoms, no numbness, no tingling, no confusion, no memory loss, no speech disorder, no convulsions, no syncope, no blackouts, no weakness, no muscle twitching, no muscle cramps, no headaches, no vertigo, no dizziness, no loss of hearing, no tinnitus, (normal) abnormality of walk: frequent falls while walking, no blurred vision, no visual loss, no double vision, (normal) difficulty walking, no insomnia, no restless legs, no sleep disorder, no loss of smell, no incoordination, no loss of taste, no neck pain, and no snoring.
        <br/>
          ROS as noted in the HPI
          `}
          />
          <EncounterNotesCard
            type='diamond-symbols'
            appointmentTitle='Cardiology CHA'
            title='Physical exam'
            content={`
          (Review of Systems)
        <br/><br/>
        Patient reports no bladder symptoms, no bowel symptoms, no numbness, no tingling, no confusion, no memory loss, no speech disorder, no convulsions, no syncope, no blackouts, no weakness, no muscle twitching, no muscle cramps, no headaches, no vertigo, no dizziness, no loss of hearing, no tinnitus, (normal) abnormality of walk: frequent falls while walking, no blurred vision, no visual loss, no double vision, (normal) difficulty walking, no insomnia, no restless legs, no sleep disorder, no loss of smell, no incoordination, no loss of taste, no neck pain, and no snoring.
        <br/>
          ROS as noted in the HPI
          `}
          />
          <EncounterNotesCard
            type='sun-ray'
            appointmentTitle='Cardiology CHA'
            title='Results and next steps'
            content={`
          (Review of Systems)
        <br/><br/>
        Patient reports no bladder symptoms, no bowel symptoms, no numbness, no tingling, no confusion, no memory loss, no speech disorder, no convulsions, no syncope, no blackouts, no weakness, no muscle twitching, no muscle cramps, no headaches, no vertigo, no dizziness, no loss of hearing, no tinnitus, (normal) abnormality of walk: frequent falls while walking, no blurred vision, no visual loss, no double vision, (normal) difficulty walking, no insomnia, no restless legs, no sleep disorder, no loss of smell, no incoordination, no loss of taste, no neck pain, and no snoring.
        <br/>
          ROS as noted in the HPI
          `}
          />
        </Box>
      </Container>

      <Container title='Disclaimer'>
        <Box title='Desktop & Mobile'>
          <Disclaimer
            text={
              <p className='font-medium text-product-forest-100'>
                The notes below are for internal clinical use. Your doctor would be happy to explain
                all salient findings to you in non-clinical terms. Please contact your Care team
                with any questions.
              </p>
            }
          />
        </Box>
      </Container>

      <Container title='Expand detailed notes'>
        <Box title='Desktop & Mobile'>
          <div className='w-full'>
            <EncounterExpandNotes title='Expand detailed notes'>
              <Disclaimer text='The notes below are for internal clinical use. Your doctor would be happy to explain all salient findings to you in non-clinical terms. Please contact your Care team with any questions.' />
              <EncounterNotesCard
                type='diamond'
                appointmentTitle='Cardiology CHA'
                title='Notes on your current concerns'
                content={`
                    (Review of Systems)
                    <br/><br/>
                    Patient reports no bladder symptoms, no bowel symptoms, no numbness, no tingling, no confusion, no memory loss, no speech disorder, no convulsions, no syncope, no blackouts, no weakness, no muscle twitching, no muscle cramps, no headaches, no vertigo, no dizziness, no loss of hearing, no tinnitus, (normal) abnormality of walk: frequent falls while walking, no blurred vision, no visual loss, no double vision, (normal) difficulty walking, no insomnia, no restless legs, no sleep disorder, no loss of smell, no incoordination, no loss of taste, no neck pain, and no snoring.
                    <br/>
                    ROS as noted in the HPI
                  `}
              />
            </EncounterExpandNotes>
          </div>
        </Box>
      </Container>
      <Container title='Signature Footer'>
        <Box title='Desktop & Mobile'>
          <EncounterSignatureFooter signature='Benjamin Lewis, MD' />
        </Box>
      </Container>

      <Header title='Care team' />
      <Container title='Static stone'>
        <StoneCard text='A world-class team of physicians delivering proactive, preventive, personalized care' />
      </Container>
      <Container title='CMO Card'>
        <Box title='Desktop & Mobile'>
          <CmoCard
            doctor={{
              name: 'Jahangir Rahman',
              title: 'Internal Medicine',
              description: [
                'Moving from Bangladesh to the Bronx at age 3—and subsequently living in all five boroughs—Dr. Jahangir Rahman is a New Yorker through and through. After experiencing incredible care from the doctors at Roosevelt Hospital who saved his father from a gunshot wound, Dr. Rahman decided to pursue becoming a physician specializing in internal medicine.',
                'Dr. Rahman graduated from NYU with a Bachelor of Arts in Psychology and Biopsychology, which eventually led him to study medicine. “It was through the study of psychology that I realized the pain that we all share as part of the human condition,” he says. Dr. Rahman earned his medical degree from Stony Brook School of Medicine and completed his internship and residency at Mount Sinai Hospital, where he specialized in internal medicine.',
                'Dr. Rahman served as a Director of Medical Consultation at Bellevue Hospital Center, a role he describes as one that profoundly developed his clinical expertise. In Dr. Rahman’s words, “At Bellevue, one sees the most complicated cases. By taking care of patients there, teaching the medical students and residents, and running the medical consultation course, there’s almost no situation I have not dealt with.” Following a decade at Bellevue Hospital Center, Dr. Rahman joined a private practice where he has acted as one of the managing partners for 20 years. He is board certified by the Diplomate of the American Board of Internal Medicine and a Fellow of the American College of Physicians.',
                'Dr. Rahman has dedicated his storied career to education. He has taught at Presbyterian Hospital in the City of New York, Tisch Hospital New York University Medical Center, and currently teaches third-year medical students at Columbia University as an Assistant Clinical Professor of Medicine. He has also written and published various articles for prestigious medical journals and books.',
                'Dr. Rahman brings to Atria his commitment to health care and decades of experience in internal medicine. He is excited to be collaborating with his former Columbia University and Bellevue Hospital Center colleagues to bring patients an elevated level of personalized care.',
              ],
              pods: {
                podMembers: [
                  {
                    location: 'New York',
                    name: 'Jahangir Rahman',
                    profession: 'MD',
                    title: 'Internal Medicine',
                  },
                ],
                phone: '123-555-555',
              },
              athenaProvider: '123456',
              cmoNumber: '123456',
              degree: 'MD',
              shortDescription: [
                'Dr. Rahman brings to Atria his commitment to health care and decades of experience in internal medicine.',
              ],
              tags: ['Internal Medicine', 'Primary Care Doctor'],
              awards: [
                {
                  description: 'New York University',
                  title: 'Founders Day Award for Scholastic Achievement',
                },
                {
                  description: '',
                  title: 'Alpha Omega Alpha National Medical Honor Society',
                },
              ],
              seoSlug: 'jahangir-rahman',
              profession: 'Dr. ',
              photo:
                'https://cdn.sanity.io/images/3h389p8z/production/ba46d0bb91448e23186e3e7efdd4e715b60dc7ea-464x619.jpg',
              location: ['New York'],
              credentials: [
                {
                  description: 'Columbia University Vagelos College of Physicians and Surgeons',
                  title: 'Assistant Clinical Professor of Medicine',
                },
                {
                  description: 'NYU Grossman School of Medicine',
                  title: 'Assistant Clinical Professor of Medicine',
                },
                {
                  description: 'Medical Consultation, Bellevue Hospital Center',
                  title: 'Former Co-Director',
                },
                {
                  description: 'New York Physicians',
                  title: 'Former Managing Partner',
                },
              ],
              affiliations: [
                {
                  description: 'American College of Physicians',
                  title: 'Fellow',
                },
                {
                  description: 'Alpha Omega Alpha National Medical Honor Society',
                  title: 'Member',
                },
              ],
              seoTitle: 'Dr. Jay Rahman, Internal Medicine, Primary Care Doctor, Atria',
              id: 'e1ef6a50-39ce-4336-af9e-724678f38822',
            }}
          />
        </Box>
      </Container>
      <Container title='Your Specialists'>
        <CareTeamCard />
        <CareTeamProviderList
          isSidebarVisible={false}
          data={[
            {
              speciality: 'Your specialists',
              providers: [
                {
                  name: 'Jaime Burkle',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/306faf1a3205a66fa09ee773004d8d9e93e169d5-1920x2560.png',
                  profession: 'MD',
                  isYourCMO: false,
                  title: 'Chair of Cardiology',
                  locations: ['Palm Beach'],
                  contact: '123-555-555',
                },
                {
                  name: 'Lawrence C. Newman',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/ba46d0bb91448e23186e3e7efdd4e715b60dc7ea-464x619.jpg',
                  profession: 'MD',
                  isYourCMO: false,
                  title: 'Director of the Atria Brain Health Program; Neurology',
                  locations: ['New York'],
                  contact: '456-555-555',
                },
                {
                  name: 'Kristine Kaw',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/ba46d0bb91448e23186e3e7efdd4e715b60dc7ea-464x619.jpg',
                  profession: 'PA',
                  isYourCMO: false,
                  title: 'Internal Medicine',
                  locations: ['New York'],
                  contact: '789-555-555',
                },
              ],
            },
          ]}
        />
      </Container>
      <Container title='Providers'>
        <CareTeamProviderList
          paginationLimit={2}
          data={[
            {
              speciality: 'Leadership',
              providers: [
                {
                  name: 'David W. Dodick',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/48f37c6e62dd96fae8f2a1dce9e972b5206b5326-1920x2560.png',
                  profession: 'MD',
                  title:
                    'Chief Science & Medical Officer, Atria; Co-Chair, Atria Academy of Science & Medicine',
                  locations: ['New York', 'Palm Beach'],
                },
              ],
            },
            {
              speciality: 'Internal Medicine',
              note: 'CMOs curate personalized care plans for you.',
              providers: [
                {
                  name: 'Jaime Burkle',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/306faf1a3205a66fa09ee773004d8d9e93e169d5-1920x2560.png',
                  profession: 'MD',
                  isYourCMO: true,
                  title: 'Internal Medicine',
                  locations: ['Palm Beach'],
                },
                {
                  name: 'Jahangir Rahman',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/ba46d0bb91448e23186e3e7efdd4e715b60dc7ea-464x619.jpg',
                  profession: 'MD',
                  isYourCMO: true,
                  title: 'Internal Medicine',
                  locations: ['New York'],
                },
                {
                  name: 'Paul G. Lee',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/7c1a6fb8e803d4ab1733b16abc5b47a8b26d344b-3024x4032.jpg',
                  profession: 'MD, MPH',
                  isYourCMO: false,
                  title: 'Co-Chair of International Executive Health; Internal Medicine',
                  locations: ['New York'],
                },
                {
                  name: 'Richard S. Isaacson',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/306faf1a3205a66fa09ee773004d8d9e93e169d5-1920x2560.png',
                  profession: 'MD',
                  isYourCMO: false,
                  title: 'Director of the Atria Precision Prevention Program',
                  locations: ['New York', 'Palm Beach'],
                },
                {
                  name: 'Bernard M. Kruger',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/ba46d0bb91448e23186e3e7efdd4e715b60dc7ea-464x619.jpg',
                  profession: 'MD',
                  isYourCMO: true,
                  title: 'Internal Medicine',
                  locations: ['New York'],
                },
                {
                  name: 'Edward J. Greaney',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/7c1a6fb8e803d4ab1733b16abc5b47a8b26d344b-3024x4032.jpg',
                  profession: 'MD, MPH',
                  isYourCMO: false,
                  title: 'Co-Chair of International Executive Health; Internal Medicine',
                  locations: ['New York'],
                },
                {
                  name: 'Richard S. Isaacson',
                  imageURL:
                    'https://cdn.sanity.io/images/3h389p8z/production/306faf1a3205a66fa09ee773004d8d9e93e169d5-1920x2560.png',
                  profession: 'MD',
                  isYourCMO: false,
                  title: 'Director of the Atria Precision Prevention Program',
                  locations: ['New York', 'Palm Beach'],
                },
              ],
            },
            { speciality: 'Cardiology', note: 'One-liner about cardiology', providers: [] },
            { speciality: 'Brain Health', note: 'One-liner about brain health', providers: [] },
            {
              speciality: "Women's Health",
              note: 'One-liner about women&#39;s health',
              providers: [],
            },
            {
              speciality: 'Integrative Health',
              note: 'One-liner about integrative health',
              providers: [],
            },
            { speciality: 'Movement', note: 'One-liner about movement', providers: [] },
            { speciality: 'Nutrition', note: 'One-liner about nutrition', providers: [] },
            { speciality: 'Urgent Care', note: 'One-liner about urgent care', providers: [] },
            { speciality: 'Dermatology', note: 'One-liner about dermatology', providers: [] },
            { speciality: 'Geriatrics', note: 'One-liner about geriatrics', providers: [] },
            { speciality: 'Pediatrics', note: 'One-liner about pediatrics', providers: [] },
          ]}
        />
      </Container>
      <Header title='Appointments Page' />
      <Container title='Schedule'>
        <Box title='Location Card'>
          <div className='bg-product-sand-200 w-full p-3'>
            <AppointmentsCardLocation
              location={{
                street: '36 East 57th Street',
                complement: 'Fifth Floor',
                city: 'New York',
                phoneNumber: '212.600.2000',
                notes: {
                  description:
                    'Atria has reserved parking spots with stanchions that we will move upon your arrival. Call our office if you need assistance.',
                },
                directionMaps:
                  'https://www.google.com/maps/dir//Atria+Institute,+36+E+57th+St,+New+York,+NY+10022,+United+States/@40.761855,-73.9745417,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c259e6a735da41:0x12bb2347d8eef0ff!2m2!1d-73.9719668!2d40.761851?entry=ttu',
              }}
            />
          </div>
        </Box>
        <Box title='Video Visit'>
          <div className='bg-product-sand-200 w-full p-3'>
            <AppointmentsCardTelehealth
              emailCmo='drrahmanteam@atria.org'
              phoneNumber='123-555-555'
              videoLink='https://zoom.us/j/123456789'
              meetingId='123456789'
              passcode='123456'
            />
          </div>
        </Box>
        <Box title='Home Visit'>
          <div className='bg-product-sand-200 w-full p-3'>
            <AppointmentsCardHomeVisit
              emailCmo='drrahmanteam@atria.org'
              phoneNumber='123-555-555'
              address='36 East 57th Street, Fifth Floor, New York, NY 10022'
            />
          </div>
        </Box>
        <Box title='Phone call'>
          <div className='bg-product-sand-200 w-full p-3'>
            <AppointmentsCardPhoneCall
              emailCmo='drrahmanteam@atria.org'
              phoneNumber='123-555-555'
            />
          </div>
        </Box>
        <Box title='Footer'>
          <div className='bg-product-sand-200 w-full p-3 mx-auto'>
            <AppointmentsCardFooter
              text='Your care team is here to assist with your appointments. Please reach out at any time.'
              buttons={[
                { label: 'Schedule an appointment', onClick: () => {} },
                { label: 'Ask a question', onClick: () => {} },
              ]}
            />
          </div>
        </Box>
      </Container>
    </div>
  );
}

function Header({ title }: { title: string }) {
  return (
    <div className='bg-product-forest-100 flex flex-col py-[60px] px-[64px] rounded-[20px]'>
      <img src={AtriaLogo} alt='Atria' title='Atria' className='max-w-[56px]' />
      <p className='font-display text-[15.0vw] lg:text-[120px] text-white'>{title}</p>
    </div>
  );
}

function Container({ children, title }: { children: React.ReactNode; title: string }) {
  return (
    <div
      id={title}
      className='bg-white w-full flex-1 flex flex-col flex-wrap items-start justify-center rounded-[20px] py-[80px] md:px-[64px] px-4 gap-10'
    >
      <h1 className='font-display text-[48px] font-medium text-base'>{title}</h1>
      {children}
    </div>
  );
}

function Box({
  title,
  children,
  className,
}: {
  title?: string;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className='w-full flex flex-col flex-wrap'>
      {title && <h2 className='font-body text-2xl  font-medium text-base pb-5'>{title}</h2>}
      <div
        className={clsx(
          'w-full flex flex-row gap-2 border border-regular py-20 md:px-10 px-3',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
}

function Card({ description, children }: { description: string; children: React.ReactNode }) {
  return (
    <div className='flex-1 flex flex-col items-center justify-between gap-3'>
      <div className='flex-1 w-full flex flex-col flex-wrap items-center justify-center gap-3'>
        {children}
      </div>
      <span className='font-body font-medium leading-6 text-content-emphasis pt-10'>
        {description}
      </span>
    </div>
  );
}
