import { MaterialIcons } from '@/components/icons';
import { TextLayout } from '@/components/text';
import { cn } from '@lib-atria/ui-toolkit';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

type BackButtonProps = {
  label?: string;
};

export function BackButton({ label = 'Back' }: BackButtonProps) {
  const history = useNavigate();

  const handleBack = useCallback(() => {
    history(-1);
  }, [history]);

  return (
    <a className='items-center flex justify-between gap-5 lg:gap-8' href='#' onClick={handleBack}>
      <MaterialIcons name='arrow_back' className='text-fern text-2xl' />
      <span className={cn(TextLayout.callout2, 'text-kelp-light')}>{label}</span>
    </a>
  );
}
