import { useEffect, useMemo, useState } from 'react';

import { HomeCards, HomeContentCards, HomeContentLoading } from '@/components';
import { env } from '@/constants';
import { useApplicationContext, useAuthContext, usePageContext } from '@/contexts';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

export function HomePage() {
  const { patient } = useAuthContext();
  const { dashboardSummary, findDashboardSummary } = useApplicationContext();
  const { setPageTitle } = usePageContext();
  const [isLoading, setIsLoading] = useState(false);

  const greetings = useMemo(() => {
    const hours = new Date().getHours();
    if (hours < 12) {
      return 'Good morning';
    } else if (hours < 17) {
      return 'Good afternoon';
    }
    return 'Good evening';
  }, []);

  useEffect(() => {
    setPageTitle('Home');
  }, [setPageTitle]);

  useEffect(() => {
    async function init() {
      setIsLoading(true);

      await findDashboardSummary();
      setIsLoading(false);
    }

    if (!dashboardSummary) {
      init();
    }
  }, [dashboardSummary, findDashboardSummary]);

  useEffect(() => {
    eventAnalytics.track(TRACK_EVENTS.HOME_PAGE_VIEWED, { patientId: patient?.id });
  }, [patient?.id]);

  return (
    <>
      {env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_HOME_PAGE ? (
        <HomeContentCards />
      ) : (
        <>
          <div className='flex flex-row items-start flex-wrap gap-4 justify-between pb-6 lg:pb-0 border-b border-kelp mb-6'>
            <h1 className='pt-10 font-display font-medium text-kelp text-[4rem] leading-tight'>
              {greetings}, {patient?.firstNameUsed || patient?.firstName}
            </h1>
          </div>
          {isLoading ? <HomeContentLoading /> : <HomeCards summary={dashboardSummary} />}
        </>
      )}
    </>
  );
}
