import { useEffect } from 'react';

import { PatientRegistrationForm } from '@/components';
import { usePageContext } from '@/contexts';

export function PatientRegistrationPage() {
  const { setPageTitle } = usePageContext();

  useEffect(() => {
    setPageTitle('Patient Registration');
  }, [setPageTitle]);

  return <PatientRegistrationForm />;
}
