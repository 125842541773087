import { Button, cn, Sheet, TextLayout } from '@lib-atria/ui-toolkit';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { SearchPatientByName } from '@/components';
import { useToastContext } from '@/contexts';
import { UserService } from '@/services';
import { patientAddSheetResolver, PatientAddSheetType } from './patientAddSheetSchema';
import { admViewRelations, Relation } from '@/hooks';

type Patient = {
  patientId: number;
  name: string;
  relation: Relation;
  permissions: Array<string>;
};

type Patients = Array<Omit<Patient, 'name'>>;

type Props = {
  userId: string;
  isVisible?: boolean;
  onClose: VoidFunction;
  patients?: Patients;
  permissions?: Array<{ id: string; name: string; description: string }>;
  onAdd: (patient: Patient) => void;
};

export function PatientAddSheet({
  userId,
  isVisible = false,
  onClose,
  patients,
  permissions,
  onAdd,
}: Props) {
  const {
    setValue,
    control,
    setError,
    clearErrors,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm<PatientAddSheetType>({ resolver: patientAddSheetResolver });
  const { toast } = useToastContext();
  const [isLoading, setIsLoading] = useState(false);

  const hasErrors = Object.keys(errors).length > 0;

  const userRelationsOptions = useMemo(
    () => Object.entries(admViewRelations).map(([key, val]) => ({ label: val, value: key })),
    []
  );

  const onSubmit = useCallback(
    (values: PatientAddSheetType) => {
      const exists = patients?.find((p) => p.patientId === values.patient.id);
      if (exists) {
        setError('patient.id', {
          type: 'custom',
          message: 'This person has already been added to the connections list',
        });
        return;
      }
      setIsLoading(true);
      const newPatients = patients ? [...patients] : [];
      const newPatient = {
        patientId: values.patient.id,
        permissions: values.permissions,
        relation: values.relation as Relation,
      };
      newPatients.push(newPatient);
      const body = { appMetadata: { patients: newPatients } };
      UserService.updateUserById(userId, body)
        .then(() => {
          toast?.current.show({
            severity: 'success',
            summary: 'Adding new connection',
            detail: 'New connection successfully added',
            life: 2500,
          });
          onAdd({ ...newPatient, name: values.patient.name });
          reset();
          onClose();
        })
        .catch((error) => {
          toast?.current.show({
            severity: 'error',
            summary: 'Adding conection',
            detail: error.response.data?.message || 'An unexpected error occurred',
            life: 2500,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [onAdd, onClose, patients, reset, setError, toast, userId]
  );

  useEffect(() => {
    return () => reset();
  }, [reset]);

  return (
    <Sheet isVisible={isVisible} onClose={onClose}>
      <p className={cn(TextLayout.callout1, 'text-product-forest-100 mb-4')}>Add new connection</p>
      <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Connected person</p>
      <SearchPatientByName
        searchBy='lastName'
        onSelect={(patient) => {
          if (patient) {
            setValue('patient', {
              id: patient.id,
              name: `${patient.firstName} ${patient.lastName}`,
            });
            clearErrors('patient');
          }
        }}
      />
      {errors.patient?.id?.message && (
        <p className={cn(TextLayout.body3, 'text-rust my-4')}>{errors.patient.id.message}</p>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <p className={cn(TextLayout.body3, 'text-product-forest-100 my-4')}>Relation</p>
        <Controller
          control={control}
          name='relation'
          render={({ field }) => (
            <Dropdown
              {...field}
              options={userRelationsOptions}
              placeholder='Relation'
              className='w-full rounded-[42px] outline-none px-9 py-2 mb-4'
            />
          )}
        />
        {errors.relation?.message && (
          <p className={cn(TextLayout.body3, 'text-rust mb-4')}>{errors.relation.message}</p>
        )}
        <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Permissions</p>
        <Controller
          control={control}
          name='permissions'
          render={({ field }) => (
            <div className='pb-4'>
              <MultiSelect
                multiple
                className='w-full rounded-[42px] outline-none px-9 py-2 hover:ring-0'
                options={permissions}
                placeholder='Select permissions'
                optionLabel='description'
                optionValue='id'
                filter
                {...field}
              />
            </div>
          )}
        />
        {errors.permissions?.message && (
          <p className={cn(TextLayout.body3, 'text-rust mb-4')}>{errors.permissions.message}</p>
        )}
        <div className='w-full py-4 flex justify-end pr-10'>
          <Button type='submit' label='Save' disabled={isLoading || hasErrors} />
        </div>
      </form>
    </Sheet>
  );
}
