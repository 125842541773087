import { Theme } from '@/theme';

type Props = React.SVGProps<SVGSVGElement>;

export function SunRayIcon({
  width = 20,
  height = 20,
  fill = Theme.colors['fern'],
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.2582 19L10.6044 11.8791L8.23077 18.6044L6.84615 18.0604L9.91209 11.5824L5.16484 16.9231L4.07692 15.8352L9.41758 11.0879L2.93956 14.1538L2.3956 12.7692L9.12088 10.3956L2 10.7418V9.25824L9.12088 9.65385L2.3956 7.23077L2.93956 5.8956L9.41758 8.96154L4.07692 4.16483L5.16484 3.12637L9.91209 8.41758L6.84615 1.98901L8.23077 1.3956L10.6044 8.17033L10.2582 1H11.7418L11.3462 8.12088L13.7692 1.3956L15.1044 1.98901L12.0385 8.41758L16.8352 3.12637L17.8736 4.16483L12.5824 8.96154L19.011 5.8956L19.6044 7.23077L12.8297 9.65385L20 9.25824V10.7418L12.8297 10.3956L19.6044 12.7692L19.011 14.1538L12.5824 11.0879L17.8736 15.8352L16.8352 16.9231L12.0385 11.5824L15.1044 18.0604L13.7692 18.6044L11.3462 11.8791L11.7418 19H10.2582Z'
        fill={fill}
      />
    </svg>
  );
}
