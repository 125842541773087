import { useEffect } from 'react';

import { LoginLoader } from '@/components';
import { useApplicationContext, useAuthContext } from '@/contexts';

export function SwitchMemberPage() {
  const { switchMember } = useAuthContext();
  const { reset } = useApplicationContext();

  useEffect(() => {
    switchMember();
    reset();
  }, [reset, switchMember]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <LoginLoader />
    </div>
  );
}
