import { useEffect } from 'react';

import { LoginLoader } from '@/components';
import { useAuthContext, usePageContext } from '@/contexts';
import { useOktaAuth } from '@okta/okta-react';
import { env } from '@/constants';

export function SignOutAdminPage() {
  const { setPageTitle } = usePageContext();
  const { signOutUser } = useAuthContext();
  const { oktaAuth } = useOktaAuth();

  useEffect(() => {
    setPageTitle('Sign Out');
  }, [setPageTitle]);

  useEffect(() => {
    async function init() {
      signOutUser();

      await oktaAuth.signOut({
        postLogoutRedirectUri: `${env.APP_MEMBER_PORTAL_FRONTEND_URL}/sign-in`,
      });
    }
    init();
  }, [signOutUser, oktaAuth]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <LoginLoader />
    </div>
  );
}
