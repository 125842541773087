import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import { StringHelper } from '@/helper';
import { PatientDocument } from '@/@types';
import { BasicCard } from '.';
import { BigButton } from '..';

type Props = {
  title: string;
  document?: PatientDocument;
};

export function FavoriteDocumentCard({ title, document }: Props) {
  return (
    <BasicCard className='h-full shadow-none bg-[#eeece5] rounded-xl px-5'>
      <p className='block font-medium leading-8 text-kelp mb-2'>{title}</p>
      <div className='flex flex-col items-top grow'>
        <h2 className='text-2xl font-medium text-kelp mt-2'>
          {StringHelper.firstLetterUppercase(document?.documentName || '')}
        </h2>
        <p className='text-md'>{DateTime.fromISO(document?.date || '').toFormat('DD')}</p>
      </div>

      <Link to={`/documents/${document?.id}`} className='mt-4'>
        <BigButton>View results</BigButton>
      </Link>
    </BasicCard>
  );
}
