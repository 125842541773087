import { CustomTableFilters } from '@/@types';
import { MaterialIcons, TextLayout } from '@/components';

import { cn } from '@lib-atria/ui-toolkit';

type FilterTableByTextProps = {
  filters: CustomTableFilters;
  handleFilterChange: (key: string, value: string) => void;
  icon?: string;
};

export function FilterTableByText({ filters, handleFilterChange, icon }: FilterTableByTextProps) {
  return filters.map((filter) => (
    <div key={filter.key} className='relative flex items-center md:w-full md:max-w-xs '>
      {!!icon && <MaterialIcons name={icon} className='text-forest-100 absolute pl-6' />}
      <input
        type='text'
        onChange={(e) => handleFilterChange(filter.key, e.target.value)}
        placeholder={filter.label}
        className={cn(
          TextLayout.body1,
          'border border-product-sand-300 font-body rounded-full py-2 bg-transparent pl-12 pr-6 text-off-black caret-forest-100 outline-none focus:border-fern'
        )}
      />
    </div>
  ));
}
