import classNames from 'classnames';
import { ButtonHTMLAttributes, ReactNode } from 'react';

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: 'light' | 'dark' | 'white' | 'outline';
  children: ReactNode;
  className?: string;
};

export function BigButton({ children, variant = 'dark', className, ...rest }: Props) {
  return (
    <button
      className={classNames([
        'block text-center px-20 duration-150 active:ring-4 rounded-full',
        variant === 'dark' && 'py-3 text-sm bg-kelp hover:bg-fern ring-fern/25 text-white w-full',
        variant === 'light' &&
          'py-3 w-full text-sm bg-fern hover:bg-fern ring-fern/25 text-[var(--stone)]',
        variant === 'white' &&
          'py-3 bg-white border-none text-kelp font-medium font-body hover:bg-gray-100 w-full',
        variant === 'outline' && 'border border-kelp text-kelp font-medium hover:bg-kelp',
        className,
      ])}
      {...rest}
    >
      {children}
    </button>
  );
}
