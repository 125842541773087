function delay(ms: number, callback?: VoidFunction) {
  let timer: NodeJS.Timeout;
  return new Promise((resolve) => {
    timer = setTimeout(() => {
      resolve(true);
      if (callback) {
        callback();
      }
      clearTimeout(timer);
    }, ms);
  });
}

function debounce<T extends Function>(cb: T, wait = 20) {
  let h: NodeJS.Timeout;
  const callable = (...args: any) => {
    clearTimeout(h);
    h = setTimeout(() => cb(...args), wait);
  };
  return <T>(<any>callable);
}

export const TimerHelper = {
  delay,
  debounce,
};
