import { useCallback, useState } from 'react';

import { CareTeamProviderLocationDesktop } from './careTeamProviderLocationDesktop';
import { CareTeamProviderLocationMobile } from './careTeamProviderLocationsMobile';

type Props = {
  selected: string;
  locations: string[];
  onSelect: (location: string) => void;
};

export function CareTeamProviderLocation({ selected, locations, onSelect }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onHide = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleOnSelect = useCallback(
    (location: string) => {
      onHide();
      onSelect(location);
    },
    [onHide, onSelect]
  );

  return (
    <>
      <CareTeamProviderLocationMobile
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onHide}
        selected={selected}
        onSelect={handleOnSelect}
        locations={locations}
      />
      <CareTeamProviderLocationDesktop
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onHide}
        selected={selected}
        onSelect={handleOnSelect}
        locations={locations}
      />
    </>
  );
}
