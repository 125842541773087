import { Button, cn, Stats } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { CustomTableHeader } from '@/@types';
import { CustomTable, PatientAddSheet, PatientEditSheet, TextLayout } from '@/components';
import { usePageContext, useToastContext } from '@/contexts';
import { admViewRelations, Relation, selfKey, userPermissionsList, useUsers } from '@/hooks';
import { patientsService, UserService } from '@/services';

const headers: Array<CustomTableHeader> = [
  {
    key: 'patientId',
    label: 'MRN',
    width: 130,
  },
  {
    key: 'name',
    label: 'Name',
  },
  {
    key: 'relation',
    label: 'Relation',
    width: 200,
  },
  {
    key: 'permissions',
    label: 'Permissions',
  },
];

type Patient = {
  patientId: number;
  name: string;
  relation: Relation;
  permissions: Array<string>;
};

type Patients = Array<Patient>;

export function UserEditPage() {
  const { id: userId } = useParams<{ id: string }>();
  const { setPageTitle, setIsBackButtonVisible } = usePageContext();
  const { toast } = useToastContext();

  const { user, findUserById } = useUsers();
  const [patients, setPatients] = useState<Patients>([]);
  const [isPatientAddSheetVisible, setIsPatientAddSheetVisible] = useState<boolean>(false);
  const [isPatientEditSheetVisible, setIsPatientEditSheetVisible] = useState<boolean>(false);
  const [patientSelected, setPatientSelected] = useState<Patient | undefined>();

  const patientsAssociated = useMemo(
    () => user?.appMetadata?.patients || [],
    [user?.appMetadata?.patients]
  );

  const findPatientsWithPermissions = useCallback(async () => {
    if (!user) return [];
    const patientIds = patientsAssociated?.map(({ patientId }) => patientId);
    if (!patientIds.length) return [];
    const { data: patientsResponse = [] } = await patientsService.findAllPatients({
      patientId: patientIds,
    });
    return patientsResponse.map((patient) => {
      const patientWithPermission = patientsAssociated?.find((p) => p.patientId === patient.id);
      const relation = (patientWithPermission?.relation || 'unknown') as Relation;
      const permissions = patientWithPermission?.permissions || [];
      return {
        patientId: patient.id,
        name: `${patient.firstName} ${patient.lastName}`,
        permissions,
        relation,
      };
    });
  }, [patientsAssociated, user]);

  const handleEditPatient = useCallback((patient: Patient) => {
    setPatientSelected(patient);
    setIsPatientEditSheetVisible(true);
  }, []);

  const deletePatient = useCallback(
    (patient: Patient) => {
      setPatients((prev) => {
        const copy = [...prev];
        const newPatientsList = copy.filter((p) => p.patientId !== patient.patientId);
        const body = { appMetadata: { patients: newPatientsList } };
        UserService.updateUserById(userId!, body)
          .then(() => {
            toast?.current.show({
              severity: 'success',
              summary: 'Removing patient',
              detail: 'Patient was successfully removed',
              life: 2500,
            });
          })
          .catch((error) => {
            toast?.current.show({
              severity: 'error',
              summary: 'Removing patient',
              detail: error.response.data?.message || 'An unexpected error occurred',
              life: 2500,
            });
            setPatients(copy);
          });
        return newPatientsList;
      });
    },
    [toast, userId]
  );

  const handleDeletePatient = useCallback(
    async (patient: Patient) => {
      const confirm = window.confirm('Are you sure you want to delete this access?');
      if (confirm) {
        return deletePatient(patient);
      }
    },
    [deletePatient]
  );

  const createActionsButton = useCallback(
    (patient: Patient) => {
      return {
        actions: {
          more: [
            {
              label: 'Edit',
              onClick: () => handleEditPatient(patient),
            },
            ...(patient.relation !== selfKey
              ? [{ label: 'Delete', onClick: () => handleDeletePatient(patient) }]
              : []),
          ],
        },
      };
    },
    [handleDeletePatient, handleEditPatient]
  );

  const handleOnNewPatientAdded = useCallback(
    (newPatient: Patient) => {
      const patientWithButtons = {
        ...newPatient,
        ...createActionsButton(newPatient),
      };
      setPatients((prev) => [...prev, patientWithButtons]);
    },
    [createActionsButton]
  );

  const handleOnPatientEdit = useCallback(
    (patientEdited: Omit<Patient, 'name'>) => {
      const currentPatient = patients.find((p) => p.patientId === patientEdited.patientId);
      if (!currentPatient) return;
      const newPatient = {
        name: currentPatient.name,
        ...patientEdited,
      };
      const patientWithButtons = {
        ...newPatient,
        ...createActionsButton(newPatient),
      };
      setPatients((prev) => {
        return [...prev].map((item) => {
          if (item.patientId === patientEdited.patientId) {
            return patientWithButtons;
          }
          return item;
        });
      });
    },
    [createActionsButton, patients]
  );

  const createPatientsList = useCallback(async () => {
    const patientsResponse = await findPatientsWithPermissions();
    const patientsTableList = patientsResponse.map((patient) => {
      return {
        ...patient,
        relation: patient.relation,
        ...createActionsButton(patient),
      };
    });
    setPatients(patientsTableList);
  }, [createActionsButton, findPatientsWithPermissions]);

  useEffect(() => {
    setPageTitle('Patients View');
    setIsBackButtonVisible(true);
    return () => {
      setIsBackButtonVisible(false);
    };
  }, [setIsBackButtonVisible, setPageTitle]);

  useEffect(() => {
    if (userId) {
      findUserById(userId);
    }
  }, [findUserById, userId]);

  useEffect(() => {
    createPatientsList();
  }, [createPatientsList]);

  return (
    <div className='py-10'>
      <h1 className={cn(TextLayout.callout1, 'text-product-forest-100 mb-4')}>{user?.name}</h1>
      <div className='w-full gap-3 grid grid-cols-1 md:grid-cols-3 items-stretch justify-between'>
        <Stats size='small' label='MRN' value={user?.appMetadata.patientId || '-'} />
        <Stats size='small' label='Logins count' value={user?.loginsCount || '-'} />
        <Stats size='small' label='Date of registration' value={user?.createdAt || '-'} />
      </div>
      <div className='w-full flex items-center justify-between pt-6 pb-2'>
        <h2 className={cn(TextLayout.callout2, 'text-product-forest-100 my-4')}>
          Connected person
        </h2>
        <Button
          label='Add'
          icon='add'
          iconVisibility='mobile-only'
          textVisibility='desktop-only'
          onClick={() => setIsPatientAddSheetVisible(true)}
        />
      </div>
      <CustomTable
        headers={headers}
        items={patients.map((patient) => ({
          ...patient,
          relation: admViewRelations[patient.relation],
          permissions: (
            <Button
              variant='link'
              size='small'
              label={`${patient.permissions.length} permissions`}
              onClick={() => handleEditPatient(patient)}
            />
          ),
        }))}
      />
      <PatientAddSheet
        userId={userId!}
        isVisible={isPatientAddSheetVisible}
        onClose={() => setIsPatientAddSheetVisible(false)}
        patients={patients}
        permissions={userPermissionsList}
        onAdd={handleOnNewPatientAdded}
      />
      <PatientEditSheet
        userId={userId!}
        isVisible={isPatientEditSheetVisible}
        onClose={() => setIsPatientEditSheetVisible(false)}
        patients={patients}
        patient={patientSelected}
        permissions={userPermissionsList}
        onEdit={handleOnPatientEdit}
      />
    </div>
  );
}
