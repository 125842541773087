import { env } from '@/constants';
import { PatientAuthRoute } from './patientAuthRoute';
import { PatientAuthZeroRoute } from './patientAuthZeroRoute';

export function PatientAuthMiddleware() {
  if (env.APP_FEATURE_FLAGS.IS_TO_USE_AUTH_ZERO_ACCESS_TOKEN) {
    return <PatientAuthZeroRoute />;
  }
  return <PatientAuthRoute />;
}
