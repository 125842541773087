import { CustomTableItems, Immunization } from '@/@types';
import { BasicCard, CustomTable } from '@/components';
import { useApplicationContext, useContactDialogContext } from '@/contexts';
import { useCallback, useMemo } from 'react';
import { DateTime } from 'luxon';
import { DateHelper } from '@/helper';

export function ImmunizationsCard() {
  const { immunizations } = useApplicationContext();
  const { setContactDialogTopic, setContactDialogVisibility } = useContactDialogContext();

  const handleAskQuestion = useCallback(
    (item: Immunization) => {
      setContactDialogTopic(`Question about ${item.genusName}`);
      setContactDialogVisibility(true);
    },
    [setContactDialogTopic, setContactDialogVisibility]
  );

  const getGroupNameFromArray = useCallback((array: string[]): string => {
    return array
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .sort()
      .join(', ');
  }, []);

  const groupItemsByGroupName = useCallback(
    (items: Immunization[] & CustomTableItems[]) => {
      const groupedItems: { [key: string]: CustomTableItems[] } = {};

      items.forEach((item) => {
        const groupName = getGroupNameFromArray(item.clinicalOrderClasses.map((c) => c.name));
        if (!groupedItems[groupName]) {
          groupedItems[groupName] = [];
        }
        groupedItems[groupName].push({ ...item, groupName });
      });

      return Object.keys(groupedItems).map((groupName) => ({
        groupName,
        items: groupedItems[groupName],
      }));
    },
    [getGroupNameFromArray]
  );

  const transformedItems = useMemo(() => {
    return groupItemsByGroupName(
      immunizations?.map((immunization) => ({
        ...immunization,
        date: DateHelper.formatDateToDisplay(
          DateTime.fromFormat(immunization.administerDate, 'mm/dd/yyyy')
        ),
        vaccine: immunization.genusName,
        actions: {
          more: [
            {
              label: 'Ask a question',
              onClick: () => {
                handleAskQuestion(immunization);
              },
            },
          ],
        },
      })) || []
    );
  }, [groupItemsByGroupName, handleAskQuestion, immunizations]);

  return (
    <BasicCard className='px-0 pt-0'>
      <CustomTable
        title='Immunizations'
        headers={[
          {
            key: 'vaccine',
            label: 'Name',
          },
          {
            key: 'date',
            label: 'Administered Date',
          },
        ]}
        items={transformedItems.flatMap((group) => [
          { groupName: group.groupName, isGroupHeader: true },
          ...group.items,
        ])}
      />
    </BasicCard>
  );
}
