import { Medications } from '@/@types';
import { StringHelper } from '@/helper';
import { Tooltip } from 'primereact/tooltip';
import { PillIcon } from '../icons';

type MedicationNameProps = {
  medication: Medications.Medication;
};

export function MedicationName({ medication }: MedicationNameProps) {
  return (
    <div className='flex gap-2'>
      <span>{medication.medication}</span>
      <span className='h-[21px] flex items-center gap-1'>
        {medication.rxNames?.length > 0 && (
          <>
            <Tooltip target='.letter-link-tooltip' className='text-xs rounded-lg' />
            <i
              data-pr-tooltip={medication.rxNames
                .map((rxName) => {
                  if (StringHelper.similarityPercentage(medication.medication, rxName.name) < 80) {
                    return rxName.name;
                  } else {
                    return '';
                  }
                })
                .join(' ')}
              data-pr-position='right'
              className='letter-link-tooltip'
            >
              <PillIcon />
            </i>
          </>
        )}
      </span>
    </div>
  );
}
