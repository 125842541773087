import { AtriaIconButton } from '../atriaLibrary';
import { CustomTableChevron } from './customTableChevron';

type Props = {
  isVisible?: boolean;
  isCollapsed?: boolean;
  onCollapse: VoidFunction;
};

export function CustomTableItemActionCollapse({
  isVisible,
  isCollapsed = false,
  onCollapse,
}: Props) {
  if (!isVisible) return null;

  return (
    <AtriaIconButton
      className='hover:bg-kelp border border-product-sand-200 hover:border-kelp'
      onClick={onCollapse}
    >
      <CustomTableChevron
        animate={isCollapsed ? 'asc' : 'desc'}
        className='text-fern group-hover:text-white'
      />
    </AtriaIconButton>
  );
}
