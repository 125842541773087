type Props = {
  note?: React.ReactNode;
};

export function CustomTableNote({ note }: Props) {
  if (!note) return null;

  return (
    <tr className='relative z-10'>
      <td colSpan={100} className='bg-white'>
        <div className='w-full px-10 py-[22px] border-b border-product-sand-200'>{note}</div>
      </td>
    </tr>
  );
}
