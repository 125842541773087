import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const requiredErrorMessage = 'This field is required';

export const authZeroLoginFormSchema = z.object({
  email: z
    .string({
      required_error: requiredErrorMessage,
    })
    .email('Please enter a valid email address'),
});

export type AuthZeroLoginFormType = z.infer<typeof authZeroLoginFormSchema>;

export const AuthZeroLoginFormSchemaResolver = zodResolver(authZeroLoginFormSchema);
