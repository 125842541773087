import { AnimatePresence, cubicBezier, motion } from 'framer-motion';
import { cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  isVisible?: boolean;
  items: ExpandedContentItem[];
};

export type ExpandedContentItem = {
  label: string;
  onClick: () => void;
};

export function EncounterExpandedContent({ isVisible = false, items }: Props) {
  return (
    <AnimatePresence mode='popLayout'>
      {isVisible && (
        <motion.div
          transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 5, opacity: 1 }}
          exit={{ y: -20, opacity: 0 }}
          className='flex flex-col items-start justify-start w-full p-2 rounded-lg bg-product-forest-100'
        >
          {items?.map((item) => (
            <p
              key={item.label}
              onClick={item.onClick}
              className={cn(
                TextLayout.body2,
                'w-full p-3 cursor-pointer text-product-sand-300 transition-colors rounded-lg bg-transparent hover:bg-product-forest-300'
              )}
            >
              {item.label}
            </p>
          ))}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
