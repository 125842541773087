import axios from 'axios';

import { env } from '@/constants';
import { localStorageHelper } from '@/helper';
import { eventAnalytics } from './eventAnalytics';
import { LocalStorage } from '@/@types';

export const baseURL = env.APP_ATRIA_API_URL;

export const api = axios.create({ baseURL });

api.interceptors.request.use((config) => {
  const { ignore } = localStorageHelper.getItem(LocalStorage.Keys.CACHE) || {};
  config.withCredentials = true;
  if (ignore) {
    config.params = config.params || {};
    config.params['cacheIgnore'] = ignore;
  }

  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    eventAnalytics.track(`Error on ${error?.config?.url} API call`, {
      method: error?.response?.method,
      endpoint: `${error?.config?.baseURL}${error?.config?.url}`,
      status: error?.response?.status,
      body: error?.response?.config?.data,
      headers: error?.response?.headers,
      data: error?.response?.data,
    });
    if ([401].includes(error.response?.status)) {
      setPatientIdHeader(undefined);
      setTimeout(() => {
        const isAdmin = !!localStorageHelper.getItem(LocalStorage.Keys.AUTH)?.user.isAdmin || false;
        Object.keys(localStorage).forEach((key) => {
          if (key.includes('okta') || key.includes('AUTH')) {
            localStorage.removeItem(key);
          }
        });
        window.location.href = isAdmin ? '/admin/sign-out' : '/sign-out';
      }, 4000);
    }
    if ([403].includes(error.response?.status)) {
      setTimeout(() => {
        window.location.href = '/unauthorized';
      }, 2000);
    }

    return Promise.reject(error);
  }
);

export function setBearerToken(token: string) {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export function setPatientIdHeader(patientId?: string) {
  if (patientId) {
    api.defaults.headers.common['x-patient-id'] = patientId;
  } else {
    delete api.defaults.headers.common['x-patient-id'];
  }
}
