import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

const schema = z.object({
  name: z.string(),
});

export type SearchPatientByNameType = z.infer<typeof schema>;

export const searchPatientByNameResolver = zodResolver(schema);
