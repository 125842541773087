import { CustomTableItems } from '@/@types';
import { AnimatePresence, cubicBezier, motion } from 'framer-motion';

type Props = {
  isVisible?: boolean;
  item: CustomTableItems;
  itemKey: number;
  onCollapse: (itemKey: number) => void;
};

export function CustomTableExpandedContent({
  isVisible = false,
  item,
  itemKey,
  onCollapse,
}: Props) {
  return (
    <AnimatePresence>
      {isVisible && (
        <tr>
          <td colSpan={100}>
            <motion.section
              key='content'
              initial='closed'
              animate='open'
              exit='closed'
              variants={{
                open: { opacity: 1, height: 'auto' },
                closed: { opacity: 0, height: 0 },
              }}
              transition={{ duration: 0.8, ease: cubicBezier(0.65, 0, 0.35, 1) }}
              className='w-full'
            >
              <motion.div
                initial='closed'
                animate='open'
                exit='closed'
                variants={{
                  closed: { marginTop: '-100px' },
                  open: { marginTop: 0 },
                }}
                transition={{ duration: 0.8 }}
                className='pointer-events-none border-b border-product-sand-200'
                onClick={() => onCollapse(itemKey)}
              >
                <div className='px-10 pt-[24px] pb-8 text-kelp py-2'>{item.actions?.expand}</div>
              </motion.div>
            </motion.section>
          </td>
        </tr>
      )}
    </AnimatePresence>
  );
}
