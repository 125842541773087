import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useMemo } from 'react';

import { TextLayout } from '../text';

type Props = {
  title: string;
  dateTime: string;
  description?: string;
};

export function EncounterHeader({ title, dateTime, description }: Props) {
  const { date, time } = useMemo(() => {
    const instance = DateTime.fromISO(dateTime);
    return {
      date: instance.toFormat('MMM dd, yyyy'),
      time: instance.toFormat('hh:mma').toLowerCase(),
    };
  }, [dateTime]);

  return (
    <div className='w-full bg-product-forest-100 flex flex-col gap-[132px] py-7 md:py-9 px-5 md:px-10 rounded-xl'>
      <div className='w-full flex items-start justify-between'>
        <p
          className={clsx(
            TextLayout.callout2,
            'text-product-sand-200 whitespace-pre-wrap block max-w-[180px] md:max-w-fit lowercase first-letter:uppercase'
          )}
        >
          {title}
        </p>
        <div className='w-[12px] h-[12px] bg-product-sand-200 rounded-full' />
      </div>
      <div className='w-full grid grid-cols-1 md:grid-cols-[200px,1fr] gap-2 md:gap-[100px]'>
        <div className='w-full max-w-[200px] flex flex-wrap gap-2 items-center justify-between'>
          <p
            className={clsx(
              TextLayout.body2,
              'whitespace-nowrap text-product-sand-200/50 md:text-product-sand-200 flex'
            )}
          >
            {date}
          </p>
          <p
            className={clsx(
              TextLayout.body2,
              'whitespace-nowrap text-product-sand-200/50 md:text-product-sand-200 flex'
            )}
          >
            {time}
          </p>
        </div>
        <p
          className={clsx(
            TextLayout.body2,
            'text-product-sand-200/50 italic md:not-italic md:text-product-sand-200 flex flex-1',
            { 'opacity-50': !description }
          )}
        >
          {description || '-'}
        </p>
      </div>
    </div>
  );
}
