import { Doctor } from '@/@types';
import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { DialogMobile } from '../_common';
import { CareTeamAbout } from '../careTeam/careTeamAbout';
import { CareTeamAboutMobile } from '../careTeam/careTeamAboutMobile';
import { TextLayout } from '../text';
import { CustomTooltip } from '../tooltip';

export type CareTeamProviderItem = {
  name: string;
  imageURL: string;
  profession: string;
  title: string;
  locations: string[];
  isYourCMO?: boolean;
  contact?: string;
  doctor?: Doctor;
};

export function CareTeamProviderItem({
  name,
  imageURL,
  profession,
  title,
  locations,
  isYourCMO = false,
  contact,
  doctor,
}: CareTeamProviderItem) {
  const isMobile = useMediaQuery({ query: '(max-width: 1024px)' });
  const [isVisibleAbout, setIsVisibleAbout] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleParentClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (event.currentTarget.contains(event.target as Node)) {
        setIsVisibleAbout(!isVisibleAbout);
      }
    },
    [isVisibleAbout]
  );

  return (
    <div
      className={cn(
        'bg-noise-sand w-full flex gap-10 items-stretch justify-between rounded-xl',
        doctor && 'cursor-pointer'
      )}
      onClick={(event) => handleParentClick(event)}
    >
      <div className='min-w-[200px] flex flex-col gap-2 flex-1 py-7 md:py-9 px-5 md:px-10'>
        <p
          className={cn(
            isMobile ? TextLayout.callout2 : TextLayout.callout1,
            'text-product-forest-100'
          )}
        >
          {name}
          {profession && (
            <>
              , <span className={TextLayout.body2}>{profession}</span>
            </>
          )}
        </p>
        <div className='flex flex-wrap gap-2 items-center justify-start'>
          <div>
            <p className={cn(TextLayout.body2, 'text-product-gray-400')}>
              {isYourCMO && (
                <span>
                  <strong>Your CMO</strong>;{' '}
                </span>
              )}
              {title}
            </p>
          </div>
          <ul className='flex gap-2'>
            {locations.map((location) => (
              <li key={location} className='flex gap-2 items-center justify-center'>
                <span>•</span>
                <span className={cn(TextLayout.body2, 'text-product-gray-400 text-nowrap')}>
                  {location}
                </span>
              </li>
            ))}
          </ul>
          {!!contact && (
            <div className='flex gap-2 items-center justify-center'>
              {isMobile ? (
                <>
                  <span>•</span>
                  <span
                    className={cn(
                      TextLayout.body2,
                      'text-product-gray-400 text-nowrap border-b-[0.5px] border-product-gray-400 cursor-pointer'
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsDialogOpen(true);
                    }}
                  >
                    Contact
                  </span>
                </>
              ) : (
                <CustomTooltip
                  content={
                    <div className='px-8 py-6 rounded-lg bg-experimental-forest text-white w-full max-w-[300px]'>
                      <p className='text-sm'>
                        Call or text {contact} at any time to speak with {name}
                      </p>
                    </div>
                  }
                >
                  <span className='mr-2'>•</span>
                  <span
                    className={cn(
                      TextLayout.body2,
                      'text-product-gray-400 text-nowrap border-b-[0.5px] border-product-gray-400 cursor-pointer'
                    )}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    Contact
                  </span>
                </CustomTooltip>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className='hidden sm:block w-[93px] min-w-[93px] md:w-[154px] min-h-[148px] md:min-h-[136px] bg-cover bg-center md:bg-top bg-no-repeat rounded-lg md:rounded-tr-lg md:rounded-br-lg md:rounded-tl-none md:rounded-bl-none'
        style={{ backgroundImage: `url(${imageURL}?h=300)` }}
      ></div>
      {doctor &&
        (isMobile ? (
          <CareTeamAboutMobile
            key={doctor.id}
            doctor={doctor}
            isVisible={isVisibleAbout}
            setIsVisible={setIsVisibleAbout}
          />
        ) : (
          <CareTeamAbout
            key={doctor.id}
            doctor={doctor}
            isVisible={isVisibleAbout}
            setIsVisible={setIsVisibleAbout}
          />
        ))}
      <AnimatePresence>
        {isDialogOpen && (
          <DialogMobile
            item={
              <div className='flex space-x-5 items-center pb-4 w-full'>
                <p className='text-base'>
                  Call or text {contact} at any time to speak with {name}
                </p>
              </div>
            }
            handleOnClose={(e) => {
              e!.stopPropagation();
              setIsDialogOpen(false);
            }}
          />
        )}
      </AnimatePresence>
    </div>
  );
}
