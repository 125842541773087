import { DateTime } from 'luxon';

const specialCaseMonths: { [key: string]: string } = {
  Mar: 'March',
  Apr: 'April',
  Jun: 'June',
  Jul: 'July',
  Sep: 'Sept',
};

const specialCaseMonthsKeys = Object.keys(specialCaseMonths);

function formatDateToDisplay(date: DateTime) {
  const displayDate = date.toFormat('LLL dd, y');
  const [month] = displayDate.split(' ');
  if (!specialCaseMonthsKeys.includes(month)) {
    return displayDate;
  }

  return displayDate.replace(month, specialCaseMonths[month]);
}

export const DateHelper = {
  formatDateToDisplay,
};
