import { useCallback, useState } from 'react';
import { cn } from '@lib-atria/ui-toolkit';

import { BottomSheet } from '../bottomSheet';
import { CustomTableChevron } from '../customTable/customTableChevron';
import { TextLayout } from '../text';
import { CareTeamProviderMobileListItem } from './careTeamProviderMobileListItem';

type Props = {
  locations: string[];
  isOpen: boolean;
  onOpen: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onClose: VoidFunction;
  selected: string;
  onSelect: (location: string) => void;
};

export function CareTeamProviderLocationMobile({ locations, selected, onSelect }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div className='flex md:hidden items-center justify-center'>
      <div className='flex gap-1 cursor-pointer items-center justify-center' onClick={onToggle}>
        <p className={cn(TextLayout.body2, 'text-product-forest-100 text-right')}>{selected}</p>
        <CustomTableChevron
          animate={isOpen ? 'asc' : 'desc'}
          className='text-xs text-product-forest-100'
        />
        <BottomSheet headerTitle='Location' isVisible={isOpen} onClose={onClose}>
          <div className='flex flex-col p-4 gap-1'>
            {locations.map((location) => (
              <CareTeamProviderMobileListItem
                key={location}
                label={location}
                onClick={() => onSelect(location)}
                isSelected={selected === location}
              />
            ))}
          </div>
        </BottomSheet>
      </div>
    </div>
  );
}
