import { Button, cn, Sheet, TextLayout } from '@lib-atria/ui-toolkit';
import { DateTime } from 'luxon';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { User } from '@/@types';
import { SearchUserByEmail, SearchUserByEmailType } from '@/components';
import { useToastContext } from '@/contexts';
import { userPermissionsList, userRelationsOptions, useUsers } from '@/hooks';
import { userAddSheetResolver, UserAddSheetType } from './userAddSheetSchema';

export namespace UserAddSheetType {
  export type OnAddParams = {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    mrn: number;
    email: string;
    lastLogin?: string;
    loginsCount: number;
    createdAt: string;
    relation: string;
    registrationStatus: string;
    resendInviteCount: number;
    permissions: Array<string>;
  };

  export type SubmitResult =
    | { success: true; data: OnAddParams }
    | { success: false; message: string };

  export type Props = {
    patientId: number;
    users: User.FindAllUsers.Response;
    isVisible: boolean;
    onClose: VoidFunction;
    onAdd: (props: UserAddSheetType.OnAddParams) => void;
  };
}

export function UserAddSheet({
  patientId,
  users,
  isVisible,
  onClose,
  onAdd,
}: UserAddSheetType.Props) {
  const {
    reset,
    formState: { errors },
    control,
    setValue,
    handleSubmit,
  } = useForm<UserAddSheetType>({ resolver: userAddSheetResolver });
  const { toast } = useToastContext();
  const { createNewUser, updateUserById } = useUsers();
  const [userSelected, setUserSelected] = useState<
    SearchUserByEmailType.OnSelectUser | undefined
  >();
  const [userFound, setUserFound] = useState<boolean | undefined>();
  const [isRegisterFieldsVisible, setIsRegisterFieldsVisible] = useState(false);
  const [isAllFieldsVisible, setIsAllFieldsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchUserError, setSearchUserError] = useState<string | undefined>();

  const hasErrors = Object.keys(errors).length > 0 || !!searchUserError;

  const handleOnClose = useCallback(() => {
    onClose();
    reset();
    setIsRegisterFieldsVisible(false);
    setIsAllFieldsVisible(false);
  }, [onClose, reset]);

  const handleOnUserSelected = useCallback(
    (user: SearchUserByEmailType.OnSelectUser) => {
      const userAlreadyAdded = users?.find((u) => u.id === user.id);
      if (userAlreadyAdded) {
        setSearchUserError('This user is already connected to the patient');
        return;
      }
      setSearchUserError(undefined);
      setUserSelected(user);
      setValue('userId', user.id);
      setValue('firstName', user.firstName);
      setValue('lastName', user.lastName);
      setValue('email', user.email);
    },
    [setValue, users]
  );

  const handleOnSearch = useCallback(
    (options: SearchUserByEmailType.OnSearchUserOptions) => {
      setUserFound(options.found);
      if (options.found) {
        setIsRegisterFieldsVisible(false);
        setIsAllFieldsVisible(true);
      } else {
        setIsRegisterFieldsVisible(false);
        setIsAllFieldsVisible(false);
        setValue('userId', undefined);
        setValue('firstName', undefined);
        setValue('lastName', undefined);
        setValue('email', options.email);
      }
    },
    [setValue]
  );

  const handleUpdateUserById = useCallback(
    async (values: UserAddSheetType) => {
      let result: UserAddSheetType.SubmitResult = { success: false, message: '' };
      const response = await updateUserById(values.userId!, {
        appMetadata: {
          patients: [
            ...(userSelected?.patients || []),
            {
              patientId,
              permissions: values.permissions,
              relation: values.relation,
            },
          ],
        },
      });
      if (response.success) {
        result = {
          success: true,
          data: {
            email: userSelected!.email,
            firstName: userSelected!.firstName,
            lastName: userSelected!.lastName,
            name: userSelected!.name,
            id: userSelected!.id,
            mrn: Number(userSelected!.mrn),
            createdAt: userSelected!.createdAt,
            lastLogin: userSelected?.lastLogin,
            loginsCount: userSelected?.loginsCount || 0,
            permissions: values.permissions,
            relation: values.relation,
            registrationStatus: userSelected!.registrationStatus,
            resendInviteCount: userSelected?.resendInviteCount || 0,
          },
        };
      } else {
        result = { success: false, message: response.message };
      }
      return result;
    },
    [patientId, updateUserById, userSelected]
  );

  const handleCreateNewUser = useCallback(
    async (values: UserAddSheetType) => {
      let result: UserAddSheetType.SubmitResult = { success: false, message: '' };
      const response = await createNewUser({
        email: values.email!,
        firstName: values.firstName!,
        lastName: values.lastName!,
        patients: [
          {
            patientId,
            permissions: values.permissions,
            relation: values.relation,
          },
        ],
      });
      if (response.data.success) {
        result = {
          success: true,
          data: {
            id: response.data.data.id,
            email: values.email!,
            firstName: values.firstName!,
            lastName: values.lastName!,
            mrn: patientId,
            name: `${values.firstName!} ${values.lastName!}`,
            createdAt: DateTime.now().toFormat('MM/dd/yyyy hh:mma').toLowerCase(),
            permissions: values.permissions,
            relation: values.relation,
            registrationStatus: 'invited',
            resendInviteCount: 0,
            loginsCount: 0,
          },
        };
      } else {
        result = { success: false, message: response.data.message };
      }
      return result;
    },
    [createNewUser, patientId]
  );

  const onSubmit = useCallback(
    async (values: UserAddSheetType) => {
      setIsLoading(true);
      let result: UserAddSheetType.SubmitResult = { success: false, message: '' };
      if (values.userId) {
        result = await handleUpdateUserById(values);
      } else {
        result = await handleCreateNewUser(values);
      }
      if (!result.success) {
        toast?.current.show({
          severity: 'error',
          summary: 'Adding conection',
          detail: result.message || 'An unexpected error occurred',
          life: 2500,
        });
        setIsLoading(false);
        return;
      }
      onAdd(result.data);
      handleOnClose();
      toast?.current.show({
        severity: 'success',
        summary: 'Adding new connection',
        detail: 'New connection successfully added',
        life: 2500,
      });
      setIsLoading(false);
    },
    [handleCreateNewUser, handleOnClose, handleUpdateUserById, onAdd, toast]
  );

  return (
    <Sheet isVisible={isVisible} onClose={handleOnClose}>
      <p className={cn(TextLayout.callout1, 'font-display text-product-forest-100 mb-4')}>
        Connect new user
      </p>
      <div className='w-full flex flex-col gap-2 p-6 border border-stone rounded-[42px] mb-4'>
        <SearchUserByEmail onSelect={handleOnUserSelected} onSearch={handleOnSearch} />
        {searchUserError && (
          <p className={cn(TextLayout.body3, 'pl-5 text-rust mb-4')}>{searchUserError}</p>
        )}
        {userFound === false && (
          <Button
            variant='secondary'
            label='Create a new user with this email'
            onClick={() => {
              setIsRegisterFieldsVisible(true);
              setIsAllFieldsVisible(true);
            }}
          />
        )}
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='w-full flex flex-col gap-2'>
          {isRegisterFieldsVisible && (
            <>
              <div className='w-full flex items-start justify-between gap-2'>
                <div className='flex-1 flex flex-col gap-2'>
                  <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>First name</p>
                  <Controller
                    control={control}
                    name='firstName'
                    render={({ field }) => (
                      <InputText
                        name='firstName'
                        className='w-full rounded-[42px] outline-none px-9 py-5'
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || ''}
                        placeholder='Name'
                      />
                    )}
                  />
                  {errors.firstName?.message && (
                    <p className={cn(TextLayout.body3, 'pl-5 text-rust mb-4')}>
                      {errors?.firstName?.message}
                    </p>
                  )}
                </div>
                <div className='flex-1 flex flex-col gap-2'>
                  <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Last name</p>
                  <Controller
                    control={control}
                    name='lastName'
                    render={({ field }) => (
                      <InputText
                        name='lastName'
                        className='w-full rounded-[42px] outline-none px-9 py-5'
                        onChange={(e) => field.onChange(e.target.value)}
                        value={field.value || ''}
                        placeholder='Name'
                      />
                    )}
                  />
                  {errors.lastName?.message && (
                    <p className={cn(TextLayout.body3, 'pl-5 text-rust mb-4')}>
                      {errors?.lastName?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className='w-full flex flex-col gap-2'>
                <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Email</p>
                <Controller
                  control={control}
                  name='email'
                  render={({ field }) => (
                    <InputText
                      name='email'
                      className='w-full rounded-[42px] outline-none px-9 py-5'
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value || ''}
                      placeholder='Email'
                    />
                  )}
                />
                {errors.email?.message && (
                  <p className={cn(TextLayout.body3, 'pl-5 text-rust mb-4')}>
                    {errors?.email?.message}
                  </p>
                )}
              </div>
            </>
          )}
          {isAllFieldsVisible && (
            <>
              <div className='w-full flex flex-col gap-2'>
                <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Relation</p>
                <Controller
                  control={control}
                  name='relation'
                  render={({ field }) => (
                    <Dropdown
                      {...field}
                      value={field.value}
                      options={userRelationsOptions}
                      placeholder='Relation'
                      className='w-full rounded-[42px] outline-none px-9 py-2'
                    />
                  )}
                />
                {errors.relation?.message && (
                  <p className={cn(TextLayout.body3, 'pl-5 text-rust mb-4')}>
                    {errors?.relation?.message}
                  </p>
                )}
              </div>
              <div className='w-full flex flex-col gap-2'>
                <p className={cn(TextLayout.body3, 'text-product-forest-100 mb-4')}>Permissions</p>
                <Controller
                  control={control}
                  name='permissions'
                  render={({ field }) => (
                    <MultiSelect
                      multiple
                      className='w-full rounded-[42px] outline-none px-9 py-2 hover:ring-0'
                      options={userPermissionsList}
                      placeholder='Select permissions'
                      optionLabel='description'
                      optionValue='id'
                      filter
                      onChange={(e) => field.onChange(e.target.value)}
                      value={field.value}
                    />
                  )}
                />
                {errors.permissions?.message && (
                  <p className={cn(TextLayout.body3, 'pl-5 text-rust mb-4')}>
                    {errors.permissions.message}
                  </p>
                )}
              </div>
              <div className='w-full py-4 flex justify-end'>
                <Button type='submit' label='Save' disabled={isLoading || hasErrors} />
              </div>
            </>
          )}
        </div>
      </form>
    </Sheet>
  );
}
