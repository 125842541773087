export type SearchCmoParams = {
  id: string;
};

export type Doctor = {
  id: string;
  name: string;
  title: string;
  photo: string;
  description: string[];
  location: string[];
  credentials: ItemDetails[];
  awards: ItemDetails[];
  affiliations: ItemDetails[];
  seoTitle: string;
  seoSlug: string;
  profession: string;
  tags: string[];
  cmoNumber: string;
  athenaProvider: string;
  shortDescription: string[];
  degree: string;
  pods: {
    phone: string;
    podMembers: PodMember[];
  };
};

export type PodMember = {
  name: string;
  title: string;
  profession: string;
  location: string;
};

export type AllDoctors = {
  doctors: Doctor[];
  locations: string[];
  titles: string[];
};

type ItemDetails = {
  title: string;
  description: string;
};

export enum CareTeamType {
  ALL,
  MY_CARE_TEAM,
}

export type Provider = {
  note: string;
  members: Doctor[];
};
