export const TextLayout = {
  // Callout
  callout1:
    'font-body text-2xl md:text-[32px] font-medium leading-7 md:leading-10 -tracking-[0.72px] md:-tracking-[0.96px]',
  callout2:
    'font-body text-xl md:text-2xl font-medium leading-6 md:leading-8 -tracking-[0.2px] md:-tracking-[0.48px]',
  callout3:
    'font-body text-xl md:text-2xl font-medium leading-6 md:leading-8 -tracking-[0.2px] md:-tracking-[0.48px]',
  callout4: 'font-body text-[11px] font-bold uppercase leading-3 tracking-[1.1px]',

  // Body
  body1: 'font-body text-base font-medium leading-6 -tracking-[0.32px] md:-tracking-[0.4px]',
  body2: 'font-body text-sm font-medium leading-5 md:-tracking-[0.28px]',
  body3: 'font-body text-[11px] font-medium leading-4',

  // Empashis
  empashis: 'font-body font-bold text-[11px] leading-3 tracking-[1.1px] uppercase',

  // Headers
  h3: {
    ivar: 'font-body font-display font-normal text-xl md:text-2xl leading-[28px] tracking-[0.48px] ',
    scto: 'font-body font-body font-medium text-[28px] md:text-[40px] leading-[32px] md:leading-[40px] -tracking-[1.12px] md:-tracking-[1.6px] ',
  },
  h2: {
    ivar: 'text-4xl md:text-5xl font-display font-normal leading-[36px] md:leading-[48px] -tracking-[0.36px] md:-tracking-[0.48px]',
    scto: 'font-body text-[32px] md:text-[44px] font-medium leading-[36px] md:leading-[48px] -tracking-[1.28px] md:-tracking-[2.2px]',
  },
  h1: {
    ivar: 'font-display text-[48px] lg:text-[80px] font-normal leading-[48px] lg:leading-[80px] -tracking-[2px]',
    scto: 'font-body text-[44px] lg:text-[76px] md:text-[56px] font-medium leading-[48px] md:leading-[52px] lg:leading-[72px] -tracking-[1.48px] md:-tracking-[4px]',
  },
};
