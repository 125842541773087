import { LoginLoader } from '@/components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  to: string;
};

export function Redirect({ to }: Props) {
  const navigate = useNavigate();

  useEffect(() => {
    if (to) {
      navigate(to);
    }
  }, [navigate, to]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <LoginLoader />
    </div>
  );
}
