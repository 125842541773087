import { Auth, FindDashboardSummary } from '@/@types';
import { Variants, motion } from 'framer-motion';
import {
  AppointmentCard,
  FavoriteDocumentCard,
  ImagingResultCard,
  LabResultCard,
  MedicationsListCard,
} from '.';
import { NeedAnythingCard, withPermission } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

type Props = {
  summary?: FindDashboardSummary;
};

export function HomeCards({ summary }: Props) {
  const UpcomingAppointmentCard = withPermission(
    AppointmentCard,
    Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS
  );
  const PreviousAppointmentCard = withPermission(
    AppointmentCard,
    Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS
  );
  const ImagingResultsCard = withPermission(
    ImagingResultCard,
    Auth.Permissions.UserPermissions.VIEW_RESULTS
  );
  const LabResultsCard = withPermission(
    LabResultCard,
    Auth.Permissions.UserPermissions.VIEW_RESULTS
  );
  const MedicationsCard = withPermission(
    MedicationsListCard,
    Auth.Permissions.UserPermissions.VIEW_MEDICATIONS
  );
  const NeedAnyCard = withPermission(
    NeedAnythingCard,
    Auth.Permissions.UserPermissions.CONTACT_CARE_TEAM
  );
  return (
    <motion.div
      initial='hidden'
      animate='show'
      variants={container}
      className='grid grid-cols-1 lg:grid-cols-2 justify-start gap-6'
    >
      <motion.div variants={itemVariant}>
        <UpcomingAppointmentCard
          title='Upcoming appointment'
          appointment={summary?.appointments.future}
        />
      </motion.div>
      <motion.div variants={itemVariant}>
        <PreviousAppointmentCard
          title='Previous appointment'
          appointment={summary?.appointments.previous}
        />
      </motion.div>
      <motion.div variants={itemVariant}>
        <ImagingResultsCard title='Imaging results' imagingResult={summary?.imagingResults} />
      </motion.div>
      <motion.div variants={itemVariant}>
        <LabResultsCard title='Lab Results' labResult={summary?.labResults} />
      </motion.div>
      {!!summary?.medicationsList.length && (
        <motion.div variants={itemVariant}>
          <MedicationsCard title='Medications' medicationsList={summary?.medicationsList} />
        </motion.div>
      )}
      {!!summary?.favoriteDocument && (
        <motion.div variants={itemVariant}>
          <FavoriteDocumentCard title='Documents' document={summary?.favoriteDocument} />
        </motion.div>
      )}
      <NeedAnyCard />
    </motion.div>
  );
}
