import { Theme } from '@/theme';

type Props = React.SVGProps<SVGSVGElement>;

export function DiamondIcon({
  width = 20,
  height = 20,
  fill = Theme.colors['fern'],
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x={10} y={1} width={12.8284} height={12.8284} transform='rotate(45 10 1)' fill={fill} />
    </svg>
  );
}
