import { AdminRoutes } from './adminRoutes';
import { PatientsRoutes } from './patientsRoutes';

export function AppRoutes() {
  return (
    <>
      <PatientsRoutes />
      <AdminRoutes />
    </>
  );
}
