import { useMediaQuery } from 'react-responsive';
import { AtriaIcon } from '..';

type Props = {
  text?: string | React.ReactNode;
};

const LogoGroup = ({ className }: { className?: string }) => (
  <div className={`flex justify-between w-full ${className}`}>
    <AtriaIcon width={40} height={40} fill='#CBC6BD' />
    <AtriaIcon width={40} height={40} fill='#CBC6BD' />
  </div>
);

export function Disclaimer({ text }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 765px)' });
  return (
    <div className='bg-noise-sand rounded-xl p-1 md:p-2 w-full'>
      <div className='rounded-lg border border-fern border-opacity-30 p-[3px]'>
        <div className='rounded-[5px] border border-fern border-opacity-20 flex md:flex-row flex-col xl:space-x-48 md:space-x-6 justify-between md:p-6 p-4 items-center'>
          {isMobile && <LogoGroup className='mb-7' />}
          {text ? (
            text
          ) : (
            <p>
              The following notes are for the purpose of formal documentation and internal clinical
              use. <br />
              If you have any questions about the below, your Care team will be happy to assist you.
            </p>
          )}
          {isMobile && <LogoGroup className='mt-7' />}
          {!isMobile && (
            <div className='flex justify-end'>
              <AtriaIcon width={50} height={50} fill='#CBC6BD' />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
