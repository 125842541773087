import { Button, cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';
import { Relation } from '@/hooks';

type Props = {
  firstName: string;
  lastName: string;
  relation: Relation;
  onClick?: VoidFunction;
};

export function SwitchAccountsCard({ firstName, lastName, relation, onClick }: Props) {
  return (
    <div
      data-testid={relation}
      className='w-full h-full min-w-[250px] min-h-[350px] flex-1 flex flex-col bg-white pt-3 px-3 pb-8 rounded-[20px] gap-5'
    >
      <div className='flex-1 bg-product-sand-100 rounded-xl p-4 xl:p-8 flex flex-col'>
        <div className='flex-1 flex items-start justify-between'>
          <div className='flex-1'>
            <p className={cn(TextLayout.h3.scto, 'text-product-forest-100')}>{firstName}</p>
            <p className={cn(TextLayout.h3.scto, 'text-product-forest-100')}>{lastName}</p>
          </div>
          <div className='w-[10px] flex'>
            <div className='w-[8px] h-[8px] bg-fern rounded-full'></div>
          </div>
        </div>
        <div className='w-full flex'>
          <p className={cn(TextLayout.body1, 'text-product-gray-400')}>{relation}</p>
        </div>
      </div>
      <div className='w-full flex flex-col px-8 [&_span]:text-nowrap'>
        <Button variant='tertiary' size='large' label='View portal' onClick={onClick} />
      </div>
    </div>
  );
}
