import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const userAddSheetSchema = z
  .object({
    userId: z.string().optional(),
    firstName: z.string().optional(),
    lastName: z.string().optional(),
    email: z
      .string({ required_error: 'Please enter an email address' })
      .email({ message: 'Please enter a valid email address' })
      .optional(),
    relation: z
      .string({ required_error: 'Please select a relation' })
      .min(1, 'User must contain one relation name'),
    permissions: z
      .array(z.string(), { required_error: 'Please select at least one permission' })
      .min(1, 'User must contain at least 1 permission'),
  })
  .superRefine((schema, ctx) => {
    if (!schema.userId) {
      if (!schema.firstName) {
        ctx.addIssue({
          code: 'custom',
          message: 'Please enter a first name',
          path: ['firstName'],
        });
      }
      if (!schema.lastName) {
        ctx.addIssue({
          code: 'custom',
          message: 'Please enter a last name',
          path: ['lastName'],
        });
      }
    }
  });

export type UserAddSheetType = z.infer<typeof userAddSheetSchema>;

export const userAddSheetResolver = zodResolver(userAddSheetSchema);
