import { cn } from '@lib-atria/ui-toolkit';

import { MaterialIcons, SidebarVariants } from '@/components';

type Props = {
  variant?: SidebarVariants;
  onHide: VoidFunction;
};

export function SidebarMobileCloseButton({ variant, onHide }: Props) {
  return (
    <div className='absolute top-5 -left-5 w-full flex flex-col items-end justify-end'>
      <a
        href='#'
        onClick={onHide}
        className={cn(
          'border  border-opacity-10 rounded-full w-[48px] h-[48px] flex items-center justify-center',
          variant === 'forest' && 'border-white',
          variant === 'gray' && 'border-product-forest-100'
        )}
      >
        <MaterialIcons
          name='close'
          className={cn(
            'text-2xl -tracking-[0.48px]',
            variant === 'forest' && 'text-white',
            variant === 'gray' && 'text-product-forest-100'
          )}
        />
      </a>
    </div>
  );
}
