import { env } from '@/constants';

export type Location = {
  title: string;
  image: string;
  street: string;
  complement?: string;
  city: string;
  state: string;
  zipCode: string;
  phoneNumber: string;
  notes: {
    image: string;
    description: string;
  };
  email: string;
  directionMaps: string;
};
export enum LocationType {
  NEW_YORK = 'New York',
  PALM_BEACH = 'Palm Beach',
}
export const locations: Location[] = [
  {
    title: 'New York',
    image: `${env.APP_CDN_URL}/images/new-york.png`,
    street: '36 East 57th Street',
    complement: 'Fifth Floor',
    city: 'New York',
    state: 'NY',
    zipCode: '10022',
    phoneNumber: '212.600.2000',
    notes: {
      image: `${env.APP_CDN_URL}/images/new-york-tooltip.png`,
      description:
        'The closest garage is MPG Parking, located behind the Institute at 63 East 56th Street, New York, NY 10022.',
    },
    email: 'careteam@atria.org',
    directionMaps:
      'https://www.google.com/maps/dir//Atria+Institute,+36+E+57th+St,+New+York,+NY+10022,+United+States/@40.761855,-73.9745417,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c259e6a735da41:0x12bb2347d8eef0ff!2m2!1d-73.9719668!2d40.761851?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D',
  },
  {
    title: 'Palm Beach',
    image: `${env.APP_CDN_URL}/images/palm-beach.png`,
    street: '50 Cocoanut Row #100',
    city: 'Palm Beach',
    state: 'FL',
    zipCode: '33480',
    phoneNumber: '561.200.7000',
    notes: {
      image: `${env.APP_CDN_URL}/images/palm-beach-tooltip.png`,
      description:
        'Atria has reserved parking spots with stanchions that we will move upon your arrival. Call our office if you need assistance.',
    },
    email: 'careteam@atria.org',
    directionMaps:
      'https://www.google.com/maps/dir//The+Slat+House,+50+Cocoanut+Row+%23100,+Palm+Beach,+FL+33480,+USA/@26.7164923,-80.0443063,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x88d8d6900669a047:0xaa4b1a93b3e57505!2m2!1d-80.0417314!2d26.7164875?entry=ttu&g_ep=EgoyMDI0MDgyMC4xIKXMDSoASAFQAw%3D%3D',
  },
];
export function getLocationData(locationType: LocationType): Location | undefined {
  return locations.find((location) => location.title === locationType);
}
