import ReactDOM from 'react-dom/client';
import { App } from './App';

import './assets/fonts/Atlas Grotesk/atlasGrotesk.css';
import './assets/fonts/Ivar Display/ivarDisplay.css';
import './assets/fonts/Ivar Display/ivarDisplay.css';
import './assets/fonts/Scto Grotesk/sctoGroteskA.css';

import './index.css';
import './theme.css';
import './providers/sentry';

import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';

ReactDOM.createRoot(document.getElementById('root')!).render(<App />);
