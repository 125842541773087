import { Theme } from '@/theme';

type Props = React.SVGProps<SVGSVGElement>;

export function SquareIcon({
  width = 20,
  height = 20,
  fill = Theme.colors['fern'],
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect x={1} y={1} width={18} height={18} fill={fill} />
    </svg>
  );
}
