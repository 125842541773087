import { cn } from '@lib-atria/ui-toolkit';

import { AtriaIconButton } from '../atriaLibrary';

type Props = {
  title: string;
  className?: string;
  onClose: VoidFunction;
};

export function BottomSheetHeader({ title, className, onClose }: Props) {
  return (
    <div className={cn('w-full flex justify-between items-center px-8', className)}>
      <p className='text-4xl font-display py-6 text-white'>{title}</p>
      <AtriaIconButton icon='close' iconClassName='text-base text-white' onClick={onClose} />
    </div>
  );
}
