import { LocalStorage } from '@/@types';
import { DateTime } from 'luxon';

const STORAGE_SETTINGS_PREFIX = '__memberPortal_settings__';
const DEFAULT_TTL_SECONDS = 3_600;

function getDateNowTTLISOString() {
  return DateTime.now().plus({ seconds: DEFAULT_TTL_SECONDS }).toJSDate().toISOString();
}

function updateItem(data: LocalStorage.Actions) {
  const current = localStorageHelper.getItem(data.key)! as any;
  const newPayload = { ...current, ...data.payload } as any;
  localStorageHelper.setItem(
    { key: data.key, payload: newPayload },
    {
      forever: current?.[STORAGE_SETTINGS_PREFIX]?.forever,
      ttl: current?.[STORAGE_SETTINGS_PREFIX]?.ttl,
    }
  );
}

function setItem(
  data: LocalStorage.Actions,
  options: { forever?: boolean; ttl?: string } = { forever: true }
) {
  options = { ...options, forever: options?.forever ?? true };
  const valueWithSettings: any = { ...data.payload, [STORAGE_SETTINGS_PREFIX]: options };
  if (!options?.forever) {
    options.ttl = options?.ttl || getDateNowTTLISOString();
    valueWithSettings[STORAGE_SETTINGS_PREFIX] = options;
  }
  return localStorage.setItem(data.key, JSON.stringify(valueWithSettings));
}

function getItem<T extends LocalStorage.Actions['key'], Y extends LocalStorage.Data<T>>(
  key: T
): Y | undefined {
  let value: any = localStorage.getItem(key);
  if (!value) return undefined;
  value = JSON.parse(value) as Y;
  const itemSettings = (value as any)[STORAGE_SETTINGS_PREFIX];
  if (!itemSettings?.forever) {
    const ttl = itemSettings?.ttl || getDateNowTTLISOString();
    const diffFromNow = DateTime.fromISO(ttl).diffNow('seconds').seconds;
    const isExpired = diffFromNow < 0;
    if (isExpired) {
      removeItem(key);
      return undefined;
    }
  }
  return value as Y;
}

function removeItem(key: LocalStorage.Keys) {
  return localStorage.removeItem(key);
}

export const localStorageHelper = {
  setItem,
  getItem,
  updateItem,
  removeItem,
};
