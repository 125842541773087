import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { PatientDocument } from '@/@types';
import { BasicCard, CustomPdfViewer, Loading, UploadDocumentSidebar } from '@/components';
import { useApplicationContext, useAuthContext, usePageContext, useToastContext } from '@/contexts';
import { usePatientDocuments } from '@/hooks';
import { DateHelper } from '@/helper';

export function PatientDocumentPage() {
  const navigate = useNavigate();
  const { setPageTitle } = usePageContext();
  const { patient, isAdmin } = useAuthContext();
  const { findDashboardSummary, getDocuments } = useApplicationContext();
  const { documentId } = useParams();
  const { toast } = useToastContext();
  const { deletePatientDocument, findPatientDocument, findPatientDocumentAttachment } =
    usePatientDocuments();

  const [document, setDocument] = useState<PatientDocument>({} as PatientDocument);
  const [isAttachmentLoading, setIsAttachmentLoading] = useState(false);
  const [attachmentData, setAttachmentData] = useState<{ src: string; type: string }>();
  const [isUploadSidebarVisible, setIsUploadSidebarVisible] = useState(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);

  const findAttachment = useCallback(
    async (documentData: PatientDocument) => {
      const attachment = await findPatientDocumentAttachment(
        documentData.patientId,
        documentData.id,
        documentData.documentUrl,
        documentData.isAthenaDocument
      );
      setAttachmentData(attachment);
    },
    [findPatientDocumentAttachment]
  );

  const loadInitialData = useCallback(async () => {
    if (patient && documentId) {
      const documentData = await findPatientDocument(patient.id, Number(documentId));

      setDocument(documentData);

      try {
        setIsAttachmentLoading(true);
        await findAttachment({
          patientId: patient.id,
          id: Number(documentId),
          documentUrl: documentData.isAthenaDocument ? 'athenaDocument' : documentData.documentUrl,
          isAthenaDocument: documentData.isAthenaDocument,
          documentName: documentData.documentName,
          date: documentData.date,
          favorite: documentData.favorite,
          createdAt: documentData.createdAt,
        });
      } finally {
        setIsAttachmentLoading(false);
      }
    }
  }, [documentId, findAttachment, findPatientDocument, patient]);

  const handleDocumentUpload = useCallback(() => {
    loadInitialData();
    findDashboardSummary();
    getDocuments();
    setIsUploadSidebarVisible(false);
  }, [getDocuments, loadInitialData, findDashboardSummary]);

  const handleDocumentDelete = useCallback(async () => {
    if (patient && documentId) {
      try {
        await deletePatientDocument(patient.id, Number(documentId));
        toast?.current.show({
          severity: 'success',
          summary: 'Success',
          detail: 'Document deleted successfully',
          life: 2500,
        });
        getDocuments();
        navigate('/documents');
      } catch {
        toast?.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Document delete failed',
          life: 2500,
        });
      }
    }
    setIsDeleteDialogVisible(false);
  }, [deletePatientDocument, documentId, getDocuments, navigate, patient, toast]);

  useEffect(() => {
    setPageTitle('Documents');
  }, [setPageTitle]);

  useEffect(() => {
    loadInitialData();
  }, [loadInitialData]);

  return (
    <div className='mt-5'>
      <a className='md:px-[66px] items-center flex' href='#' onClick={() => navigate(-1)}>
        <i className='pi pi-chevron-left mr-1'></i>
        <span className='hover:underline'>Back</span>
      </a>
      {document && (
        <main>
          <BasicCard title={document.documentName}>
            {isAttachmentLoading ? (
              <Loading label='Loading attachments...' />
            ) : attachmentData?.src ? (
              <div className='flex flex-col'>
                {document.date && (
                  <div className='flex'>
                    {DateHelper.formatDateToDisplay(DateTime.fromISO(document.date))}
                  </div>
                )}

                {isAdmin && !document.isAthenaDocument && (
                  <div className='flex flex-col my-5 md:flex-row'>
                    <div>
                      <Button
                        label='Edit document'
                        icon='pi pi-file-import'
                        size='small'
                        className='w-[100%] md:justify-self-start'
                        outlined
                        onClick={() => setIsUploadSidebarVisible(true)}
                      />
                    </div>
                    <div>
                      <Button
                        label='Delete document'
                        icon='pi pi-file-import'
                        size='small'
                        className='w-[100%] mt-5 md:mt-0 md:ml-5 md:justify-self-start'
                        outlined
                        severity='danger'
                        onClick={() => setIsDeleteDialogVisible(true)}
                      />
                    </div>
                    <ConfirmDialog
                      draggable={false}
                      visible={isDeleteDialogVisible}
                      onHide={() => setIsDeleteDialogVisible(false)}
                      message='Are you sure you want to delete this document?'
                      header='Delete document'
                      icon='pi pi-exclamation-triangle'
                      accept={handleDocumentDelete}
                      reject={() => setIsDeleteDialogVisible(false)}
                    />

                    <UploadDocumentSidebar
                      visible={isUploadSidebarVisible}
                      handleOnHide={() => setIsUploadSidebarVisible(false)}
                      handleDocumentUpload={handleDocumentUpload}
                      data={document}
                      isEditing={true}
                    />
                  </div>
                )}

                <CustomPdfViewer fileUrl={attachmentData.src} />
              </div>
            ) : (
              <span>No attachments found</span>
            )}
          </BasicCard>
        </main>
      )}
    </div>
  );
}
