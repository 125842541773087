/* eslint-disable @typescript-eslint/no-unused-vars */
function decode<T = { [key: string]: any }>(jwtToken: string): T {
  const [_header, payload, _signature] = jwtToken.split('.');
  const base64 = payload.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

export const JwtHelper = {
  decode,
};
