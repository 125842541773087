import { useMemo } from 'react';
import { cn } from '@lib-atria/ui-toolkit';

import { AtriaIconButton } from '../atriaLibrary';
import { MaterialIcons } from '../icons';
import { TextLayout } from '../text';

type Props = {
  paginationLimit?: number;
  total: number;
  onClick: VoidFunction;
};

export function CareTeamProviderPagination({ paginationLimit = 9, total, onClick }: Props) {
  const pagination = useMemo(() => {
    if (total > paginationLimit) return paginationLimit;
    return total;
  }, [paginationLimit, total]);

  return (
    <div
      onClick={onClick}
      className='w-full py-9 px-10 cursor-pointer flex items-center justify-between bg-product-sand-300 hover:bg-product-sand-300/90 transition-colors rounded-xl'
    >
      <p className={cn(TextLayout.callout2, 'text-product-forest-100 select-none')}>
        Show {pagination} more
      </p>
      <AtriaIconButton
        onClick={onClick}
        className='hover:bg-kelp border border-product-sand-400 hover:border-kelp group'
      >
        <MaterialIcons name='expand_less' className='text-kelp-light group-hover:text-white' />
      </AtriaIconButton>
    </div>
  );
}
