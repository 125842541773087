import { Immunization } from '@/@types';
import { useLoaderContext } from '@/contexts';
import { patientsService } from '@/services';
import { useCallback } from 'react';
export function useImmunizations() {
  const { startLoader, stopLoader } = useLoaderContext();
  const findPatientImmunizations = useCallback(
    async (id: number): Promise<Immunization[]> => {
      try {
        startLoader();
        const { data } = await patientsService.getImmunizations(id);
        return data;
      } catch (error) {
        return [];
      } finally {
        stopLoader();
      }
    },
    [startLoader, stopLoader]
  );

  return {
    findPatientImmunizations,
  };
}
