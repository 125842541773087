import { env } from '@/constants';
import { Auth0Provider as ReactAuth0Provider } from '@auth0/auth0-react';

type Props = {
  children: React.ReactNode;
};

export function AuthZeroProvider({ children }: Props) {
  if (env.APP_FEATURE_FLAGS.IS_TO_USE_AUTH_ZERO_ACCESS_TOKEN) {
    return (
      <ReactAuth0Provider
        domain={env.AUTH_ZERO_URL}
        clientId={env.AUTH_ZERO_CLIENT_ID}
        cacheLocation='memory'
        authorizationParams={{
          redirect_uri: `${env.APP_MEMBER_PORTAL_FRONTEND_URL}/sign-in/callback?provider=auth0`,
          audience: env.AUTH_ZERO_API_AUDIENCE,
          scope: 'openid',
        }}
      >
        {children}
      </ReactAuth0Provider>
    );
  }

  return <>{children}</>;
}
