import { Relation, userRelations } from '@/hooks';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

const requiredErrorMessage = 'This field is required';

export const patientRegistrationFormSchema = z.object({
  users: z.array(
    z
      .object({
        patientId: z.string({
          required_error: requiredErrorMessage,
        }),
        relation: z
          .string()
          .refine((value) => userRelations[value as Relation] || value === userRelations.self, {
            message: 'Please chose a valid relationship',
          }),
        permissions: z.array(z.string()),
        emailConfirmation1: z
          .string({
            required_error: requiredErrorMessage,
          })
          .email('Please enter a valid email address'),
        emailConfirmation2: z
          .string({
            required_error: requiredErrorMessage,
          })
          .email('Please enter a valid email address'),
      })
      .refine((data) => data.emailConfirmation1 === data.emailConfirmation2, {
        message: 'The email and email confirmation do not match',
        path: ['emailConfirmation2'],
      })
  ),
});

export type PatientsRegistrationFormType = z.infer<typeof patientRegistrationFormSchema>;

export const PatientsRegistrationFormSchemaResolver = zodResolver(patientRegistrationFormSchema);
