import { TextLayout } from '@/components';
import { usePageContext } from '@/contexts';
import { cn } from '@lib-atria/ui-toolkit';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function UnauthorizedPage() {
  const navigate = useNavigate();
  const { setPageTitle } = usePageContext();

  const handleBackHistory = useCallback(() => {
    navigate('/');
  }, [navigate]);
  useEffect(() => {
    setPageTitle('');
  }, [setPageTitle]);

  return (
    <div className='flex flex-col items-center justify-center h-[70vh]'>
      <h1 className={cn(TextLayout.h2.scto, 'text-forest-100 ')}>Page does not exist</h1>
      <p className='cursor-pointer underline' onClick={handleBackHistory}>
        Go to home
      </p>
    </div>
  );
}
