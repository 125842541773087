import classNames from 'classnames';
import { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  title?: string;
  className?: string;
};

export function BasicCard({ children, title, className }: Props) {
  return (
    <div
      className={classNames('py-6 flex flex-col flex-1', className, {
        'md:px-[66px]': !className?.includes('px'),
      })}
    >
      {!!title && <h2 className='text-2xl font-medium leading-8 text-kelp mt-3 mb-5'>{title}</h2>}
      {children}
    </div>
  );
}
