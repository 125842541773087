import clsx from 'clsx';
import { useMemo } from 'react';
import { cn } from '@lib-atria/ui-toolkit';
import { AtriaIcon } from '../icons';
import { TextLayout } from '../text';

type Props = {
  signature: string;
};

export function EncounterSignatureFooter({ signature }: Props) {
  const signatureFormatted = useMemo(() => {
    const [name, degree] = signature.toLowerCase().split(', ');
    return { name, degree };
  }, [signature]);

  return (
    <div className='bg-noise-fern rounded-xl p-5 md:p-10 w-full'>
      <div className='flex flex-col justify-between items-center gap-[7.44px]'>
        <LogoGroup />
        <div className='flex flex-col gap-4 text-center'>
          <span className={clsx(TextLayout.body3, 'text-white')}>Signed by:</span>
          <p className={clsx(TextLayout.h2.ivar, 'text-white capitalize')}>
            {signatureFormatted.name},{' '}
            <span className={cn(TextLayout.h2.ivar, 'uppercase')}>{signatureFormatted.degree}</span>
          </p>
        </div>
        <LogoGroup />
      </div>
    </div>
  );
}

const LogoGroup = () => (
  <div className='flex justify-between w-full'>
    <AtriaIcon width={30} height={30} fill='#869D89' />
    <AtriaIcon width={30} height={30} fill='#869D89' />
  </div>
);
