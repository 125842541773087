import { useCallback, useState } from 'react';
import { cn } from '@lib-atria/ui-toolkit';

import { BottomSheet } from '../bottomSheet';
import { CustomTableChevron } from '../customTable/customTableChevron';
import { TextLayout } from '../text';
import { CareTeamProviderMobileListItem } from './careTeamProviderMobileListItem';

type Props = {
  specialities: string[];
  selected?: string;
  onSelect: (speciality: string) => void;
};

export function CareTeamSpecialitiesMobile({ specialities, selected, onSelect }: Props) {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <div
      onClick={onToggle}
      className='flex md:hidden gap-1 py-6 cursor-pointer items-center justify-center'
    >
      <p className={cn(TextLayout.body2, 'text-product-forest-100')}>
        {selected ? selected : 'Category'}
      </p>
      <CustomTableChevron
        animate={isOpen ? 'asc' : 'desc'}
        className='text-xs text-product-forest-100'
      />
      <BottomSheet headerTitle='Category' isVisible={isOpen} onClose={onClose}>
        <div className='flex flex-col p-4'>
          {specialities.map((speciality) => (
            <CareTeamProviderMobileListItem
              key={speciality}
              label={speciality}
              onClick={() => onSelect(speciality)}
              isSelected={selected === speciality}
            />
          ))}
        </div>
      </BottomSheet>
    </div>
  );
}
