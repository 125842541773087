import classNames from 'classnames';

type Props = {
  label: string;
  className?: string;
};

export function Loading({ label, className }: Props) {
  return (
    <p
      className={classNames(
        'w-full flex flex-row items-center justify-center gap-2 text-sm text-gray-2 text-center',
        className
      )}
    >
      <i className='pi pi-spinner animate-spin text-fern'></i>
      {label}
    </p>
  );
}
