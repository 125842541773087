import { LoginHeader } from '@/components';
import { env } from '@/constants';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Outlet, useLocation } from 'react-router-dom';

export function SignInLayout() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const location = useLocation();

  const isSignInPage = useMemo(
    () => location.pathname === '/sign-in' || location.pathname === '/admin',
    [location]
  );

  return (
    <>
      <div className='w-full h-full flex flex-col lg:flex-row items-stretch justify-start bg-login-logout overflow-y-auto overflow-x-hidden'>
        {(!isMobile || (isSignInPage && !env.APP_FEATURE_FLAGS.IS_TO_SHOW_AUTH_ZERO_LOGIN)) && (
          <div className='relative flex-1 h-full bg-center flex items-start justify-start bg-sign-in-background bg-no-repeat bg-cover pt-20 pl-20'>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
              className='w-full h-full absolute top-0 left-0 from-stone to-transparent'
            ></motion.div>
          </div>
        )}

        <div className='relative flex-1 flex flex-col'>
          <LoginHeader />
          <div className='relative flex-1 flex flex-col items-center justify-center'>
            <div className='w-full max-w-sm text-left relative min-h-[300px]'>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
