import { Appointments } from '@/@types';
import { AppointmentsCardTelehealth } from '../appointmentsCard';

type TelehealthCardProps = {
  appointments: Appointments.FindAllPatientsAppointments.AppointmentList;
  cmoEmail?: string;
  cmoPhone?: string;
};

export function TelehealthCard({ appointments, cmoEmail, cmoPhone }: TelehealthCardProps) {
  return (
    <>
      {appointments.map(
        (appt) =>
          appt.conferenceLink && (
            <AppointmentsCardTelehealth
              key={appt.id}
              meetingId={appt.conferenceId || ''}
              videoLink={appt.conferenceLink}
              phoneNumber={cmoPhone}
              emailCmo={cmoEmail}
              passcode={appt.conferencePassword!}
            />
          )
      )}
    </>
  );
}
