import { cn } from '@lib-atria/ui-toolkit';
import { useCallback, useMemo } from 'react';

import { useAuthContext } from '@/contexts';
import { TextLayout } from '../../text';
import { SidebarVariants } from '../sidebar';

type Props = {
  variant?: SidebarVariants;
  isAdmin?: boolean;
  adminName?: string;
  onSwitchMember?: VoidFunction;
  onSignOut: VoidFunction;
  onNavigate?: (url: string) => void;
  isAdminView?: boolean;
};

export function SidebarFooter({
  variant,
  adminName,
  onSwitchMember,
  onSignOut,
  onNavigate,
  isAdminView = false,
}: Props) {
  const { patient, isAdmin, hasMultiplePatients } = useAuthContext();

  const showAdmin = useMemo(() => !!isAdmin && !!patient, [isAdmin, patient]);
  const onInviteAUser = useCallback(() => {
    if (!onNavigate) return;
    onNavigate('/admin/users/create');
  }, [onNavigate]);

  if (showAdmin) {
    return (
      <div className='w-full flex flex-col items-start justify-end gap-[2px] p-5'>
        <div className='w-full flex flex-col pl-5'>
          <p
            className={cn(
              TextLayout.body3,
              'opacity-50',
              variant === 'forest' && 'text-product-sand-100',
              variant === 'gray' && 'text-product-forest-100'
            )}
          >
            Connected as {adminName}
          </p>
          <a
            href='#'
            className={cn(
              'block text-[11px] font-medium',
              variant === 'forest' && 'text-product-sand-100',
              variant === 'gray' && 'text-product-forest-100'
            )}
            onClick={onSwitchMember}
          >
            Switch member
          </a>
        </div>
      </div>
    );
  }

  if (hasMultiplePatients && !isAdminView) {
    return (
      <div className='w-full flex items-center justify-between py-5 px-10'>
        <button
          className={cn(TextLayout.body3, 'block text-product-sand-100 text-[11px] font-medium')}
          onClick={onSignOut}
        >
          Log out
        </button>
        <button
          className={cn(TextLayout.body3, 'block text-product-sand-100 text-[11px] font-medium')}
          onClick={onSwitchMember}
        >
          Switch account
        </button>
      </div>
    );
  }

  return (
    <div className='w-full flex flex-col items-start justify-end gap-[2px] p-5'>
      {isAdminView && (
        <button
          className={cn(
            'flex items-start justify-start font-medium outline-none transition-all mb-3 px-6 py-2 w-full rounded-[4px]',
            'border border-product-sand-300 hover:border-product-sand-200 focus:border-sage focus:ring-2 focus:ring-sage hover:bg-product-sand-100'
          )}
          onClick={onInviteAUser}
        >
          <i className='material-icons-round text-base pr-5'>add</i>
          <span>Invite a user</span>
        </button>
      )}

      <button
        className={cn(
          'block text-[11px] font-medium py-2 px-5 rounded-lg transition-all duration-150 ',
          variant === 'forest' && 'text-product-sand-100 hover:bg-product-forest-200',
          variant === 'gray' &&
            'text-product-forest-100 hover:bg-product-forest-200 hover:text-product-sand-100'
        )}
        onClick={onSignOut}
      >
        Logout
      </button>
    </div>
  );
}
