import { AnimationHelper, Button, cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback } from 'react';
import { useMediaQuery } from 'react-responsive';
import { CustomTableCheckboxDialogMobile } from './customTableCheckboxDialogMobile';

export type TableCheckboxAction = {
  label: string;
  onClick: () => void;
  shouldDeselect?: boolean;
};

type Props = {
  actions: TableCheckboxAction[];
  visible: boolean;
  length: number;
  offset?: number;
  deselectAll: VoidFunction;
};

export function CustomTableCheckboxDialog({
  length,
  visible,
  actions,
  deselectAll,
  offset = 0,
}: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const onClick = useCallback(
    (action: TableCheckboxAction) => {
      action.onClick();
      deselectAll();
    },
    [deselectAll]
  );

  return (
    <>
      {isMobile ? (
        <AnimatePresence>
          {visible && (
            <CustomTableCheckboxDialogMobile
              length={length}
              actions={actions}
              deselectAll={deselectAll}
            />
          )}
        </AnimatePresence>
      ) : (
        <AnimatePresence>
          {visible && (
            <motion.div
              initial='closed'
              animate='open'
              exit='closed'
              variants={{
                open: { translateY: 0 },
                closed: { translateY: '300%' },
              }}
              transition={{ duration: 0.8, ease: AnimationHelper.ease }}
              className={cn(
                'flex justify-center fixed bottom-12 inset-x-0 my-0 z-50 mx-0 text-white',
                [`lg:w-[calc(100vw+${offset}px)]`]
              )}
            >
              <div className='flex flex-row justify-between items-center w-full sm:max-w-xl md:max-w-2xl lg:max-w-4xl p-4 bg-fern rounded-[4px]'>
                <span>{length} selected</span>
                <div className='flex flex-row gap-2'>
                  {actions.map((action) => (
                    <div key={action.label} className='bg-product-sand-100 rounded-[42px]'>
                      <Button
                        variant='secondary'
                        label={action.label}
                        onClick={() => onClick(action)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </>
  );
}
