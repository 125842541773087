import { DateTime } from 'luxon';
import { useCallback } from 'react';

import { CustomTableHeader, PatientDocument } from '@/@types';
import { useAuthContext, useContactDialogContext, useToastContext } from '@/contexts';
import { usePatientDocuments } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import { CustomTable } from '..';
import { DateHelper } from '@/helper';
import { eventAnalytics } from '@/providers';
import { TRACK_EVENTS } from '@/providers/eventAnalytics/trackEvents';

type Props = {
  documents: PatientDocument[];
};

const tableHeaders: CustomTableHeader[] = [
  {
    key: 'documentName',
    label: 'Document Name',
  },
  {
    key: 'date',
    label: 'Date',
    width: 200,
  },
  {
    key: 'favorite',
    label: 'Favorite',
    width: 200,
  },
];

export function PatientDocumentsCard({ documents }: Props) {
  const navigate = useNavigate();
  const { patient } = useAuthContext();
  const { toast } = useToastContext();
  const { findPatientDocumentAttachment } = usePatientDocuments();
  const { contactDialogTopic, setContactDialogVisibility, setContactDialogTopic } =
    useContactDialogContext();

  const handleOnClickItem = useCallback(
    (item: PatientDocument) => {
      eventAnalytics.track(TRACK_EVENTS.DOCUMENT_DETAILS_VIEWED, {
        'document name': item.documentName,
        'document date': item.date,
      });
      navigate(`/documents/${item.id}`);
    },
    [navigate]
  );

  const findDocumentAttachment = useCallback(
    async (item: PatientDocument) => {
      if (!patient?.id) return;
      const attachment = await findPatientDocumentAttachment(
        patient.id!,
        item.id,
        item.isAthenaDocument ? 'athenaDocument' : item.documentUrl,
        item.isAthenaDocument
      );
      if (attachment) {
        window.open(URL.createObjectURL(attachment.blob!));
        return true;
      } else {
        toast?.current.show({
          severity: 'info',
          summary: 'No attachment found',
          life: 2500,
        });
      }
    },
    [findPatientDocumentAttachment, patient?.id, toast]
  );

  const handleShowContactCareTeamDialog = useCallback(
    (item: PatientDocument) => {
      setContactDialogVisibility(true);
      setContactDialogTopic(
        contactDialogTopic.length > 0
          ? `${contactDialogTopic} and '${item.documentName}'`
          : `'${item.documentName}'`
      );
    },
    [setContactDialogVisibility, setContactDialogTopic, contactDialogTopic]
  );

  return (
    <CustomTable
      emptyMessage='No document found'
      headers={tableHeaders}
      items={documents.map((document) => ({
        ...document,
        favorite: document.favorite && <i className='pi pi-star-fill' />,
        date: DateHelper.formatDateToDisplay(DateTime.fromISO(document.date)),
        onClick: () => handleOnClickItem(document),
        actions: {
          more: [
            {
              label: 'View',
              onClick: () => handleOnClickItem(document),
            },
            {
              label: 'Download',
              onClick: () => findDocumentAttachment(document),
            },
            {
              label: 'Ask a question',
              onClick: () => handleShowContactCareTeamDialog(document),
            },
          ],
        },
      }))}
    />
  );
}
