import { motion } from 'framer-motion';
import { cn } from '@lib-atria/ui-toolkit';

type Props = {
  animate: 'asc' | 'desc' | 'invisible';
  className?: string;
};

export function CustomTableChevron({ animate, className }: Props) {
  return (
    <motion.i
      initial={{ rotate: '0deg', opacity: 0 }}
      variants={{
        asc: { rotate: '0deg', opacity: 1 },
        desc: { rotate: '180deg', opacity: 1 },
      }}
      transition={{ duration: 0.4 }}
      animate={animate}
      className={cn('material-icons-round text-base', className)}
    >
      keyboard_arrow_up
    </motion.i>
  );
}
