import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CustomTable } from '@/components';
import { useApplicationContext, usePageContext } from '@/contexts';

export function MedicalRecordsPage() {
  const { patientMedicalRecords, findPatientMedicalRecords } = useApplicationContext();
  const { setPageTitle } = usePageContext();
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle('Medical Records');
  }, [setPageTitle]);

  useEffect(() => {
    findPatientMedicalRecords();
  }, [findPatientMedicalRecords]);

  return (
    <>
      <CustomTable
        headers={[
          { label: 'Document', key: 'documentDescription' },
          { label: 'Created', key: 'createdDateTime' },
        ]}
        items={
          patientMedicalRecords?.map((medicalRecord) => ({
            ...medicalRecord,
            onClick: () => navigate(`/medical-records/${medicalRecord.id}`),
          })) || []
        }
      />
    </>
  );
}
