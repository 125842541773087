import { AppointmentLetter } from '@/@types';
import { BasicCard } from '..';
import { Button } from 'primereact/button';
import { LetterHelper } from '@/helper';
import { NotFoundLetter } from './notFoundLetter';

type Props = {
  encounterLetter: AppointmentLetter;
};

export function EncounterLetterCard({ encounterLetter }: Props) {
  async function generatePdf() {
    if (encounterLetter) {
      LetterHelper.createAndSavePdfWithLetter(encounterLetter);
    }
  }
  return (
    <BasicCard className='flex justify-items-end p-5 md:p-10 rounded-lg text-kelp text-sm leading-6 '>
      {encounterLetter.summaryHtml ? (
        <>
          <Button
            outlined
            icon='pi pi-download'
            size='small'
            severity='secondary'
            label='Download'
            onClick={generatePdf}
            className='mb-10 md:self-end'
          />
          <div dangerouslySetInnerHTML={{ __html: encounterLetter.summaryHtml }}></div>
        </>
      ) : (
        <NotFoundLetter />
      )}
    </BasicCard>
  );
}
