import { AppointmentSelectionType } from '@/@types';
import { DialogModalFormAppointments, PageTitle, UpcomingAppointments } from '@/components';
import { useApplicationContext, useContactDialogContext, usePageContext } from '@/contexts';
import { useCallback, useEffect } from 'react';
import { DinamicSwitchContent } from './switchContent/dinamicSwitchContent';
import { PastAppointments } from '@/components/pastAppointments/pastAppointments';

export function AppointmentsPage() {
  const { setPageTitle, setActions } = usePageContext();
  const { selectedAppointmentsPage, setSelectedAppointmentsPage } = useApplicationContext();

  const {
    setContent,
    setContactDialogTitle,
    resetContactDialog,
    setContactDialogVisibility,
    setContactDialogTopic,
  } = useContactDialogContext();

  const handleOpenContactCareButton = useCallback(
    (visible: boolean) => {
      setContactDialogVisibility(visible);
      setContactDialogTitle('Request appointment');
      setContent(<DialogModalFormAppointments />);
    },
    [setContactDialogVisibility, setContactDialogTitle, setContent]
  );

  useEffect(() => {
    setPageTitle('Appointments');

    setActions({
      buttons: [
        {
          icon: 'add',
          label: 'Schedule an appointment',
          onClick: () => handleOpenContactCareButton(true),
          variant: 'tertiary',
        },
      ],
    });
    return () => {
      resetContactDialog();
      setActions({ buttons: [] });
    };
  }, [
    handleOpenContactCareButton,
    resetContactDialog,
    setActions,
    setContactDialogTopic,
    setPageTitle,
  ]);

  useEffect(() => {
    setContactDialogTopic('General question about appointments');
  }, [setContactDialogTopic, setContent]);

  const handleSelectedOption = useCallback(
    (option: string) => {
      setSelectedAppointmentsPage(option);
    },
    [setSelectedAppointmentsPage]
  );

  return (
    <>
      <PageTitle title='Your Atria journey' />
      <DinamicSwitchContent
        onSelect={handleSelectedOption}
        options={[
          {
            label: 'Upcoming',
            value: AppointmentSelectionType.UPCOMING.toString(),
            component: UpcomingAppointments,
          },
          {
            label: 'Past',
            value: AppointmentSelectionType.PAST.toString(),
            component: PastAppointments,
          },
        ]}
        selectedOption={selectedAppointmentsPage as string}
      />
    </>
  );
}
