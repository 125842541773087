export enum ResourceCategory {
  MEMBERSHIP = 'MEMBERSHIP',
  NY = 'NY',
  PB = 'PB',
}

export enum ResourceCategoryWithGeneral {
  MEMBERSHIP = 'MEMBERSHIP',
  NY = 'NEW YORK',
  PB = 'PALM BEACH',
  GENERAL = 'GENERAL',
}

export type Resource = {
  id: number;
  resourceName: string;
  resourceUrl: string;
  signedUrl: string | null;
  category?: ResourceCategory | undefined | null;
  createdAt: string;
  createdAtFormatted: string;
  updatedAt?: string | null;
  deletedAt?: string | null;
};
