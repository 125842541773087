import { cn, DisplayCard } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';
import { AppointmentsCardDivider, AppointmentsCardWrapper } from './';

type HomeVisitCardProps = {
  phoneNumber?: string;
  emailCmo?: string;
  address?: string;
};

export function AppointmentsCardHomeVisit({ phoneNumber, emailCmo, address }: HomeVisitCardProps) {
  return (
    <DisplayCard>
      <AppointmentsCardWrapper title='Home visit'>
        <div className='flex flex-col items-center text-center mt-3 '>
          {!!address && (
            <span className={cn(TextLayout.body1, 'text-gray-400')}>
              We will visit you at {address} at the time of your appointment.
            </span>
          )}

          <AppointmentsCardDivider className='mt-12 mb-6' />
          <div className={cn(TextLayout.body2, 'flex flex-col space-y-4 text-gray-400')}>
            {!!phoneNumber && !!emailCmo && (
              <p>
                For assistance, reach out to your care team at {phoneNumber} or {emailCmo}
              </p>
            )}
          </div>
        </div>
      </AppointmentsCardWrapper>
    </DisplayCard>
  );
}
