import { CustomTableItems } from '@/@types';
import { AtriaIconButton } from '../atriaLibrary';
import { useTableAction } from './customTableActionDialog';

type Props = {
  item: CustomTableItems;
};

export function CustomTableItemActionMore({ item }: Props) {
  const tableAction = useTableAction();

  if (item.actions?.more?.length === 0) return null;

  return (
    <>
      {item.actions?.more && (
        <>
          <AtriaIconButton
            icon='more_horiz'
            className='hover:bg-kelp border border-product-sand-200 hover:border-kelp'
            iconClassName='group-hover:text-white'
            onClick={(element) => {
              tableAction.show({ element, actions: item.actions!.more! });
            }}
          />
        </>
      )}
    </>
  );
}
