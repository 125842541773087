import { cn } from '@lib-atria/ui-toolkit';
import { useMemo } from 'react';

type CommonProps = {
  name: string;
  className?: string;
};

type MaterialIconsStyles = CommonProps & {
  family?: 'material-icons';
  style?: 'outlined' | 'filled' | 'rounded' | 'sharp' | 'two tone';
};

type MaterialSymbolsStyles = CommonProps & {
  family?: 'material-symbols';
  style?: 'outlined' | 'rounded' | 'sharp';
};

export type MaterialIconsProps = MaterialIconsStyles | MaterialSymbolsStyles;

export function MaterialIcons({
  family = 'material-icons',
  style = 'rounded',
  name,
  className,
}: MaterialIconsProps) {
  const classes = useMemo(() => {
    if (family === 'material-icons') {
      let styleName: string = style;
      if (style === 'filled') {
        styleName = '';
      }
      if (style === 'rounded') {
        styleName = 'round';
      }
      return `${family}-${styleName}`;
    }
    return `${family}-${style}`;
  }, [family, style]);

  return (
    <span
      className={cn(classes, 'text-base font-normal leading-6 text-product-forest-100', className)}
    >
      {name}
    </span>
  );
}
