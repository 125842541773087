import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { Auth, SidebarType } from '@/@types';
import { env } from '@/constants';
import { useAuthContext } from '@/contexts';
import { Sidebar } from './sidebar';

const items: SidebarType.Item[] = [
  {
    label: 'Home',
    icon: { name: 'home' },
    url: '/',
  },
  {
    label: 'Appointments',
    icon: { name: 'event' },
    url: '/appointments',
    isToShow: env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_APPOINTMENTS_PAGE,
  },
  {
    label: 'Appointments',
    icon: { name: 'event' },
    items: [
      {
        label: 'Upcoming',
        url: '/appointments/upcoming',
      },
      {
        label: 'Past',
        url: '/appointments/previous',
      },
    ],
    isToShow: !env.APP_FEATURE_FLAGS.IS_TO_SHOW_NEW_APPOINTMENTS_PAGE,
    permission: Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS,
  },
  {
    label: 'Results',
    icon: { name: 'analytics' },
    items: [
      {
        label: 'Labs',
        url: '/results/lab',
      },
      {
        label: 'Imaging',
        url: '/results/imaging',
      },
      {
        label: 'Documents',
        url: '/documents',
      },
    ],
    permission: Auth.Permissions.UserPermissions.VIEW_RESULTS,
  },
  {
    label: 'Medications',
    icon: { name: 'medication' },
    url: '/medications',
    permission: Auth.Permissions.UserPermissions.VIEW_MEDICATIONS,
  },
  {
    label: 'Immunizations',
    icon: { name: 'vaccines' },
    url: '/immunizations',
    isToShow: env.APP_FEATURE_FLAGS.IS_TO_SHOW_IMMUNIZATIONS_PAGE,
    permission: Auth.Permissions.UserPermissions.VIEW_IMMUNIZATIONS,
  },
  {
    label: 'Care team',
    icon: { name: 'people' },
    url: '/care-team',
    isToShow: env.APP_FEATURE_FLAGS.IS_TO_SHOW_CARE_TEAM_PAGE,
    permission: Auth.Permissions.UserPermissions.VIEW_MY_CARE_TEAM,
  },
  {
    label: 'Resources',
    icon: { name: 'book' },
    url: '/resources',
  },
  {
    label: 'Export chart',
    icon: { name: 'upgrade' },
    url: '/chart-export',
    permission: Auth.Permissions.UserPermissions.VIEW_CHART_EXPORT,
  },
  {
    label: 'Medical Records',
    icon: { name: 'folder' },
    url: '/medical-records',
    isToShow: env.APP_FEATURE_FLAGS.IS_TO_SHOW_MEDICAL_RECORDS_PAGE,
    permission: Auth.Permissions.UserPermissions.VIEW_RESULTS,
  },
  {
    label: 'AI (Admin)',
    icon: {
      family: 'material-symbols',
      style: 'sharp',
      name: 'robot_2',
    },
    url: '/admin/ai',
    adminOnly: true,
    isToShow: env.APP_FEATURE_FLAGS.IS_TO_SHOW_AI_ADMIN_PAGE,
  },
];

export function SidebarPatients() {
  const { isAdmin, user, patient, switchMember, hasMultiplePatients } = useAuthContext();
  const navigate = useNavigate();

  const patientName = useMemo(() => {
    return `${patient?.firstNameUsed || patient?.firstName} ${patient?.lastName}`;
  }, [patient?.firstName, patient?.firstNameUsed, patient?.lastName]);

  const handleOnNavigate = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  const handleOnSwitchMember = useCallback(() => {
    switchMember();
  }, [switchMember]);

  const handleOnSignOut = useCallback(() => {
    navigate('/sign-out');
  }, [navigate]);

  return (
    <Sidebar
      variant='forest'
      items={items}
      onNavigate={handleOnNavigate}
      userName={patientName}
      isAdmin={isAdmin}
      adminName={user?.name}
      isContactButtonVisible={true}
      hasMultiplePatients={hasMultiplePatients}
      onSwitchMember={handleOnSwitchMember}
      onSignOut={handleOnSignOut}
    />
  );
}
