import { AnimatePresence } from 'framer-motion';
import { useEffect } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { Auth } from '@/@types';
import { SidebarPatients } from '@/components';
import { useAuthContext, useContactDialogContext } from '@/contexts';
import { SignInLayout } from '@/layout';
import {
  CareTeamPage,
  ChartExportPage,
  ChartPdfViewerPage,
  DocumentsPage,
  EncounterLetterPage,
  HomePage,
  ImagingResultDetailsPage,
  ImagingResultsPage,
  ImmunizationsPage,
  LabResultDetailsPage,
  LabResultsPage,
  MedicalRecordsDetailsPage,
  MedicalRecordsPage,
  MedicationsPage,
  PatientDocumentPage,
  PreviousAppointmentsPage,
  ResourceDetailsPage,
  ResourcesPage,
  SignInAuthZeroPage,
  SignInCallbackPage,
  SignInWithPatientsPage,
  SignOutPatientPage,
  SignUpBySignedURLPage,
  UnauthorizedPage,
  UpcomingAppointmentsPage,
} from '@/pages';
import { AppointmentsPage } from '@/pages/appointmentsPage';
import { eventAnalytics } from '@/providers';
import { SentryRoutes } from '@/providers/sentry';
import { PatientAuthMiddleware } from './auth';
import { PermissionAuth } from './auth/permissionAuth';

export function PatientsRoutes() {
  const location = useLocation();
  const { setContactDialogVisibility, setAnalyticsParentPage } = useContactDialogContext();
  const { user, patient, isAdmin } = useAuthContext();

  useEffect(() => {
    eventAnalytics.setDefaultProperties({
      patientId: patient?.id,
      relationship: isAdmin ? 'admin' : user?.relation,
    });
  }, [user, patient, isAdmin]);

  useEffect(() => {
    setAnalyticsParentPage(location.pathname);
    setContactDialogVisibility(false);
  }, [location, setContactDialogVisibility, setAnalyticsParentPage]);

  return (
    <AnimatePresence>
      <SentryRoutes location={location} key={location.pathname}>
        <Route element={<PatientAuthMiddleware />}>
          <Route element={<SidebarPatients />}>
            <Route path='' element={<HomePage />} />
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS]}
                />
              }
            >
              <Route path='/appointments' element={<AppointmentsPage />} />
              <Route path='/appointments/upcoming' element={<UpcomingAppointmentsPage />} />
              <Route path='/appointments/previous' element={<PreviousAppointmentsPage />} />
            </Route>
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[Auth.Permissions.UserPermissions.VIEW_CHART_EXPORT]}
                />
              }
            >
              <Route path='/chart-export' element={<ChartExportPage />} />
            </Route>
            <Route path='/documents' element={<DocumentsPage />} />
            <Route path='/documents/:documentId' element={<PatientDocumentPage />} />
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[
                    Auth.Permissions.UserPermissions.VIEW_ENCOUNTER_LETTER,
                    Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS,
                  ]}
                />
              }
            >
              <Route
                path='/appointments/:appointmentId/encounter/:encounterId/letter'
                element={<EncounterLetterPage />}
              />
            </Route>
            <Route path='/home' element={<HomePage />} />
            <Route path='/patient/chart/pdf/:documentId' element={<ChartPdfViewerPage />} />
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[Auth.Permissions.UserPermissions.VIEW_RESULTS]}
                />
              }
            >
              <Route path='/results/imaging' element={<ImagingResultsPage />} />
              <Route path='/results/lab' element={<LabResultsPage />} />
              <Route path='/results/imaging/:id' element={<ImagingResultDetailsPage />} />
              <Route path='/results/lab/:id' element={<LabResultDetailsPage />} />
            </Route>
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[Auth.Permissions.UserPermissions.VIEW_MEDICATIONS]}
                />
              }
            >
              <Route path='/medications' element={<MedicationsPage />} />
            </Route>
            <Route path='/resources' element={<ResourcesPage />} />
            <Route path='/resources/:id' element={<ResourceDetailsPage />} />
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[Auth.Permissions.UserPermissions.VIEW_MY_CARE_TEAM]}
                />
              }
            >
              <Route path='/care-team' element={<CareTeamPage />} />
            </Route>
            <Route
              element={
                <PermissionAuth
                  requiredPermission={[Auth.Permissions.UserPermissions.VIEW_IMMUNIZATIONS]}
                />
              }
            >
              <Route path='/immunizations' element={<ImmunizationsPage />} />
            </Route>
            <Route path='/medical-records' element={<MedicalRecordsPage />} />
            <Route path='/medical-records/:id' element={<MedicalRecordsDetailsPage />} />
          </Route>
          <Route element={<SignInLayout />}>
            <Route path='/sign-in/patients' element={<SignInWithPatientsPage />} />
          </Route>
          <Route path='/unauthorized' element={<UnauthorizedPage />} />
        </Route>
        <Route element={<SignInLayout />}>
          <Route path='/sign-in' element={<SignInAuthZeroPage />} />
          <Route path='/sign-in/code' element={<SignUpBySignedURLPage />} />
        </Route>
        <Route path='/sign-out' element={<SignOutPatientPage />} />
        <Route path='/sign-in/callback' element={<SignInCallbackPage />} />
      </SentryRoutes>
    </AnimatePresence>
  );
}
