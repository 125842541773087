import { CustomTableHeaderAction } from '@/@types';
import { AtriaIconButton } from '../atriaLibrary';
import { useTableAction } from './customTableActionDialog';
import { useMemo } from 'react';
import { cn } from '@lib-atria/ui-toolkit';

type Props = {
  headerActions?: CustomTableHeaderAction[];
  isMobile?: boolean;
  stickyActions?: boolean;
};

export function CustomTableHeaderActions({
  headerActions,
  isMobile = false,
  stickyActions = false,
}: Props) {
  const applyStickyClasses = useMemo(() => {
    if (isMobile || !stickyActions) {
      return '';
    }

    return 'sticky right-0 bg-fern';
  }, [isMobile, stickyActions]);

  const tableAction = useTableAction();
  return (
    <th className={cn('px-10 py-[26px] border-fern border-l-[1px]', applyStickyClasses)}>
      {(headerActions || [])?.length > 0 && (
        <AtriaIconButton
          icon='more_horiz'
          className='bg-product-forest-100/30'
          iconClassName='text-white'
          onClick={(element) => {
            tableAction.show({ element, actions: headerActions! });
          }}
        />
      )}
    </th>
  );
}
