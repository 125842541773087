import { cn } from '@lib-atria/ui-toolkit';

import { TextLayout } from '../text';

type Props = {
  label: string;
  isSelected?: boolean;
  onClick: VoidFunction;
};

export function CareTeamProviderMobileListItem({ label, isSelected = false, onClick }: Props) {
  return (
    <p
      onClick={onClick}
      className={cn(
        TextLayout.body2,
        'p-4 cursor-pointer text-product-sand-300 transition-colors rounded-lg',
        isSelected ? 'bg-product-forest-300' : 'bg-transparent hover:bg-product-forest-300'
      )}
    >
      {label}
    </p>
  );
}
