import { cn, DisplayCard } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';
import { AppointmentsCardDivider, AppointmentsCardWrapper } from './';

type PhoneCallCardProps = {
  phoneNumber: string;
  emailCmo: string;
};

export function AppointmentsCardPhoneCall({ phoneNumber, emailCmo }: PhoneCallCardProps) {
  return (
    <DisplayCard>
      <AppointmentsCardWrapper title='Phone call'>
        <div className='flex flex-col items-center text-center mt-3 '>
          <span className={TextLayout.body1}>
            We will call you at {phoneNumber} at the time of your appointment.
          </span>

          <AppointmentsCardDivider className='mt-12 mb-6' />
          <div className={cn(TextLayout.body2, 'flex flex-col space-y-4 text-gray-400 ')}>
            <p>
              For assistance, reach out to your care team at {phoneNumber} or {emailCmo}
            </p>
          </div>
        </div>
      </AppointmentsCardWrapper>
    </DisplayCard>
  );
}
