import { DateTime } from 'luxon';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import {
  ApplicationContextType,
  Appointments,
  AppointmentSelectionType,
  Doctor,
  FindDashboardSummary,
  LocalStorage,
  MedicalRecords,
} from '@/@types';
import { localStorageHelper } from '@/helper';
import {
  useCareTeam,
  useDashboard,
  useMedicalRecords,
  useMedications,
  usePatientAppointments,
  usePatientDocuments,
  usePatientImagingResults,
  usePatientLabResults,
  useResources,
} from '@/hooks';
import { useImmunizations } from '@/hooks/useImmunizations';
import { patientsService } from '@/services';
import { ImagingResultType, LabResultType, StringHelper } from '@lib-atria/ui-toolkit';
import { useAuthContext, useLoaderContext } from '.';

const ApplicationContext = createContext<ApplicationContextType>(undefined!);

export const useApplicationContext = () => useContext(ApplicationContext);

const today = DateTime.now().toFormat('yyyy-MM-dd');
const upcomingAppointmentsEndDate = DateTime.now().plus({ year: 1 }).toFormat('yyyy-MM-dd');
const previousAppointmentsStartDate = DateTime.now().minus({ year: 5 }).toFormat('yyyy-MM-dd');

export function ApplicationProvider({ children }: { children: ReactNode }) {
  const { startLoader, stopLoader } = useLoaderContext();
  const { patient, setPatient } = useAuthContext();
  const { findPatientDocuments } = usePatientDocuments();
  const { findPatientAppointments } = usePatientAppointments();
  const { findPatientImagingResults } = usePatientImagingResults();
  const { findPatientLabResults } = usePatientLabResults();
  const { findPatientsMedicationsList } = useMedications();
  const { findResources } = useResources();
  const { findSummary } = useDashboard();
  const { findDoctor, findAllDoctors, findSpecialistsByPatient } = useCareTeam();
  const { findPatientImmunizations } = useImmunizations();
  const { findAllPatientMedicalRecords } = useMedicalRecords();

  const [documents, setDocuments] = useState<ApplicationContextType['documents']>();
  const [imagingResults, setImagingResults] = useState<ApplicationContextType['imagingResults']>();
  const [labResults, setLabResults] = useState<ApplicationContextType['labResults']>();
  const [medications, setMedications] = useState<ApplicationContextType['medications']>();
  const [upcomingAppointments, setUpcomingAppointments] = useState<
    ApplicationContextType['upcomingAppointments']
  >([]);
  const [upcomingAppointmentsExtended, setUpcomingAppointmentsExtended] = useState<
    Array<Appointments.FindAllPatientsAppointments.GroupItemContext>
  >([]);
  const [upcomingAppointmentsSidebarList, setUpcomingAppointmentsSidebarList] = useState<
    Array<Appointments.SidebarList.Item>
  >([]);
  const [hasMoreUpcomingAppointments, setHasMoreUpcomingAppointments] = useState<boolean>(true);
  const setUpcomingPagesLoaded = useRef<Array<number>>([]);

  const [pastAppointmentsExtended, setPastAppointmentsExtended] = useState<
    Array<Appointments.FindAllPatientsAppointments.GroupItemContext>
  >([]);
  const [pastAppointmentsSidebarList, setPastAppointmentsSidebarList] = useState<
    Array<Appointments.SidebarList.Item>
  >([]);
  const [hasMorePastAppointments, setHasMorePastAppointments] = useState<boolean>(true);
  const setPastPagesLoaded = useRef<Array<number>>([]);

  const [previousAppointments, setPreviousAppointments] =
    useState<ApplicationContextType['previousAppointments']>();

  const [resources, setResources] = useState<ApplicationContextType['resources']>();
  const [dashboardSummary, setDashboardSummary] = useState<FindDashboardSummary>();
  const [doctor, setDoctor] = useState<Doctor>();
  const [allDoctors, setAllDoctors] = useState<Doctor[]>();
  const [locations, setLocations] = useState<ApplicationContextType['locations']>();
  const [immunizations, setImmunizations] = useState<ApplicationContextType['immunizations']>();
  const [patientMedicalRecords, setPatientMedicalRecords] = useState<
    Array<MedicalRecords.Type> | undefined
  >();
  const [providers, setProviders] = useState<ApplicationContextType['providers']>();

  const [selectedAppointmentsPage, setSelectedAppointmentsPage] = useState<string>(
    AppointmentSelectionType.UPCOMING.toString()
  );

  const allDoctorsGroupedByTags = useMemo(() => {
    const groupedDoctorsList = new Map<string, Doctor[]>();

    allDoctors?.forEach((item) => {
      item.tags?.forEach((tag) => {
        const formattedTag = `${tag.charAt(0).toUpperCase()}${tag.substring(1)}`;

        if (!groupedDoctorsList || !groupedDoctorsList.has(formattedTag)) {
          groupedDoctorsList.set(formattedTag, []);
        }

        groupedDoctorsList.set(formattedTag, [...groupedDoctorsList.get(formattedTag)!, item]);
      });
    });

    return groupedDoctorsList;
  }, [allDoctors]);

  const getDocuments = useCallback(async () => {
    if (patient) {
      const documentsResponse = await findPatientDocuments(patient.id);
      setDocuments(documentsResponse);
    }
  }, [findPatientDocuments, patient]);

  const getImagingResults = useCallback(async () => {
    if (patient) {
      const imagingResultsResponse = await findPatientImagingResults(patient.id);
      setImagingResults(imagingResultsResponse);
    }
  }, [findPatientImagingResults, patient]);

  const getLabResults = useCallback(async () => {
    if (!patient) return;
    const labResultsResponse = await findPatientLabResults(patient.id);
    setLabResults(
      labResultsResponse.sort((a, b) => {
        const dateA = a.observationDateTime || a.createdDateTime;
        const dateB = b.observationDateTime || b.createdDateTime;
        return dateB.localeCompare(dateA);
      })
    );
  }, [findPatientLabResults, patient]);

  const getMedications = useCallback(async () => {
    if (patient) {
      const medicationsResponse = await findPatientsMedicationsList(patient.id);

      setMedications(medicationsResponse);
    }
  }, [findPatientsMedicationsList, patient]);

  const getUpcomingAppointments = useCallback(async () => {
    if (!patient || upcomingAppointments) return;
    startLoader();
    const upcomingAppointmentsResponse = await findPatientAppointments(patient.id, {
      startDate: today,
      endDate: upcomingAppointmentsEndDate,
      atriaAppointment: false,
    });
    setUpcomingAppointments(
      upcomingAppointmentsResponse.sort(
        (a, b) => new Date(a.date).getTime() - new Date(b.end).getTime()
      )
    );
    stopLoader();
  }, [findPatientAppointments, upcomingAppointments, patient, startLoader, stopLoader]);

  const getPatientAppointments = useCallback(
    async (
      params: Appointments.GetPatientsResultList.Params
    ): Promise<Appointments.GetPatientsResultList.Response> => {
      const result: Appointments.GetPatientsResultList.Response = {
        hasMore: false,
        sidebarList: [],
        appointments: [],
      };
      const { data } = await patientsService.findAllPatientsAppointments({
        startDate: params.startDate,
        endDate: params.endDate,
        patientId: params.patientId,
        athenaAppointments: params.athenaAppointments,
        atriaAppointment: params.atriaAppointment,
        includesDocuments: params.includesDocuments,
        page: params?.page || 1,
        limit: params?.limit || 3,
      });

      const allGroupsByPages = data.pages;

      let allItemsFromAllPages: Appointments.FindAllPatientsAppointments.GroupItemContext[] = [];
      for (const { page, items } of allGroupsByPages) {
        allItemsFromAllPages = [
          ...allItemsFromAllPages,
          ...items.map((item, index) => ({
            ...item,
            metadata: {
              page,
              firstElement: index === 0,
              lastElement: index === items.length - 1,
              loaded: page === params.page,
            },
          })),
        ];
      }

      const numberOfPages = Array.from({ length: data.metadata.pageCount }, (_, i) => i + 1);
      const allPagesLoaded = numberOfPages.every((i) => params.pagesLoaded.includes(i));
      result.hasMore = !allPagesLoaded;

      const sidebarList = allItemsFromAllPages.map((p) => ({
        key: p.key,
        date: p.date,
        page: p.metadata.page,
      }));
      result.sidebarList = sidebarList;

      const auth = localStorageHelper.getItem(LocalStorage.Keys.AUTH);
      const authUpcomingAppts = auth?.patient?.upcomingAppointments || [];

      const { appointmentCallback } = params;
      type List = Array<Appointments.FindAllPatientsAppointments.GroupItemContext>;
      const groupList: List = await new Promise((resolve) => {
        appointmentCallback((prev: List) => {
          if (prev?.length === 0) {
            resolve(allItemsFromAllPages);
            return allItemsFromAllPages;
          }

          let copy = [];
          for (const item of allItemsFromAllPages) {
            const prevItem = prev.find((p) => p.key === item.key);
            const itemStored = authUpcomingAppts?.find(({ key }) => key === item.key);
            copy.push({
              ...item,
              confirmed: item.confirmed || itemStored?.confirmed || false,
              canceled: itemStored?.canceled || false,
              metadata: {
                ...item.metadata,
                loaded: prevItem?.metadata.loaded || item.metadata.page === params.page,
              },
            });
          }
          copy = copy.sort((a, b) => a.metadata.page - b.metadata.page);

          resolve(copy);
          return copy;
        });
      });
      result.appointments = groupList;
      return result;
    },
    []
  );

  const getUpcomingAppointmentsExtended = useCallback(
    async (params: { page: number; limit?: number }) => {
      const isFullLoaded =
        upcomingAppointmentsExtended?.length > 0 &&
        upcomingAppointmentsExtended?.every((i) => i.metadata.loaded === true);
      if (isFullLoaded) {
        return upcomingAppointmentsExtended;
      }
      const pagesLoaded = [...new Set([...setUpcomingPagesLoaded.current, params.page])];
      setUpcomingPagesLoaded.current = pagesLoaded;
      const { appointments, hasMore, sidebarList } = await getPatientAppointments({
        startDate: today,
        endDate: upcomingAppointmentsEndDate,
        athenaAppointments: true,
        atriaAppointment: true,
        includesDocuments: false,
        patientId: patient!.id,
        page: params.page,
        limit: params.limit,
        pagesLoaded,
        appointmentCallback: setUpcomingAppointmentsExtended,
      });
      setHasMoreUpcomingAppointments(hasMore);
      setUpcomingAppointmentsSidebarList(sidebarList);
      setUpcomingAppointmentsExtended(appointments);
      return appointments;
    },
    [getPatientAppointments, patient, upcomingAppointmentsExtended]
  );

  /**
   * Merge new appointments with the old ones keeping the documents from previous appointments in the list
   */
  const getMergedAppointments = (
    oldPastAppointments: Appointments.FindAllPatientsAppointments.GroupItemContext[],
    newPastAppointments: Appointments.FindAllPatientsAppointments.GroupItemContext[]
  ) => {
    return Array.from(
      [...oldPastAppointments, ...newPastAppointments].reduce((acc, appointment) => {
        if (!acc.has(appointment.key)) {
          acc.set(appointment.key, { ...appointment });
          return acc;
        }

        const oldAppointment = acc.get(appointment.key);

        acc.set(appointment.key, {
          ...oldAppointment,
          ...appointment,
          doctor: appointment.doctor || oldAppointment.doctor,
          labResults: appointment.labResults.length
            ? appointment.labResults
            : oldAppointment.labResults,
          appointmentNotes: appointment.appointmentNotes.length
            ? appointment.appointmentNotes
            : oldAppointment.appointmentNotes,
          imagingResults: appointment.imagingResults.length
            ? appointment.imagingResults
            : oldAppointment.imagingResults,
        });

        return acc;
      }, new Map()),
      ([, value]) => value
    );
  };

  const getPastAppointmentsExtended = useCallback(
    async (params: { page: number; limit?: number }) => {
      const isFullLoaded =
        pastAppointmentsExtended?.length > 0 &&
        pastAppointmentsExtended?.every((i) => i.metadata.loaded === true);
      if (isFullLoaded) {
        return pastAppointmentsExtended;
      }
      const pagesLoaded = [...new Set([...setPastPagesLoaded.current, params.page])];
      setPastPagesLoaded.current = pagesLoaded;
      const { appointments, hasMore, sidebarList } = await getPatientAppointments({
        startDate: previousAppointmentsStartDate,
        endDate: today,
        athenaAppointments: true,
        atriaAppointment: true,
        includesDocuments: true,
        patientId: patient!.id,
        page: params.page,
        limit: params.limit,
        pagesLoaded,
        appointmentCallback: setPastAppointmentsExtended,
      });

      setHasMorePastAppointments(hasMore);
      setPastAppointmentsSidebarList(sidebarList);
      const pastAppointments = appointments.map((item) => {
        return {
          ...item,
          location:
            item.location === 'New York' || item.location === 'Palm Beach'
              ? `Atria ${item.location}`
              : item.location,
          labResults: item.labResults.map((result) => {
            const quantity = result.observations?.length;
            const labResultParams: ImagingResultType['params'] | LabResultType['params'] = {
              name: StringHelper.firstLetterUppercase(result.description),
              description: result.patientNote,
              status: result.resultsStatus,
              date: result.createdDateTime,
              doctor: result.providerName,
              notes: result.patientNote,
              quantity: quantity || 1,
              label: quantity && quantity > 1 ? 'results' : 'document',
            } as ImagingResultType['params'] | LabResultType['params'];
            let resultType: 'Lab' | 'Imaging' = 'Lab';
            if (result.documentDescription) {
              resultType = 'Imaging';
              labResultParams.name = StringHelper.firstLetterUppercase(result.documentDescription);
            }
            return {
              params: labResultParams,
              id: result.id,
              key: `${result.id}-${Math.random()}`,
              type: resultType,
            };
          }),
        };
      });

      const mergedPastAppointments = getMergedAppointments(
        pastAppointmentsExtended,
        pastAppointments
      );

      setPastAppointmentsExtended(mergedPastAppointments);

      return mergedPastAppointments;
    },
    [getPatientAppointments, pastAppointmentsExtended, patient]
  );

  const updatePatientLocalStorage = useCallback(() => {
    const updatedAppointments = upcomingAppointmentsExtended.map((groupItem) => ({
      key: groupItem.key,
      confirmed: groupItem.confirmed,
      canceled: groupItem.canceled,
    }));
    const user = localStorageHelper.getItem(LocalStorage.Keys.AUTH);
    if (user) {
      localStorageHelper.setItem({
        key: LocalStorage.Keys.AUTH,
        payload: {
          ...user,
          patient: {
            ...user.patient!,
            upcomingAppointments: updatedAppointments!,
          },
        },
      });
    }
  }, [upcomingAppointmentsExtended]);

  useEffect(() => {
    updatePatientLocalStorage();
  }, [updatePatientLocalStorage]);

  const getPreviousAppointments = useCallback(async () => {
    if (!patient || previousAppointments) return;
    startLoader();
    const previousAppointmentsResponse = await findPatientAppointments(patient.id, {
      startDate: previousAppointmentsStartDate,
      endDate: today,
      atriaAppointment: false,
    });
    setPreviousAppointments(
      previousAppointmentsResponse.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      )
    );
    stopLoader();
  }, [findPatientAppointments, patient, previousAppointments, startLoader, stopLoader]);

  const getResources = useCallback(async () => {
    const resourcesResponse = await findResources();

    setResources(resourcesResponse);
  }, [findResources]);

  const findDashboardSummary = useCallback(async () => {
    if (!patient) return;
    const data = await findSummary(patient!.id);
    setDashboardSummary(data);
  }, [findSummary, patient]);

  const getDoctor = useCallback(
    async (id: string) => {
      if (!patient) return;

      const doctorResponse = await findDoctor(id);
      if (!doctorResponse) return;
      setDoctor(doctorResponse);
    },
    [findDoctor, patient]
  );

  const getAllDoctors = useCallback(async () => {
    if (!patient) return;

    const doctors = await findAllDoctors();
    if (!doctors) return;
    setAllDoctors(doctors.doctors);

    setLocations(doctors.locations);
  }, [findAllDoctors, patient]);

  const getImmunizations = useCallback(async () => {
    if (!patient) return;
    const response = await findPatientImmunizations(patient.id);
    setImmunizations(response);
  }, [findPatientImmunizations, patient]);

  const findPatientMedicalRecords = useCallback(async () => {
    const data = await findAllPatientMedicalRecords(patient!.id);
    setPatientMedicalRecords(data);
  }, [findAllPatientMedicalRecords, patient]);

  const getSpecialists = useCallback(async () => {
    const data = await findSpecialistsByPatient();
    if (!data) return;
    setProviders(data.members);
  }, [findSpecialistsByPatient]);

  const reset = useCallback(() => {
    setPatient(undefined);
    setDocuments(undefined);
    setImagingResults(undefined);
    setLabResults(undefined);
    setMedications(undefined);
    setUpcomingAppointments(undefined);
    setPreviousAppointments(undefined);
    setResources(undefined);
    setDashboardSummary(undefined);
    setDoctor(undefined);
    setImmunizations(undefined);
    setPatientMedicalRecords(undefined);
    setAllDoctors(undefined);
    setLocations(undefined);
    setProviders(undefined);
    setUpcomingAppointmentsExtended([]);
    setPastAppointmentsExtended([]);
    setSelectedAppointmentsPage(AppointmentSelectionType.UPCOMING.toString());
  }, [setPatient]);

  return (
    <ApplicationContext.Provider
      value={{
        documents,
        imagingResults,
        labResults,
        medications,
        upcomingAppointments,
        previousAppointments,
        resources,
        dashboardSummary,
        doctor,
        allDoctors,
        locations,
        immunizations,
        providers,
        pastAppointmentsSidebarList,
        setUpcomingAppointmentsExtended,
        upcomingAppointmentsSidebarList,
        upcomingAppointmentsExtended,
        patientMedicalRecords,
        allDoctorsGroupedByTags,
        selectedAppointmentsPage,
        getDocuments,
        getImagingResults,
        getLabResults,
        getMedications,
        getPreviousAppointments,
        getResources,
        findDashboardSummary,
        reset,
        getDoctor,
        getAllDoctors,
        getImmunizations,
        findPatientMedicalRecords,
        getSpecialists,
        getUpcomingAppointments,
        getUpcomingAppointmentsExtended,
        hasMorePastAppointments,
        getPastAppointmentsExtended,
        pastAppointmentsExtended,
        setSelectedAppointmentsPage,
        hasMoreUpcomingAppointments,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
}
