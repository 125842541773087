import { User } from '@/@types';
import { api } from '@/providers';
import { AxiosRequestConfig } from 'axios';

function createNewUser(params: User.CreateNewUser.Params) {
  return api.post<User.CreateNewUser.Response>('/api/v1/users', params);
}

function findUserById(id: string) {
  return api.get<User.FindUserById.Response>(`/api/v1/users/${id}`);
}

function findAllUsers(params?: User.FindAllUsers.Params) {
  return api.get<User.FindAllUsers.Response>('/api/v1/users', { params });
}

function updateUserById(id: string, body: User.UpdateUserById.Body) {
  return api.patch<User.UpdateUserById.Response>(`/api/v1/users/${id}`, body);
}

function findAllUsersPermissions() {
  return api.get<User.FindAllPermissions.Response>('/api/v1/users/permissions');
}

function exportUsersCSVFile(params?: User.ExportAllUsers.Params) {
  const config: AxiosRequestConfig<any> = {
    headers: { Accept: 'text/csv' },
    responseType: 'arraybuffer',
  };
  if (params?.userIds?.length) {
    return api.post('/api/v1/users/export', { userIds: params.userIds }, { ...config });
  }
  if (params?.relation) {
    config.params = { relation: params.relation };
  }
  return api.get('/api/v1/users', config);
}

async function resendInvitation(userId: string) {
  return api.post<User.ResendInvite.Response>(`/api/v1/users/${userId}/resend-invitation`);
}

export const UserService = {
  findUserById,
  findAllUsers,
  exportUsersCSVFile,
  resendInvitation,
  findAllUsersPermissions,
  updateUserById,
  createNewUser,
};
