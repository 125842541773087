import { Auth } from '@/@types';
import { useAuthContext } from '@/contexts';
import { BaseCard, MaterialIcons } from '@lib-atria/ui-toolkit';
import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type Props = {
  handleRequestRefill: () => void;
};

export function Tootlbox({ handleRequestRefill }: Props) {
  const navigate = useNavigate();
  const { user } = useAuthContext();

  const redirectUri = useCallback(
    (url: string) => {
      navigate(url);
    },
    [navigate]
  );

  const items = useMemo(() => {
    return [
      {
        title: 'Request a refill',
        icon: 'medication',
        permission: Auth.Permissions.UserPermissions.VIEW_MEDICATIONS,
        onClick: () => {
          handleRequestRefill();
        },
      },
      {
        title: 'Schedule an appointment',
        icon: 'event',
        permission: Auth.Permissions.UserPermissions.VIEW_APPOINTMENTS,
        onClick: () => {
          redirectUri('/appointments');
        },
      },
      {
        title: 'Personalized Health Assessment',
        icon: 'summarize',
      },
      {
        title: 'Atria locations',
        icon: 'location_on',
        onClick: () => {
          redirectUri('/resources');
        },
      },
      {
        title: 'Atria resources',
        icon: 'description',
        onClick: () => {
          redirectUri('/resources#resources');
        },
      },
    ];
  }, [handleRequestRefill, redirectUri]);

  const filteredItems = useMemo(
    () => items.filter((item) => !item.permission || user?.permissions?.includes(item.permission)),
    [items, user?.permissions]
  );

  return (
    <BaseCard title='Toolbox' className='bg-sand-100'>
      <div className='space-y-2'>
        {filteredItems.map((item, index) => (
          <div
            key={index}
            className='flex gap-3 rounded-md border border-sand-300 p-4 text-gray-400 cursor-pointer'
            onClick={item?.onClick}
          >
            <MaterialIcons family='material-icons' name={item.icon} className='text-gray-400' />
            <p className='text-gray-400'>{item.title}</p>
          </div>
        ))}
      </div>
    </BaseCard>
  );
}
