import { useCallback, useState } from 'react';

import { CareTeamSpecialitiesDesktop } from './careTeamSpecialitiesDesktop';
import { CareTeamSpecialitiesMobile } from './careTeamSpecialitiesMobile';

type Props = {
  specialities: string[];
  onSelect: (speciality: string) => void;
};

export function CareTeamSpecialities({ specialities, onSelect }: Props) {
  const [selected, setSelected] = useState<string | undefined>();

  const handleOnSelect = useCallback(
    (speciality: string) => {
      setSelected(speciality);
      onSelect(speciality);
    },
    [onSelect]
  );

  return (
    <>
      <CareTeamSpecialitiesMobile
        specialities={specialities}
        selected={selected}
        onSelect={handleOnSelect}
      />
      <CareTeamSpecialitiesDesktop
        specialities={specialities}
        selected={selected}
        onSelect={handleOnSelect}
      />
    </>
  );
}
