import { AnimatePresence } from 'framer-motion';
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { createContext, SyntheticEvent, useCallback, useContext, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { TableMoreAction } from '@/@types';
import { CustomTableActionDialogMobile } from './customTableActionDialogMobile';

type TableActionShowProps = {
  element: SyntheticEvent;
  actions: TableMoreAction[];
};

type Context = {
  show: (props: TableActionShowProps) => void;
  hide: VoidFunction;
};

const TableAction = createContext<Context>(undefined!);

export const useTableAction = () => useContext(TableAction);

type Props = {
  children: React.ReactNode;
};

export function CustomTableActionDialog({ children }: Props) {
  const [isVisible, setIsVisible] = useState(false);
  const [actions, setActions] = useState<TableMoreAction[]>([]);
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const show = useCallback((options: TableActionShowProps) => {
    setIsVisible(true);
    setActions(options.actions);
    overlayPanelRef.current?.toggle(options.element);
  }, []);

  const hide = useCallback(() => {
    setIsVisible(false);
    overlayPanelRef.current?.hide();
    setActions([]);
  }, []);

  const onClick = useCallback(
    (action: TableMoreAction) => {
      action.onClick();
      hide();
    },
    [hide]
  );

  return (
    <>
      <TableAction.Provider value={{ show, hide }}>{children}</TableAction.Provider>
      {isMobile ? (
        <AnimatePresence>
          {isVisible && <CustomTableActionDialogMobile actions={actions} onClose={hide} />}
        </AnimatePresence>
      ) : (
        <OverlayPanel
          ref={overlayPanelRef}
          className='bg-experimental-forest p-1 shadow-[0_11px_17px_0_rgba(218, 218, 218, 0.24)] after:content-[] before:content-[] rounded-lg'
          pt={{ content: { className: 'p-0' } }}
          onHide={hide}
        >
          <div className='flex flex-col gap-2'>
            {actions.map((action) => (
              <Button
                className='text-white text-left p-3 self-stretch text-sm'
                key={action.label}
                label={action.label}
                onClick={() => onClick(action)}
                text
              />
            ))}
          </div>
        </OverlayPanel>
      )}
    </>
  );
}
