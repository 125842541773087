import { useCallback } from 'react';

import { ShowLoaderOptions } from '@/@types';
import { TimerHelper } from '@/helper';
import createFastContext from './fastContext';

const { Provider, useStore } = createFastContext({
  isLoading: false,
  isLoaderComponentVisible: false,
  label: '',
});

export const LoaderProvider = Provider;

export const useLoaderContext = () => {
  const [state, setState] = useStore((s) => s);

  const startLoader = useCallback(
    (options?: ShowLoaderOptions) => {
      setState({
        isLoading: true,
        isLoaderComponentVisible: options?.isDefaultComponentVisible ?? true,
        label: options?.label || 'Loading...',
      });
    },
    [setState]
  );

  const stopLoader = useCallback(() => {
    TimerHelper.delay(500, () => {
      setState({ isLoading: false, isLoaderComponentVisible: false, label: 'Loading...' });
    });
  }, [setState]);

  return {
    ...state,
    startLoader,
    stopLoader,
  };
};
