import { Appointments } from '@/@types';
import { AppointmentsCardPhoneCall } from '../appointmentsCard';

type Props = {
  appointments: Appointments.FindAllPatientsAppointments.AppointmentList;
  phoneNumber: string;
  cmoEmail: string;
};

export function PhoneCallCard({ appointments, phoneNumber, cmoEmail }: Props) {
  return (
    <>
      {appointments.map((appt) => (
        <AppointmentsCardPhoneCall key={appt.id} phoneNumber={phoneNumber} emailCmo={cmoEmail} />
      ))}
    </>
  );
}
