import { CareTeamType } from '@/@types';
import { AllTeam, MyTeam, TextLayout } from '@/components';
import { usePageContext } from '@/contexts';
import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';

export function CareTeamPage() {
  const { setPageTitle } = usePageContext();
  const [selectedOption, setSelectedOption] = useState<CareTeamType>(CareTeamType.MY_CARE_TEAM);

  const hasSelectedOption = useCallback(
    (option: CareTeamType) =>
      selectedOption === option ? 'text-product-forest-100' : 'text-product-gray-400',
    [selectedOption]
  );

  useEffect(() => {
    setPageTitle('Care team');
  }, [setPageTitle]);

  const handleSelectedOption = useCallback((option: CareTeamType) => {
    setSelectedOption(option);
  }, []);

  return (
    <div className='md:mt-[100px] mt-[64px]'>
      <div className='flex space-x-[40px] cursor-pointer'>
        <h1
          className={cn(TextLayout.callout2, hasSelectedOption(CareTeamType.MY_CARE_TEAM))}
          onClick={() => handleSelectedOption(CareTeamType.MY_CARE_TEAM)}
        >
          Your care team
        </h1>
        <h1
          className={cn(TextLayout.callout2, hasSelectedOption(CareTeamType.ALL))}
          onClick={() => handleSelectedOption(CareTeamType.ALL)}
        >
          All
        </h1>
      </div>
      <AnimatePresence mode='wait'>
        <SelectedOption selectedOption={selectedOption} />
      </AnimatePresence>
    </div>
  );
}

const SelectedOption = ({ selectedOption }: { selectedOption: CareTeamType }) => {
  const Component = selectedOption === CareTeamType.ALL ? AllTeam : MyTeam;
  return (
    <InitAnimation id={selectedOption}>
      <Component />
    </InitAnimation>
  );
};

const InitAnimation = ({ children, id }: { children: React.ReactNode; id: CareTeamType }) => {
  return (
    <motion.div
      key={id}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1.2, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {children}
    </motion.div>
  );
};
