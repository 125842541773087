import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import { env } from '@/constants';

export function LoginHeader() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const atriaLogoHeader = `${env.APP_CDN_URL}/images/atria-logo-01.svg`;
  const atriaEllipse = `${env.APP_CDN_URL}/images/records/ellipse-1.svg`;

  const location = useLocation();

  const isVisible = useMemo(
    () => location.pathname === '/sign-in' && env.APP_FEATURE_FLAGS.IS_TO_SHOW_AUTH_ZERO_LOGIN,
    [location]
  );

  if (!isVisible) {
    return <></>;
  }

  return (
    <div className='p-10 absolute inset-0'>
      {!isMobile ? (
        <>
          <div className='float-left'>
            <img src={atriaLogoHeader} />
          </div>
          <div className='float-right'>
            <img src={atriaEllipse} className='mt-2' />
          </div>
        </>
      ) : (
        <>
          <div className='flex justify-center'>
            <img src={atriaLogoHeader} />
          </div>
          <div className='flex justify-center p-2'>
            <img src={atriaEllipse} className='mt-2' />
          </div>
        </>
      )}
    </div>
  );
}
