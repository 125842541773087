import { motion } from 'framer-motion';

type Props = {
  children: React.ReactNode;
  className?: string;
  duration?: number;
  delay?: number;
};

export function StaggerChildren({ children, className, duration = 0.8, delay = 0 }: Props) {
  return (
    <motion.ul
      variants={{
        hidden: { opacity: 0 },
        show: { opacity: 1, transition: { staggerChildren: duration, delayChildren: delay } },
      }}
      initial='hidden'
      animate='show'
      exit='hidden'
      className={className}
    >
      {children}
    </motion.ul>
  );
}
