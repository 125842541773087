import { useApplicationContext } from '@/contexts';
import { CareTeamCardHeader, CareTeamMembers } from './';

export function CareTeamCard() {
  const { doctor } = useApplicationContext();
  return (
    <div className='bg-noise-sand w-full flex flex-col rounded-xl gap-6 text-product-forest-100 flex-1 py-7 md:p-10 px-5'>
      <CareTeamCardHeader
        title='Care team'
        description='Your team is available 7 days a week to assist you with all questions and concerns.'
      />
      <div className='border border-b-fern border-b-2 md:mt-[96px] mt-10' />
      {doctor?.pods.podMembers.map((member, index) => (
        <CareTeamMembers member={member} key={index} />
      ))}
    </div>
  );
}
