import { Auth } from '.';

const prefix = '@memberPortal';

export namespace LocalStorage {
  export type Data<T> = T extends LocalStorage.Keys.AUTH
    ? SetAuthStorageData['payload']
    : T extends LocalStorage.Keys.CACHE
      ? SetCacheStorageData['payload']
      : never;

  export type Actions = SetAuthStorageData | SetCacheStorageData;

  export enum Keys {
    AUTH = `${prefix}/AUTH`,
    CACHE = `${prefix}/CACHE`,
  }

  type LocalStorageItem<K, P> = {
    key: K;
    payload: P;
  };

  type SetAuthStorageData = LocalStorageItem<
    LocalStorage.Keys.AUTH,
    {
      accessToken: string;
      expiresIn: string;
      user: Auth.Context.User;
      patient?: Auth.Context.Patient;
      hasMultiplePatients: boolean;
    }
  >;

  type SetCacheStorageData = LocalStorageItem<
    LocalStorage.Keys.CACHE,
    {
      ignore: boolean;
    }
  >;
}
