import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Button } from 'primereact/button';
import { useAuthContext, useLoaderContext, usePageContext } from '@/contexts';

export interface Summary {
  patient_id?: number;
  google_doc?: string;
  executive_summary?: string;
  medical_summary?: string;
  spikes?: string;
  trends?: string;
  message?: string; // error
}

export function ArtificialIntelligencePage() {
  const { setPageTitle } = usePageContext();
  const { startLoader, stopLoader } = useLoaderContext();
  const { patient, user } = useAuthContext();
  const [summary, setSummary] = useState<Summary>();
  const goMLUrl = 'https://ai-api.atria.org/summary';

  useEffect(() => {
    setPageTitle('Artificial Intelligence');
  }, [setPageTitle]);

  const getSummary = useCallback(async () => {
    try {
      startLoader();
      const { data } = await axios.post(goMLUrl, {
        patient_id: patient?.id,
        user_type: 'Patient',
        summary_type: 'Detailed',
        user_email: user!.email,
        default_email: 'gomlapi@atria.org',
      });
      setSummary(data);
    } finally {
      stopLoader();
    }
  }, [patient?.id, startLoader, stopLoader, user]);

  return (
    <div className='w-full'>
      <div className='flex justify-between'>
        <Button
          className='mb-5'
          label='Generate summary'
          size='small'
          type='submit'
          onClick={getSummary}
        />
        {summary?.google_doc && (
          <Button
            className='mb-5'
            icon='pi pi-external-link'
            label='Google Docs'
            size='small'
            type='submit'
            onClick={() => window.open(summary?.google_doc, '_blank')}
          />
        )}
      </div>
      {summary && (
        <div className='w-12/12 border-solid border-2 p-4 rounded-lg'>
          {summary?.message ? (
            <>{summary?.message}</>
          ) : (
            <>
              <div className='mb-8'>
                <div className='text-lg font-semibold'>Executive Summary</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{
                    __html: summary?.executive_summary || '-',
                  }}
                />
              </div>
              <div className='mb-8'>
                <div className='text-lg font-semibold'>Medical Summary</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{
                    __html: summary?.medical_summary || '-',
                  }}
                />
              </div>
              <div className='mb-8'>
                <div className='text-lg font-semibold'>Spikes</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{ __html: summary?.spikes || '-' }}
                />
              </div>
              <div>
                <div className='text-lg font-semibold'>Trends</div>
                <div
                  className='whitespace-pre-line'
                  dangerouslySetInnerHTML={{ __html: summary?.trends || '-' }}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
