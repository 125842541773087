import { cn } from '@lib-atria/ui-toolkit';

type Props = {
  title: string;
  className?: string;
  isVisible: boolean;
  note?: string;
  setIsVisible: (isVisible: boolean) => void;
  subtitle?: string;
};

export function DialogModalHeader({ title, className, note, isVisible, setIsVisible }: Props) {
  return (
    <div className='pb-6 pt-6 mb-4'>
      <div className={cn('sticky top-0 flex justify-between items-center z-10', className)}>
        <p className='text-4xl font-display'>{title}</p>
        <i className='pi pi-times cursor-pointer' onClick={() => setIsVisible(!isVisible)} />
      </div>
      {note && <p className='mt-3 opacity-50 text-[11px] leading-4'>{note}</p>}
    </div>
  );
}
