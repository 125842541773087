import { PodMember } from '@/@types';
import { cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  member: PodMember;
};
export function CareTeamMembers({ member }: Props) {
  return (
    <>
      <div className='grid md:grid-cols-3 grid-cols-1 '>
        <div className='flex flex-row items-center '>
          <div className='h-2 w-2 bg-fern rounded-full mr-[22px]' />
          <span className={cn(TextLayout.body1)}>{member.name}</span>
        </div>
        <p className={cn(TextLayout.body1, 'text-product-gray-400 ml-[30px] md:ml-0')}>
          {member.title}
        </p>
        <p className={cn(TextLayout.body1, 'text-product-gray-400 md:block hidden')}>
          {member.location}
        </p>
      </div>
      <div className='border-b border-b-product-sand-500 h-px' />
    </>
  );
}
