import { cn } from '@lib-atria/ui-toolkit';
import { cubicBezier, motion } from 'framer-motion';

import { SidebarType } from '@/@types';
import { SidebarVariants } from '../sidebar';
import { SidebarFooter } from './sidebarFooter';
import { SidebarList } from './sidebarList';
import { SidebarUserProfile } from './sidebarUserProfile';
import { useMemo } from 'react';

type Props = {
  variant?: SidebarVariants;
  items: SidebarType.Item[];
  isVisible: boolean;
  userName: string;
  isAdmin?: boolean;
  adminName?: string;
  prefix?: string;
  onNavigate: (url: string) => void;
  onSwitchMember?: VoidFunction;
  onSignOut: VoidFunction;
};

export function SidebarDesktop({
  variant,
  items,
  isVisible,
  userName,
  isAdmin = false,
  adminName,
  prefix,
  onSwitchMember,
  onSignOut,
  onNavigate,
}: Props) {
  const isAdminView = useMemo(() => prefix === '/admin', [prefix]);
  return (
    <motion.aside
      variants={{ open: { x: 0 }, closed: { x: -282 } }}
      transition={{ duration: 0.5, ease: cubicBezier(0.65, 0, 0.35, 1) }}
      animate={isVisible ? 'open' : 'closed'}
      className={cn(
        'fixed w-screen lg:w-[282px] h-screen max-h-screen z-30 flex flex-col items-center justify-between overflow-y-auto scrollbar-hidden',
        variant === 'forest' && 'bg-product-forest-100',
        variant === 'gray' && 'bg-white'
      )}
    >
      <div className='flex-1'>
        <SidebarUserProfile isAdminView={isAdminView} variant={variant} userName={userName} />
        <SidebarList
          variant={variant}
          isAdmin={isAdmin}
          items={items}
          onClick={onNavigate}
          prefix={prefix}
        />
      </div>
      <SidebarFooter
        variant={variant}
        isAdmin={isAdmin}
        adminName={adminName}
        onSwitchMember={onSwitchMember}
        onSignOut={onSignOut}
        onNavigate={onNavigate}
        isAdminView={isAdminView}
      />
    </motion.aside>
  );
}
