import { env } from '@/constants';
import { useAuthContext } from '@/contexts';
import { AuthHelper } from '@/helper';
import { AuthService } from '@/services';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

const oktaAuth = new OktaAuth({
  issuer: env.APP_OKTA_DOMAIN,
  clientId: env.APP_OKTA_CLIENT_ID,
  redirectUri: `${env.APP_MEMBER_PORTAL_FRONTEND_URL}/sign-in/okta/callback`,
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
});
export function OktaAuthContainer({ children }: { children: ReactNode }) {
  const navigate = useNavigate();
  const { setUser } = useAuthContext();

  async function restoreOriginalUri(_oktaAuth: any, originalUri: any) {
    const urlToGo = toRelativeUrl(originalUri || '/', window.location.pathname);
    const token = _oktaAuth.getAccessToken();
    if (!token) {
      navigate(urlToGo);
      return;
    }

    AuthService.getAccessTokenByOktaAccessToken(token)
      .then((res) => {
        setUser({
          email: '',
          id: '',
          name: '',
          isAdmin: true,
          permissions: (res?.data?.permissions || []).map(AuthHelper.convertToUserPermissionEnum),
        });

        navigate(urlToGo);
      })
      .catch(() => {
        _oktaAuth.clearStorage();
        setTimeout(() => {
          navigate(urlToGo);
        }, 10000);
      });
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
}
