import { SidebarType } from '@/@types';
import { SidebarMobileLogoutButton } from './SidebarMobileLogoutButton';
import { SidebarMobileListItem } from './sidebarMobileListItem';
import { SidebarMobileListSubItem } from './sidebarMobileListSubItem';
import { SidebarVariants } from '../sidebar';

type Props = {
  variant?: SidebarVariants;
  items: SidebarType.Item[];
  isAdmin?: boolean;
  adminName?: string;
  hasMultiplePatients?: boolean;
  onClickItem: (url: string) => void;
  onSignOut: VoidFunction;
  onSwitchMember?: VoidFunction;
};

export function SidebarMobileList({
  variant,
  items,
  isAdmin = false,
  adminName,
  hasMultiplePatients,
  onClickItem,
  onSignOut,
  onSwitchMember,
}: Props) {
  return (
    <div className='w-full max-h-full pt-[100px] pb-[200px] flex flex-col items-center justify-start gap-4 text-center overflow-y-scroll box-content scrollbar-hidden'>
      {items
        .filter((item) => (isAdmin || !item.adminOnly) && item.isToShow !== false)
        .map((item) => (
          <SidebarMobileListItem
            variant={variant}
            key={item.label}
            label={item.label}
            onClick={() => item.url && onClickItem(item.url)}
          >
            {!!item.items?.length && (
              <>
                {item.items.map((subItem) => (
                  <SidebarMobileListSubItem
                    key={subItem.label}
                    variant={variant}
                    label={subItem.label}
                    onClick={() => subItem.url && onClickItem(subItem.url)}
                  />
                ))}
              </>
            )}
          </SidebarMobileListItem>
        ))}

      <SidebarMobileLogoutButton
        variant={variant}
        isAdmin={isAdmin}
        adminName={adminName}
        hasMultiplePatients={hasMultiplePatients}
        onClick={onSignOut}
        onSwitchMember={onSwitchMember}
      />
    </div>
  );
}
