import jsPDF from 'jspdf';
import { useEffect, useState } from 'react';

import { AttachmentOptions, CustomPdfViewer } from '..';

type Attachment = { src: string; type: string };

type Props = {
  images: Attachment[];
  options?: AttachmentOptions;
};

export function GalleriaToPDF({ images, options }: Props) {
  const [output, setOutput] = useState<string>();

  useEffect(() => {
    async function init() {
      const doc = new jsPDF();
      const x = 0;
      const y = 0;
      const width = doc.internal.pageSize.getWidth();
      const height = doc.internal.pageSize.getHeight();
      const compression = 'NONE';
      let id = 1;
      for (const image of images) {
        const alias = `Image ${id}`;
        doc.addImage(image.src, 'png', x, y, width, height, alias, compression);
        if (id < images.length) {
          doc.addPage();
        }
        id++;
      }
      const pdfBinary = doc.output('bloburi');
      setOutput(pdfBinary.toString());
    }
    init();
  }, [images]);

  if (!output) return null;
  return (
    <div className='w-full min-w-full border border-gray-100 bg-white rounded-lg p-2 shadow-sm flex items-center justify-center'>
      <CustomPdfViewer fileUrl={output} fileName={options?.fileName} options={options} />
    </div>
  );
}
