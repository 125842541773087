import * as z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { ResourceCategory } from '@/@types';

export const UploadEditedResourceFormSchema = z.object({
  fileName: z.string({
    required_error: 'Please provide a resource name',
  }),
  file: z.instanceof(File).optional(),
  category: z.nativeEnum(ResourceCategory).optional(),
});

export type UploadEditedResourceFormType = z.infer<typeof UploadEditedResourceFormSchema>;
export const UploadEditedResourceFormSchemaResolver = zodResolver(UploadEditedResourceFormSchema);
