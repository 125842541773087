import { AppointmentsCardTitle } from './';

type Props = {
  children: React.ReactNode;
  title: string;
};

export function AppointmentsCardWrapper({ children, title }: Props) {
  return (
    <div className='flex w-full flex-col gap-4'>
      <div className='mx-auto flex w-full max-w-xs flex-col items-center justify-center space-y-3'>
        <AppointmentsCardTitle title={title} />
        {children}
      </div>
    </div>
  );
}
