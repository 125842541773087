import { useMediaQuery } from 'react-responsive';
import { Fragment } from 'react/jsx-runtime';
import { cn } from '@lib-atria/ui-toolkit';
import { TextLayout } from '../text';

type Props = {
  tableColumns: string[][];
};

export function CareTeamTable({ tableColumns }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 640px)' });
  return (
    <>
      {tableColumns.map((columnPair, index) => (
        <Fragment key={index}>
          <div
            className='grid py-[18px]'
            style={{
              gridTemplateColumns: `${isMobile ? 'repeat(1, minmax(0, 1fr))' : `repeat(${columnPair.length}, minmax(0, 1fr))`} `,
            }}
          >
            {columnPair.map((item, itemIndex) => (
              <div
                key={itemIndex}
                className={`${itemIndex === 0 ? 'flex flex-row items-baseline' : ''}`}
              >
                {itemIndex === 0 ? (
                  <>
                    <div className='h-2 w-2 bg-fern rounded-full mr-[22px]' />
                    <span
                      className={cn(TextLayout.body1, 'text-product-forest-100 md:max-w-[210px]')}
                    >
                      {item}
                    </span>
                  </>
                ) : (
                  <p
                    className={cn(
                      TextLayout.body1,
                      'text-product-gray-400 md:ml-0 ml-[30px] md:max-w-[210px]'
                    )}
                  >
                    {item}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div className='border-b border-b-product-sand-200 h-px w-full' />
        </Fragment>
      ))}
    </>
  );
}
