import { Button, cn } from '@lib-atria/ui-toolkit';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Patient } from '@/@types';
import { usePatients } from '@/hooks';
import { TextLayout } from '../text';
import { SearchPatientByNameType, searchPatientByNameResolver } from './searchPatientByNameSchema';

type SearchPatientByNameProps = {
  searchBy: 'firstName' | 'lastName';
  onSelect: (patients?: Patient.Type) => void;
};

export const SearchPatientByName = ({
  searchBy = 'firstName',
  onSelect,
}: SearchPatientByNameProps) => {
  const { isLoading, patientsOptions, setPatientsOptions, findAllPatients } = usePatients();
  const [patientSelected, setPatientSelected] = useState<number | null>();

  const {
    register,
    formState: { errors, dirtyFields, isSubmitted },
    handleSubmit,
  } = useForm<SearchPatientByNameType>({ resolver: searchPatientByNameResolver });

  const placeholder = useMemo(
    () => (searchBy === 'firstName' ? 'First name' : 'Last name'),
    [searchBy]
  );

  const isButtonDisabled = useMemo(() => {
    return isLoading || !dirtyFields.name || !!errors.name?.message?.length;
  }, [dirtyFields.name, errors.name?.message?.length, isLoading]);

  const noResultFound = useMemo(() => !isLoading && isSubmitted, [isLoading, isSubmitted]);

  const handlePatientSearch = useCallback(
    (values: SearchPatientByNameType) => {
      setPatientSelected(undefined);
      onSelect(undefined);
      setPatientsOptions([]);
      findAllPatients({ [searchBy]: values.name });
    },
    [findAllPatients, onSelect, searchBy, setPatientsOptions]
  );

  const handlePatientDropdown = useCallback(
    (event: DropdownChangeEvent) => {
      const patientId = event.target.value;
      setPatientSelected(patientId);
      const patient = patientsOptions.find((p) => p.id === patientId)!;
      onSelect(patient);
    },
    [onSelect, patientsOptions]
  );

  return (
    <div className='w-full flex flex-col gap-4'>
      <form
        className='w-full flex items-center justify-start gap-2'
        onSubmit={handleSubmit(handlePatientSearch)}
      >
        <InputText
          {...register('name')}
          placeholder={placeholder}
          className='w-full rounded-[42px] outline-none px-9 py-5'
        />
        <div className='w-full flex flex-1'>
          <Button
            type='submit'
            variant='secondary'
            icon='search'
            size='large'
            iconVisibility='mobile-only'
            textVisibility='desktop-only'
            label={isLoading ? 'Loading...' : 'Search'}
            disabled={isButtonDisabled}
          />
        </div>
      </form>
      {patientsOptions?.length > 0 ? (
        <Dropdown
          data-testid='patientDropdown'
          className='w-full rounded-[42px] outline-none px-9 py-2 hover:ring-0'
          options={patientsOptions}
          value={patientSelected}
          onChange={handlePatientDropdown}
          placeholder='Select a patient'
          optionLabel='patientName'
          optionValue='id'
          filter
        />
      ) : (
        noResultFound && (
          <p className={cn('text-red-600 text-center', TextLayout.body2)}>No result found</p>
        )
      )}
    </div>
  );
};
