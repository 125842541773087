import { useCallback } from 'react';
import CryptoJS from 'crypto-js';

export function useHandleToken() {
  const encodeToken = useCallback((data: object) => {
    return btoa(
      CryptoJS.DES.encrypt(
        JSON.stringify({ ...data, timestamp: new Date().getTime() }),
        'token'
      ).toString()
    );
  }, []);

  const isValidToken = useCallback((timestamp: number) => {
    const oneHour = 3600000;
    const timestampInHour = new Date().getTime() - timestamp / oneHour;
    const isValid = timestampInHour < 1;
    return isValid;
  }, []);

  const decodeToken = useCallback(
    (token: string) => {
      const decrypt = CryptoJS.DES.decrypt(atob(token), 'token');
      const payload = JSON.parse(decrypt.toString(CryptoJS.enc.Utf8));
      const isValid = isValidToken(payload.timestamp);
      return { ...payload, isValid };
    },
    [isValidToken]
  );

  return {
    encodeToken,
    decodeToken,
  };
}
