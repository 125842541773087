import {
  Appointment,
  Appointments,
  DateOfBirthValidationBody,
  GetPatientDocumentId,
  ImagingResult,
  Immunization,
  LabResult,
  LabResultDocument,
  MedicalRecords,
  Medications,
  Patient,
  PatientDocument,
  Provider,
  SignedUrlResponse,
} from '@/@types';
import { api } from '@/providers';

function findAllPatients(params: Patient.FindAllPatients.Params) {
  return api.get<Patient.FindAllPatients.Response>('/api/v1/patients', {
    params,
  });
}

function dateOfBirthValidation(body: DateOfBirthValidationBody) {
  return api.post<void>('/api/v1/patients/birth/validation', body);
}

function getPatientDocumentId(id: number, startDate: string, documentClass: string[]) {
  return api.get<GetPatientDocumentId>(`/api/v1/patients/${id}/chart/document-export`, {
    params: { startDate, documentClass },
  });
}

function getPatientChartByDocumentId(patientId: number, documentId: number) {
  return api.get<ArrayBuffer>(`/api/v1/patients/${patientId}/chart/document-export/${documentId}`, {
    responseType: 'arraybuffer',
  });
}

function getPatientAppointments(
  patientId: number,
  params: { startDate: string; endDate: string; atriaAppointment?: boolean }
) {
  return api.get<Appointment[]>(`/api/v1/patients/${patientId}/appointments`, { params });
}

function findAllPatientsAppointments(params: Appointments.FindAllPatientsAppointments.Params) {
  const { patientId, ...restParams } = params;
  return api.get<Appointments.FindAllPatientsAppointments.Response>(
    `/api/v1/member-portal/patients/${patientId}/appointments`,
    { params: restParams }
  );
}

function getPatientLabResults(patientId: number) {
  return api.get<LabResultDocument[]>(`/api/v1/patients/${patientId}/lab-results`);
}

function getPatientImagingResults(patientId: number) {
  return api.get(`/api/v1/patients/${patientId}/imaging-results`);
}

function getPatientImagingResultsDetailsAttachment(patientId: number, documentId: number) {
  return api.get<ArrayBuffer>(
    `/api/v1/patients/${patientId}/imaging-results/${documentId}/attachment`,
    {
      responseType: 'arraybuffer',
    }
  );
}

function getPatientImagingResultAttachmentByPageId(
  patientId: number,
  documentId: number,
  pageId: number
) {
  return api.get<ArrayBuffer>(
    `/api/v1/patients/${patientId}/imaging-results/${documentId}/attachment/pages/${pageId}`,
    {
      responseType: 'arraybuffer',
    }
  );
}

function getPatientLabResultAttachment(patientId: number, documentId: number) {
  return api.get<ArrayBuffer>(
    `/api/v1/patients/${patientId}/lab-results/${documentId}/attachment`,
    {
      responseType: 'arraybuffer',
    }
  );
}

function getPatientLabResultAnalytes(patientId: number, documentId: number) {
  return api.get<ArrayBuffer>(`/api/v1/patients/${patientId}/lab-results/${documentId}/analytes`, {
    responseType: 'arraybuffer',
  });
}

function getPatientLabResultAttachmentByPageId(
  patientId: number,
  documentId: number,
  pageId: number
) {
  return api.get<ArrayBuffer>(
    `/api/v1/patients/${patientId}/lab-results/${documentId}/attachment/pages/${pageId}`,
    {
      responseType: 'arraybuffer',
    }
  );
}

function getPatientLabResult(patientId: number, resultId: number) {
  return api.get<LabResult>(`/api/v1/patients/${patientId}/lab-results/${resultId}`);
}

function getPatientImagingResultsDetails(patientId: number, documentId: number) {
  return api.get<ImagingResult>(`/api/v1/patients/${patientId}/imaging-results/${documentId}`);
}

function generateSignedUrl(patientId: number, fileName: string) {
  return api.get<SignedUrlResponse>(`/api/v1/patients/${patientId}/documents/generate`, {
    params: { fileName },
  });
}

function downloadPatientLabResult(patientId: number, documentId: number) {
  return api.get<ArrayBuffer>(`/api/v1/patients/${patientId}/lab-results/${documentId}/download`, {
    responseType: 'arraybuffer',
  });
}

function createPatientDocument(
  patientId: number,
  fileName: string,
  fileUrl: string,
  date: string,
  favorite: boolean
) {
  return api.post<PatientDocument>(`/api/v1/patients/${patientId}/documents/`, {
    fileUrl,
    fileName,
    date,
    favorite,
  });
}

function deletePatientDocument(patientId: number, documentId: number) {
  return api.delete(`/api/v1/patients/${patientId}/documents/${documentId}`);
}

function deletePatientDocumentAttachment(patientId: number, documentId: number) {
  return api.delete(`/api/v1/patients/${patientId}/documents/${documentId}/attachment`);
}

function findPatientDocuments(patientId: number) {
  return api.get<PatientDocument[]>(`/api/v1/patients/${patientId}/documents`);
}

function findPatientDocument(patientId: number, documentId: number) {
  return api.get<PatientDocument>(`/api/v1/patients/${patientId}/documents/${documentId}`);
}

function findPatientDocumentAttachment(
  patientId: number,
  documentId: number,
  fileName: string,
  isAthenaDocument?: boolean
) {
  return api.get<ArrayBuffer>(`/api/v1/patients/${patientId}/documents/${documentId}/attachment`, {
    params: { fileName, isAthenaDocument },
    responseType: 'arraybuffer',
  });
}

function updatePatientDocument(
  patientId: number,
  documentId: number,
  fileName: string,
  fileUrl: string,
  date: string,
  favorite: boolean
) {
  return api.put<PatientDocument>(`/api/v1/patients/${patientId}/documents/${documentId}`, {
    fileUrl,
    fileName,
    date,
    favorite,
  });
}

function findPatientsMedicationsList(patientId: number) {
  return api.get<Medications.Response>(`/api/v1/patients/${patientId}/medications`);
}

function register(patientId: number, users: Patient.Register.Params) {
  return api.post<Patient.Register.Response>(`/api/v1/patients/${patientId}/users`, { users });
}

function registerContactCMO(params: Patient.ContactCMO.Params) {
  return api.post<Patient.ContactCMO.Response>(
    `/api/v1/patients/${params.patient.id}/contact-cmo`,
    params
  );
}

function getImmunizations(patientId: number) {
  return api.get<Immunization[]>(`/api/v1/patients/${patientId}/vaccines`);
}

function findPatientById(params: Patient.FindPatientById.Params) {
  return api.get<Patient.FindPatientById.Response>(`/api/v1/patients`, { params });
}

function findAllPatientMedicalRecords(patientId: number) {
  return api.get<MedicalRecords.FindAll.Response>(`/api/v1/patients/${patientId}/medical-records`);
}

function findPatientMedicalRecordsById(patientId: number, medicalRecordId: number) {
  return api.get<MedicalRecords.FindById.Response>(
    `/api/v1/patients/${patientId}/medical-records/${medicalRecordId}`
  );
}

function findPatientMedicalRecordAttachment(patientId: number, medicalRecordId: number) {
  return api.get<MedicalRecords.FindAttachmentById.Response>(
    `/api/v1/patients/${patientId}/medical-records/${medicalRecordId}/attachment`
  );
}
function findSpecialistsByPatient(patientId: number) {
  return api.get<Provider>(`/api/v1/patients/${patientId}/specialists`);
}

export const patientsService = {
  createPatientDocument,
  dateOfBirthValidation,
  deletePatientDocument,
  deletePatientDocumentAttachment,
  downloadPatientLabResult,
  findPatientDocument,
  findPatientDocumentAttachment,
  findPatientDocuments,
  findPatientsMedicationsList,
  getPatientAppointments,
  getPatientChartByDocumentId,
  getPatientDocumentId,
  getPatientLabResults,
  getPatientImagingResults,
  getPatientImagingResultsDetailsAttachment,
  getPatientImagingResultsDetails,
  getPatientImagingResultAttachmentByPageId,
  getPatientLabResult,
  getPatientLabResultAttachment,
  getPatientLabResultAnalytes,
  generateSignedUrl,
  findAllPatients,
  getPatientLabResultAttachmentByPageId,
  updatePatientDocument,
  register,
  registerContactCMO,
  getImmunizations,
  findPatientById,
  findAllPatientMedicalRecords,
  findPatientMedicalRecordsById,
  findPatientMedicalRecordAttachment,
  findSpecialistsByPatient,
  findAllPatientsAppointments,
};
