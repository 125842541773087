import { useCallback, useState } from 'react';
import { DateTime } from 'luxon';

import { patientsService } from '@/services';
import { MedicalRecords } from '@/@types';

export function useMedicalRecords() {
  const [medicalRecordDetail, setMedicalRecordDetail] =
    useState<MedicalRecords.FindById.Response | null>(null);
  const [medicalRecordAttachment, setMedicalRecordAttachment] =
    useState<MedicalRecords.FindAttachmentById.Response | null>(null);

  const findAllPatientMedicalRecords = useCallback(async (patientId: number) => {
    try {
      const { data } = await patientsService.findAllPatientMedicalRecords(patientId);
      return data.map((item) => ({
        ...item,
        createdDateTime: DateTime.fromISO(item.createdDateTime).toFormat('LLLL d, y'),
      }));
    } catch (error) {
      return [];
    }
  }, []);

  const findPatientMedicalRecordsById = useCallback(
    async (patientId: number, medicalRecordId: number) => {
      try {
        const { data } = await patientsService.findPatientMedicalRecordsById(
          patientId,
          medicalRecordId
        );
        setMedicalRecordDetail(data);
      } catch (error) {
        setMedicalRecordDetail(null);
      }
    },
    []
  );

  const findPatientMedicalRecordAttachment = useCallback(
    async (patientId: number, medicalRecordId: number) => {
      try {
        const { data } = await patientsService.findPatientMedicalRecordAttachment(
          patientId,
          medicalRecordId
        );
        setMedicalRecordAttachment(data);
      } catch (error) {
        setMedicalRecordAttachment(null);
      }
    },
    []
  );

  return {
    medicalRecordDetail,
    medicalRecordAttachment,
    findAllPatientMedicalRecords,
    findPatientMedicalRecordsById,
    findPatientMedicalRecordAttachment,
  };
}
