import { AnimatePresence, Variants, motion } from 'framer-motion';
import { useMemo } from 'react';

import { useLoaderContext } from '@/contexts';

export function Loader() {
  const { isLoaderComponentVisible, isLoading, label } = useLoaderContext();

  const isVisible = useMemo(
    () => isLoaderComponentVisible && isLoading,
    [isLoaderComponentVisible, isLoading]
  );

  const animationsVariant: Variants = {
    visible: { opacity: 1 },
    invisible: { opacity: 0, pointerEvents: 'none' },
  };

  return (
    <AnimatePresence>
      <motion.div
        data-testid='loaderBlurBackground'
        initial={{ opacity: 0, pointerEvents: 'none' }}
        variants={animationsVariant}
        animate={isVisible ? 'visible' : 'invisible'}
        transition={{ damping: 40 }}
        className={
          'fixed w-screen h-screen overflow-hidden inset-0 flex items-center justify-center bg-white/5 backdrop-blur-xl z-[999] transition-opacity duration-300'
        }
      >
        <p className='w-full min-w-[500px] flex flex-col items-center justify-center gap-2 text-lg text-gray-2 text-center'>
          <i className='pi pi-spinner text-4xl animate-spin text-fern'></i>
          {label}
        </p>
      </motion.div>
    </AnimatePresence>
  );
}
