import { Variants, motion } from 'framer-motion';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';
import { BasicCard } from '..';

const container: Variants = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      stiffness: 50,
      staggerChildren: 0.1,
    },
  },
};

const itemVariant: Variants = {
  show: { opacity: 1 },
  hidden: { opacity: 0 },
};

export function CareTeamLoading() {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  return (
    <motion.div
      initial='hidden'
      animate='show'
      exit='hidden'
      variants={container}
      className='grid grid-cols-1 justify-start gap-6 '
    >
      <motion.div variants={itemVariant}>
        <BasicCard className='shadow-none bg-[#eeece5] rounded-xl px-5'>
          <ContentLoader
            speed={2}
            width='100%'
            height={isMobile ? 560 : 400}
            viewBox={isMobile ? '0 0 300 560' : '0 0 600 250'}
            backgroundColor='#ccc'
            foregroundColor='#ddd'
          >
            {isMobile ? (
              <>
                <rect x='0%' y='0' rx='3' ry='3' width='100%' height='160' />
                <rect x='0%' y='200' rx='3' ry='3' width='90%' height='20' />
                <rect x='0%' y='230' rx='3' ry='3' width='75%' height='20' />
                <rect x='0%' y='50%' rx='3' ry='3' width='100%' height='15' />
                <rect x='0%' y='55%' rx='3' ry='3' width='100%' height='15' />
                <rect x='0%' y='60%' rx='3' ry='3' width='100%' height='15' />
                <rect x='0%' y='65%' rx='3' ry='3' width='100%' height='15' />
                <rect x='0%' y='70%' rx='3' ry='3' width='100%' height='15' />
                <rect x='0%' y='75%' rx='3' ry='3' width='100%' height='15' />
                <rect x='0' y='95%' rx='3' ry='3' width='25%' height='10' />
                <rect x='35%' y='95%' rx='3' ry='3' width='25%' height='10' />
              </>
            ) : (
              <>
                <rect x='0' y='0' rx='3' ry='3' width='50%' height='20' />
                <rect x='0' y='10%' rx='3' ry='3' width='30%' height='20' />
                <rect x='0' y='35%' rx='3' ry='3' width='50%' height='10' />
                <rect x='0' y='45%' rx='3' ry='3' width='50%' height='10' />

                <rect x='0' y='55%' rx='3' ry='3' width='50%' height='10' />
                <rect x='0' y='65%' rx='3' ry='3' width='50%' height='10' />
                <rect x='0' y='90%' rx='3' ry='3' width='5%' height='10' />
                <rect x='10%' y='90%' rx='3' ry='3' width='5%' height='10' />
                <rect x='55%' y='0' rx='3' ry='3' width='50%' height='100%' />
              </>
            )}
          </ContentLoader>
        </BasicCard>
      </motion.div>
    </motion.div>
  );
}
