import { DateTime } from 'luxon';

function firstLetterUppercase(str: string): string {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function removeSpecialChars(str: string) {
  return String(str)
    .toLowerCase()
    .trim()
    .replace(/[^\w\s]+/g, '');
}

function compare(a: string, b: string) {
  return removeSpecialChars(a).localeCompare(removeSpecialChars(b));
}

function sort(a: string, b: string, ordering: 'asc' | 'desc') {
  if (!Number.isNaN(new Date(a).getTime())) {
    if (ordering === 'asc') {
      return (
        DateTime.fromJSDate(new Date(a)).toMillis() - DateTime.fromJSDate(new Date(b)).toMillis()
      );
    }
    return (
      DateTime.fromJSDate(new Date(b)).toMillis() - DateTime.fromJSDate(new Date(a)).toMillis()
    );
  }
  if (!Number.isNaN(Number(b))) {
    if (ordering === 'asc') {
      return Number(a) - Number(b);
    }
    return Number(b) - Number(a);
  }
  if (typeof a === 'string') {
    if (ordering === 'asc') {
      return compare(a, b);
    }
    return compare(b, a);
  }
  return 0;
}

function generateRandomString(size: number) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';

  for (let i = 0; i < size; i++) {
    const randomIndex = Math.floor(Math.random() * chars.length);
    result += chars.charAt(randomIndex);
  }

  return result;
}

function jaccardSimilarity(str1: string, str2: string) {
  const set1 = new Set(str1);
  const set2 = new Set(str2);

  const intersectionSize = [...set1].filter((char) => set2.has(char)).length;
  const unionSize = set1.size + set2.size - intersectionSize;

  return intersectionSize / unionSize;
}

function similarityPercentage(str1: string, str2: string) {
  const similarity = jaccardSimilarity(str1, str2);
  const percentage = similarity * 100;
  return percentage;
}

export const StringHelper = {
  firstLetterUppercase,
  generateRandomString,
  removeSpecialChars,
  compare,
  sort,
  similarityPercentage,
};
