import { TextLayout } from '@/components';
import { cn } from '@lib-atria/ui-toolkit';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useMemo } from 'react';

type Props = {
  selectedOption: string;
  options: Option[];
  onSelect: (option: string) => void;
};

type Option = {
  label: string;
  value: string;
  component: React.FC;
};

export function DinamicSwitchContent({ selectedOption, options, onSelect }: Props) {
  const hasSelectedOption = useCallback(
    (option: string) =>
      selectedOption === option ? 'text-product-forest-100' : 'text-product-gray-400',
    [selectedOption]
  );

  return (
    <div className='md:mt-[100px] mt-[64px]'>
      <div className='flex space-x-[40px] cursor-pointer pb-8'>
        {options.map(({ label, value }) => (
          <h1
            key={value}
            className={cn(TextLayout.callout2, hasSelectedOption(value))}
            onClick={() => onSelect(value)}
          >
            {label}
          </h1>
        ))}
      </div>
      <AnimatePresence mode='wait'>
        <SelectedOption selectedOption={selectedOption} options={options} />
      </AnimatePresence>
    </div>
  );
}

const SelectedOption = ({
  selectedOption,
  options,
}: {
  selectedOption: string;
  options: Option[];
}) => {
  const Component = useMemo(() => {
    const componentMap = new Map(options.map((option) => [option.value, option.component]));
    return componentMap.get(selectedOption) || null;
  }, [options, selectedOption]);

  return <InitAnimation id={selectedOption}> {Component && <Component />}</InitAnimation>;
};

const InitAnimation = ({ children, id }: { children: React.ReactNode; id: string | number }) => {
  return (
    <motion.div
      key={id}
      initial={{ y: -20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 1.2, ease: [0.6, -0.05, 0.01, 0.99] }}
    >
      {children}
    </motion.div>
  );
};
