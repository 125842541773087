import { Toast } from 'primereact/toast';
import { MutableRefObject, ReactNode, createContext, useContext, useRef } from 'react';

type ToastContextProps = {
  toast?: MutableRefObject<Toast>;
};

const ToastContext = createContext<ToastContextProps>({ toast: undefined });

export function ToastProvider({ children }: { children: ReactNode }) {
  const ref = useRef<Toast>(undefined!);

  return (
    <ToastContext.Provider value={{ toast: ref }}>
      <Toast ref={ref} />
      {children}
    </ToastContext.Provider>
  );
}

export const useToastContext = () => useContext(ToastContext);
