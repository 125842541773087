export const TRACK_EVENTS = {
  CONTACT_CARE_BUTTON: 'Contact care team button clicked',
  CONTACT_CARE_TEAM_SENT: 'Contact care team message sent',
  REQUEST_APPOINTMENT_BUTTON_CLICKED: 'Request appointment button clicked',
  REQUEST_APPOINTMENT_BUTTON_SENT: 'Request appointment button sent',
  REQUEST_REFILL_BUTTON_CLICKED: 'Request a refill button clicked',
  REQUEST_REFILL_BUTTON_SENT: 'Request a refill button sent',
  PREVIOUS_APPOINTMENT_LETTER_VIEWED: 'Previous appointment letter viewed',
  LAB_RESULT_DETAILS_VIEWED: 'Lab result details viewed',
  LAB_RESULT_DOWNLOADED: 'Lab result downloaded',
  IMAGING_RESULT_DETAILS_VIEWED: 'Imaging result details viewed',
  IMAGING_RESULTS_DOWNLOADED: 'Imaging result downloaded',
  IMAGING_AMBRA_LINK_CLICKED: 'Imaging Ambra link clicked',
  DOCUMENT_DETAILS_VIEWED: 'Document details viewed',
  RESOURCE_VIEWED: 'Resource viewed',
  RESOURCE_DOWNLOADED: 'Resource downloaded',
  ALL_CARE_TEAM_PAGE_VIEWED: 'All care team page viewed',
  YOUR_CARE_TEAM_PAGE_VIEWED: 'Your care team page viewed',
  DOCUMENTS_PAGE_VIEWED: 'Documents page viewed',
  HOME_PAGE_VIEWED: 'Home page viewed',
  IMAGING_RESULTS_PAGE_VIEWED: 'Imaging Results page viewed',
  LAB_RESULTS_PAGE_VIEWED: 'Lab Results page viewed',
  MEDICATIONS_PAGE_VIEWED: 'Medications page viewed',
  PREVIOUS_APPOINTMENT_PAGE_VIEWED: 'Previous appointments page viewed',
  UPCOMING_APPOINTMENTS_PAGE_VIEWED: 'Upcoming appointments page viewed',
  RESOURCES_PAGE_VIEWED: 'Resources page viewed',
};

const parentPageMap = new Map<string, string>([
  ['/', 'home'],
  ['/appointments/previous', 'past appointments'],
  ['/appointments/upcoming', 'upcoming appointments'],
  ['/results/lab', 'lab results'],
  ['/results/imaging', 'imaging results'],
  ['/documents', 'documents'],
  ['/medications', 'medications'],
  ['/resources', 'resources'],
  ['/care-team', 'care team'],
]);

const detailsMap = new Map<string, string>([
  ['/results/lab', 'lab result details'],
  ['/results/imaging', 'imaging result details'],
  ['/documents', 'document details'],
]);

export const getAnalyticsParentPage = (key: string) => {
  const parentKey = parentPageMap.get(key);
  if (parentKey) {
    return parentKey;
  }

  return Array.from(detailsMap).find(([detailKey]) => key.includes(detailKey))?.[1];
};
