import { Button, cn, TextLayout } from '@lib-atria/ui-toolkit';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { useCallback, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { useUsers } from '@/hooks';
import { searchUserByEmailResolver, SearchUserByEmailType } from './searchUserByEmailSchema';

export namespace SearchUserByEmailType {
  export type OnSearchUserOptions = { found: true } | { found: false; email: string };

  export type OnSelectUser = {
    name: string;
    firstName: string;
    lastName: string;
    mrn?: number;
    email: string;
    registrationStatus: string;
    cmoEmail?: string;
    lastLogin?: string;
    loginsCount: number;
    createdAt: string;
    id: string;
    lastResendInvite?: string;
    resendInviteCount: number;
    patients: Array<{
      relation: string;
      permissions: Array<string>;
      patientId: number;
    }>;
  };

  export type Props = {
    onSelect: (selectedUser: OnSelectUser) => void;
    onSearch?: (options: OnSearchUserOptions) => void;
  };
}

export function SearchUserByEmail({ onSelect, onSearch }: SearchUserByEmailType.Props) {
  const {
    formState: { isSubmitted, errors },
    control,
    handleSubmit,
  } = useForm<SearchUserByEmailType>({ resolver: searchUserByEmailResolver });
  const { users, findAllUsers } = useUsers();

  const [userSelected, setUserSelected] = useState<
    SearchUserByEmailType.OnSelectUser | undefined
  >();

  const onSubmit = useCallback(
    async (values: SearchUserByEmailType) => {
      setUserSelected(undefined);
      const found = await findAllUsers({ email: values.email });
      if (onSearch) {
        onSearch({ found: found.length > 0, email: values.email });
      }
    },
    [findAllUsers, onSearch]
  );

  const handleSelectUser = useCallback(
    (userId: string) => {
      const user = users.find((u) => u.id === userId)!;
      setUserSelected(user);
      onSelect(user);
    },
    [onSelect, users]
  );

  const hasErrors = useMemo(() => Object.values(errors).length, [errors]);

  const noResultFound = useMemo(
    () => users.length === 0 && isSubmitted && !hasErrors,
    [hasErrors, isSubmitted, users.length]
  );

  return (
    <div className='w-full flex flex-col gap-2'>
      <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
        <div className='w-full flex flex-col'>
          <div className='w-full flex gap-2 mb-2'>
            <Controller
              control={control}
              name='email'
              render={({ field }) => (
                <InputText
                  name='name'
                  className={cn('w-full rounded-[42px] outline-none px-9 py-5', {
                    'border-red': noResultFound,
                  })}
                  onChange={(e) => field.onChange(e.target.value)}
                  value={field.value}
                  placeholder='Search by email'
                />
              )}
            />
            <Button type='submit' icon='search' textVisibility='invisible' />
          </div>
          {errors?.email?.message && (
            <p className={cn(TextLayout.body3, 'text-rust pl-5')}>{errors.email.message}</p>
          )}
        </div>
        {noResultFound && <p className={cn(TextLayout.body3, 'text-rust pl-5')}>No users found</p>}
      </form>

      {users.length > 0 && (
        <Dropdown
          className='w-full rounded-[42px] outline-none px-9 py-2 hover:ring-0 mb-2'
          placeholder='Select an user'
          options={users.map((u) => ({ label: u.email, value: u.id }))}
          value={userSelected?.id}
          onChange={(option) => handleSelectUser(option.value)}
        />
      )}
    </div>
  );
}
