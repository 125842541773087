import { cn, TextLayout } from '@lib-atria/ui-toolkit';
import { motion } from 'framer-motion';
import { DateTime } from 'luxon';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';

import { Appointments } from '@/@types';
import { DomHelper } from '@/helper';
import { AppointmentsCardDateSidebar } from '../appointmentsCard';
import { AtriaIcon } from '../icons';

export type UpcomingAppointmentsSidebarListRef = {
  setKey: (key: string) => void;
};

type Props = {
  items: Appointments.SidebarList.Item[];
  onClick: (sidebarItem: Appointments.SidebarList.Item) => void;
};

export const AppointmentsSidebarList = forwardRef<UpcomingAppointmentsSidebarListRef, Props>(
  function AppointmentsSidebarList({ items, onClick }, ref) {
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const itemsRef = useRef<Array<HTMLDivElement>>([]);

    const [selectedKey, setSelectedKey] = useState<string | undefined>();

    const years = useMemo(() => [...new Set(items.map((i) => getYear(i.date)))], [items]);
    const shouldShowYear = useMemo(() => years.length > 1, [years.length]);

    const scrollToItem = useCallback(() => {
      const index = items.findIndex((i) => i.key === selectedKey);
      const element = itemsRef.current[index];
      if (!element) {
        return;
      }
      const isVisible = DomHelper.isVisibleInViewport(element);
      if (!isVisible) {
        itemsRef.current[index]?.scrollIntoView({ behavior: 'instant', inline: 'center' });
      }
    }, [items, selectedKey]);

    useEffect(() => {
      scrollToItem();
    }, [scrollToItem]);

    useImperativeHandle(ref, () => ({ setKey: setSelectedKey }), []);

    if (isMobile) {
      return (
        <div className='z-10 fixed left-0 bottom-0 flex flex-row items-center justify-start overflow-x-auto scrollbar-hidden bg-product-sand-200 p-4 w-full gap-6 px-6'>
          {items?.map((sidebarItem, index) => {
            const initialSelection = index === 0 && selectedKey === undefined;
            const selected = initialSelection ? true : selectedKey === sidebarItem.key;
            return (
              <div key={sidebarItem.key} ref={(el) => el && (itemsRef.current[index] = el)}>
                <AppointmentsCardDateSidebar
                  date={sidebarItem.date}
                  onSelect={() => onClick(sidebarItem)}
                  selected={selected}
                />
              </div>
            );
          })}
        </div>
      );
    }

    let aux: string | undefined;
    return (
      <div className='-order-1 md:order-1 block'>
        <motion.div
          variants={{
            hidden: { opacity: 0 },
            show: { opacity: 1 },
          }}
          initial='hidden'
          animate='show'
          className='sticky top-[48px] flex flex-row md:flex-col items-center justify-start gap-[7px] h-screen max-h-[85vh] overflow-y-scroll scrollbar-hidden'
        >
          {items?.map((sidebarItem, index) => {
            const initialSelection = index === 0 && selectedKey === undefined;
            const selected = initialSelection ? true : selectedKey === sidebarItem.key;
            const year = getYear(sidebarItem.date);
            const newYear = aux !== year;
            if (index === 0 || aux !== year) {
              aux = year;
            }

            return (
              <motion.div
                ref={(el) => el && (itemsRef.current[index] = el)}
                variants={{
                  hidden: { translateY: -10, opacity: 0 },
                  show: { translateY: 0, opacity: 1, transition: { delay: 0.05 * index } },
                }}
                key={sidebarItem.key}
                className='flex flex-col gap-[6.23px] items-center'
              >
                {shouldShowYear && newYear && (
                  <p className={cn(TextLayout.body3, 'text-product-gray-400')}>{aux}</p>
                )}
                <AppointmentsCardDateSidebar
                  date={sidebarItem.date}
                  onSelect={() => onClick(sidebarItem)}
                  selected={selected}
                />
              </motion.div>
            );
          })}
          <div className='w-full flex justify-center max-w-[68px] mb-3'>
            <AtriaIcon className='mx-0 h-[50px] w-[25px]' fill='#CBC6BD' />
          </div>
        </motion.div>
      </div>
    );
  }
);

function getYear(date: string) {
  return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('yyyy');
}
