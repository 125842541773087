import { Button } from '@lib-atria/ui-toolkit';
import { AnimatePresence } from 'framer-motion';
import { useCallback, useState } from 'react';
import ContentLoader from 'react-content-loader';
import { useMediaQuery } from 'react-responsive';

import { useContactDialogContext } from '@/contexts';
import { CopyToClipboard } from '../_common';
import { DialogMobile } from '../_common/dialogMobile';
import { CustomTooltip } from '../tooltip';

interface Props {
  location: {
    title: string;
    image: string;
    street: string;
    complement?: string;
    city: string;
    state: string;
    zipCode: string;
    phoneNumber: string;
    notes: {
      image?: string;
      description: string;
    };
    email: string;
  };
}

export function AddressCard({ location }: Props) {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { isContactDialogVisible, setContactDialogVisibility, setContactDialogTopic } =
    useContactDialogContext();

  const handleOpenDialog = useCallback(() => {
    setIsDialogOpen(true);
  }, []);

  const handleSendEmail = useCallback(() => {
    setContactDialogTopic(location.title);
    setContactDialogVisibility(!isContactDialogVisible);
  }, [isContactDialogVisible, setContactDialogTopic, setContactDialogVisibility, location.title]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
  };

  return (
    <div className='w-full rounded-xl bg-product-sand-300 font-display '>
      <img className='w-full rounded-t-lg' src={location.image} alt='Sunset in the mountains' />
      <div className='px-5 md:px-8 pb-5 pt-6 text-kelp bg-product-sand'>
        <h2 className='font-medium md:text-[32px] text-2xl mb-4'>{location.title}</h2>
        <div className='flex flex-col font-medium text-sm'>
          <span>{location.street}</span>
          {location.complement && <p>{location.complement}</p>}
          <CopyToClipboard
            copyText={`${location.street}${location?.complement ? `, ${location.complement},` : ''} ${location.city}, ${location.state} ${location.zipCode}`}
          >
            <div className='flex flex-col justify-end'>
              {location.city}, {location.state} {location.zipCode}
            </div>
          </CopyToClipboard>
          <CopyToClipboard copyText={location.phoneNumber.replace(/\./g, ' ')}>
            {location.phoneNumber}
          </CopyToClipboard>

          {isMobile ? (
            <span className='underline cursor-pointer not-italic' onClick={handleOpenDialog}>
              Parking notes
            </span>
          ) : (
            <CustomTooltip
              content={
                <div className='px-8 py-6 rounded-lg bg-experimental-forest text-white flex space-x-4 items-center max-w-[300px]'>
                  {!imageLoaded && location.notes.image && (
                    <ContentLoader
                      speed={2}
                      width='100%'
                      height={100}
                      viewBox={'0 0 100 100'}
                      className='rounded'
                      backgroundColor='#ccc'
                      foregroundColor='#ddd'
                    >
                      <rect x='0%' y='0%' rx='3' ry='3' width='100%' height='100' />
                    </ContentLoader>
                  )}
                  {location.notes.image && (
                    <img
                      src={location.notes.image}
                      alt=''
                      className={`w-1/3 rounded ${!imageLoaded ? 'hidden' : ''}`}
                      onLoad={handleImageLoaded}
                    />
                  )}
                  <p className='text-sm'>{location.notes.description}</p>
                </div>
              }
            >
              <span className='underline cursor-pointer not-italic'>Parking notes</span>
            </CustomTooltip>
          )}
          <AnimatePresence>
            {isDialogOpen && (
              <DialogMobile
                item={
                  <div className='flex space-x-5 items-center pb-6'>
                    <p>{location.notes.description}</p>
                  </div>
                }
                handleOnClose={() => {
                  setIsDialogOpen(false);
                }}
              />
            )}
          </AnimatePresence>
        </div>
        <div className='mb-3 mt-8'>
          <Button variant='secondary' size='medium' label='Contact' onClick={handleSendEmail} />
        </div>
      </div>
    </div>
  );
}
