import classNames from 'classnames';

type Props = React.SVGProps<SVGSVGElement>;

export function CopyIcon({
  width = 24,
  height = 25,
  fill = '#646464',
  className,
  ...props
}: Props) {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 12 14'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M8 12.3438C8.375 12.3438 8.65625 12.625 8.65625 13C8.65625 13.375 8.375 13.6562 8 13.6562H1.34375C0.59375 13.6562 0 13.0625 0 12.3438V3.65625C0 3.3125 0.3125 3 0.65625 3C1.03125 3 1.34375 3.3125 1.34375 3.65625V12.3438H8ZM11.3438 9.65625C11.3438 10.4062 10.7188 11 10 11H4C3.28125 11 2.65625 10.4062 2.65625 9.65625V1.65625C2.65625 0.9375 3.28125 0.34375 4 0.34375H10C10.7188 0.34375 11.3438 0.9375 11.3438 1.65625V9.65625ZM10 9.65625V1.65625H4V9.65625H10Z'
        fill={fill}
        className={classNames([className, 'fill-current'])}
      />
    </svg>
  );
}
